import { useAppState } from 'js/hooks/app-state';
import { getCuratedSavedLocationFeatures, getSavedLocationFeatures } from 'js/utils/map-data-utils';
import React, { memo, useEffect, useState } from 'react';
import ContentDrawer, { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

function SavedLocations() {
	const [{ savedLocations, curatedFeatures, poiStateData }] = useAppState();
	const [savedFeatures, setSavedFeatures] = useState([]);

	useEffect(() => {
		if (savedLocations && poiStateData && curatedFeatures) {
			const features1 = getSavedLocationFeatures(savedLocations, poiStateData);
			const features2 = getCuratedSavedLocationFeatures(savedLocations, curatedFeatures);

			// sort in order they were added
			const unsortedFeatures = [...features1, ...features2];
			const finalFeatures = [];
			savedLocations?.forEach((locationId) => {
				const feature = unsortedFeatures.filter((dataItem) => dataItem?.p?.gid === locationId || dataItem?.properties?.id === locationId)[0];
				finalFeatures.push(feature);
			});

			// Debug.log('saved ids:', savedLocations);
			// Debug.log('saved features:', finalFeatures);

			setSavedFeatures(finalFeatures);
		}
	}, [savedLocations, poiStateData, curatedFeatures]);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | My Saved Places</title>
				<meta name="description" content="See a piece of The Culture you'd like to visit? Save it and email it straight to your inbox to help plan your next trip." />
			</HelmetWrapper>
			<ContentDrawer contentType={CONTENT_TYPES.SAVED_LOCATIONS} content={savedFeatures} />
		</>
	);
}

export default memo(SavedLocations);
