import { FORCE_LOGS, IS_DEVELOPMENT } from '../config/constants';
import { getParameterByName, noop } from './utils';

// if used - console logs will be stripped out in production
function _Debug() {
	const debug = {};

	const enabled = IS_DEVELOPMENT || FORCE_LOGS || getParameterByName('logs');

	if (enabled) {
		for (let prop in console) {
			if (typeof console[prop] === 'function') {
				debug[prop] = console[prop];
			}
		}
	} else {
		for (let prop in console) {
			if (typeof console[prop] === 'function') {
				debug[prop] = noop;
			}
		}
	}

	return debug;
}

const Debug = new _Debug();

export { Debug };
