import React from 'react';
import { getStateCode, getStateName } from 'js/utils/map-data-utils';
import { motion } from 'framer-motion';
import { useAppState } from 'js/hooks/app-state';
import CaseStudyController from 'js/controllers/case-study-controller';

import './CurrentLocation.scss';

function CurrentLocation(props) {
	// if standard city is open use that value instead so they match
	const [{ currentLocationOverride, caseStudyControls }] = useAppState();

	if (!props?.currentLocation || (caseStudyControls && !CaseStudyController.instance?.uiOn)) return null;

	const { type = 'city' } = props;

	const renderCity = (props) => {
		const city = currentLocationOverride?.city || props?.currentLocation?.city;
		const state = currentLocationOverride?.state || props?.currentLocation?.state;
		const stateCode = getStateCode(state) || state;

		return (
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="CurrentLocation">
				<div className="location">
					{city}, {stateCode}
				</div>
				<div className="divider">
					<div className="left" />
					<div className="middle" />
					<div className="right" />
				</div>
			</motion.div>
		);
	};

	const renderState = (props) => {
		const {
			currentLocation: { state },
		} = props;

		let stateStr = state.length === 2 ? getStateName(state) : state;

		return (
			<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="CurrentLocation">
				<div className="location">{stateStr}</div>
				<div className="divider">
					<div className="left" />
					<div className="middle" />
					<div className="right" />
				</div>
			</motion.div>
		);
	};

	return type === 'city' ? renderCity(props) : renderState(props);
}

export default CurrentLocation;
