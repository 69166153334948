import React from 'react';
import ContentDrawer, { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

function SocialNational() {
	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Travel Feed</title>
				<meta name="description" content="See what other Black Travelers are saying across the globe on the Black Elevation Map." />
			</HelmetWrapper>
			<ContentDrawer contentType={CONTENT_TYPES.SOCIAL_NATIONAL} />
		</>
	);
}

export default SocialNational;
