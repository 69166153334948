import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
  EASE_OUT,
} from 'js/utils/motion';
import iconQuote from '../../../../../images/icons/quote.svg';

import './AboutQuote.scss';

const containerMotion = {
  initial: { 
    opacity: 0 
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.04,
      delayChildren: 0.1,
      ease: EASE_OUT,
    }
  },
  exit: { 
    opacity: 0 
  }
};

function AboutQuote({ content }) {
  const { text, attrib, animOnce } = content;

  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <motion.div 
      className="AboutQuote"
      initial="initial"
      animate={anim}
      variants={containerMotion}
      ref={ref}
    >
      <motion.div variants={containerMotion} className="quote-icon">
        <motion.span 
          variants={MOTION_VARIANTS}
          custom={MOTION_PRESETS.SCALE_FROM_LEFT}
        />
        <motion.img 
          src={iconQuote}
          variants={MOTION_VARIANTS}
          custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
          aria-hidden="true"
        />
        <motion.span 
          variants={MOTION_VARIANTS}
          custom={MOTION_PRESETS.SCALE_FROM_LEFT}
        />
      </motion.div>
      
      <motion.div 
        className="quote-text"
        variants={containerMotion}
      >
        { text.split(' ').map((d,i) => {
          return (
            <span key={i}>
              <motion.span
                dangerouslySetInnerHTML={{__html:d}} 
                variants={MOTION_VARIANTS}
                custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
              />
              <span dangerouslySetInnerHTML={{__html:`&#160;`}}></span>
            </span>
          )
        })}
      </motion.div>
      
      <motion.div 
        className="quote-attrib"
        variants={MOTION_VARIANTS}
        custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
      >
        {attrib}
      </motion.div>
    </motion.div>
  )
}

export default AboutQuote;