// import { mapProps } from 'js/components/Map/Map';
import MarkerSet from 'js/entities/marker-set';
import { deleteAllProperties } from 'js/utils/utils';

export default class MarkerController {
	constructor(props) {
		this.props = props;
		this.init();
	}

	init() {
		const { markerSetConfig, conditionValues } = this.props;

		this.markerSets = {};
		this.markerSetsArray = [];
		this.markersCanBeActive = true;

		for (const [key, value] of Object.entries(markerSetConfig)) {
			this.markerSets[key] = new MarkerSet({
				id: key,
				...value,
				// deepLinkData, // adds double marker now, don't use
				conditionValues,
			});

			this.markerSetsArray.push(this.markerSets[key]);
		}
	}

	getDataForBusinessId(businessId) {
		let data;

		for (let i = 0; i < this.markerSetsArray.length; i++) {
			const markerSet = this.markerSetsArray[i];
			data = markerSet.getDataForBusinessId(businessId);
			if (data) break;
		}

		return data;
	}

	getMarkerForBusinessId(businessId) {
		let marker;
		this.markerSetsArray?.forEach((markerSet) => {
			if (markerSet.visible && markerSet.enabled) {
				marker = markerSet.getMarkerForBusinessId(businessId);
				if (marker) return marker;
			}
		});
	}

	setEnabled(enabled = true) {
		this.markerSetsArray?.forEach((markerSet) => markerSet?.setEnabled(enabled));
	}

	setConditionValues(conditions) {
		this.markerSetsArray?.forEach((markerSet) => markerSet?.setConditionValues(conditions));
	}

	setMarkerActive(businessId) {
		// Debug.warn('setMarkerActive:', businessId);

		// don't allow markers to be set active if the route doesn't allow it
		if (businessId && !this.markersCanBeActive) return;

		let result;
		this.markerSetsArray?.forEach((markerSet) => {
			result = markerSet.setMarkerActive(businessId);
			if (result) return;
		});
	}

	setMarkerAsSaved(businessId, saved = true) {
		this.markerSetsArray?.forEach((markerSet) => {
			markerSet.setMarkerAsSaved(businessId, saved);
		});
	}

	setMarkersCanBeActive(value) {
		// Debug.warn('setMarkersCanBeActive:', value);
		this.markersCanBeActive = value;

		if (!this.markersCanBeActive) {
			this.setMarkerActive(null);
		}
	}

	// if a location is selected but the zoom level still has the marker
	// inside a cluster zoom down to the level needed to expand the cluster
	expandClusterIfActiveMarkerIsInside(businessId, zoomOverride, setActiveAfter) {
		const promises = [];

		this.markerSetsArray?.forEach((markerSet) => promises.push(markerSet?.expandClusterIfActiveMarkerIsInside(businessId, zoomOverride, setActiveAfter)));

		return Promise.all(promises);
	}

	triggerManualUpdate() {
		this.markerSetsArray?.forEach((markerSet) => {
			markerSet?.update(true);
			markerSet?.updateNonCluster(true);
		});
	}

	checkActiveAndSavedMarkers() {
		this.markerSetsArray?.forEach((markerSet) => {
			markerSet?.checkActiveAndSavedMarkers();
		});
	}

	dispose() {
		this.markerSetsArray?.forEach((markerSet) => markerSet?.dispose());
		deleteAllProperties(this);
	}
}
