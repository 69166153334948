import { mapProps } from 'js/components/Map/Map';
import { FORCE_GLOBAL_REFS, IS_DEVELOPMENT, ZOOM_LEVELS, IS_MOBILE, MOBILE_LOCATION_DETAIL_OFFSET } from 'js/config/constants';
import { Debug } from 'js/utils/debug';
import { easings } from 'js/utils/easings';
import { randomRange, map as mapValue, clamp, deleteAllProperties } from 'js/utils/utils';
import { QuadraticBezierCurve3, Vector3 } from 'three/build/three.module';
import { lerp } from '../utils/three-utils';
import { MercatorCoordinate } from 'mapbox-gl';
import gsap from 'gsap/gsap-core';
import distance from '@turf/distance';
import EventEmitter from 'event-emitter';
import CaseStudyController from './case-study-controller';

// various camera transitions
export default class CameraController {
	constructor(props) {
		EventEmitter(CameraController.prototype);
		CameraController.instance = this;
		if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
			window._.cameraController = this;
		}
		this.props = props;
		this.init();
	}

	init() {
		this.enabled = false;
		this.curveTimeValues = {
			value1: { value: 0 },
			value2: { value: 0 },
		};
		this.durationScalar = 1;
	}

	setEnabled(enabled = true) {
		this.enabled = enabled;
	}

	setDurationScalar(scalar = 1) {
		this.durationScalar = scalar;
	}

	// goToAltitude(altitude = 8000, lookAtCoordinates, options) {
	// 	return new Promise((resolve) => {
	// 		const { map } = this.props;

	// 		let camera = map.getFreeCameraOptions();

	// 		const currentPosition = { ...camera.position };

	// 		const currentCoordinates = camera.position.toLngLat();
	// 		const targetPosition = MercatorCoordinate.fromLngLat(currentCoordinates, altitude);

	// 		let currentLookAt, targetLookAt, lookAt;
	// 		if (lookAtCoordinates) {
	// 			currentLookAt = map.getCenter();
	// 			targetLookAt = { lng: lookAtCoordinates[0], lat: lookAtCoordinates[1] };
	// 			lookAt = { ...currentLookAt };
	// 		}

	// 		const { duration = 3, ease = 'power2.inOut' } = options;
	// 		const time = { value: 0 };

	// 		const positionVector = new Vector3();

	// 		gsap.to(time, {
	// 			value: 1,
	// 			duration,
	// 			ease,
	// 			onUpdate: function () {
	// 				const t = this.targets()[0].value;

	// 				positionVector.lerpVectors(currentPosition, targetPosition, t);

	// 				camera = map.getFreeCameraOptions();

	// 				camera.position.x = positionVector.x;
	// 				camera.position.y = positionVector.y;
	// 				camera.position.z = positionVector.z;

	// 				if (lookAtCoordinates) {
	// 					const lookLng = lerp(currentLookAt.lng, targetLookAt.lng, t);
	// 					const lookLat = lerp(currentLookAt.lat, targetLookAt.lat, t);
	// 					lookAt.lng = lookLng;
	// 					lookAt.lat = lookLat;
	// 					camera.lookAtPoint(lookAt);
	// 				}

	// 				map.setFreeCameraOptions(camera);
	// 			},
	// 			onComplete: () => {
	// 				resolve();
	// 			},
	// 		});
	// 	});
	// }

	flyTo(coordinates, options, forceBasicMotion) {
		// Debug.warn('flyTo');
		return new Promise((resolve) => {
			const { map } = this.props;
			const {
				reactProps: { setInputDisabled },
			} = mapProps;

			setInputDisabled(true);

			const bearing = map.getBearing();
			const pitch = map.getPitch();
			const { lng, lat } = map.getCenter();
			const dist = distance([lng, lat], coordinates, { units: 'kilometers' });
			const dur = clamp(mapValue(dist, 100, 1000, 1500, 5000), 1500, 5000);
			const currentZoom = map.getZoom();

			// don't hide if not moving far
			if (dist > 500) {
				this.setMapLayerVisibility('none');
			}

			map.once('moveend', () => {
				this.setMapLayerVisibility('visible');
				setInputDisabled(false);
				resolve();
			});

			const useExaggeratedMotion =
				currentZoom < ZOOM_LEVELS.STREET && !forceBasicMotion && !CaseStudyController?.instance?.forceBasicMotion;

			if (options.pitch && !useExaggeratedMotion) options.pitch = pitch;
			if (options.bearing && !useExaggeratedMotion) options.bearing = bearing;

			const finalOptions = {
				center: coordinates,
				zoom: ZOOM_LEVELS.CITY,
				pitch: useExaggeratedMotion ? randomRange(50, 80) : pitch,
				bearing: useExaggeratedMotion ? (bearing + randomRange(35, 55) * (Math.random() < 0.5 ? -1 : 1)) | 0 : bearing,
				duration: dur,
				curve: useExaggeratedMotion ? 1.42 : 1,
				easing: easings.easeInOutCubic,
				essential: true,
				...options,
			};

			finalOptions.duration /= this.durationScalar;

			map.flyTo(finalOptions);
		});
	}

	// curveFlyTo(coordinates, options = {}) {
	// 	// Debug.warn('curveFlyTo');
	// 	const { map } = this.props;
	// 	const dist = distance(this.getCameraPosition().toLngLat().toArray(), coordinates, {
	// 		units: 'kilometers',
	// 	});
	// 	if (dist < 150) {
	// 		return this.flyTo(coordinates, { zoom: map.getZoom(), offset: IS_MOBILE ? MOBILE_LOCATION_DETAIL_OFFSET : [0, 0] });
	// 	}

	// 	return new Promise((resolve) => {
	// 		const {
	// 			reactProps: { setInputDisabled },
	// 		} = mapProps;
	// 		const { curvePositionOffsetOverride, ease = 'power2.inOut' } = options;

	// 		const minDur = 2.5;
	// 		const maxDur = 5.5;
	// 		const dur = clamp(mapValue(dist, 200, 1200, minDur, maxDur), minDur, maxDur);
	// 		const currentPosition = this.getCameraPosition();
	// 		const controlPoint = MercatorCoordinate.fromLngLat(map.getCenter(), 16000);
	// 		const targetPosition = MercatorCoordinate.fromLngLat(coordinates, 8000);
	// 		const curve = new QuadraticBezierCurve3(currentPosition, controlPoint, targetPosition);

	// 		setInputDisabled(true);
	// 		this.setMapLayerVisibility('none');

	// 		const positionVector = new Vector3();
	// 		const lookAt = new MercatorCoordinate();
	// 		// curve position offset, sit back slightly from POI
	// 		const curvePositionOffset = curvePositionOffsetOverride !== undefined ? curvePositionOffsetOverride : 0.05;

	// 		let camera = map.getFreeCameraOptions();

	// 		// initial look at
	// 		const startLookAt = MercatorCoordinate.fromLngLat(map.getCenter());
	// 		const targetLookAt = MercatorCoordinate.fromLngLat(coordinates);

	// 		this.curveTimeValues.value1.value = 0;
	// 		this.curveTimeValues.value2.value = 0;

	// 		// position
	// 		gsap.to(this.curveTimeValues.value2, {
	// 			value: 1,
	// 			duration: dur,
	// 			ease,
	// 			overwrite: true,
	// 			onUpdate: function () {
	// 				const t = this.targets()[0].value;
	// 				camera = map.getFreeCameraOptions();

	// 				// position
	// 				curve.getPoint(clamp(t - curvePositionOffset, 0, 1), positionVector);
	// 				camera.position.x = positionVector.x;
	// 				camera.position.y = positionVector.y;
	// 				camera.position.z = positionVector.z;

	// 				// rotation/look
	// 				lookAt.x = lerp(startLookAt.x, targetLookAt.x, t);
	// 				lookAt.y = lerp(startLookAt.y, targetLookAt.y, t);
	// 				lookAt.z = lerp(startLookAt.z, targetLookAt.z, t);
	// 				camera.lookAtPoint(lookAt.toLngLat());

	// 				map.setFreeCameraOptions(camera);
	// 			},
	// 			onComplete: () => {
	// 				this.curveTimeValues.value1.value = 0;
	// 				this.curveTimeValues.value2.value = 0;
	// 				this.setMapLayerVisibility('visible');
	// 				setInputDisabled(false);
	// 				resolve();
	// 			},
	// 		});
	// 	});
	// }

	// freeFlyTo(coordinates, options) {
	// 	// Debug.warn('freeFlyTo');
	// 	return new Promise((resolve) => {
	// 		const {
	// 			reactProps: { setInputDisabled },
	// 		} = mapProps;
	// 		const currentAltitude = this.getCurrentAltitude();
	// 		const altitude = currentAltitude * 2;
	// 		const lookAt = coordinates;

	// 		setInputDisabled(true);
	// 		this.setMapLayerVisibility('none');

	// 		this.goToAltitude(altitude, lookAt, { duration: 3, ease: 'power2.inOut' }).then(() => {
	// 			this.flyTo(coordinates, { zoom: ZOOM_LEVELS.LOCATIONS, duration: 3000 }).then(() => {
	// 				this.setMapLayerVisibility('visible');
	// 				setInputDisabled(false);
	// 				resolve();
	// 			});
	// 		});
	// 	});
	// }

	fitBounds(bounds, options) {
		// Debug.warn('fitBounds');
		return new Promise((resolve) => {
			const { map } = this.props;
			const bearing = map.getBearing();
			const pitch = map.getPitch();

			const useExaggeratedMotion = CaseStudyController?.instance?.forceBasicMotion ? false : true;

			if (options.pitch && !useExaggeratedMotion) options.pitch = pitch;
			if (options.bearing && !useExaggeratedMotion) options.bearing = bearing;

			const finalOptions = {
				pitch: useExaggeratedMotion ? randomRange(50, 80) : pitch,
				bearing: useExaggeratedMotion ? (randomRange(15, 45) * (Math.random() < 0.5 ? -1 : 1)) | 0 : bearing,
				padding: 30,
				offset: [0, 0],
				duration: 2000,
				easing: easings.easeInOutQuart,
				essential: true,
				...options,
				linear: !useExaggeratedMotion,
			};

			finalOptions.duration /= this.durationScalar;

			map.fitBounds(bounds, finalOptions);

			map.once('moveend', () => {
				resolve();
			});
		});
	}

	zoomTo(zoom, options) {
		// Debug.warn('zoomTo');
		return new Promise((resolve) => {
			const { map } = this.props;

			const finalOptions = {
				duration: 2000,
				easing: easings.easeInOutQuart,
				essential: true,
				...options,
			};

			finalOptions.duration /= this.durationScalar;

			map.zoomTo(zoom, finalOptions);

			map.once('moveend', () => {
				resolve();
			});
		});
	}

	easeTo(options) {
		// Debug.warn('easeTo:', options);
		return new Promise((resolve) => {
			const { map } = this.props;

			options.duration = options.duration || 1;
			options.duration /= this.durationScalar;

			map.easeTo(options);

			map.once('moveend', () => {
				resolve();
			});
		});
	}

	setMapLayerVisibility(visibility = 'visible') {
		// const layers = [
		// 	'settlement-major-label',
		// 	'settlement-minor-label',
		// 	'settlement-subdivision-label',
		// 	'airport-label',
		// 	'transit-label',
		// 	'road-label-simple',
		// 	'bridge-rail',
		// 	'bridge-simple',
		// 	'bridge-case-simple',
		// 	'road-rail',
		// 	'road-simple',
		// 	'tunnel-simple',
		// 	// 'waterway',
		// 	// 'water',
		// ];
		// const { map } = this.props;
		// layers.forEach((layer) => {
		// 	map.setLayoutProperty(layer, 'visibility', visibility);
		// });
	}

	lerpLngLat(coords1, coords2, t = 0.5) {
		const lng = lerp(coords1[0], coords2[0], t);
		const lat = lerp(coords1[1], coords2[1], t);
		Debug.log('lerpLngLat:', coords1, coords2, lng, lat, t);
		return [lng, lat];
	}

	getCameraPosition() {
		return this.props?.map?.getFreeCameraOptions()?.position;
	}

	getCurrentAltitude() {
		const { map } = this.props;
		const camera = map.getFreeCameraOptions();
		return camera?.position?.toAltitude();
	}

	dispose() {
		deleteAllProperties(this);
		CameraController.instance = null;
	}
}
