import { AnimatePresence, motion } from 'framer-motion';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import React from 'react';
import CloseButtonX from '../CloseButtonX/CloseButtonX';

import './FlagModal.scss';

function FlagModal(props) {
	if (!props?.data?.source) {
		props?.onClose();
		return null;
	}

	const {
		data: { source, url },
		onClose,
	} = props;

	return (
		<AnimatePresence>
			<motion.div className="FlagModal" initial="initial" animate="animate" exit="exit" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE}>
				<div className="modal-container">
					<div className="modal-header">Submit an edit for this location</div>
					{source === 'yelp' && (
						<div className="modal-content">
							<div className="desc">
								Click on the Yelp link below and fill out the feedback form. If the categories provided don't apply, you can add your comments to the bottom of the form.
							</div>
							<a href={url} target="_blank" alt="yelp" rel="noreferrer">
								<div className="button yelp" />
								<div className="next-arrows yelp" />
							</a>
						</div>
					)}
					{source === 'google' && (
						<div className="modal-content">
							<div className="desc">Click on the Google link below and look for the “Suggest an Edit” button.</div>
							<a href={url} target="_blank" alt="yelp" rel="noreferrer">
								<div className="button google" />
								<div className="next-arrows google" />
							</a>
						</div>
					)}

					<CloseButtonX style={{ top: 10, right: 10 }} small={true} onClick={onClose} />
				</div>
			</motion.div>
		</AnimatePresence>
	);
}

export default FlagModal;
