/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, memo, useCallback } from 'react';
import { useRouteMatch, useHistory, generatePath } from 'react-router-dom';
import mapboxgl, { Marker, AttributionControl } from 'mapbox-gl';
import { easings } from '../../utils/easings';
import bbox from '@turf/bbox';

import {
	DEBUG_COUNTRY_BOUNDARIES,
	DEBUG_ROADS,
	DESKTOP_LOCATION_DETAIL_OFFSET,
	FORCE_GLOBAL_REFS,
	IS_DEVELOPMENT,
	IS_MOBILE,
	IS_PHONE,
	MAPBOX_API_KEY,
	MAP_COLORS,
	MAP_CONFIG,
	MARGIN_HORIZONTAL,
	MARGIN_VERTICAL,
	MOBILE_LOCATION_DETAIL_OFFSET,
	POI_CATEGORIES,
	PUBLIC_URL,
	SKIP_INTRO,
	USE_GEOCODING_FOR_CURRENT_LOCATION,
	ZOOM_LEVELS,
} from '../../config/constants';
import { useAppState } from '../../hooks/app-state';
import ElevationLayer from '../../layers/elevation-layer';
import CameraController from 'js/controllers/camera-controller';
import Threebox from 'js/vendor/threebox/src/Threebox';
import { Debug } from '../../utils/debug';
import gsap from 'gsap/gsap-core';
import { includesAny, randomRange, setCursor, wait } from 'js/utils/utils';
import {
	STATE_LABEL_DATA,
	getDataWrappedInFeatureCollection,
	getClosestFeature,
	getStateCode,
	getGeocodeDataForCoordinates,
} from 'js/utils/map-data-utils';
import routes from '../Router/routes';
import MarkerController from 'js/controllers/marker-controller';
import CurrentLocation from '../CurrentLocation/CurrentLocation';
import { AnimatePresence, motion } from 'framer-motion';
import HomeCTA from '../HomeCTA/HomeCTA';
import NationalGuidesCTA from '../NationalGuidesCTA/NationalGuidesCTA';
import LocationDetail from '../LocationDetail/LocationDetail';
import MapLegend from '../MapLegend/MapLegend';
import { EASE_OUT, MOTION_VARIANTS } from 'js/utils/motion';
import { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import classNames from 'classnames';
import { MathUtils } from 'three/build/three.module';
import LocationMarker from 'js/entities/location-marker';
import CaseStudyController from 'js/controllers/case-study-controller';

import 'mapbox-gl/dist/mapbox-gl.css';
import './Map.scss';

import mapboxLogoLightSrc from '../../../images/landing/mapbox-logo-white.svg';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = MAPBOX_API_KEY;

export const mapProps = {};

function Map() {
	// app state
	const [
		{
			cityData,
			guideData,
			poiStateData,
			deepLinkData,
			assets,
			filters,
			routesEnabled,
			dataSelectorVisible,
			activeGuideId,
			activeDatasetId,
			showHomeCTA,
			elevationLayerReady,
			introViewed,
			savedLocations,
			cookieConsented,
			caseStudyControls,
		},
		{ setState },
	] = useAppState();

	// local state
	const [map, setMap] = useState(null);
	const [inputDisabled, setInputDisabled] = useState(true);
	const [currentLocation, setCurrentLocation] = useState(null);
	const [locationDetailMarker, setLocationDetailMarker] = useState(null);
	const [transitionedIn, setTransitionedIn] = useState(false);

	// references
	const mapContainerRef = useRef(null);
	const locationDetailMarkerRef = useRef(null);
	const poiMarker = useRef(null);

	// routes
	const history = useHistory();
	const homeRoute = useRouteMatch(routes.home);
	const locationRoute = useRouteMatch(routes.location);
	const nationalGuidesRoute = useRouteMatch(routes.nationalGuides);
	const nationalGuideRoute = useRouteMatch(routes.nationalGuide);
	const cityGuideRoute = useRouteMatch(routes.cityGuide);
	const searchRoute = useRouteMatch(routes.search);
	const poiRoute = useRouteMatch(routes.poi);
	const markersCanBeActive = locationRoute || nationalGuideRoute || cityGuideRoute;

	useEffect(() => {
		Debug.log('map initialized');

		resetMapProps();

		// allow more zoom out for case study controls
		const minZoomOverride = caseStudyControls ? { minZoom: 0 } : {};

		// mapbox instance
		const mapboxMap = new mapboxgl.Map({
			...MAP_CONFIG,
			container: mapContainerRef.current,
			...minZoomOverride,
		});
		setMap(mapboxMap);
		mapboxMap.addControl(new AttributionControl(), 'bottom-right');

		mapProps.map = mapboxMap;
		mapProps.startMapScale = mapboxMap.transform.scale;

		// move MapBox logo and attribution
		const mapboxLogo = document.querySelector('.mapboxgl-ctrl-logo');

		if (mapboxLogo?.parentNode) {
			mapboxLogo.parentNode.style.position = 'absolute';
			mapboxLogo.parentNode.style.right = `${MARGIN_HORIZONTAL}px`;
			mapboxLogo.parentNode.style.bottom = `${MARGIN_VERTICAL}px`;
			mapboxLogo.parentNode.style.margin = 0;

			// force white mapbox logo
			mapboxLogo.style.backgroundImage = `url(${mapboxLogoLightSrc})`;

			if (IS_PHONE) {
				const mapboxAttrib = document.querySelector('.mapboxgl-ctrl-attrib');
				if (mapboxAttrib) {
					mapboxAttrib.style.margin = `${MARGIN_VERTICAL}px`;
					mapboxAttrib.style.marginBottom = `${MARGIN_VERTICAL * 2.5}px`;
				}
			}
		}

		if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
			window._.map = mapboxMap;
			window._.history = history;
			window._.mapProps = mapProps;
		}

		const locationDetail = new Marker({
			element: locationDetailMarkerRef.current,
			anchor: 'bottom',
			offset: [0, -60],
		}).setLngLat([0, 0]);
		setLocationDetailMarker(locationDetail);

		const allMarkers = [locationDetail];

		const { hoveredStateIds, reactProps } = mapProps;

		const cityGuides = guideData.filter((dataItem) => dataItem?.city !== undefined);

		Object.assign(reactProps, {
			cityData,
			guideData,
			cityGuides,
			poiStateData,
			deepLinkData,
			assets,
			history,
			generatePath,
			setState,
			setInputDisabled,
			locationDetailMarkerRef,
			locationDetail,
			allMarkers,
			filters,
			currentLocation,
			setCurrentLocation,
			setTransitionedIn,
			activeGuideId,
			activeDatasetId,
			routesEnabled,
			goToNationalView,
			goTo404View,
			getLocationIsSaved: (businessId) => savedLocations?.includes(businessId?.toString()),
			savedLocations,
			getCanRenderCurrentLocation,
			setStateLabelsVisible,
			setStateLabelHighlighted,
		});

		if (caseStudyControls) {
			mapProps.caseStudyController = new CaseStudyController({ setState, history, generatePath });
		}

		return () => {
			removeListeners();
			mapProps.layers?.elevation?.dispose();
			mapProps.markerController?.dispose();
			mapProps.caseStudyController?.dispose();
			hoveredStateIds.stateOutline = null;
			hoveredStateIds.activeStateOutline = null;
			hoveredStateIds.stateLabel = null;
			hoveredStateIds.cityLabel = null;
		};
	}, []);

	useEffect(() => {
		map?.once('load', handleLoad);
	}, [map]);

	// route handling
	useEffect(() => {
		const { currentLocationId } = mapProps;

		if (locationRoute?.params?.id !== currentLocationId) {
			mapProps.currentLocationId = locationRoute?.params?.id;
			handleLocationRoute(mapProps.currentLocationId);
		}
	}, [locationRoute]);

	// hide home cta on any route change away from home
	useEffect(() => {
		if (!homeRoute && showHomeCTA) {
			setState({ showHomeCTA: false });
		}
	}, [homeRoute]);

	useEffect(() => {
		const { markerController } = mapProps;
		markerController?.setMarkersCanBeActive(markersCanBeActive);
	}, [markersCanBeActive]);

	// reset any active markers
	useEffect(() => {
		if (!locationRoute && !nationalGuideRoute) {
			const { markerController } = mapProps;
			markerController?.setMarkerActive(null);
			setState({ activeGuideId: -1 });
		}
	}, [locationRoute, nationalGuideRoute]);

	useEffect(() => {
		const { reactProps, markerController, layers } = mapProps;

		reactProps.activeDatasetId = activeDatasetId;
		reactProps.activeGuideId = activeGuideId;

		markerController?.setConditionValues({
			activeDatasetId,
			activeGuideId,
			routesEnabled,
		});

		// update terrain when activeDatasetId updates
		const terrain = layers?.elevation?.terrain;
		if (terrain) {
			if (terrain.getCurrentHeightmapIndex() !== activeDatasetId) {
				setState({ dataSelectorEnabled: false });

				terrain.setCurrentHeightmap(activeDatasetId, 1.5, 'power3.inOut').then(() => {
					setState({ dataSelectorEnabled: true });
				});
			}
		}
	}, [activeDatasetId, activeGuideId, routesEnabled]);

	useEffect(() => {
		if (elevationLayerReady && introViewed) {
			Debug.log('Intro Complete');
			transitionIn();
		}
	}, [elevationLayerReady, introViewed]);

	useEffect(() => {
		const { reactProps, markerController } = mapProps;
		if (reactProps) {
			reactProps.savedLocations = savedLocations;
			reactProps.getLocationIsSaved = (businessId) => savedLocations?.includes(businessId?.toString());
		}

		markerController?.checkActiveAndSavedMarkers();
		markerController?.triggerManualUpdate();
	}, [savedLocations]);

	useEffect(() => {
		if (routesEnabled) {
			// hide home cta on first input
			const hideHomeCTA = () => {
				if (cookieConsented) {
					window.removeEventListener('mousedown', hideHomeCTA);
					setState({ showHomeCTA: false });
				}
			};
			window.addEventListener('mousedown', hideHomeCTA);
		}
	}, [routesEnabled, cookieConsented]);

	useEffect(() => {
		if (!dataSelectorVisible) {
			setStateLabelsVisible(false);
		}
	}, [dataSelectorVisible]);

	useEffect(() => {
		if (poiRoute && !poiMarker.current) {
			// Debug.warn('poiRoute:', poiRoute);
			if (!poiRoute.params.name || !poiRoute.params.lng || !poiRoute.params.lat) return;

			const { map } = mapProps;
			const { name, lng, lat, address } = poiRoute.params;

			const feature = {
				geometry: {
					coordinates: [lng, lat],
				},
				properties: {
					id: MathUtils.generateUUID(),
					n: decodeURI(name).replace('%2F', '/'),
					address: decodeURI(address),
					type: POI_CATEGORIES.GENERAL_POI,
				},
			};
			const options = {
				iconOverride: POI_CATEGORIES.GENERAL_POI,
				linkToAddress: true,
			};

			poiMarker.current = new LocationMarker(feature, options);
			poiMarker.current.addTo(map);
			poiMarker.current.getElement().classList.add('active', 'forced-hover');
			poiMarker.current.getElement().style.opacity = 1;
			wait(500).then(() => {
				CameraController.instance?.flyTo([lng, lat], { zoom: ZOOM_LEVELS.STREET + 1 });
			});
		} else if (!poiRoute && poiMarker.current) {
			poiMarker.current.getElement().style.opacity = 0;
			wait(300).then(() => {
				poiMarker.current.remove();
				poiMarker.current.dispose();
				poiMarker.current = null;
			});
		}
		//poiMarker
	}, [poiRoute]);

	const canRenderCurrentLocation = getCanRenderCurrentLocation();
	const currentLocationType = getCurrentLocationType();

	// moved here so poi popover doesn't appear underneath search
	const renderSearch = useCallback(() => {
		const searchMotionProps = {
			initial: {
				opacity: 0,
				transform: transitionedIn ? 'translateY(0px)' : 'translateY(-30px)',
			},
			animate: {
				opacity: 1,
				transform: 'translateY(0px)',
				transition: { duration: 0.4, ease: EASE_OUT, delay: transitionedIn ? 0 : 0.525 },
			},
			exit: {
				opacity: 0,
				transform: transitionedIn ? 'translateY(0px)' : 'translateY(-30px)',
				transition: { duration: 0.4, ease: EASE_OUT },
			},
		};

		return (
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={searchMotionProps}
				className="search-container"
			>
				<div className="search" onClick={() => history.push(routes.search.path)}>
					Search
				</div>
			</motion.div>
		);
	}, [transitionedIn]);

	const mapContainerClass = classNames({
		'map-container': true,
		'mapboxgl-map': true,
		saved: !filters[POI_CATEGORIES.FAVORITES],
		curated: !filters[POI_CATEGORIES.CURATED],
		entertainment: !filters[POI_CATEGORIES.ENTERTAINMENT],
		see: !filters[POI_CATEGORIES.SEE],
		'self-care': !filters[POI_CATEGORIES.BEAUTY],
		shop: !filters[POI_CATEGORIES.SHOP],
		stay: !filters[POI_CATEGORIES.STAY],
		know: !filters[POI_CATEGORIES.LEARN],
		learn: !filters[POI_CATEGORIES.LEARN],
		flex: !filters[POI_CATEGORIES.FLEX],
		eat: !filters[POI_CATEGORIES.EAT],
	});

	return (
		<div>
			{/* map container */}
			<div ref={(el) => (mapContainerRef.current = el)} className={mapContainerClass}>
				<AnimatePresence exitBeforeEnter>
					{canRenderCurrentLocation && (
						<CurrentLocation key="CurrentLocation" currentLocation={currentLocation} type={currentLocationType} />
					)}
				</AnimatePresence>
				<AnimatePresence>{!IS_PHONE && routesEnabled && !searchRoute && renderSearch()}</AnimatePresence>
			</div>
			<LocationDetail reference={locationDetailMarkerRef} marker={locationDetailMarker} routesEnabled={routesEnabled} />
			<AnimatePresence>{routesEnabled && <MapLegend />}</AnimatePresence>
			<AnimatePresence>{routesEnabled && showHomeCTA && <HomeCTA />}</AnimatePresence>
			<AnimatePresence>{!IS_PHONE && routesEnabled && !nationalGuidesRoute && <NationalGuidesCTA />}</AnimatePresence>
			{inputDisabled && <div className="map-input-blocker" />}
		</div>
	);
}

function handleMouseMove(e) {
	const {
		layers: { elevation },
	} = mapProps;

	elevation?.handleMouseMove(e);
}

/* uncomment below if they want states & state labels selectable again */

// function handleLayerMouseMove(e) {
// 	const { map } = mapProps;
// 	const { hoveredStateIds } = mapProps;

// 	// state outline hovers
// 	if (e.features.length > 0) {
// 		if (hoveredStateIds.stateOutline) {
// 			map?.setFeatureState({ source: 'states', id: hoveredStateIds.stateOutline }, { hover: false });
// 		}
// 		hoveredStateIds.stateOutline = e.features[0].id;
// 		map?.setFeatureState({ source: 'states', id: hoveredStateIds.stateOutline }, { hover: true });
// 	}

// 	if (SHOW_DEBUG_INFO) {
// 		document.querySelector('.debug-info').innerHTML =
// 			// e.point is the x, y coordinates of the mousemove event relative
// 			// to the top-left corner of the map
// 			JSON.stringify(e.point) +
// 			'<br />' +
// 			// e.lngLat is the longitude, latitude geographical position of the event
// 			JSON.stringify(e.lngLat.wrap());
// 	}
// }

// function handleLayerMouseLeave(e) {
// 	const { hoveredStateIds, map } = mapProps;

// 	// state outline hovers
// 	if (hoveredStateIds.stateOutline) {
// 		map?.setFeatureState({ source: 'states', id: hoveredStateIds.stateOutline }, { hover: false });
// 	}
// 	hoveredStateIds.stateOutline = null;
// }

function goToCity(selectedCityName, stateName, coordinates, options) {
	// Debug.log('goToCity:', selectedCityName, stateName, coordinates, options);

	const {
		// map,
		reactProps: { cityGuides, history, generatePath },
	} = mapProps;

	let curatedGuideId;

	const stateCode = getStateCode(stateName);

	cityGuides.forEach(({ id, city: { cityName, cityState } }) => {
		if (selectedCityName === cityName && stateCode === cityState) {
			curatedGuideId = id;
		}
	});

	if (curatedGuideId) {
		// const forceBasicMotion = map.getZoom() >= ZOOM_LEVELS.CITY;
		// Debug.warn('forceBasicMotion:', forceBasicMotion);
		CameraController.instance?.flyTo(coordinates, options).then(() => {
			history.push(generatePath(routes.cityGuideLanding.path, { id: curatedGuideId }));
		});
	} else {
		// standard city
		const pathParams = { city: encodeURI(selectedCityName) };
		if (stateCode) pathParams.state = stateCode;

		// Debug.log('pathParams:', pathParams);

		history.push({
			pathname: generatePath(pathParams.state ? routes.standardCity.path : routes.standardCityNoState.path, pathParams),
			search: `?lng=${coordinates[0]}&lat=${coordinates[1]}&zoom=${options?.zoom}`,
		});
	}
}

function handleClick(e) {
	// only allow if mapbox canvas was clicked, not something above.
	const wasMapClicked = e?.originalEvent?.target?.classList?.contains('mapboxgl-canvas');
	if (!wasMapClicked) return;

	const { map } = mapProps;
	const features = map.queryRenderedFeatures(e.point);

	let feature = features[0];

	// Debug.warn('CLICK LOCATION:', feature?.geometry?.coordinates);

	// if state-labels was clicked remap feature to state-fills
	if (feature?.layer?.id === 'state-labels') {
		feature = features.filter((f) => f?.layer?.id === 'state-fills')[0];
	}
	// if (includesAny(feature?.layer?.id, 'state-labels', 'settlement-minor-label', 'settlement-subdivision-label')) {
	// 	feature = features.filter((f) => f?.layer?.id === 'state-fills')[0];
	// }

	if (!feature) return;

	// Debug.log('handleClick:', features);
	// Debug.log('handleClick:', feature?.layer?.id, feature, e);

	// if (feature?.layer?.id === 'settlement-major-label') {
	if (includesAny(feature?.layer?.id, 'settlement-major-label', 'settlement-minor-label', 'settlement-subdivision-label')) {
		resetActiveMarker();

		// zoom to highest level to ensure city label doesn't disappear
		const currentZoom = map.getZoom();
		const extraZoom = feature?.properties?.type === 'city' ? 1 : 2;
		const targetZoom = ZOOM_LEVELS.CITY + extraZoom;
		const zoom = currentZoom > targetZoom ? currentZoom : targetZoom;

		// Debug.log('city zoom:', feature?.properties?.type, extraZoom, targetZoom, zoom);

		const options = {
			pitch: randomRange(70, 80),
			offset: [0, 0],
			duration: 2000,
			zoom,
			easing: easings.easeInOutQuart,
			essential: true,
		};

		// find state of click area
		const bbox = [
			[e.point.x - 50, e.point.y - 50],
			[e.point.x + 50, e.point.y + 50],
		];

		let stateFeatures = map.queryRenderedFeatures(bbox, {
			layers: ['state-fills'],
		});

		goToCity(feature.properties.name, stateFeatures[0]?.properties?.name, feature.geometry.coordinates, options);
	}
	/* uncomment below if they want states & state labels selectable again */

	// else if (feature.layer?.id === 'state-labels') {
	// 	resetActiveMarker();
	// 	CameraController.instance?.flyTo(feature.geometry.coordinates, {
	// 		zoom: ZOOM_LEVELS.STATE,
	// 		duration: 2000,
	// 		easing: easings.easeInOutQuart,
	// 		essential: true,
	// 	});
	else if (feature?.layer?.id === 'state-fills') {
		if (map.getZoom() < ZOOM_LEVELS.STATE) {
			const bounds = bbox(feature);

			const options = {
				pitch: randomRange(70, 80),
				padding: 30,
				offset: [0, 0],
				duration: 2000,
				zoom: ZOOM_LEVELS.STATE, // zoom to state level
				easing: easings.easeInOutQuart,
				essential: true,
			};

			CameraController.instance?.fitBounds(bounds, options);
		}

		// // set active state for ... selected state
		// if (hoveredStateIds.activeStateOutline) {
		// 	map?.setFeatureState({ source: 'states', id: hoveredStateIds.activeStateOutline }, { active: false });
		// }
		// hoveredStateIds.activeStateOutline = feature.id;
		// map?.setFeatureState({ source: 'states', id: hoveredStateIds.activeStateOutline }, { active: true });
	}
}

function handleZoom() {
	const {
		map,
		layers: { elevation },
		reactProps: { setState },
	} = mapProps;

	wait(50).then(() => {
		const zoom = map?.getZoom();
		const terrain = elevation?.terrain;

		if (zoom >= ZOOM_LEVELS.TERRAIN_CUTOFF && !terrain?.isFlat) {
			terrain?.flattenCompletely(true);
			setState({ dataSelectorVisible: false });
		} else if (zoom < ZOOM_LEVELS.TERRAIN_CUTOFF && terrain?.isFlat) {
			terrain?.flattenCompletely(false);
			setState({ dataSelectorVisible: true });
		}
	});
}

function handleZoomEnd() {
	if (CaseStudyController.instance) return;

	const { map } = mapProps;

	// reset orientation to default
	if (map.getZoom() < ZOOM_LEVELS.NATIONAL + 1) {
		const { pitch, bearing } = MAP_CONFIG;

		const pitchDiff = Math.abs(map.getPitch() - pitch);
		const bearingDiff = Math.abs(map.getBearing() - bearing);

		if (pitchDiff > 15 || bearingDiff > 20) {
			CameraController.instance?.easeTo({
				pitch,
				bearing,
				center: { lng: -102.56816939489971, lat: 37.05095375459564 },
				easing: easings.easeInOutCubic,
				duration: 2000,
			});
		}
	}
}

function handleResize() {
	mapProps?.layers?.elevation?.handleResize();
	ElementColorSwapper.instance?.resize();
}

function handleMove() {
	ElementColorSwapper.instance?.update();
}

function handleMoveEnd() {
	const {
		map,
		reactProps: { setCurrentLocation, cityData },
	} = mapProps;

	ElementColorSwapper.instance?.run();

	if (map && getCanRenderCurrentLocation()) {
		if (USE_GEOCODING_FOR_CURRENT_LOCATION) {
			const { lng, lat } = map.getCenter();
			const zoom = map.getZoom();

			getGeocodeDataForCoordinates(lng, lat)
				.then((response) => {
					let city, state, place;
					response?.features?.forEach((feature) => {
						// // if (feature?.place_type.includes('place')) {
						// // if ((feature?.place_type.includes('locality') || feature?.place_type.includes('place')) && !city) {
						// if (includesAny(feature?.place_type, 'neighborhood', 'place') && !city) {
						// 	city = feature?.text;
						// } else if (feature?.place_type.includes('region')) {
						// 	state = getStateCode(feature?.text);
						// }

						if (feature?.place_type.includes('place')) {
							if (zoom < ZOOM_LEVELS.STREET) {
								city = feature?.text;
							} else {
								place = feature?.text;
							}
							// } else if (feature?.place_type.includes('neighborhood') && zoom >= ZOOM_LEVELS.STREET) {
						} else if (includesAny(feature?.place_type, 'neighborhood', 'locality') && zoom >= ZOOM_LEVELS.STREET) {
							city = feature?.text;
						} else if (feature?.place_type.includes('region')) {
							state = getStateCode(feature?.text);
						}
					});

					if (!city && place) {
						city = place;
					}

					if (city && state) {
						setCurrentLocation({ city, state });
					}

					// Debug.log('getGeocodeDataForCoordinates:', response, city, state);
				})
				.catch((e) => Debug.warn('Error getting geocode data for:', lng, lat));
		} else {
			// search all cities instead of rendered ones just in case closest city is off screen
			const closestFeature = getClosestFeature(cityData, map.getCenter().toArray());
			const {
				properties: { city, state },
			} = closestFeature;

			if (city && state) {
				setCurrentLocation({ city, state });
			} else {
				setCurrentLocation(null);
			}
		}
	} else {
		setCurrentLocation(null);
	}
}

// function handleStateLabelOver(e, featureOverride) {
// 	const {
// 		hoveredStateIds,
// 		map,
// 		reactProps: { activeStateNameRef },
// 	} = mapProps;

// 	// state label hovers
// 	const feature = featureOverride || e.features[0];

// 	if (feature) {
// 		if (hoveredStateIds.stateLabel) {
// 			map?.setFeatureState({ source: 'state-names', id: hoveredStateIds.stateLabel }, { hover: false });
// 		}
// 		hoveredStateIds.stateLabel = feature.id;
// 		map?.setFeatureState({ source: 'state-names', id: hoveredStateIds.stateLabel }, { hover: true });

// 		const {
// 			properties: { name },
// 			geometry: { coordinates },
// 		} = feature;

// 		// Debug.log('state-label:', feature);

// 		showStateScore({ score: -1 }, name, coordinates);
// 		markers?.setHoveredMarker(null); // only show one popover at a time
// 		activeStateNameRef.current = name;

// 		getStateScore(name)
// 			.then((data) => {
// 				if (activeStateNameRef.current === name) {
// 					activeStateNameRef.current = null;
// 					showStateScore(data, name, coordinates);
// 					markers?.setHoveredMarker(null); // only show one popover at a time
// 				}
// 			})
// 			.catch((error) => Debug.log(error));

// 		setCursor(map.getCanvas(), 'pointer');
// 	}
// }

// function handleStateLabelOut(e) {
// 	const {
// 		hoveredStateIds,
// 		map,
// 	} = mapProps;
// 	// state label hovers
// 	if (hoveredStateIds.stateLabel) {
// 		map?.setFeatureState({ source: 'state-names', id: hoveredStateIds.stateLabel }, { hover: false });
// 	}
// 	hoveredStateIds.stateLabel = null;

// 	setCursor(map.getCanvas());

// 	removeStateScore();
// }

function handleCityLabelOver(e, featureOverride) {
	const { hoveredStateIds, map } = mapProps;

	// city label hovers
	const feature = featureOverride || e.features[0];

	// Debug.log('handleCityLabelOver:', feature);

	if (feature) {
		if (hoveredStateIds.cityLabel) {
			map?.setFeatureState(
				{ source: 'composite', sourceLayer: 'place_label', id: hoveredStateIds.cityLabel },
				{ hover: false }
			);
		}
		hoveredStateIds.cityLabel = feature.id;
		map?.setFeatureState({ source: 'composite', sourceLayer: 'place_label', id: hoveredStateIds.cityLabel }, { hover: true });

		setCursor(map.getCanvas(), 'pointer');
	}
}

function handleCityLabelOut(e) {
	const { hoveredStateIds, map } = mapProps;

	// city label hovers
	if (hoveredStateIds.cityLabel) {
		map?.setFeatureState(
			{ source: 'composite', sourceLayer: 'place_label', id: hoveredStateIds.cityLabel },
			{ hover: false }
		);
	}
	hoveredStateIds.cityLabel = null;

	setCursor(map.getCanvas());
}

// mapbox style overrides
function handleStyleData() {
	const { map } = mapProps;
	Debug.log('map style loaded:', map.isStyleLoaded());
	map.off('styledata', handleStyleData);

	const handleLoaded = () => {
		const otherLabelColor = MAP_COLORS.OTHER_LABEL;
		const roadColor = MAP_COLORS.ROAD;

		map.setLayoutProperty('settlement-major-label', 'text-size', ['interpolate', ['linear'], ['zoom'], 6, 12, 13, 20]);
		// TODO: if adding these layers add to camera-controller:setMapLayerVisibility as well
		map.setLayoutProperty('settlement-minor-label', 'text-size', ['interpolate', ['linear'], ['zoom'], 6, 12, 13, 20]);
		map.setLayoutProperty('settlement-subdivision-label', 'text-size', ['interpolate', ['linear'], ['zoom'], 6, 12, 13, 20]);
		// map.setLayoutProperty('settlement-minor-label', 'visibility', 'visible');
		// map.setLayoutProperty('settlement-subdivision-label', 'visibility', 'visible');

		map.setLayoutProperty('airport-label', 'text-size', 11);
		map.setLayoutProperty('transit-label', 'text-size', 11);
		map.setLayoutProperty('road-label-simple', 'text-size', 11);

		map.setPaintProperty('airport-label', 'text-color', otherLabelColor);
		map.setPaintProperty('transit-label', 'text-color', otherLabelColor);
		map.setPaintProperty('road-label-simple', 'text-color', otherLabelColor);

		// road colors
		map.setPaintProperty('bridge-rail', 'line-color', roadColor);
		map.setPaintProperty('bridge-simple', 'line-color', roadColor);
		map.setPaintProperty('bridge-case-simple', 'line-color', roadColor);
		map.setPaintProperty('road-rail', 'line-color', roadColor);
		map.setPaintProperty('road-simple', 'line-color', roadColor);
		map.setPaintProperty('tunnel-simple', 'line-color', roadColor);

		// // state label off/over styles
		// map.setPaintProperty('state-labels', 'text-color', [
		// 	'case',
		// 	['boolean', ['feature-state', 'hover'], false],
		// 	'#ffffff',
		// 	stateColor,
		// ]);

		// city label off/over styles
		map.setPaintProperty('settlement-major-label', 'text-color', [
			'case',
			['boolean', ['feature-state', 'hover'], false],
			MAP_COLORS.CITY_LABEL_HOVER,
			MAP_COLORS.CITY_LABEL,
		]);
		map.setPaintProperty('settlement-minor-label', 'text-color', [
			'case',
			['boolean', ['feature-state', 'hover'], false],
			MAP_COLORS.CITY_LABEL_HOVER,
			MAP_COLORS.CITY_LABEL,
		]);
		map.setPaintProperty('settlement-subdivision-label', 'text-color', [
			'case',
			['boolean', ['feature-state', 'hover'], false],
			MAP_COLORS.CITY_LABEL_HOVER,
			MAP_COLORS.CITY_LABEL,
		]);
		// map.setPaintProperty('settlement-minor-label', 'text-color', MAP_COLORS.CITY_LABEL);
		// map.setPaintProperty('settlement-subdivision-label', 'text-color', MAP_COLORS.CITY_LABEL);

		if (DEBUG_ROADS) {
			map.setLayerZoomRange('road-simple', 5, 22);
			map.setPaintProperty('road-simple', 'line-color', '#ff0000');
			Debug.warn('DEBUG_ROADS');
		}

		if (DEBUG_COUNTRY_BOUNDARIES) {
			map.setPaintProperty('country-boundaries', 'fill-opacity', 1);
			map.moveLayer('country-boundaries', 'settlement-major-label');
		}
	};

	const checkLoaded = () => {
		if (map.isStyleLoaded()) {
			clearInterval(loadInterval);
			handleLoaded();
		} else {
			Debug.log('STYLE NOT LOADED');
		}
	};

	let loadInterval = setInterval(checkLoaded, 200);
}

// once mapbox map is loaded add sources/layers/markers/listeners
function handleLoad() {
	const {
		map,
		reactProps: { setState, allMarkers },
	} = mapProps;
	addSources();
	addLayers();
	addMarkers();
	addListeners();
	handleResize();

	// add markers after layers so they're on top
	allMarkers.forEach((marker) => marker.addTo(map));

	setState({ mapReady: true });
	goToNationalView(0, easings.easeOutCubic, true);
	if (IS_PHONE) {
		map?.setMinZoom(ZOOM_LEVELS.MOBILE_BUFFER); // allow user to zoom out further than national on mobile
	}
}

// goes to home route which resets any active/selected markers
function resetActiveMarker() {
	const {
		reactProps: { history },
	} = mapProps;

	if (history.location.pathname.includes('/location')) {
		history.push(routes.home.path);
	}
}

// map data sources for state outlines & labels
function addSources() {
	const {
		map,
		reactProps: { cityData },
	} = mapProps;

	if (!map?.getSource('cities')) {
		const data = getDataWrappedInFeatureCollection(cityData);
		map.addSource('cities', {
			type: 'geojson',
			data,
		});
	}

	// Add a source for the state polygons.
	if (!map?.getSource('states')) {
		map.addSource('states', {
			type: 'geojson',
			data: `${PUBLIC_URL}/assets/data/us-states.json`,
		});
	}

	if (!map?.getSource('state-names')) {
		map.addSource('state-names', {
			type: 'geojson',
			data: STATE_LABEL_DATA,
		});
	}

	if (!map?.getSource('us-outline')) {
		map.addSource('us-outline', {
			type: 'geojson',
			data: `${PUBLIC_URL}/assets/data/us-outline-low.json`,
		});
	}
}

// adds various layers like USA outline, state borders, webgl layer, etc
function addLayers() {
	const {
		map,
		reactProps: { cityData, assets, setState },
	} = mapProps;

	// Threebox syncs Three.js webgl layer with mapbox
	const tb = new Threebox(map, map.getCanvas().getContext('webgl'), {
		defaultLights: true,
		enableSelectingFeatures: false, //change this to false to disable fill-extrusion features selection
		enableSelectingObjects: false, //change this to false to disable 3D objects selection
		enableDraggingObjects: false, //change this to false to disable 3D objects drag & move once selected
		enableRotatingObjects: false, //change this to false to disable 3D objects rotation once selected
		enableTooltips: false, // change this to false to disable default tooltips on fill-extrusion and 3D models
		passiveRendering: true,
		multiLayer: true,
	});

	Debug.log('renderer:', tb.renderer);

	mapProps.tb = tb;
	if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
		window._.tb = tb;
	}

	map.addLayer(
		{
			id: 'state-fills',
			type: 'fill',
			source: 'states',
			layout: {},
			paint: {
				'fill-color': '#2e2e34',
				'fill-opacity': 0,
			},
		},
		'tunnel-simple'
	);

	// us outline
	map.addLayer(
		{
			id: 'usa-outline',
			type: 'line',
			source: 'us-outline',
			layout: {},
			paint: {
				'line-width': 1,
				'line-color': '#ffffff',
				'line-opacity': 0,
			},
		},
		'tunnel-simple'
	);

	// persistent state outlines
	map.addLayer({
		id: 'state-outlines-static',
		type: 'line',
		source: 'states',
		layout: {},
		paint: {
			'line-width': 1,
			'line-color': '#ffffff',
			'line-opacity': 0.05,
		},
	});

	// cities for detecting nearby to center
	map.addLayer(
		{
			id: 'cities',
			type: 'circle',
			source: 'cities',
			paint: {
				'circle-color': '#ffffff',
				'circle-opacity': 0,
			},
			// layout: {
			// 	visibility: 'none',
			// },
		},
		'state-fills'
	);

	/* uncomment below if they want states & state labels selectable again */
	// const outlineVisible = [
	// 	'any',
	// 	['boolean', ['feature-state', 'hover'], false],
	// 	['boolean', ['feature-state', 'active'], false],
	// ];

	// map.addLayer({
	// 	id: 'state-outlines',
	// 	type: 'line',
	// 	source: 'states',
	// 	layout: {},
	// 	paint: {
	// 		'line-width': 2,
	// 		'line-color': '#ffffff',
	// 		'line-opacity': ['case', outlineVisible, 0.3, 0],
	// 	},
	// });

	map.addLayer({
		id: 'state-labels',
		type: 'symbol',
		source: 'state-names',
		layout: {
			'text-field': ['get', 'stateCode'],
			'text-font': ['VTC Martin', 'VTCWilliam', 'sans-serif'],
			// 'text-size': 9,
			'text-size': ['interpolate', ['linear'], ['zoom'], 6, 13, 13, 26], // min-zoom, min-size, max-zoom, max-size
			'text-offset': [0, 0],
			'text-anchor': 'center',
		},
		paint: {
			'text-color': 'rgba(255, 255, 255, 0)',
			// 'text-color': '#ffffff',
			'text-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.3],
			// 'text-opacity': 0.3,
		},
	});

	// map.addLayer(
	// 	{
	// 		id: 'state-fills',
	// 		type: 'fill',
	// 		source: 'states',
	// 		layout: {},
	// 		paint: {
	// 			'fill-color': '#2e2e34',
	// 			'fill-opacity': 0,
	// 		},
	// 	},
	// 	// 'country-boundaries'
	// 	'usa-outline'
	// 	// 'tunnel-simple'
	// );

	const elevationLayer = new ElevationLayer({
		cityData,
		assets,
		readyCallback: (layer) => {
			setState({ elevationLayerReady: true });
		},
	});
	map.addLayer(elevationLayer.layer, 'tunnel-simple');
	mapProps.layers.elevation = elevationLayer;
}

// adds marker controllers
function addMarkers() {
	const {
		reactProps: { guideData, poiStateData, deepLinkData, activeDatasetId, activeGuideId, routesEnabled, setState },
	} = mapProps;

	// start with POI markers
	const markerSetConfig = {
		poiMarkers: {
			data: poiStateData, // feature set
			visibilityProps: {
				// show based on zoom range and conditions
				minZoom: ZOOM_LEVELS.MARKERS_VISIBLE,
				maxZoom: ZOOM_LEVELS.MAP_MAX,
				conditions: [{ key: 'routesEnabled', equals: true }],
			},
			useCluster: true, // cluster or show all instead of clustering
			clusterProps: {
				minZoom: ZOOM_LEVELS.NATIONAL + 1,
				maxZoom: ZOOM_LEVELS.STREET + 1,
				radius: IS_MOBILE ? 100 : 200,
				minPoints: IS_MOBILE ? 16 : 32,
				// clusterThresholds: [
				// 	{ minPoints: 60, radius: 200, minZoom: ZOOM_LEVELS.NATIONAL, maxZoom: 7.99 },
				// 	{ minPoints: 30, radius: 200, minZoom: 8, maxZoom: ZOOM_LEVELS.MAP_MAX },
				// ],
			},
			visible: true,
			iconType: null, // icon override or null to use location-specific type
		},
	};

	const curatedFeatures = {};

	// add guide markers
	guideData.forEach((guideDataItem, index) => {
		const { id, curatedPois, city } = guideDataItem;

		// keep a reference of all curated poi's and convert them to features
		curatedPois.forEach((poi) => {
			const {
				id: poiId,
				googlePlacesId,
				location: { lat, lon },
			} = poi;

			const feature = {
				geometry: {
					type: 'Point',
					coordinates: [lon, lat],
				},
				properties: {
					type: POI_CATEGORIES.CURATED,
					gid: googlePlacesId,
					guideId: id,
					guideType: city !== undefined ? CONTENT_TYPES.CITY_GUIDE : CONTENT_TYPES.NATIONAL_GUIDE,
					pc: [POI_CATEGORIES.CURATED],
					...poi,
				},
			};

			if (!curatedFeatures[poiId]) curatedFeatures[poiId] = feature;
		});
	});

	const curatedFeatureArray = Object.values(curatedFeatures);
	curatedFeatures.array = curatedFeatureArray;

	// TODO: check all curated for gid's and remove from poiStateData to avoid dups

	markerSetConfig.guideMarkers = {
		data: curatedFeatureArray, // feature set
		visibilityProps: {
			// show based on zoom range and conditions
			minZoom: ZOOM_LEVELS.STATE,
			maxZoom: ZOOM_LEVELS.MAP_MAX,
			conditions: [{ key: 'routesEnabled', equals: true }],
		},
		useCluster: false,
		useStaggeredTransition: true,
		showDelay: 1000,
		clusterProps: null,
		visible: true,
		// TODO: remove?
		iconType: null, // icon override or null to use location-specific type
	};

	Debug.log('curatedFeatures:', curatedFeatures);
	Debug.log('markerSetConfig:', markerSetConfig);

	const markerController = new MarkerController({
		markerSetConfig,
		curatedFeatures,
		deepLinkData,
		conditionValues: {
			activeDatasetId,
			activeGuideId,
			routesEnabled,
		},
	});

	mapProps.markerController = markerController;

	setState({ curatedFeatures: curatedFeatureArray });
}

function addListeners() {
	const { map } = mapProps;

	removeListeners();

	if (!IS_MOBILE) {
		window.addEventListener('mousemove', handleMouseMove, false);
		map.on('mouseover', 'settlement-major-label', handleCityLabelOver);
		map.on('mouseout', 'settlement-major-label', handleCityLabelOut);

		map.on('mouseover', 'settlement-minor-label', handleCityLabelOver);
		map.on('mouseout', 'settlement-minor-label', handleCityLabelOut);
		map.on('mouseover', 'settlement-subdivision-label', handleCityLabelOver);
		map.on('mouseout', 'settlement-subdivision-label', handleCityLabelOut);

		/* uncomment below if they want states & state labels selectable again */
		// map.on('mousemove', 'state-fills', handleLayerMouseMove);
		// map.on('mouseleave', 'state-fills', handleLayerMouseLeave);
		// map.on('mouseover', 'state-labels', handleStateLabelOver);
		// map.on('mouseout', 'state-labels', handleStateLabelOut);
	}

	map.on('click', handleClick);
	map.on('zoom', handleZoom);
	map.on('resize', handleResize);
	map.on('styledata', handleStyleData);
	map.on('moveend', handleMoveEnd);
	map.on('move', handleMove);

	document.addEventListener('visibilitychange', handleVisibilityChange, false);
}

function removeListeners() {
	const { map } = mapProps;

	if (!IS_MOBILE) {
		window.removeEventListener('mousemove', handleMouseMove);
		map?.off('mouseover', 'settlement-major-label', handleCityLabelOver);
		map?.off('mouseout', 'settlement-major-label', handleCityLabelOut);

		map?.off('mouseover', 'settlement-minor-label', handleCityLabelOver);
		map?.off('mouseout', 'settlement-minor-label', handleCityLabelOut);
		map?.off('mouseover', 'settlement-subdivision-label', handleCityLabelOver);
		map?.off('mouseout', 'settlement-subdivision-label', handleCityLabelOut);
		/* uncomment below if they want states & state labels selectable again */
		// map?.off('mousemove', 'state-fills', handleLayerMouseMove);
		// map?.off('mouseleave', 'state-fills', handleLayerMouseLeave);
		// map?.off('mouseover', 'state-labels', handleStateLabelOver);
		// map?.off('mouseout', 'state-labels', handleStateLabelOut);
	}

	map?.off('click', handleClick);
	map?.off('zoom', handleZoom);
	map?.off('resize', handleResize);
	map?.off('styledata', handleStyleData);
	map?.off('moveend', handleMoveEnd);
	map?.off('move', handleMove);

	document.removeEventListener('visibilitychange', handleVisibilityChange);
}

function setStateLabelHighlighted(id, highlighted = true) {
	const { hoveredStateIds, map } = mapProps;

	if (hoveredStateIds.stateLabel !== undefined) {
		map?.setFeatureState({ source: 'state-names', id: hoveredStateIds.stateLabel }, { hover: false });
		hoveredStateIds.stateLabel = undefined;
	}

	if (highlighted) {
		hoveredStateIds.stateLabel = id;
		map?.setFeatureState({ source: 'state-names', id: hoveredStateIds.stateLabel }, { hover: true });
	}
}

function setStateLabelsVisible(visible = true, duration = 1, delay = 0) {
	if (CaseStudyController.instance) return;

	// Debug.trace('setStateLabelsVisible');

	const { map } = mapProps;

	const animate = () => {
		const currentColor = map?.getPaintProperty('state-labels', 'text-color');
		const currentOpacity = Number(currentColor.substr(currentColor.lastIndexOf(', ') + 2).replace(')', ''));

		// Debug.log('text opacity:', currentOpacity);

		const textOpacity = {
			value: currentOpacity,
			// value: map?.getPaintProperty('state-labels', 'text-opacity'),
		};

		gsap.to(textOpacity, {
			value: visible ? 1 : 0,
			duration,
			delay,
			ease: 'power3.out',
			onUpdate: () => {
				const { value } = textOpacity;
				map?.setPaintProperty('state-labels', 'text-color', `rgba(255, 255, 255, ${value})`);
				// map?.setPaintProperty('state-labels', 'text-opacity', value);
			},
		});
	};

	const checkLoaded = () => {
		if (map?.isStyleLoaded()) {
			clearInterval(loadInterval);
			animate();
		}
	};

	let loadInterval = setInterval(checkLoaded, 200);
	checkLoaded();
}

function setMapLabelDimmed(dimmed = true, duration = 0.5, delay = 0) {
	const { map } = mapProps;

	const animate = () => {
		const textOpacity = {
			value: map?.getPaintProperty('settlement-major-label', 'text-color'),
		};

		// MAP_COLORS.CITY_LABEL_HOVER, MAP_COLORS.CITY_LABEL

		gsap.to(textOpacity, {
			value: dimmed ? MAP_COLORS.OTHER_LABEL_DIMMED : MAP_COLORS.OTHER_LABEL,
			duration,
			delay,
			ease: 'power3.out',
			onUpdate: () => {
				const { value } = textOpacity;
				map?.setPaintProperty('settlement-major-label', 'text-color', value);
				map?.setPaintProperty('settlement-minor-label', 'text-color', value);
				map?.setPaintProperty('settlement-subdivision-label', 'text-color', value);
				map?.setPaintProperty('airport-label', 'text-color', value);
				map?.setPaintProperty('transit-label', 'text-color', value);
				map?.setPaintProperty('road-label-simple', 'text-color', value);
				// map?.setPaintProperty('settlement-minor-label', 'text-color', value);
				// map?.setPaintProperty('settlement-subdivision-label', 'text-color', value);
			},
		});
	};

	const checkLoaded = () => {
		if (map?.isStyleLoaded()) {
			clearInterval(loadInterval);
			animate();
		}
	};

	let loadInterval = setInterval(checkLoaded, 200);
	checkLoaded();
}

function getCanRenderCurrentLocation() {
	const { map } = mapProps;
	const zoom = map?.getZoom();
	return zoom >= ZOOM_LEVELS.STATE;
}

function getCurrentLocationType() {
	return 'city';
	// const { map } = mapProps;
	// const zoom = map?.getZoom();
	// if (zoom >= ZOOM_LEVELS.STATE && zoom < ZOOM_LEVELS.STATE + 1) return 'state';
	// else return 'city';
}

function transitionIn() {
	const {
		map,
		currentLocationId,
		reactProps: { setState, setInputDisabled, setTransitionedIn },
		layers: { elevation },
		markerController,
	} = mapProps;

	const { maxBounds } = MAP_CONFIG;

	ElementColorSwapper.instance?.run();

	map?.setMaxBounds(maxBounds);

	map?.once('movestart', () => {
		setState({ showHomeCTA: false });
	});

	map?.once('moveend', () => {
		map?.on('zoomend', handleZoomEnd);
		markerController?.checkActiveAndSavedMarkers();
	});

	setState({ routesEnabled: true });
	setInputDisabled(false);
	markerController?.setEnabled();
	elevation?.setStatic();
	elevation?.terrain?.transitionIn(2).then(() => {
		setTransitionedIn(true);
	});

	if (SKIP_INTRO) {
		wait(500).then(() => elevation?.terrain?.transitionIn(1.5));
	}

	// deep link
	if (currentLocationId) {
		handleLocationRoute(currentLocationId, 0.02, 250);
	}
}

// handles route changes
async function handleLocationRoute(id, curvePositionOffsetOverride, delay = 0) {
	Debug.log('handleLocationRoute:', id);

	const { markerController, map } = mapProps;

	if (id) {
		let locationData = markerController?.getDataForBusinessId(id);

		Debug.log('handleLocationRoute: locationData:', locationData);

		if (!locationData) {
			Debug.warn('location data not found for businessId:', id);
			return;
		}

		// try setting marker active if it's already visible, otherwise in {postTransition} once the cluster is expanded.
		// slight delay to allow for route change effects to trigger
		wait(50).then(() => markerController?.setMarkerActive(id));

		const selectedMarkerZoomLevel = ZOOM_LEVELS.STREET + 1;

		const postTransition = () => {
			markerController?.expandClusterIfActiveMarkerIsInside(id, selectedMarkerZoomLevel, true);
		};

		wait(delay).then(() => {
			if (IS_MOBILE) {
				const options = { zoom: selectedMarkerZoomLevel, offset: MOBILE_LOCATION_DETAIL_OFFSET };
				CameraController.instance?.flyTo(locationData?.geometry?.coordinates, options).then(postTransition);
			} else {
				const options = { zoom: selectedMarkerZoomLevel, offset: DESKTOP_LOCATION_DETAIL_OFFSET };
				CameraController.instance?.flyTo(locationData?.geometry?.coordinates, options).then(postTransition);
			}
		});

		// setMapLabelDimmed(true);
	} else {
		mapProps.currentLocationId = null;
		markerController?.setMarkerActive(null);
		// setMapLabelDimmed(false);
		map?.setZoom(map?.getZoom());
	}
}

function handleVisibilityChange() {}

function goTo404View(duration = 2500, easing = easings.easeInOutCubic) {
	return new Promise((resolve) => {
		if (IS_PHONE) {
			CameraController.instance
				?.easeTo({
					center: [-75.05642794067684, 30.022532443394567],
					pitch: 53,
					bearing: 21,
					zoom: 4,
					duration,
					easing,
					essential: true,
				})
				.then(resolve);
		} else {
			CameraController.instance
				?.flyTo([-72.76072408569779, 32.991053765676995], {
					pitch: 53,
					bearing: 21,
					zoom: 5.18,
					duration,
					easing,
					essential: true,
				})
				.then(resolve);
		}
	});
}

// resets view to national view
function goToNationalView(duration = 2500, easing = easings.easeOutCubic, isPostTransition, isFrom404) {
	Debug.log('goToNationalView');

	return new Promise((resolve) => {
		const { map, markerController } = mapProps;

		const { bounds, pitch, bearing } = MAP_CONFIG;

		if (IS_PHONE) {
			if (isFrom404) {
				CameraController.instance
					?.easeTo({
						center: [-96.59811774959908, 34.86869165050061],
						pitch: 53,
						bearing: 21,
						zoom: ZOOM_LEVELS.NATIONAL,
						duration,
						easing,
						essential: true,
					})
					.then(() => {
						if (!isPostTransition) {
							markerController?.setEnabled(true);
						}
						resolve();
					});
			} else {
				map?.setMinZoom(ZOOM_LEVELS.MIN);

				CameraController.instance
					?.fitBounds(bounds, {
						pitch,
						bearing,
						padding: 0,
						offset: [0, -20],
						duration,
						easing,
						essential: true,
					})
					.then(() => {
						if (!isPostTransition) {
							markerController?.setEnabled(true);
						}
						map?.setMinZoom(ZOOM_LEVELS.MOBILE_BUFFER); // allow user to zoom out further than national on mobile
						resolve();
					});
			}
		} else {
			CameraController.instance
				?.fitBounds(bounds, {
					pitch,
					bearing,
					padding: -400,
					offset: [75, -35],
					duration,
					easing,
					essential: true,
				})
				.then(() => {
					if (!isPostTransition) {
						markerController?.setEnabled(true);
					}
					resolve();
				});
		}
	});
}

// resets props used globally. needed during dev hot reloads.
export function resetMapProps() {
	Object.assign(mapProps, {
		tb: null,
		map: null,
		layers: {},
		reactProps: {},
		sharedUniforms: null,
		depthUniforms: null,
		postProcessingPass: null,
		currentLocationId: null,
		hotspots: null,
		hoveredStateIds: {
			stateOutline: null,
			activeStateOutline: null,
			stateLabel: null,
			cityLabel: null,
		},
		heightmap: null,
		topLeft: null,
		startMapScale: null,
		markerController: null,
		playlistController: null,
		caseStudyController: null,
	});
}

export default memo(Map);
