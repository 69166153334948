import { deleteAllProperties, wait } from './utils';
import mapboxLogoDarkSrc from '../../images/landing/mapbox-logo-black.svg';
import mapboxLogoLightSrc from '../../images/landing/mapbox-logo-white.svg';
import { IS_PHONE } from 'js/config/constants';

export default class ElementColorSwapper {
	constructor(props) {
		ElementColorSwapper.instance = this;
		this.run = this.run.bind(this);
		this.props = props;
		this.init();
	}

	init() {
		this.rootElement = document.querySelector(':root');
		this.mapboxLogo = document.querySelector('.mapboxgl-ctrl-logo');
		this.colorLight = '#fff';
		this.colorDark = '#000';
		this.colorGold = '#af997e';
		this.frame = 0;
		this.frameThrottle = 5;

		this.setPixelPositions();
	}

	setPixelPositions() {
		const width = window.innerWidth;
		const height = window.innerHeight;

		if (!IS_PHONE) {
			this.positions = {
				topLeft: { x: 90, y: height - 90 },
				bottomLeftAlt: { x: 90, y: 135 },
				bottomLeft: { x: 90, y: 70 },
				topRight: { x: width - 100, y: height - 72 },
				bottomRight: { x: width - 70, y: 200 },
				topCenter: { x: width / 2, y: height - 85 },
				topCenterAlt: { x: width / 2, y: height - 160 },
				bottomCenter: { x: width / 2, y: 62 },
				centerLeft: { x: 20, y: height / 2 },
				mapboxLogo: { x: width - 80, y: 58 },
			};
		} else {
			this.positions = {
				topLeft: { x: 50, y: height - 50 },
				bottomLeftAlt: { x: 50, y: 30 },
				bottomLeft: { x: 50, y: 30 },
				topRight: { x: width - 55, y: height - 45 },
				bottomRight: { x: width - 60, y: 180 },
				topCenter: { x: width / 2, y: height - 45 },
				topCenterAlt: { x: width / 2, y: height - 144 },
				bottomCenter: { x: width / 2, y: 118 },
				centerLeft: { x: 20, y: height / 2 },
				mapboxLogo: { x: width - 52, y: 27 },
			};
		}
	}

	update() {
		if (this.frame === this.frameThrottle) {
			this.run();
			this.frame = 0;
		}
		this.frame++;
	}

	setForceDark(force) {
		this.forceDark = force;
		this.run();
		// Debug.trace('setForceDark:', force);
	}

	resize() {
		this.setPixelPositions();
		wait(0).then(this.run);
	}

	run() {
		if (!this.props) return;
		const { postProcessor } = this.props;
		const { forceDark } = this;
		let pixelIsDark = forceDark;

		// Debug.log('run');

		// top left
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.topLeft.x, this.positions.topLeft.y);

		// Debug.log('forceDark:', forceDark);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-tl', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-tl-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-tl', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-tl-hover', this.colorLight);
		}

		// bottom left alt
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.bottomLeftAlt.x, this.positions.bottomLeftAlt.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-bl-alt', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-bl-alt-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-bl-alt', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-bl-alt-hover', this.colorLight);
		}

		// bottom left
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.bottomLeft.x, this.positions.bottomLeft.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-bl', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-bl-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-bl', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-bl-hover', this.colorLight);
		}

		// top right
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.topRight.x, this.positions.topRight.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-tr', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-tr-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-tr', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-tr-hover', this.colorLight);
		}

		// bottom right
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.bottomRight.x, this.positions.bottomRight.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-br', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-br-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-br', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-br-hover', this.colorLight);
			// this.rootElement.style.setProperty('--nav-color-br-highlighted', this.colorLight);
		}

		// mapbox logo
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.mapboxLogo.x, this.positions.mapboxLogo.y);

		if (this.mapboxLogo) {
			if (pixelIsDark) {
				if (this.mapboxLogo) {
					this.mapboxLogo.style.backgroundImage = `url(${mapboxLogoLightSrc})`;
				}
			} else {
				if (this.mapboxLogo) {
					this.mapboxLogo.style.backgroundImage = `url(${mapboxLogoDarkSrc})`;
				}
			}
		}

		// top center
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.topCenter.x, this.positions.topCenter.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-tc', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-tc-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-tc', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-tc-hover', this.colorLight);
		}

		// top center-alt
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.topCenterAlt.x, this.positions.topCenterAlt.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-tc-alt', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-tc-alt-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-tc-alt', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-tc-alt-hover', this.colorLight);
		}

		// bottom center
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.bottomCenter.x, this.positions.bottomCenter.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-bc', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-bc-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-bc', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-bc-hover', this.colorLight);
		}

		// center left
		if (!forceDark) pixelIsDark = postProcessor.getPixelIsDark(this.positions.centerLeft.x, this.positions.centerLeft.y);

		if (pixelIsDark) {
			this.rootElement.style.setProperty('--nav-color-cl', this.colorLight);
			this.rootElement.style.setProperty('--nav-color-cl-hover', this.colorGold);
		} else {
			this.rootElement.style.setProperty('--nav-color-cl', this.colorDark);
			this.rootElement.style.setProperty('--nav-color-cl-hover', this.colorLight);
		}
	}

	dispose() {
		deleteAllProperties(this);
	}
}
