import React from 'react';
import classNames from 'classnames';

import './FormSubmitButton.scss';

function FormSubmitButton(props) {
  const { formSending, formSent, validate, formMessage, handleSubmit, className = "default" } = props;

  const submitClass = classNames(className, {
    'FormSubmitButton': true,
    'form-submit': true,
    'show-message': formSending || formSent,
    disabled: !validate(),
  });

  return (
    <div className={submitClass} onClick={handleSubmit}>
      <div className="form-submit-content">
        <div className="form-submit-message">{formMessage}</div>
        <div className="form-submit-button">
          <div className="form-submit-label">Send</div>
          <div className="form-submit-arrows" />
        </div>
      </div>
    </div>
  )
}

export default FormSubmitButton;