import React, { useState } from 'react';

import './Video.scss';

function Video(props) {
	const { src } = props;
	const [videoPlaying, setVideoPlaying] = useState(false);

	const handleVideoPlaying = () => {
		setVideoPlaying(true);
	};

	const videoStyle = {
		opacity: videoPlaying ? 1 : 0,
	};

	return (
		<video
			className="Video"
			src={src}
			style={videoStyle}
			autoPlay
			playsInline
			controls={false}
			loop
			muted
			onPlay={handleVideoPlaying}
		/>
	);
}

export default Video;
