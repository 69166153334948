/* eslint-disable react-hooks/exhaustive-deps */
// This module is the authority on routes within the application.

import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import routes from './routes';
import Location from 'js/components/Location/Location';
import AddToMap from '../AddToMap/AddToMap';
import Privacy from '../Privacy/Privacy';
import Terms from '../Terms/Terms';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import CityGuides from '../CityGuides/CityGuides';
import Search from '../SearchMapbox/SearchMapbox';
import Nav from '../Nav/Nav';
import ShareModal from '../ShareModal/ShareModal';
import { useAppState } from 'js/hooks/app-state';
import NationalGuides from '../NationalGuides/NationalGuides';
import About from '../About/About';
import CityLanding from '../CityLanding/CityLanding';
import CityGuide from '../CityGuide/CityGuide';
import NationalGuide from '../NationalGuide/NationalGuide';
import Intro from '../Intro/Intro';
import StandardCity from '../StandardCity/StandardCity';
import RotateDevice from '../RotateDevice/RotateDevice';
import { IS_DEVELOPMENT, IS_PHONE } from 'js/config/constants';
import NotFound from '../NotFound/NotFound';
import SavedLocations from '../SavedLocations/SavedLocations';
import SocialNational from '../SocialNational/SocialNational';
import DataSelector from '../DataSelector/DataSelector';
import FPSStats from 'react-fps-stats';
import { Debug } from 'js/utils/debug';
import OnBoarding from '../OnBoarding/OnBoarding';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';
import UserLocation from '../UserLocation/UserLocation';

const handlers = [
	[
		'home',
		{
			render: () => {
				return (
					<HelmetWrapper>
						<title>Black Elevation Map</title>
						<meta
							name="description"
							content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture."
						/>
					</HelmetWrapper>
				);
			},
		},
	],
	['intro', { component: Intro }],
	['location', { component: Location }],
	['poi', { render: () => null }],
	['about', { component: About }],
	['hamburgerMenu', { component: HamburgerMenu }],
	['cityGuides', { component: CityGuides }],
	['addToMap', { component: AddToMap }],
	['savedLocations', { component: SavedLocations }],
	['privacy', { component: Privacy }],
	['terms', { component: Terms }],
	['search', { component: Search }],
	['cityGuideLanding', { component: CityLanding }],
	['cityGuide', { component: CityGuide }],
	['standardCity', { component: StandardCity }],
	['standardCityNoState', { component: StandardCity }],
	['nationalGuide', { component: NationalGuide }],
	['nationalGuides', { component: NationalGuides }],
	['socialNational', { component: SocialNational }],
	['userLocation', { component: UserLocation }],
	['notFound', { component: NotFound }],
];

const Router = () => {
	const location = useLocation();
	const [
		{
			routesEnabled,
			dataSelectorVisible,
			dataSelectorEnabled,
			guideData,
			currentPoi,
			shareModal,
			helmetTitle,
			helmetChangeKey,
		},
	] = useAppState();
	const [orientation, setOrientation] = useState(0); // mobile
	// const [lastTrackTime, setLastTracktime] = useState(null);
	const searchRoute = useRouteMatch(routes.search);
	const exitBeforeEnter = IS_PHONE ? true : searchRoute ? false : true;
	const [trackingEnabled, setTrackingEnabled] = useState(false);

	useEffect(() => {
		if (IS_PHONE) {
			const handleResize = () => {
				if (window?.orientation !== undefined) {
					setOrientation(window?.orientation);
				} else if (window?.screen?.orientation?.angle !== undefined) {
					setOrientation(window.screen.orientation.angle);
				}
			};

			handleResize();

			window.addEventListener('resize', handleResize, false);
			window.addEventListener('orientationchange', handleResize, false);

			return () => {
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('orientationchange', handleResize);
			};
		}
	}, []);

	useEffect(() => {
		if (!trackingEnabled) {
			setTrackingEnabled(true);
			return;
		}

		Debug.log(`track page view: (path: ${window.location.pathname}) (title: ${helmetTitle})`);

		window.dataLayer.push({
			event: 'page_view',
			url: window.location.pathname,
			pageTitle: helmetTitle,
			pagePath: window.location.pathname,
		});
	}, [helmetChangeKey]);

	// useEffect(() => {
	// 	// Debug.warn(lastTrackTime, Date.now(), Date.now() - lastTrackTime);
	// 	const valid = !lastTrackTime || Date.now() - lastTrackTime > 100;
	// 	if (!valid) return;

	// 	setLastTracktime(Date.now());

	// 	Debug.log('track:', window.location.pathname + window.location.search, document.title);

	// 	// FIXME: need a way to grab title value from helmet instead of document and remove delay.
	// 	// FIXME: currently results in incorrect title if switching routes before 2500ms delay

	// 	wait(2500).then(() => {
	// 		Debug.log(`track page view: (path: ${window.location.pathname}) (title: ${document.title})`);
	// 		window.dataLayer.push({
	// 			event: 'page_view',
	// 			url: window.location.pathname,
	// 			pageTitle: document.title,
	// 			pagePath: window.location.pathname,
	// 		});
	// 	});
	// }, [window.location.pathname]);

	const canRenderDataSelector = routesEnabled && dataSelectorVisible;

	return (
		<>
			{IS_DEVELOPMENT && <FPSStats />}
			{IS_PHONE && orientation !== 0 && <RotateDevice />}
			<AnimatePresence>{canRenderDataSelector && <DataSelector enabled={dataSelectorEnabled} />}</AnimatePresence>
			<AnimatePresence>{routesEnabled && <Nav />}</AnimatePresence>
			<AnimatePresence exitBeforeEnter={exitBeforeEnter}>
				<Switch location={location} key={location.pathname}>
					{routesEnabled &&
						handlers.map(([key, handlerProps]) => <Route key={key} {...routes[key]} {...handlerProps} />)}
					{IS_PHONE && guideData && <Route key="landing" path={routes.landing.path} component={OnBoarding} />}
					{routesEnabled && <Route key="noMatch" component={NotFound} />}
				</Switch>
			</AnimatePresence>
			<AnimatePresence>
				{routesEnabled && shareModal && currentPoi && <ShareModal location={location}></ShareModal>}
			</AnimatePresence>
		</>
	);
};

export default Router;
