/* eslint-disable jsx-a11y/anchor-has-content */
import React, { memo, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import { useAppState } from 'js/hooks/app-state';
import Image from '../Image/Image';
import sample from 'lodash.samplesize';
import truncate from 'lodash.truncate';
import { generatePath, useHistory } from 'react-router-dom';
import routes from '../Router/routes';
import { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import OnBoardingSection from './OnBoardingSection';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import { MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import classNames from 'classnames';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';
import { Debug } from 'js/utils/debug';

import './OnBoarding.scss';

const POI_DESCRIPTION_LENGTH = 80;

function OnBoarding() {
	const [{ guideData, mapLoaded, skipOnBoardingAnimation }, { setState }] = useAppState();
	const [originalNationalGuides, setOriginalNationalGuides] = useState([]);
	const [originalCityGuides, setOriginalCityGuides] = useState([]);
	const [nationalGuides, setNationalGuides] = useState([]);
	const [cityGuides, setCityGuides] = useState([]);
	const [imageWidth, setImageWidth] = useState(window.innerWidth);
	const [dpr, setDPR] = useState(1);
	const history = useHistory();

	useEffect(() => {
		const guidesNational = guideData.filter((dataItem) => dataItem?.city === undefined);
		const guidesCity = guideData.filter((dataItem) => dataItem?.city !== undefined);

		const finalNationalGuides = [];
		const finalCityGuides = [];

		// clone so original doesn't get overridden & pull 2 random curated poi's to display
		guidesNational.forEach((guideItem) => {
			finalNationalGuides.push({
				...guideItem,
				curatedPois: sample(guideItem.curatedPois, 2),
			});
		});

		guidesCity.forEach((guideItem) => {
			finalCityGuides.push({
				...guideItem,
				curatedPois: sample(guideItem.curatedPois, 2),
			});
		});

		// Debug.log('finalNationalGuides:', finalNationalGuides);
		// Debug.log('finalCityGuides:', finalCityGuides);
		// Debug.log('guideData:', guideData);

		setOriginalNationalGuides(guidesNational);
		setOriginalCityGuides(guidesCity);
		setNationalGuides(finalNationalGuides);
		setCityGuides(finalCityGuides);
		setDPR(Math.min(window.devicePixelRatio || 1, 2));
		setImageWidth(window.innerWidth);
	}, [guideData]);

	const dismissModal = () => {
		setState({ routesEnabled: true });
	};

	const viewOnMap = (guideId, guideType) => {
		const isNational = guideType === CONTENT_TYPES.NATIONAL_GUIDE;
		const guideDataset = isNational ? originalNationalGuides : originalCityGuides;
		const route = isNational ? routes.nationalGuide.path : routes.cityGuide.path;
		let firstPoiId;

		guideDataset.forEach((guideItem) => {
			if (guideItem?.id === guideId) {
				firstPoiId = guideItem?.curatedPois[0]?.id;
			}
		});

		if (guideId && firstPoiId) {
			history.push({
				pathname: generatePath(route, { id: guideId }),
				search: `?poiId=${firstPoiId}`,
			});
		}

		dismissModal();
	};

	const handlePoi = (guideId, poiId, guideType) => {
		const route = guideType === CONTENT_TYPES.NATIONAL_GUIDE ? routes.nationalGuide.path : routes.cityGuide.path;
		history.push({
			pathname: generatePath(route, { id: guideId }),
			search: `?poiId=${poiId}`,
		});
		dismissModal();
	};

	const handleAbout = () => {
		history.push(routes.about.path);
		dismissModal();
	};

	const handlePrivacy = () => {
		history.push(routes.privacy.path);
		dismissModal();
	};

	const handleViewMap = () => {
		history.push(routes.home.path);
	};

	const initial = skipOnBoardingAnimation ? false : 'initial';

	const loaded = guideData?.length > 0;

	const mainClass = classNames('OnBoarding', {
		'no-scroll': !loaded,
	});

	const landingScrollMessageClass = classNames('scroll-message', {
		hidden: !loaded,
	});

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<motion.div
				className={mainClass}
				variants={MOTION_VARIANTS}
				initial={initial}
				animate="animate"
				exit="exit"
				custom={MOTION_PRESETS.SLIDE_FROM_BOTTOM}
				onAnimationComplete={() => {
					setState({ skipOnBoardingAnimation: false });
				}}
			>
				<div className="content">
					<div className="landing content-section">
						<div className="header">
							<div className="baa" />
							<p className="presents">Presents</p>
						</div>
						<div className="bem" />
						{!loaded && <div className="loader-spin" />}
						<div className={landingScrollMessageClass}>
							<div className="arrow" />
							<div className="header">Tour The Culture</div>
							<div className="subheader">
								Take a tour across America with these
								<br />
								culture-packed guides.
							</div>
						</div>
					</div>
					{nationalGuides.map((guideItem) => {
						return (
							<OnBoardingSection key={guideItem.id}>
								<div className="guide content-section">
									<div className="guide-image">
										<Image src={guideItem.coverImage.url} alt={guideItem.title} maxWidth={imageWidth * dpr} loading="lazy" />
									</div>
									<div className="guide-content">
										<div className="title">{guideItem.title}</div>
										<div className="description">{guideItem.guideDescription}</div>
										<div className="poi-container">
											{guideItem.curatedPois.map((poiItem, index) => {
												return (
													<div className="poi-item" key={index}>
														<div className="poi-image">
															<Image src={poiItem.coverImage.url} alt={poiItem.locationName} maxWidth={(imageWidth / 2) * dpr} loading="lazy" />
														</div>
														<div className="poi-title">{poiItem.title}</div>
														<div className="poi-subtitle">{poiItem.locationName}</div>
														<div className="poi-description">{truncate(poiItem.description, { length: POI_DESCRIPTION_LENGTH, separator: / ? +/ })}</div>
														<div
															className="learn-more-btn"
															onClick={() => handlePoi(guideItem.id, poiItem.id, CONTENT_TYPES.NATIONAL_GUIDE)}
															aria-label={`learn more - ${poiItem.locationName}`}
														>
															<div className="label" aria-hidden="true">
																Learn More
															</div>
															<div className="arrows" aria-hidden="true" />
														</div>
													</div>
												);
											})}
										</div>
										<div className="view-map-button" onClick={() => viewOnMap(guideItem.id, CONTENT_TYPES.NATIONAL_GUIDE)} aria-label={`view on map - ${guideItem.title}`}>
											<div className="icon" aria-hidden="true" />
											<div className="label" aria-hidden="true">
												View On Map
											</div>
										</div>
									</div>
								</div>
							</OnBoardingSection>
						);
					})}
					<OnBoardingSection>
						<div className="scroll-message">
							<div className="arrow lrg-gap" />
							<div className="header">City Guides</div>
							<div className="subheader">Explore The Culture in these American Cities</div>
						</div>
					</OnBoardingSection>
					{cityGuides
						.sort((a, b) => (a.city.cityId > b.city.cityId ? 1 : -1))
						.map((guideItem) => {
							return (
								<OnBoardingSection key={guideItem.id}>
									<div className="guide content-section">
										<div className="guide-image">
											<Image src={guideItem.coverImage.url} alt={`${guideItem.city.cityName}, ${guideItem.city.cityState}`} maxWidth={imageWidth * dpr} loading="lazy" />
										</div>
										<div className="guide-content">
											<div className="title">{`${guideItem.city.cityName}, ${guideItem.city.cityState}`}</div>
											<div className="description">{guideItem.title}</div>
											<div className="poi-container">
												{guideItem.curatedPois.map((poiItem, index) => {
													return (
														<div className="poi-item" key={index}>
															<div className="poi-image">
																<Image src={poiItem.coverImage.url} alt={poiItem.locationName} maxWidth={(imageWidth / 2) * dpr} loading="lazy" />
															</div>
															<div className="poi-title">{poiItem.title}</div>
															<div className="poi-subtitle">{poiItem.locationName}</div>
															<div className="poi-description">{truncate(poiItem.description, { length: POI_DESCRIPTION_LENGTH, separator: / ? +/ })}</div>
															<div
																className="learn-more-btn"
																onClick={() => handlePoi(guideItem.id, poiItem.id, CONTENT_TYPES.CITY_GUIDE)}
																aria-label={`learn more - ${poiItem.locationName}`}
															>
																<div className="label" aria-hidden="true">
																	Learn More
																</div>
																<div className="arrows" aria-hidden="true" />
															</div>
														</div>
													);
												})}
											</div>
											<div className="view-map-button" onClick={() => viewOnMap(guideItem.id, CONTENT_TYPES.CITY_GUIDE)} aria-label={`view on map - ${guideItem.title}`}>
												<div className="icon" aria-hidden="true" />
												<div className="label" aria-hidden="true">
													View On Map
												</div>
											</div>
										</div>
									</div>
								</OnBoardingSection>
							);
						})}
					<OnBoardingSection>
						<div className="about content-section">
							<div className="header">
								<div className="arrow lrg-gap" />
								<div className="title">What Is The Black Elevation Map?</div>
							</div>
							<div className="media-embed">
								<iframe
									src="https://www.youtube.com/embed/hrCLlX-W6F8?modestbranding=1&playsinline=1"
									title="Black Elevation Map"
									width="100%"
									height="212"
									frameBorder="0"
									allowFullScreen={true}
								></iframe>
							</div>
							<div className="footer">
								<div className="copy">
									The Black Elevation Map is an immersive digital experience that reimagines the American landscape by visualizing the heights of The Culture as elevation. The
									platform takes cultural data, such as Black population data, historical markers, Black-owned businesses, and social media activity, and visualizes it as points of
									interest on a dynamic, searchable elevation map of the United States. The greater the density of data, the higher the elevation.
								</div>
								<div className="learn-more-btn" onClick={handleAbout} aria-label="learn more - about">
									<div className="label" aria-hidden="true">
										Learn More
									</div>
									<div className="arrows" aria-hidden="true" />
								</div>
							</div>
						</div>
					</OnBoardingSection>
					<OnBoardingSection>
						<div className="bottom content-section">
							<div className="scroll-message dark">
								<div className="arrow lrg-gap" />
								<div className="header" onClick={handleViewMap}>
									View The Map
								</div>
								<div className="subheader">See the American landscape reimagined as a searchable travel experience &#8212; elevated by Black cultural data.</div>
								<div className="learn-more-btn dark" onClick={handleViewMap} aria-label="learn more - view the map">
									<div className="label" aria-hidden="true">
										Learn More
									</div>
									<div className="arrows" aria-hidden="true" />
								</div>
							</div>
							<div className="footer">
								<div>&copy;{new Date().getFullYear()} Black &amp; Abroad</div>
								<a href="https://www.blackandabroad.com/aboutus" target="_blank" rel="noopener noreferrer">
									Contact Us
								</a>
								<div onClick={handlePrivacy}>Privacy</div>
								<a className="twitter" href="https://twitter.com/blackandabroad" target="_blank" rel="noopener noreferrer" aria-label="Twitter Link" />
								<a className="instagram" href="https://www.instagram.com/blackandabroad/" target="_blank" rel="noopener noreferrer" aria-label="Instagram Link" />
							</div>
						</div>
					</OnBoardingSection>
				</div>
				{mapLoaded && <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 0 }} lightTheme={true} />}
			</motion.div>
		</>
	);
}

export default memo(OnBoarding);
