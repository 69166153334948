/* eslint-disable import/no-anonymous-default-export */
import { getGPUTier } from 'detect-gpu';
import { Vector2 } from 'three/build/three.module';
import { getParameterByName } from './utils';
import { IS_MOBILE, PUBLIC_URL } from '../config/constants';

export const GRAPHICS_HIGH = 'high';
export const GRAPHICS_NORMAL = 'normal';
export const GRAPHICS_LOW = 'low';
export const GRAPHICS_MODES = [GRAPHICS_HIGH, GRAPHICS_NORMAL, GRAPHICS_LOW];
export const RESOLUTIONS = {
	HIGH: new Vector2(1920, 1080),
	MID: new Vector2(1440, 900),
	LOW: new Vector2(1280, 720),
};
export const ASSET_QUALITY = {
	HIGH: 'high',
	LOW: 'low',
};

let graphicsMode = GRAPHICS_NORMAL;
let gpuTier;
let ctx;

let graphicsModeParam, tierParam;

export function initGraphics(abortSignal) {
	return new Promise((resolve, reject) => {
		abortSignal.addEventListener('abort', () => {
			reject('Aborted initGraphics');
		});

		(async () => {
			gpuTier = await getGPUTier({
				benchmarksURL: `${PUBLIC_URL}/assets/benchmarks`,
			});

			graphicsModeParam = getParameterByName('graphics');
			tierParam = getParameterByName('tier');

			if (GRAPHICS_MODES.includes(graphicsModeParam) && typeof graphicsModeParam === 'string') {
				graphicsMode = graphicsModeParam;
			} else {
				graphicsMode = profiler();
			}
			resolve();
		})();
	});
}

export function getGraphicsMode() {
	return graphicsMode;
}

export function profiler() {
	if (gpuTier.tier > 1) {
		return GRAPHICS_HIGH;
	} else if (gpuTier.tier > 0) {
		return GRAPHICS_NORMAL;
	} else {
		return GRAPHICS_LOW;
	}
}

// export function getTier() {
// 	return gpuTier?.tier;
// }

export function getTier() {
	let gpu = getGPU();

	// Fix intel chipset having tier 2 but very old
	let tier = gpuTier?.tier || 0;

	if (!gpu) {
		// Check for Apple chipset
		ctx = ctx || document.createElement('canvas').getContext('webgl');
		const info = ctx.getExtension('WEBGL_debug_renderer_info');
		const g = (info && ctx.getParameter(info.UNMASKED_RENDERER_WEBGL)) || '';

		if (g.match(/Apple/) && !g.match(/Apple GPU/)) {
			tier = 2;
			if (gpuTier) {
				gpuTier.gpu = g;
				graphicsMode = graphicsModeParam || GRAPHICS_HIGH;
			}
		}
	} else {
		if (tier > 0 && (gpu.indexOf('iris') > 0 || gpu.indexOf('iris pro') > 0)) {
			let splitted = gpu.split(' ');
			let number = splitted[splitted.length - 1];
			number = parseInt(number, 10);
			if (!isNaN(number) && number < 650) {
				tier = 0;
			}
		}

		if (gpu.indexOf('hd graphics') > 0) {
			tier = 0;
		}

		// intel chipset
		if (gpu.indexOf('intel') > 0) {
			tier = Math.min(tier, 1);
		}

		// apple chipeset
		if (gpu.indexOf('Apple') > -1) {
			tier = 2;
			graphicsMode = graphicsModeParam || GRAPHICS_HIGH;
		}
	}

	if (tierParam) tier = tierParam;

	return tier;
}

export function getGPU() {
	return gpuTier?.gpu;
}

export function getScreenDimensions() {
	return new Vector2(window.screen.width, window.screen.height);
}

export function isSmallScreen() {
	const dimensions = getScreenDimensions();
	if (dimensions.x * dimensions.y <= RESOLUTIONS.MID.x * RESOLUTIONS.MID.y) {
		return true;
	}

	return false;
}

export default {
	[GRAPHICS_HIGH]: {
		antialias: false, // Enable antialias if you're not using post processing
		pixelRatio: Math.min(window.devicePixelRatio || 1, 2),
		maxFrameBufferSize: IS_MOBILE ? RESOLUTIONS.MID : RESOLUTIONS.HIGH,
		assetQuality: IS_MOBILE ? ASSET_QUALITY.LOW : ASSET_QUALITY.HIGH,
	},
	[GRAPHICS_NORMAL]: {
		antialias: false,
		pixelRatio: Math.min(window.devicePixelRatio || 1, 1.5),
		maxFrameBufferSize: !IS_MOBILE ? (isSmallScreen() ? RESOLUTIONS.MID : RESOLUTIONS.HIGH) : RESOLUTIONS.MID,
		assetQuality: !IS_MOBILE ? (isSmallScreen() ? ASSET_QUALITY.LOW : ASSET_QUALITY.HIGH) : ASSET_QUALITY.LOW,
	},
	[GRAPHICS_LOW]: {
		antialias: false,
		pixelRatio: 1,
		maxFrameBufferSize: RESOLUTIONS.LOW,
		assetQuality: ASSET_QUALITY.LOW,
	},
};
