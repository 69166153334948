import { useState } from 'react';

export function useFormFields(initialState) {
	const [fields, setValues] = useState(initialState);

	return [
		fields,
		function (event) {
			setValues({
				...fields,
				[event.target.id]: event._valueKeyOverride ? event.target[event._valueKeyOverride] : event.target.value,
			});
		},
		function () {
			setValues(initialState);
		},
	];
}
