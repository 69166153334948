import { useAppState } from 'js/hooks/app-state';
import React, { memo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { MathUtils } from 'three/build/three.module';
import { Debug } from 'js/utils/debug';

function HelmetWrapper(props) {
	const [, { setState }] = useAppState();
	const [changeKey, setChangeKey] = useState(MathUtils.generateUUID());

	const setTitle = (title) => {
		if (!title.includes('undefined')) setState({ helmetTitle: title, helmetChangeKey: changeKey });
	};

	return <Helmet onChangeClientState={(newState) => setTitle(newState.title)}>{props?.children}</Helmet>;
}

export default memo(HelmetWrapper);
