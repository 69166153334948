export const STATS = {
	BLACK_OWNED_BUSINESSES: {
		title: 'Black-Owned\nBusinesses',
		description: 'Self-reported Black-owned business data from Google & Yelp.',
	},
	BLACK_POPULATION: {
		title: 'Black\nPopulation',
		description:
			'The number of people who self-identify as "Black Only" or "Black and Other Race(s)" based on the Decennial 2020 U.S. Census.',
	},
	TOTAL_POPULATION: {
		title: 'Total\nPopulation',
		description: 'The total population of this city based on the Decennial 2020 U.S. Census.',
	},
	HISTORICAL_MARKERS: {
		title: 'Historical\nMarkers',
		description: 'Historical sites and landmarks tagged "African American" from The Historical\nMarker Database.',
	},
	STANDARD_CITY_NO_STATS: {
		body: 'Own a business or organization in this region?\nPut it on the map.',
	},
};

export const POWERED_BY = 'Powered By\nThe Culture';

export const ABOUT = {
	ID_WHAT: 'what-is-the-black-elevation-map',
	ID_WHY: 'why-a-map',
	ID_WHERE: 'wheres-the-data-coming-from',
	ID_HOW: 'how-does-it-work',
	ID_ICONS: 'about-the-map-icons',
	ID_WHO: 'who-is-black-and-abroad',
	ID_BACK: 'the-go-back-to-africa-proejct',
	ID_LAST: 'one-last-thing',
	ID_CONTRIBUTORS: 'contributors',
	ID_PARTNERS: 'partners',
	ID_SUBSCRIBE: 'subscribe',
};
