import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
  EASE_OUT,
} from 'js/utils/motion';
import { locationIcons } from 'js/config/asset-manifest';

import './AboutIcons.scss';

const containerMotion = {
  initial: { 
    opacity: 0 
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: EASE_OUT,
    }
  },
  exit: { 
    opacity: 0 
  }
};

function AboutIcons(props) {
  const { content, animOnce } = props;
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <motion.div 
      className="AboutIcons"
      variants={containerMotion}
      initial="initial"
      animate={anim}
      ref={ref}
    >
      { content.map((d,i) => {
        const { description, label, icon } = d;
        
        const iconsrc = locationIcons.white[icon];
        return (
          <motion.div 
            className="icon-el"
            variants={MOTION_VARIANTS}
            custom={MOTION_PRESETS.FADE_SLIDE_FROM_RIGHT}
            key={i}
          >
            <div className="icon-image">
              <img src={iconsrc} aria-hidden="true" />
            </div>
            <div className="icon-info">
              <div className="icon-icon-label">{label}</div>
              <div className="icon-desc"><p>{description}</p></div>
            </div>
          </motion.div>
        )
      })}
    </motion.div>
  )
}

export default AboutIcons;