import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
  EASE_OUT,
} from 'js/utils/motion';

import classNames from 'classnames';

import './AboutFonts.scss';

const containerMotion = {
  initial: { 
    opacity: 0 
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: EASE_OUT,
    }
  },
  exit: { 
    opacity: 0 
  }
};


function AboutFonts(props) {
  const { content, animOnce } = props;
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <motion.div 
      className="AboutFonts" 
      variants={containerMotion}
      initial="initial"
      animate={anim}
      ref={ref}
    >
      { content.map((d,i) => {
        const { cls, image, credit, name, description } = d;
        const fontClass = classNames('font-name', cls);
        return (
          <div className="font-block" key={i}>
            <div className="font-image">
              <motion.div 
                className="image-motion-wrap"
                variants={MOTION_VARIANTS}
                custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
              >
                <img {...image}/>
              </motion.div>
            </div>
            <motion.div
              variants={MOTION_VARIANTS}
              custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
            >
              { credit &&
                <span className="image-credit">{credit}</span>
              }
              <h3 className={fontClass}>{name}</h3>
              <div className="font-desc" dangerouslySetInnerHTML={{__html:description}} ></div>
            </motion.div>
          </div>
        )
      })}
    </motion.div>
  )
}

export default AboutFonts;
