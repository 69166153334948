import React from 'react';

import './RotateDevice.scss';

function RotateDevice() {
	return (
		<div className="RotateDevice">
			<div className="device" />
			<div className="message">
				experience best viewed in
				<br />
				portrait mode only
			</div>
		</div>
	);
}

export default RotateDevice;
