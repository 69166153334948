import { IS_PHONE } from 'js/config/constants';
import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import ContentFooter from '../ContentFooter/ContentFooter';
import { MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { COMMON_DURATION, EASE_IN_OUT, EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION, STAGGER_DELAY } from 'js/utils/motion';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './Terms.scss';

const delay = 0.6;

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateY(50px)',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
};

const motionProps0 = MOTION_PRESETS.FADE;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY },
});

function Terms() {
	const containerRef = useRef(null);

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);

			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
			};
		}
	}, []);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Terms & Conditions</title>
				<meta name="description" content="Explore a new map of America - with curated guides, 30,000 points of interest and the millions who make it rise." />
			</HelmetWrapper>
			<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} className="Terms HamburgerMenu" ref={containerRef}>
				<div className="HamburgerMenu-inner">
					<div className="pad-container">
						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="title">
							Terms & Conditions
						</motion.div>
						<div className="scroll-container">
							<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="Terms-copy">
								<p>
									At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi
									sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis
									est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis
									voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
									repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
									perferendis doloribus asperiores repellat.
								</p>

								<p>
									At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi
									sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis
									est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis
									voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
									repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
									perferendis doloribus asperiores repellat.
								</p>

								<p>
									At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi
									sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis
									est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis
									voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates
									repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut
									perferendis doloribus asperiores repellat.
								</p>
							</motion.div>
						</div>

						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} className="footer-container">
							<ContentFooter />
						</motion.div>
					</div>
				</div>
				{IS_PHONE ? <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 22 }} lightTheme={true} /> : <CloseButtonX />}
			</motion.div>
		</>
	);
}

export default Terms;
