import { deleteAllProperties } from 'js/utils/utils';
import { Debug } from 'js/utils/debug';
import CameraController from './camera-controller';
import { mapProps } from 'js/components/Map/Map';

export default class CaseStudyController {
	constructor(props) {
		CaseStudyController.instance = this;
		this.props = props;
		this.handleKey = this.handleKey.bind(this);
		this.init();
	}

	init() {
		this.uiOn = true;
		this.poisOn = true;
		this.forceBasicMotion = false;

		this.keys = [
			'KeyQ',
			'KeyW',
			'KeyE',
			'KeyR',
			'KeyT',
			'KeyY',
			'KeyU',
			'KeyI',
			'KeyO',
			'KeyP',
			'KeyA',
			'KeyS',
			'KeyD',
			'KeyF',
			'KeyG',
			'KeyH',
			'KeyJ',
			'KeyK',
			'KeyL',
			'KeyZ',
			'KeyX',
			'KeyC',
			'KeyV',
			'KeyB',
			'KeyN',
			'KeyM',
		];

		const storedViewConfig = window.localStorage?.getItem('viewConfig');
		this.viewConfig = storedViewConfig ? JSON.parse(storedViewConfig) : new Array(11); // 0-6 & qwerty
		this.viewConfig[0] = {
			center: [-75.10438252002503, 41.74317571835908],
			bearing: 35.39,
			pitch: 62.52,
			zoom: 5.47,
		};

		const storedUrlConfig = window.localStorage?.getItem('urlConfig');
		this.urlConfig = storedUrlConfig ? JSON.parse(storedUrlConfig) : new Array(20); // alphabet - qwerty
		this.urlConfig[0] = {
			pathname: '/city-guide/6nAda0in59DEYoK3QMZlRJ',
			search: '?poiId=3Usrh8KwadFzx5OwmhDQzl',
		};

		this.addListeners();
	}

	toggleUI(uiOnOverride) {
		this.uiOn = uiOnOverride !== undefined ? uiOnOverride : !this.uiOn;
		const value = this.uiOn ? 'visible' : 'hidden';
		document.querySelector('.Nav')?.style?.setProperty('visibility', value);
		document.querySelector('.DataSelector')?.style?.setProperty('visibility', value);
		document.querySelector('.CurrentLocation')?.style?.setProperty('visibility', value);
		document.querySelector('.Home')?.style?.setProperty('visibility', value);
		document.querySelector('.search-container')?.style?.setProperty('visibility', value);
		document.querySelector('.NationalGuidesCTA')?.style?.setProperty('visibility', value);
		document.querySelector('.MapLegend')?.style?.setProperty('visibility', value);
		document.querySelector('.mapboxgl-ctrl-logo')?.style?.setProperty('visibility', value);
		document.querySelector('.mapboxgl-ctrl-attrib')?.style?.setProperty('visibility', value);

		// if (!this.uiOn) {
		// 	document
		// 		.querySelector('.map-container')
		// 		?.classList.add('cluster', 'curated', 'saved', 'entertainment', 'see', 'beauty', 'self-care', 'shop', 'stay', 'learn', 'know', 'flex', 'eat', 'active');
		// } else {
		// 	document
		// 		.querySelector('.map-container')
		// 		?.classList.remove('cluster', 'curated', 'saved', 'entertainment', 'see', 'beauty', 'self-care', 'shop', 'stay', 'learn', 'know', 'flex', 'eat', 'active');
		// }
	}

	togglePois() {
		this.poisOn = !this.poisOn;

		if (!this.poisOn) {
			document
				.querySelector('.map-container')
				?.classList.add(
					'cluster',
					'curated',
					'saved',
					'entertainment',
					'see',
					'beauty',
					'self-care',
					'shop',
					'stay',
					'learn',
					'know',
					'flex',
					'eat',
					'active'
				);
		} else {
			document
				.querySelector('.map-container')
				?.classList.remove(
					'cluster',
					'curated',
					'saved',
					'entertainment',
					'see',
					'beauty',
					'self-care',
					'shop',
					'stay',
					'learn',
					'know',
					'flex',
					'eat',
					'active'
				);
		}
	}

	saveCameraView(index = 0) {
		const { map } = mapProps;

		const center = map.getCenter();

		this.viewConfig[index] = {
			center: [center.lng, center.lat],
			bearing: map.getBearing(),
			pitch: map.getPitch(),
			zoom: map.getZoom(),
		};

		window.localStorage?.setItem('viewConfig', JSON.stringify(this.viewConfig));
	}

	triggerCameraView(index = 0) {
		if (!this.viewConfig[index]) return;

		const { center, bearing, pitch, zoom } = this.viewConfig[index];

		CameraController.instance?.flyTo(center, {
			bearing,
			pitch,
			zoom,
			duration: 2500,
		});
	}

	saveUrl(index = 0) {
		const { pathname, search } = window.location;

		this.urlConfig[index] = {
			pathname,
			search,
		};

		window.localStorage?.setItem('urlConfig', JSON.stringify(this.urlConfig));
	}

	triggerUrl(index = 0) {
		if (!this.urlConfig[index]) return;

		const {
			props: { history },
		} = this;

		const { pathname, search } = this.urlConfig[index];
		history.push(`${pathname}${search}`);
	}

	handleKey(e) {
		Debug.log('handleKey:', e.code, e.shiftKey);

		const {
			props: { setState },
		} = this;

		setState({ showHomeCTA: false });

		const terrain = mapProps?.layers?.elevation?.terrain;

		switch (e.code) {
			case 'Escape':
			case 'Slash':
				this.toggleUI();
				break;

			case 'Period':
				this.togglePois();
				break;

			// set morph speed to 1
			case 'Backslash': // \
				this.forceBasicMotion = !this.forceBasicMotion;
				break;

			// flatten map
			case 'Backquote': // `
				if (e.shiftKey) {
					terrain?.setDurationScalar(1);
				} else if (e.ctrlKey) {
					CameraController.instance?.setDurationScalar(1);
				} else {
					terrain?.flatten(terrain?.isSemiFlat ? false : true);
				}
				break;

			case 'Digit1':
				if (e.shiftKey) {
					terrain?.setDurationScalar(0.1);
				} else if (e.ctrlKey) {
					CameraController.instance?.setDurationScalar(0.1);
				} else {
					terrain?.flatten(false);
					setState({ activeDatasetId: 0 });
				}
				break;

			case 'Digit2':
				if (e.shiftKey) {
					terrain?.setDurationScalar(0.2);
				} else if (e.ctrlKey) {
					CameraController.instance?.setDurationScalar(0.2);
				} else {
					terrain?.flatten(false);
					setState({ activeDatasetId: 4 });
				}
				break;

			case 'Digit3':
				if (e.shiftKey) {
					terrain?.setDurationScalar(0.3);
				} else if (e.ctrlKey) {
					CameraController.instance?.setDurationScalar(0.3);
				} else {
					terrain?.flatten(false);
					setState({ activeDatasetId: 2 });
				}
				break;

			case 'Digit4':
				if (e.shiftKey) {
					terrain?.setDurationScalar(0.4);
				} else if (e.ctrlKey) {
					CameraController.instance?.setDurationScalar(0.4);
				} else {
					terrain?.flatten(false);
					setState({ activeDatasetId: 1 });
				}
				break;

			case 'Digit5':
				if (e.shiftKey) {
					terrain?.setDurationScalar(0.5);
				} else if (e.ctrlKey) {
					CameraController.instance?.setDurationScalar(0.5);
				} else {
					terrain?.flatten(false);
					setState({ activeDatasetId: 3 });
				}
				break;

			case 'Digit6':
				if (e.shiftKey) {
					this.saveCameraView(0);
				} else {
					this.triggerCameraView(0);
				}
				break;

			case 'Digit7':
				if (e.shiftKey) {
					this.saveCameraView(1);
				} else {
					this.triggerCameraView(1);
				}
				break;

			case 'Digit8':
				if (e.shiftKey) {
					this.saveCameraView(2);
				} else {
					this.triggerCameraView(2);
				}
				break;

			case 'Digit9':
				if (e.shiftKey) {
					this.saveCameraView(3);
				} else {
					this.triggerCameraView(3);
				}
				break;

			case 'Digit0':
				if (e.shiftKey) {
					this.saveCameraView(4);
				} else {
					this.triggerCameraView(4);
				}
				break;

			case 'KeyQ':
				if (e.shiftKey) {
					this.saveCameraView(5);
				} else {
					this.triggerCameraView(5);
				}
				break;

			case 'KeyW':
				if (e.shiftKey) {
					this.saveCameraView(6);
				} else {
					this.triggerCameraView(6);
				}
				break;

			case 'KeyE':
				if (e.shiftKey) {
					this.saveCameraView(6);
				} else {
					this.triggerCameraView(6);
				}
				break;

			case 'KeyR':
				if (e.shiftKey) {
					this.saveCameraView(7);
				} else {
					this.triggerCameraView(7);
				}
				break;

			case 'KeyT':
				if (e.shiftKey) {
					this.saveCameraView(8);
				} else {
					this.triggerCameraView(8);
				}
				break;

			case 'KeyY':
				if (e.shiftKey) {
					this.saveCameraView(9);
				} else {
					this.triggerCameraView(9);
				}
				break;

			default:
				const index = this.keys.findIndex((value) => value === e.code);
				if (index > -1) {
					if (e.shiftKey) {
						this.saveUrl(index);
					} else {
						this.triggerUrl(index);
					}
				}
				break;
		}
	}

	addListeners() {
		window.addEventListener('keydown', this.handleKey, false);
	}

	removeListeners() {
		window.removeEventListener('keydown', this.handleKey);
	}

	dispose() {
		this.removeListeners();
		deleteAllProperties(this);
	}
}
