/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import routes from '../Router/routes';
import { motion } from 'framer-motion';
import {
	COMMON_DURATION,
	EASE_OUT,
	MOTION_PRESETS,
	MOTION_VARIANTS,
	overrideMotionVariants,
	STAGGER_DELAY,
} from 'js/utils/motion';
import { mapProps } from '../Map/Map';
import { easings } from 'js/utils/easings';
import { useAppState } from 'js/hooks/app-state';
import {
	DEBUG_USER_LOCATION,
	IS_PHONE,
	IS_TABLET,
	TEST_USER_LOCATION,
	TOOL_TIPS,
	USE_TEST_USER_LOCATION,
	ZOOM_LEVELS,
} from 'js/config/constants';
import classNames from 'classnames';
import CameraController from 'js/controllers/camera-controller';
import ToolTip from '../ToolTip/ToolTip';
import { wait } from 'js/utils/utils';
import { Debug } from '../../utils/debug';
import { getGeocodeDataForCoordinates } from 'js/utils/map-data-utils';

import './Nav.scss';

const motionProps = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: COMMON_DURATION,
			staggerChildren: STAGGER_DELAY,
			ease: EASE_OUT,
		},
	},
	exit: {
		opacity: 0,
	},
};

const elementMotionProps = overrideMotionVariants({
	...MOTION_PRESETS.FADE_SLIDE_FROM_TOP,
	enterTransProps: { ease: EASE_OUT, duration: 0.4 },
});

const ZOOM_INCREMENT = 1;

function Nav() {
	const history = useHistory();
	const [{ audioOn }, { setState }] = useAppState();
	const [zoomEnabled, setZoomEnabled] = useState(true);
	const [locationLoading, setLocationLoading] = useState(false);
	const [previousRoute, setPreviousRoute] = useState('');
	const [haveGoneToUserLocation, setHaveGoneToUserLocation] = useState(false);
	const [userIsInUSA, setUserIsInUSA] = useState(true);

	// const landingRoute = useRouteMatch(routes.landing);
	const homeRoute = useRouteMatch(routes.home);
	const nationalGuidesRoute = useRouteMatch(routes.nationalGuides);
	const searchRoute = useRouteMatch(routes.search);
	const menuRoute = useRouteMatch(routes.hamburgerMenu);
	const privacyRoute = useRouteMatch(routes.privacy);
	const termsRoute = useRouteMatch(routes.terms);
	const addToMapRoute = useRouteMatch(routes.addToMap);
	const cityGuideRoute = useRouteMatch(routes.cityGuides);
	const menuOpen =
		nationalGuidesRoute || searchRoute || menuRoute || privacyRoute || termsRoute || addToMapRoute || cityGuideRoute;
	const location = useLocation();

	useEffect(() => {
		if (IS_PHONE) {
			Debug.log(
				'location changed - request user location:',
				!haveGoneToUserLocation,
				Boolean(homeRoute),
				previousRoute === routes.landing.path
			);

			if (!haveGoneToUserLocation && Boolean(homeRoute) && previousRoute === routes.landing.path) {
				// if (USE_TEST_USER_LOCATION) {
				// 	const { lon, lat } = TEST_USER_LOCATION;
				// 	flyToUserLocation(lon, lat, ZOOM_LEVELS.MARKERS_VISIBLE);
				// } else {
				// 	getUserLocation()
				// 		.then(({ lon, lat }) => {
				// 			flyToUserLocation(lon, lat, ZOOM_LEVELS.MARKERS_VISIBLE);
				// 		})
				// 		.catch((error) => Debug.warn(error));
				// }
				goToUserLocation();
				setHaveGoneToUserLocation(true);
			}

			setPreviousRoute(location.pathname);
		}
	}, [location]);

	// const handleUserLocation = useCallback(
	// 	({ lon, lat }) => {
	// 		if (homeRoute) {
	// 			Debug.warn('GO TO USER LOCATION:', location);
	// 			flyToUserLocation(lon, lat);
	// 		} else {
	// 			Debug.warn('GO TO USER LOCATION - CANCELLED');
	// 		}
	// 	},
	// 	[location.pathname]
	// );

	const { reactProps, map } = mapProps;

	const hamburgerClass = classNames({
		hamburger: true,
		hidden: IS_PHONE && menuOpen,
	});

	const stopEvent = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};

	const reset = (e) => {
		stopEvent(e);
		setZoomEnabled(false);
		wait(100).then(() => {
			reactProps?.goToNationalView(2000, easings.easeInOutCubic).then(() => setZoomEnabled(true));
		});
	};

	const zoomIn = (e) => {
		stopEvent(e);
		if (map) {
			setZoomEnabled(false);
			const zoom = map.getZoom() + ZOOM_INCREMENT;
			CameraController.instance?.zoomTo(zoom, { duration: 1000 }).then(() => setZoomEnabled(true));
		}
	};

	const zoomOut = (e) => {
		stopEvent(e);
		if (map) {
			setZoomEnabled(false);
			const zoom = map.getZoom() - ZOOM_INCREMENT;
			CameraController.instance?.zoomTo(zoom, { duration: 1000 }).then(() => setZoomEnabled(true));
		}
	};

	const turnAudioOff = (e) => {
		stopEvent(e);
		setState({ audioOn: false });
	};

	const turnAudioOn = (e) => {
		stopEvent(e);
		setState({ audioOn: true });
	};

	const getUserLocation = () => {
		return new Promise((resolve, reject) => {
			setLocationLoading(true);
			navigator.geolocation.getCurrentPosition(
				(position) => {
					Debug.log('goToUserLocation - success:', position);

					if (position) {
						const {
							coords: { longitude, latitude },
						} = position;

						getGeocodeDataForCoordinates(longitude, latitude).then((response) => {
							Debug.log('getGeocodeDataForCoordinates:', response);
							let isUSA = true;
							response?.features?.forEach((feature) => {
								if (feature?.place_type?.includes('country')) {
									isUSA = feature?.place_name === 'United States';
								}
							});

							if (isUSA || DEBUG_USER_LOCATION) {
								resolve({ lon: longitude, lat: latitude });
							} else {
								reject('error: user is not in USA');
							}

							setUserIsInUSA(isUSA);
						});
					} else {
						reject('error: no position found for user location');
					}

					setLocationLoading(false);
				},
				(error) => {
					setLocationLoading(false);
					reject(error);
					Debug.warn('goToUserLocation - error:', error);
				}
			);
		});
	};

	const flyToUserLocation = (lon, lat, zoom = ZOOM_LEVELS.CITY) => {
		return CameraController.instance?.flyTo([lon, lat], {
			offset: [0, 0],
			zoom,
		});
	};

	const goToUserLocation = (showNearByPois = true, zoom = ZOOM_LEVELS.CITY) => {
		setLocationLoading(true);
		if (USE_TEST_USER_LOCATION) {
			const { lon, lat } = TEST_USER_LOCATION;
			setLocationLoading(false);
			flyToUserLocation(lon, lat, zoom).then(() => {
				if (showNearByPois) {
					history.push({ pathname: generatePath(routes.userLocation.path, { lon, lat }) });
				}
			});
		} else {
			getUserLocation()
				.then(({ lon, lat }) => {
					setLocationLoading(false);
					flyToUserLocation(lon, lat, zoom).then(() => {
						if (showNearByPois) {
							history.push({ pathname: generatePath(routes.userLocation.path, { lon, lat }) });
						}
					});
				})
				.catch((error) => Debug.warn(error));
		}
	};

	const resetClass = classNames({
		reset: true,
		disabled: !zoomEnabled,
	});

	const zoomInClass = classNames({
		'zoom-in': true,
		disabled: !zoomEnabled,
	});

	const zoomOutClass = classNames({
		'zoom-out': true,
		disabled: !zoomEnabled,
	});

	return (
		<motion.div className="Nav" variants={motionProps} initial="initial" animate="animate" exit="exit">
			<div className="group-top-left">
				<motion.div
					className="nav-bem"
					onClick={(e) => {
						reset(e);
						setState({ activeDatasetId: 0 });
						history.push(IS_PHONE ? routes.landing.path : routes.nationalGuides.path);
					}}
					variants={MOTION_VARIANTS}
					custom={elementMotionProps}
				/>
				{!IS_PHONE && (
					<motion.div
						className="social"
						onClick={() => history.push(routes.socialNational.path)}
						variants={MOTION_VARIANTS}
						custom={elementMotionProps}
					/>
				)}
				{!IS_PHONE && (
					<motion.div
						className="saved"
						onClick={() => history.push(routes.savedLocations.path)}
						variants={MOTION_VARIANTS}
						custom={elementMotionProps}
					/>
				)}
			</div>

			<div className="group-bottom-left">
				{!IS_PHONE && !IS_TABLET && (
					<div className="map-controls">
						<motion.div className={resetClass} onClick={reset} variants={MOTION_VARIANTS} custom={elementMotionProps}>
							<ToolTip className="tooltip" leftSide={true} label={TOOL_TIPS.RESET} />
						</motion.div>
						<motion.div
							className={zoomInClass}
							onClick={zoomIn}
							variants={MOTION_VARIANTS}
							custom={elementMotionProps}
						>
							<ToolTip className="tooltip" leftSide={true} label={TOOL_TIPS.ZOOM_IN} />
						</motion.div>
						<motion.div
							className={zoomOutClass}
							onClick={zoomOut}
							variants={MOTION_VARIANTS}
							custom={elementMotionProps}
						>
							<ToolTip className="tooltip" leftSide={true} label={TOOL_TIPS.ZOOM_OUT} />
						</motion.div>
					</div>
				)}
				<motion.div
					className="nav-baa"
					onClick={() => window.open('https://www.weareblackandabroad.com/')}
					variants={MOTION_VARIANTS}
					custom={elementMotionProps}
				/>
			</div>

			<div className="group-top-right">
				{IS_PHONE && (
					<motion.div
						className="search"
						onClick={() => history.push(routes.search.path)}
						variants={MOTION_VARIANTS}
						custom={elementMotionProps}
					/>
				)}

				{/* TODO: update geolocation icon */}
				{IS_PHONE && (userIsInUSA || DEBUG_USER_LOCATION) && (
					<motion.div
						className={classNames('user-location', {
							loading: locationLoading,
						})}
						onClick={() => goToUserLocation(false, ZOOM_LEVELS.STREET)}
						variants={MOTION_VARIANTS}
						custom={elementMotionProps}
					/>
				)}

				{IS_PHONE && (
					<motion.div
						className="social"
						onClick={() => history.push(routes.socialNational.path)}
						variants={MOTION_VARIANTS}
						custom={elementMotionProps}
					/>
				)}

				{IS_PHONE && (
					<motion.div
						className="saved"
						onClick={() => history.push(routes.savedLocations.path)}
						variants={MOTION_VARIANTS}
						custom={elementMotionProps}
					/>
				)}
				{false && (
					<motion.div className="audio" variants={MOTION_VARIANTS} custom={elementMotionProps}>
						{audioOn && <div className="audio-off" onClick={turnAudioOff} />}
						{!audioOn && <div className="audio-on" onClick={turnAudioOn} />}
					</motion.div>
				)}
				<motion.div
					className={hamburgerClass}
					onClick={() => history.push(routes.hamburgerMenu.path)}
					variants={MOTION_VARIANTS}
					custom={elementMotionProps}
				/>
			</div>
			{nationalGuidesRoute && IS_PHONE && (
				<motion.div
					className="nav-mapbox"
					variants={MOTION_VARIANTS}
					custom={elementMotionProps}
					onClick={() => window.open('https://www.mapbox.com/')}
				/>
			)}
		</motion.div>
	);
}

export default Nav;
