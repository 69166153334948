import { IS_PHONE } from 'js/config/constants';
import { mapProps } from '../Map/Map';
import { useAppState } from 'js/hooks/app-state';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import routes from '../Router/routes';
import { motion } from 'framer-motion';
import ContentFooter from '../ContentFooter/ContentFooter';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import { MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { COMMON_DURATION, EASE_IN_OUT, EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION } from 'js/utils/motion';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './CityGuides.scss';

const IDEAL_ICON_WIDTH = 200;

const delay = 0.6;

const containerMotion = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.3,
			staggerChildren: 0.02,
			delayChildren: 0.2,
			ease: EASE_OUT,
		},
	},
	exit: {
		opacity: 0,
	},
};

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateY(100px)',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
};

const motionProps0 = MOTION_PRESETS.FADE;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + 0.075 },
});

function CityGuides() {
	const [{ guideData }] = useAppState();
	const cityGuides = guideData.filter((dataItem) => dataItem?.city !== undefined);
	const containerRef = useRef(null);
	const [numCols, setNumCols] = useState();

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);

			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
			};
		}
	}, []);

	const history = useHistory();

	useEffect(() => {
		const handleResize = () => {
			const bnds = containerRef.current.getBoundingClientRect();
			const containerWidth = bnds.width;
			const numCols = Math.floor(containerWidth / IDEAL_ICON_WIDTH);
			setNumCols(numCols);
		};

		if (containerRef?.current) {
			window.addEventListener('resize', handleResize);
			handleResize();
		}
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [containerRef, setNumCols]);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | City Guides</title>
				<meta name="description" content="Explore The Culture in these American cities." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className="CityGuides HamburgerMenu"
				onAnimationStart={() => {
					if (IS_PHONE) {
						mapProps?.layers?.elevation?.resume();
					}
				}}
				onAnimationComplete={(definition) => {
					if (IS_PHONE && definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				<div className="HamburgerMenu-inner">
					<div className="pad-container">
						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="title">
							CITY GUIDES
						</motion.div>
						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="subtitle">
							Explore The Culture in these American cities.
						</motion.div>
						<div className="scroll-container">
							<div className="content-container">
								<div className="content-sections">
									<motion.div
										className="item-section"
										variants={containerMotion}
										initial="initial"
										animate="animate"
										exit="exit"
										ref={containerRef}
										style={{ gridTemplateColumns: `repeat(${numCols}, 1fr)` }}
									>
										{cityGuides
											.sort((a, b) => (a.city.cityId > b.city.cityId ? 1 : -1))
											.map((city_guide, index) => {
												const imgStyle = {
													backgroundImage: `url(${city_guide.coverImage.url}?w=308)`,
												};
												return (
													<motion.div key={city_guide.id} className="item" onClick={() => history.push(generatePath(routes.cityGuideLanding.path, { id: city_guide.id }))}>
														<div className="image-container">
															<motion.div className="image-wrap" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}>
																<div className="image" style={imgStyle} role="img" aria-label={`${city_guide.city.cityName}, ${city_guide.city.cityState}`} />
															</motion.div>
														</div>

														<motion.div className="label" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}>
															{city_guide.city.cityName}, {city_guide.city.cityState}
														</motion.div>
													</motion.div>
												);
											})}
									</motion.div>
								</div>
							</div>
						</div>

						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} className="footer-container">
							<ContentFooter />
						</motion.div>
					</div>
				</div>
				{IS_PHONE ? <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 22 }} lightTheme={true} /> : <CloseButtonX />}
			</motion.div>
		</>
	);
}

export default CityGuides;
