import { ELEVATION_GEOMETRY_CONFIG, FORCE_GLOBAL_REFS, IS_DEVELOPMENT, IS_MOBILE, LAYER_SCALE } from '../config/constants';
import { Clock, Group, LinearFilter, MathUtils, Vector2 } from 'three/build/three.module';
import { deleteAllProperties, wait } from '../utils/utils';
import PostProcessor from '../post-processing/post-processor';
import { Debug } from '../utils/debug';
import { mapProps, resetMapProps } from 'js/components/Map/Map';
import Terrain from 'js/entities/terrain';
import CameraController from 'js/controllers/camera-controller';
// import ElementColorSwapper from 'js/utils/element-color-swapper';

// webgl layer
export default class ElevationLayer {
	constructor(props) {
		this.props = props;
		this.onAdd = this.onAdd.bind(this);
		this.render = this.render.bind(this);
		this.init();
	}

	init() {
		this.layerId = 'elevation-3d-layer';
		this.layer = {
			id: this.layerId,
			type: 'custom',
			renderingMode: '3d',
			onAdd: this.onAdd,
			render: this.render,
		};

		this.mouse = new Vector2();
		this.cursorChangesAllowed = true;
		this.mouseMoveFrameCount = 0;
		this.mouseMoveFrameThrottle = 4;
		this.clock = new Clock();
	}

	onAdd(map, gl) {
		const { cityData, assets, readyCallback } = this.props;
		const {
			tb,
			reactProps: { routesEnabled, activeDatasetId },
		} = mapProps;
		const { alphaMap, vertAlphaMap, usaHeightmap, genPopHeightmap, blackPopHeightmap, blackOwnedBusinessesHeightmap, historicalMarkersHeightmap, mapShadow } = assets.textures;
		const { coords, maxElevationHeight } = ELEVATION_GEOMETRY_CONFIG;

		alphaMap.minFilter = alphaMap.minFilter = LinearFilter;
		vertAlphaMap.minFilter = vertAlphaMap.minFilter = LinearFilter;

		const topLeft = tb.projectToWorld(coords);

		mapProps.topLeft = topLeft;

		this.cameraController = new CameraController({ map });

		const container = new Group();

		// terrain
		this.terrain = new Terrain({
			alphaMap,
			mapShadow,
			vertAlphaMap,
			usaHeightmap,
			genPopHeightmap,
			blackPopHeightmap,
			blackOwnedBusinessesHeightmap,
			historicalMarkersHeightmap,
			cityData,
			topLeft,
			tb,
			useLines: false,
		});
		container.add(this.terrain.mesh);

		mapProps.sharedUniforms = {
			maxElevation: { value: maxElevationHeight },
			focusAmount: { value: 0 },
		};

		const { raycaster, renderer, camera, scene } = tb;

		mapProps.depthUniforms = {
			cameraNear: { value: camera.near },
			cameraFar: { value: camera.far },
			renderDepth: { value: false },
		};

		if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
			window._.uniforms = mapProps.sharedUniforms;
			window._.mainContainer = container;
		}

		this.container = container;

		// whole scene gets added here
		const mesh = tb
			.Object3D({
				obj: container,
				units: 'scene',
				rotation: { x: 180, y: 180, z: 0 },
				scale: LAYER_SCALE,
				anchor: 'top-left',
				bbox: true,
				raycasted: false,
			})
			.setCoords(coords);
		tb.add(mesh, this.layerId);
		mesh.model.frustumCulled = false;

		// adds the shadow to scene graph after ThreeBox does it's position calculations
		// so it doesn't automatically shift the terrain up which messes things up
		this.terrain.initShadow();

		// post processing effects
		this.postProcessor = new PostProcessor({
			raycaster,
			renderer,
			camera,
			scene,
		});

		this.postProcessor.render();

		// this.elementColorSwapper = new ElementColorSwapper({ postProcessor: this.postProcessor });

		// compile shaders even for hidden objects
		renderer.compile(scene, camera);

		renderer?.domElement?.addEventListener('webglcontextlost', this.handleContextLost, false);
		renderer?.domElement?.addEventListener('webglcontextrestored', this.handleContextRestored, false);

		if (typeof readyCallback === 'function') {
			readyCallback(this);
		}

		// dev refresh or webglcontextlost
		if (routesEnabled) {
			wait(500).then(() => {
				this.terrain?.setCurrentHeightmap(activeDatasetId, 0);
				this.terrain?.transitionIn(2);
			});
		}
	}

	setFocusScalar() {
		// if (this.postProcessor?.uniforms?.focusScalar) {
		// 	const { map } = mapProps;
		// 	const zoom = map.getZoom();
		// 	const pitch = map.getPitch();
		// 	const zoomScalar = mapValue(zoom, ZOOM_LEVELS.STATE - 1, ZOOM_LEVELS.CITY - 1, 0, 1);
		// 	const pitchScalar = mapValue(pitch, 45, 70, 0, 1);
		// 	this.postProcessor.uniforms.focusScalar.value = clamp(zoomScalar * pitchScalar, 0, 1);
		// }
	}

	handleContextLost(e) {
		e.preventDefault();
		const {
			reactProps: { setState },
		} = mapProps;

		Debug.warn('webglcontextlost');

		// force map to be recreated
		setState({ mapId: MathUtils.generateUUID() });
	}

	handleContextRestored(e) {
		// TODO: restore any textures if needed
	}

	handlePitch() {
		// this.setFocusScalar();
	}

	handleZoom() {}

	handleResize() {
		this.postProcessor.resize();
	}

	handleMouseMove(e) {
		this.mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
		this.mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
	}

	setStatic() {
		this.terrain?.mesh?.traverse((node) => {
			node.matrixAutoUpdate = false;
		});
	}

	pause() {
		// Debug.warn('PAUSE');
		this.paused = true;
	}

	resume() {
		// Debug.warn('RESUME');
		this.paused = false;
	}

	render(gl, matrix) {
		if (!this.props || this.paused) return;

		// Debug.log('render');

		const { tb } = mapProps;
		const delta = this.clock.getDelta();

		if (!IS_MOBILE && tb.lightContainer) {
			const targY = tb.lightContainer.userData.startRotation.y + this.mouse.x * 0.2;
			const targX = tb.lightContainer.userData.startRotation.x + this.mouse.y * 0.1;
			tb.lightContainer.rotation.y += (targY - tb.lightContainer.rotation.y) * 0.1;
			tb.lightContainer.rotation.x += (targX - tb.lightContainer.rotation.x) * 0.1;
		}

		this.terrain?.update(delta);
		this.postProcessor?.render(delta);
	}

	dispose() {
		mapProps?.tb?.dispose().catch((error) => Debug.log(error));
		mapProps?.tb?.renderer?.domElement?.removeEventListener('webglcontextlost', this.handleContextLost);
		mapProps?.tb?.renderer?.domElement?.removeEventListener('webglcontextrestored', this.handleContextRestored);

		resetMapProps();

		this.terrain?.dispose();
		this.postProcessor?.dispose();
		this.cameraController?.dispose();
		this.elementColorSwapper?.dispose();

		deleteAllProperties(this);
	}
}
