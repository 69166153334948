import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
} from 'js/utils/motion';
import './AboutImage.scss';

function AboutImage(props) {
  const { content, animOnce }  = props;
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <div className="AboutImage" ref={ref}>
      <motion.img 
        {...content}
        initial="initial"
        animate={anim}
        variants={MOTION_VARIANTS}
        custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
      />
    </div>
  )
}

export default AboutImage;