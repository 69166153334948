import { ENDPOINTS, MAPBOX_API_KEY, POI_RATING_FILTER } from 'js/config/constants';
import { getData } from './utils';
import distance from '@turf/distance';
import { Debug } from './debug';

const stateScoreCache = {};

export const STATE_CODES = {
	Alabama: 'AL',
	Alaska: 'AK',
	'American Samoa': 'AS',
	Arizona: 'AZ',
	Arkansas: 'AR',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	Delaware: 'DE',
	'District of Columbia': 'DC',
	'Federated States of Micronesia': 'FM',
	Florida: 'FL',
	Georgia: 'GA',
	Guam: 'GU',
	Hawaii: 'HI',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Iowa: 'IA',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Maine: 'ME',
	'Marshall Islands': 'MH',
	Maryland: 'MD',
	Massachusetts: 'MA',
	Michigan: 'MI',
	Minnesota: 'MN',
	Mississippi: 'MS',
	Missouri: 'MO',
	Montana: 'MT',
	Nebraska: 'NE',
	Nevada: 'NV',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	'New York': 'NY',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	'Northern Mariana Islands': 'MP',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Palau: 'PW',
	Pennsylvania: 'PA',
	'Puerto Rico': 'PR',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Vermont: 'VT',
	'Virgin Islands': 'VI',
	Virginia: 'VA',
	Washington: 'WA',
	'West Virginia': 'WV',
	Wisconsin: 'WI',
	Wyoming: 'WY',
};

export const STATE_LABEL_DATA = {
	type: 'FeatureCollection',
	features: [
		{
			id: 0,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-153.182373046875, 64.6379983895657] },
			properties: { stateCode: 'AK', name: 'Alaska' },
		},
		{
			id: 1,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-86.7919921875, 32.833442846649504] },
			properties: { stateCode: 'AL', name: 'Alabama' },
		},
		{
			id: 2,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-92.4169921875, 35.09294531373263] },
			properties: { stateCode: 'AR', name: 'Arkansas' },
		},
		{
			id: 3,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-111.42196655273438, 34.21975142578545] },
			properties: { stateCode: 'AZ', name: 'Arizona' },
		},
		{
			id: 4,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-119.69879150390625, 37.07490186820611] },
			properties: { stateCode: 'CA', name: 'California' },
		},
		{
			id: 5,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-105.60470581054688, 39.19075519349454] },
			properties: { stateCode: 'CO', name: 'Colorado' },
		},
		{
			id: 6,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-72.7239990234375, 41.62776153144344] },
			properties: { stateCode: 'CT', name: 'Connecticut' },
		},
		{
			id: 7,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-75.5035400390625, 38.99784130750072] },
			properties: { stateCode: 'DE', name: 'Delaware' },
		},
		{
			id: 8,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-81.69135936612496, 28.92389012029129] },
			properties: { stateCode: 'FL', name: 'Florida' },
		},
		{
			id: 9,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-83.25439453125, 32.333558948641084] },
			properties: { stateCode: 'GA', name: 'Georgia' },
		},
		{
			id: 10,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-155.33486147927357, 19.62896117162832] },
			// geometry: { type: 'Point', coordinates: [-156.346435546875, 20.262197124246526] },
			properties: { stateCode: 'HI', name: 'Hawaii' },
		},
		{
			id: 11,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-93.49639892578125, 42.0870126700882] },
			properties: { stateCode: 'IA', name: 'Iowa' },
		},
		{
			id: 12,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-114.65194702148438, 44.391598306810266] },
			properties: { stateCode: 'ID', name: 'Idaho' },
		},
		{
			id: 13,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-89.27490234375, 40.145289295676605] },
			properties: { stateCode: 'IL', name: 'Illinois' },
		},
		{
			id: 14,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-86.28662109375, 39.91816284660945] },
			properties: { stateCode: 'IN', name: 'Indiana' },
		},
		{
			id: 15,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-98.72589111328125, 38.532053172746544] },
			properties: { stateCode: 'KS', name: 'Kansas' },
		},
		{
			id: 16,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-85.286865234375, 37.52715361723378] },
			properties: { stateCode: 'KY', name: 'Kentucky' },
		},
		{
			id: 17,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-92.30712890625, 30.939924331023448] },
			properties: { stateCode: 'LA', name: 'Louisiana' },
		},
		{
			id: 18,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-71.96044921875, 42.3179394544685] },
			properties: { stateCode: 'MA', name: 'Massachusetts' },
		},
		{
			id: 19,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-76.849365234375, 39.47860556892209] },
			properties: { stateCode: 'MD', name: 'Maryland' },
		},
		{
			id: 20,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-69.23583984375, 45.39844997630408] },
			properties: { stateCode: 'ME', name: 'Maine' },
		},
		{
			id: 21,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-84.52880859375, 44.93369638969469] },
			properties: { stateCode: 'MI', name: 'Michigan' },
		},
		{
			id: 22,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-94.19952392578125, 46.34313560260196] },
			properties: { stateCode: 'MN', name: 'Minnesota' },
		},
		{
			id: 23,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-92.581787109375, 38.195021557955755] },
			properties: { stateCode: 'MO', name: 'Missouri' },
		},
		{
			id: 24,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-89.71435546875, 32.861132322810946] },
			properties: { stateCode: 'MS', name: 'Mississippi' },
		},
		{
			id: 25,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-109.17251586914062, 47.07292780694107] },
			properties: { stateCode: 'MT', name: 'Montana' },
		},
		{
			id: 26,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-79.38720703125, 35.55904339525897] },
			properties: { stateCode: 'NC', name: 'North Carolina' },
		},
		{
			id: 27,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-100.458984375, 47.44480754169436] },
			properties: { stateCode: 'ND', name: 'North Dakota' },
		},
		{
			id: 28,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-99.9591064453125, 41.863424671810066] },
			properties: { stateCode: 'NE', name: 'Nebraska' },
		},
		{
			id: 29,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-71.575927734375, 43.69170790307382] },
			properties: { stateCode: 'NH', name: 'New Hampshire' },
		},
		{
			id: 30,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-74.388427734375, 40.14948820651523] },
			properties: { stateCode: 'NJ', name: 'New Jersey' },
		},
		{
			id: 31,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-105.99884033203125, 34.32529192442733] },
			properties: { stateCode: 'NM', name: 'New Mexico' },
		},
		{
			id: 32,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-116.59927368164062, 40.07071544306933] },
			properties: { stateCode: 'NV', name: 'Nevada' },
		},
		{
			id: 33,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-75.465087890625, 42.755079545072135] },
			properties: { stateCode: 'NY', name: 'New York' },
		},
		{
			id: 34,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-82.716064453125, 40.41349604970196] },
			properties: { stateCode: 'OH', name: 'Ohio' },
		},
		{
			id: 35,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-97.53936767578125, 35.90128747814897] },
			properties: { stateCode: 'OK', name: 'Oklahoma' },
		},
		{
			id: 36,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-120.28106689453125, 44.03330795911947] },
			properties: { stateCode: 'OR', name: 'Oregon' },
		},
		{
			id: 37,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-78.28857421875, 41.087632124679146] },
			properties: { stateCode: 'PA', name: 'Pennsylvania' },
		},
		{
			id: 38,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-71.5814208984375, 41.68522004222072] },
			properties: { stateCode: 'RI', name: 'Rhode Island' },
		},
		{
			id: 39,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-80.9033203125, 33.9160131134017] },
			properties: { stateCode: 'SC', name: 'South Carolina' },
		},
		{
			id: 40,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-100.14862060546875, 44.868522956813365] },
			properties: { stateCode: 'SD', name: 'South Dakota' },
		},
		{
			id: 41,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-85.97900390625, 35.98689628443789] },
			properties: { stateCode: 'TN', name: 'Tennessee' },
		},
		{
			id: 42,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-98.822021484375, 31.804059692799356] },
			properties: { stateCode: 'TX', name: 'Texas' },
		},
		{
			id: 43,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-111.60186767578125, 39.25458803221994] },
			properties: { stateCode: 'UT', name: 'Utah' },
		},
		{
			id: 44,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-78.6181640625, 37.67512527892127] },
			properties: { stateCode: 'VA', name: 'Virginia' },
		},
		{
			id: 45,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-72.75146484375, 44.048115730823525] },
			properties: { stateCode: 'VT', name: 'Vermont' },
		},
		{
			id: 46,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-120.09429931640625, 48.02483529097478] },
			properties: { stateCode: 'WA', name: 'Washington' },
		},
		{
			id: 47,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-89.769287109375, 44.71551373202135] },
			properties: { stateCode: 'WI', name: 'Wisconsin' },
		},
		{
			id: 48,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-80.716552734375, 38.745515184882635] },
			properties: { stateCode: 'WV', name: 'West Virginia' },
		},
		{
			id: 49,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-107.5396728515625, 43.544566584363565] },
			properties: { stateCode: 'WY', name: 'Wyoming' },
		},
		{
			id: 50,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-66.44840240478516, 18.222829375235975] },
			properties: { stateCode: 'PR', name: 'Puerto Rico' },
		},
		{
			id: 51,
			type: 'Feature',
			geometry: { type: 'Point', coordinates: [-77.0846149, 38.8937336] },
			properties: { stateCode: 'DC', name: 'District of Columbia' },
		},
	],
};

export const FEATURE_PROPS = {
	ID: 'gid',
	NAME: 'n',
	PARENT_CATEGORY: 'pc',
	STATE_CODE: 's',
	RATING: 'r',
	REVIEW_COUNT: 'rc',
};

export function getStateLabelId(stateCode) {
	return STATE_LABEL_DATA.features?.filter((feature) => feature.properties.stateCode === stateCode)[0]?.id;
}

export function getStateLabelCoordinates(stateCode) {
	return STATE_LABEL_DATA.features?.filter((feature) => feature.properties.stateCode === stateCode)[0]?.geometry?.coordinates;
}

export function getStateCode(stateName) {
	return STATE_CODES[stateName];
}

export function getStateName(stateCode) {
	let stateName = null;

	for (const [key, value] of Object.entries(STATE_CODES)) {
		if (value === stateCode) stateName = key;
	}

	return stateName;
}

export function getStateScore(stateName) {
	return new Promise((resolve, reject) => {
		if (stateScoreCache[stateName]) {
			resolve(stateScoreCache[stateName]);
			return;
		}

		const stateCode = getStateCode(stateName);
		if (!stateCode) {
			reject('Invalid State:', stateName);
		} else {
			const data = getData(ENDPOINTS.STATE_SCORE.replace('{stateCode}', stateCode));
			stateScoreCache[stateName] = data;
			resolve(data);
		}
	});
}

export function getLocationData(id) {
	return new Promise((resolve, reject) => {
		getData(`${ENDPOINTS.LOCATION}/${id}`).then((data) => resolve(data));
	});
}

export function getPoiData(id, dataset) {
	return dataset.filter((dataItem) => dataItem?.p?.gid === id)[0];
}

export function getSavedLocationFeatures(savedLocations, dataset) {
	return dataset.filter((dataItem) => savedLocations.includes(dataItem?.p?.gid));
}

export function getCuratedSavedLocationFeatures(savedLocations, dataset) {
	return dataset.filter((dataItem) => savedLocations.includes(dataItem?.properties?.id));
}

// filter out features by distance to eachother in kilometers
export function filterFeaturesByDistance(features, threshold = 0, filterZeroDistances = true) {
	let minDist, dist;
	const len = features.length;
	const filtered = [];

	for (let i = 0; i < len; i++) {
		minDist = 999999;
		for (let j = 0; j < len; j++) {
			if (i !== j) {
				dist = distance(features[i].geometry.coordinates, features[j].geometry.coordinates);
				if (dist < minDist) {
					minDist = dist;
				}
			}
		}

		if (minDist >= threshold && (!filterZeroDistances || (filterZeroDistances && minDist > 0))) {
			filtered.push(features[i]);
		}
		// Debug.log('minDist:', minDist);
	}

	return filtered;
}

// filter out features by distance to eachother in kilometers
export function filterFeaturesByDistances(features, thresholds = [], groupByState = true, filterZeroDistances = true) {
	let minDist, dist;
	const numThresholds = thresholds.length;
	const filtered = [];
	const featureGroups = groupByState ? Object.values(groupFeaturesByState(features)) : Object.values(features);

	thresholds.forEach(() => {
		filtered.push([]);
	});

	featureGroups.forEach((group) => {
		const len = group.length;

		for (let i = 0; i < len; i++) {
			minDist = 999999;
			for (let j = 0; j < len; j++) {
				if (i !== j) {
					dist = distance(group[i].geometry.coordinates, group[j].geometry.coordinates);
					if (dist < minDist) {
						minDist = dist;
					}
				}
			}

			for (let k = 0; k < numThresholds; k++) {
				if (minDist >= thresholds[k] && (!filterZeroDistances || (filterZeroDistances && minDist > 0))) {
					filtered[k].push(group[i]);
				}
			}
		}
	});

	return filtered;
}

export function groupFeaturesByState(features) {
	const groups = {};

	features.forEach((feature) => {
		const state = getFeatureProperty(feature, FEATURE_PROPS.STATE_CODE);

		if (!groups[state]) {
			groups[state] = [];
		}

		groups[state].push(feature);
	});

	return groups;
}

export function getFeatureProperty(feature, prop) {
	const properties = feature?.p || feature?.properties;
	return properties[prop]?.toString();
}

export function getFeaturesWithoutPlaylists(allFeatures, playlistSets) {
	const stripped = [];
	allFeatures.forEach((feature) => {
		let found = false;
		for (let i = 0; i < playlistSets.length; i++) {
			for (let j = 0; j < playlistSets[i].features.length; j++) {
				if (feature.p.gid === playlistSets[i].features[j].p.gid) {
					found = true;
					break;
				}
			}
		}

		if (!found) {
			stripped.push(feature);
		}
	});

	return stripped;
}

export function getUniqueFeatures(features, comparatorProperty) {
	const uniqueIds = new Set();
	const uniqueFeatures = [];
	for (const feature of features) {
		const id = feature.properties[comparatorProperty];
		if (!uniqueIds.has(id)) {
			uniqueIds.add(id);
			uniqueFeatures.push(feature);
		}
	}
	return uniqueFeatures;
}

export function getDataConvertedToFeatureCollection(data) {
	const collection = {
		type: 'FeatureCollection',
		features: [],
	};

	data.forEach((dataItem, index) => {
		const { geometry, p } = dataItem;
		collection.features.push({
			id: index,
			type: 'Feature',
			geometry: geometry,
			properties: { ...p },
		});
	});

	return collection;
}

export function getDataWrappedInFeatureCollection(data) {
	const collection = {
		type: 'FeatureCollection',
		features: data,
	};

	return collection;
}

export function getClosestFeature(features, target) {
	let closestDistance = 99999999;
	let closestFeature;
	features.forEach((feature) => {
		const {
			geometry: { coordinates },
		} = feature;

		const dist = distance(target, coordinates);

		if (dist < closestDistance) {
			closestDistance = dist;
			closestFeature = feature;
		}
	});

	return closestFeature;
}

export function getFeaturesNearBy(
	features,
	targetId,
	targetTitle,
	targetLocation,
	maxDistance,
	numItems = 10,
	minRating = POI_RATING_FILTER
) {
	const nearBySet = new Set();

	features.forEach((feature) => {
		const {
			p: { gid, pc, r, n },
			geometry: { coordinates },
		} = feature;

		if (gid !== targetId && targetTitle?.toLowerCase() !== n?.toLowerCase() && r >= minRating) {
			const dist = distance(targetLocation, coordinates);

			if (dist <= maxDistance) {
				nearBySet.add({ feature, dist });
			}
		}
	});

	const nearBy = Array.from(nearBySet);

	// sort
	if (nearBy.length) {
		nearBy.sort((a, b) => {
			if (a.dist < b.dist) return -1;
			else if (a.dist > b.dist) return 1;
			else return 0;
		});
	}

	// cap amount of items
	if (nearBy.length > numItems) {
		nearBy.length = numItems;
	}

	const result = nearBy.map(({ feature }) => feature);

	return result;
}

export function getPeopleNearBy(people, targetId, targetLocation, maxDistance, numItems = 10) {
	const nearBySet = new Set();

	people.forEach((person) => {
		const {
			id,
			location: { lon, lat },
		} = person;

		if (id !== targetId) {
			const dist = distance(targetLocation, [lon, lat]);

			if (dist <= maxDistance) {
				nearBySet.add({ person, dist });
			}
		}
	});

	const nearBy = Array.from(nearBySet);

	// sort
	if (nearBy.length) {
		nearBy.sort((a, b) => {
			if (a.dist < b.dist) return -1;
			else if (a.dist > b.dist) return 1;
			else return 0;
		});
	}

	// cap amount of items
	if (nearBy.length > numItems) {
		nearBy.length = numItems;
	}

	const result = nearBy.map(({ person }) => person);

	return result;
}

export function getCoordinatesForBusinessId(features, businessId) {
	const result = features.filter((feature) => {
		const properties = feature?.p || feature?.properties;
		return properties?.gid === businessId;
	})[0];
	return result?.geometry;
}

export function getGeocodeDataForCoordinates(lng, lat) {
	return getData(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${MAPBOX_API_KEY}`);
}

export function getSavedLocations() {
	const savedLocations = window.localStorage?.getItem('saved-locations');
	Debug.log('getSavedLocations:', savedLocations);
	if (savedLocations) {
		return JSON.parse(savedLocations);
	} else {
		return [];
	}
}

export function setSavedLocations(value) {
	window.localStorage?.setItem('saved-locations', JSON.stringify(value));
}
