import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { validateEmail, wait } from 'js/utils/utils';
import { useFormFields } from 'js/hooks/use-form-fields';
import { MOTION_PRESETS, MOTION_VARIANTS, EASE_OUT } from 'js/utils/motion';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import FormSubmitButton from 'js/components/FormSubmitButton/FormSubmitButton';

import './AboutSubscribe.scss';

const containerMotion = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.3,
			staggerChildren: 0.1,
			delayChildren: 0.3,
			ease: EASE_OUT,
		},
	},
	exit: {
		opacity: 0,
	},
};


const CustomForm = ({ status, message, onValidated }) => {
	const [formSending, setFormSending] = useState(false);
	const [formSent, setFormSent] = useState(false);
	const [formMessage, setFormMessage] = useState(null);
	const emailRef = useRef(null);
	const [fields, handleFieldChange, clearFields] = useFormFields({
		email: '',
	});

	const validate = () => {
		return validateEmail(fields?.email);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		// setFormSending(true);
		// setFormMessage('Sending');

		fields?.email && 
		onValidated({
			EMAIL: fields.email,
		});
	}

	useEffect(() => {
		if (status === 'sending') {
			setFormSending(true);
			setFormMessage('Sending');
		} else if (status === 'success') {
			setFormSending(false);
			setFormSent(true);
			setFormMessage('Sent');
			wait(3000).then(() => {
				setFormSent(false);
				clearFields();
			});
		} else if (status === 'error') {
			clearFields();
			setFormSent(false);
			setFormSending(false);
		}

	}, [status]);

	return (
		<form className="form-email" onSubmit={handleSubmit}>
			<div className="form-email--wrap">
				<input type="email" id="email" value={fields?.email} placeholder="Your Email" onChange={handleFieldChange} required ref={emailRef} />
				<FormSubmitButton
					className="dark-mode"
					handleSubmit={handleSubmit}
					formSending={formSending}
					formSent={formSent}
					validate={validate}
					formMessage={formMessage}
				/>
			</div>
			
			{ status === 'error' && (
				<div className="form-error">
					<p dangerouslySetInnerHTML={{__html:message.slice(3)}} />
				</div>
			)}
		</form>
	)
}

// ----------------------------------------------


function AboutSubscribe(props) {
	const { animOnce } = props;

	const anim = useAnimation();
	const [ref, inView] = useInView({
		rootMargin: '-150px 0px',
		triggerOnce: animOnce,
	});	

	useEffect(() => {
		if (inView) {
			anim.start('animate');
		} else {
			anim.start('exit');
		}
	}, [anim, inView]);

	const url = 'https://weareblackandabroad.us11.list-manage.com/subscribe/post?u=aee0219e9a730f9209d42fcb4&amp;id=00e3bce882';


	return (
		<motion.div className="AboutSubscribe" variants={containerMotion} initial="initial" animate={anim} ref={ref}>
			<motion.div className="bg" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM} />
			<motion.div className="title" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}>
				<span>SUBSCRIBE</span>
			</motion.div>
			<motion.div className="signup" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}>
				<div className="input-desc">
					<p>Join our mailing list and keep up with the latest in Black travel from Black & Abroad.</p>
				</div>
				<div className="form-email-wrap" id="mc_embed_signup">	
					<MailchimpSubscribe
						url={url}
						render={({ subscribe, status, message }) => (
							<CustomForm 
								status={status}
								message={message}
								subscribe={subscribe}
								onValidated={formData => subscribe(formData)}
							/>
						)}
					/>
				</div>
			</motion.div>
		</motion.div>
	);
}

export default AboutSubscribe;
