import { mapProps } from 'js/components/Map/Map';
import { DEPTH_ENABLED, FORCE_GLOBAL_REFS, IS_DEVELOPMENT } from 'js/config/constants';
import { LinearFilter, Mesh, NearestFilter, OrthographicCamera, PlaneBufferGeometry, RGBAFormat, RGBFormat, Scene, ShaderMaterial, TextureLoader, WebGLRenderTarget } from 'three/build/three.module';
import PostShader from './post-shader';
import { deleteAllProperties } from 'js/utils/utils';

import lookupSrc from '../../images/map/lookup.png';

export default class PostProcessor {
	constructor(props) {
		this.props = props;
		this.init();
	}

	init() {
		const { width = window.innerWidth, height = window.innerHeight } = this.props;

		// post effects

		this.scene = new Scene();
		this.camera = new OrthographicCamera(width / -2, width / 2, height / 2, height / -2, -10000, 10000);
		this.camera.position.z = 100;
		this.scene.add(this.camera);

		const tLookup = new TextureLoader().load(lookupSrc);
		tLookup.minFilter = tLookup.magFilter = NearestFilter;

		const paramsDepth = { minFilter: LinearFilter, magFilter: LinearFilter, format: RGBFormat };
		this.rtTextureDepth = new WebGLRenderTarget(width, height, paramsDepth);

		const paramsDiffuse = {
			minFilter: LinearFilter,
			magFilter: LinearFilter,
			format: RGBAFormat,
		};
		this.rtTextureDiffuse = new WebGLRenderTarget(width, height, paramsDiffuse);

		this.shader = PostShader;

		const { uniforms, vertexShader, fragmentShader } = this.shader;

		uniforms.tDiffuse.value = this.rtTextureDiffuse.texture;
		uniforms.tDepth.value = this.rtTextureDepth.texture; // depth texture
		uniforms.tLookup.value = tLookup;
		uniforms.resolution.value.set(width * window.devicePixelRatio, height * window.devicePixelRatio); // fxaa
		uniforms.renderFXAA.value = true; // antialiasing
		uniforms.grainIntensity.value = 0.025; // grain

		this.uniforms = uniforms;

		if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
			window._.postUniforms = uniforms;
		}

		this.material = new ShaderMaterial({
			// defines,
			uniforms,
			vertexShader,
			fragmentShader,
			transparent: true,
		});

		this.quad = new Mesh(new PlaneBufferGeometry(width, height), this.material);
		this.quad.position.z = -500;
		this.scene.add(this.quad);
	}

	resize(width = window.innerWidth, height = window.innerHeight) {
		const rendererSize = { width, height };

		this.rtTextureDepth.setSize(rendererSize.width, rendererSize.height);
		this.rtTextureDiffuse.setSize(rendererSize.width, rendererSize.height);

		const dpr = window.devicePixelRatio || 1;
		this.uniforms.resolution.value.set(rendererSize.width * dpr, rendererSize.height * dpr); // fxaa
	}

	render(delta = 0.01) {
		if (!this.props) return;

		const { renderer, scene, camera } = this.props;
		const { focusScalar, time } = this.uniforms;

		time.value += delta;

		renderer.resetState();

		// render scene into texture
		renderer.setRenderTarget(this.rtTextureDiffuse);
		renderer.clear();
		renderer.render(scene, camera);

		if (DEPTH_ENABLED && focusScalar.value > 0) {
			// render depth into texture
			mapProps.depthUniforms.renderDepth.value = true;
			renderer.setRenderTarget(this.rtTextureDepth);
			renderer.clear();
			renderer.render(scene, camera);
			mapProps.depthUniforms.renderDepth.value = false;
		}

		// render composite
		renderer.setRenderTarget(null);
		renderer.render(this.scene, this.camera);
	}

	getAveragePixelColor(x, y) {
		// Debug.time('getAveragePixelColor');
		const { renderer } = this.props;
		const w = 20;
		const hw = w / 2;
		if (!this.pixelBuffer) this.pixelBuffer = new Uint8Array(4 * w * w);
		renderer.readRenderTargetPixels(this.rtTextureDiffuse, x - hw, y - hw, w, w, this.pixelBuffer);
		let sum = 0;
		let count = 0;
		const len = this.pixelBuffer.length;
		for (let i = 0; i < len; i += 4) {
			sum += this.pixelBuffer[i + 0];
			sum += this.pixelBuffer[i + 1];
			sum += this.pixelBuffer[i + 2];
			count += 3;
		}
		const avg = sum / count;
		// Debug.timeEnd('getAveragePixelColor');
		return avg;
	}

	getPixelColor(x, y) {
		// Debug.time('getPixelColor');
		const { renderer } = this.props;
		if (!this.pixelBuffer) this.pixelBuffer = new Uint8Array(4);
		renderer.readRenderTargetPixels(this.rtTextureDiffuse, x, y, 1, 1, this.pixelBuffer);
		// Debug.timeEnd('getPixelColor');
		return this.pixelBuffer[0];
	}

	getPixelIsDark(x, y) {
		// const pixel = this.getPixelColor(x, y);
		const pixel = this.getAveragePixelColor(x, y);
		return pixel < 128;
	}

	dispose() {
		deleteAllProperties(this);
	}
}
