import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { getAnchorFromName } from 'js/utils/utils';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
  EASE_OUT,
} from 'js/utils/motion';
import { ENDPOINTS } from '../../../../config/constants';
import './AboutContributors.scss';

const containerMotion = {
  initial: { 
    opacity: 0 
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.05,
      delayChildren: 0.1,
      ease: EASE_OUT,
    }
  },
  exit: { 
    opacity: 0 
  }
};

const Contributor = ({data}) => {
  const { name, description, photo, facebookLink, instagramLink, linkedInLink, tikTokLink, twitterLink, youTubeLink } = data;

  const imgStyle = {
    backgroundImage: `url(${photo.url})`
  }

  const socialLinks = [];
  if (facebookLink) {
    socialLinks.push({ className: 'social-link facebook-link', href: facebookLink });
  }
  if (twitterLink) {
    socialLinks.push({ className: 'social-link twitter-link', href: twitterLink });
  }
  if (instagramLink) {
    socialLinks.push({ className: 'social-link instagram-link', href: instagramLink });
  }
  if (youTubeLink) {
    socialLinks.push({ className: 'social-link youtube-link', href: youTubeLink });
  }
  if (linkedInLink) {
    socialLinks.push({ className: 'social-link linkedin-link', href: linkedInLink });
  }
  if (tikTokLink) {
    socialLinks.push({ className: 'social-link tiktok-link', href: tikTokLink });
  }

  const idval = getAnchorFromName(name);

  return (
    <motion.div className="contributor" id={idval}>
      <div className="contributor-image">
        <motion.div
          style={imgStyle}
          variants={MOTION_VARIANTS}
          custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
        />
      </div>
      <motion.div 
        className="contributor-info"
        variants={MOTION_VARIANTS}
        custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
      >
        <div className="name">{name}</div>
        <div className="description">{description}</div>
        <div className="social">
          {socialLinks.slice(0,3).map((d,i) => {
            return (
              <a {...d} rel="noopener noreferrer" target="_blank" key={i}/>
            )
          })}
        </div>
      </motion.div>
    </motion.div>
  )
}

function AboutContributors(props) {
  const { animOnce, setContributorsReady } = props;
  const [ contributors, setContributors ] = useState([]);
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  useEffect(() => {
		fetch(ENDPOINTS.CONTRIBUTORS, {
			method: 'GET',
			mode: 'cors',
			headers: {
				'Accept': 'application/json',
			},
		})
			.then(res => res.json())
			.then(data => {
        // const TEMP_ARRAY = [data.features[0],data.features[0],data.features[0],data.features[0],data.features[0]]
				setContributors([...data.features]); // replace with [...data.features]
        setContributorsReady(true);
			})
	}, [setContributors, setContributorsReady]);

  return (
    <motion.div 
      className="AboutContributors"
      variants={containerMotion}
      initial="initial"
      animate={anim}
      ref={ref}
    >
      { contributors && contributors?.map((d,i) => (
        <Contributor key={i} data={d}/>
      ))}
    </motion.div>
  )
}

export default AboutContributors;
