/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import React from 'react';
import { useAppState } from 'js/hooks/app-state';
import { generatePath, useHistory } from 'react-router-dom';
import routes from '../Router/routes';

import './OptionsFooter.scss';
import { wait } from 'js/utils/utils';

function OptionsFooter(props) {
	const [, { setState }] = useAppState();
	const { website, source, directions, curated, theme, historicalMarker, poiId } = props;
	const history = useHistory();

	const mainClass = classNames({
		OptionsFooter: true,
		'is-popover': !curated,
		'dark-theme': theme === 'dark',
	});

	// stops map input events which could trigger water click which returns to national view
	const stopPropagation = (e) => e.stopPropagation();

	const iconSource = historicalMarker ? 'hmdb' : source || 'website';

	return (
		<div className={mainClass}>
			{website && (
				<a href={website} target="_blank" rel="noreferrer" className="option" alt="Website" onMouseUp={stopPropagation} onClick={stopPropagation}>
					<div className="website-icon-link">
						<div className={`link-icon ${iconSource}`} />
						{iconSource === 'website' && <span>website</span>}
						{iconSource === 'hmdb' && <span className="font-italic">HMdb.org</span>}
					</div>
				</a>
			)}
			{directions && !historicalMarker && (
				<a href={directions} target="_blank" rel="noreferrer" className="option" alt="Directions" onMouseUp={stopPropagation} onClick={stopPropagation}>
					<div className="footer-icon directions" />
					<div className="footer-label">Directions</div>
				</a>
			)}
			<a
				href="#"
				className="option"
				onMouseUp={stopPropagation}
				onClick={() => {
					let delay = 0;
					if (poiId) {
						history.push(generatePath(routes.location.path, { id: poiId.toString() }));
						delay = 1000;
					}
					wait(delay).then(() => {
						setState({ shareModal: true });
					});
				}}
			>
				<div className="footer-icon share" />
				<div className="footer-label">Share</div>
			</a>
		</div>
	);
}

export default OptionsFooter;
