/* eslint-disable react-hooks/exhaustive-deps */
import { IS_PHONE, ZOOM_LEVELS } from 'js/config/constants';
import CameraController from 'js/controllers/camera-controller';
import { useAppState } from 'js/hooks/app-state';
import useQueryParams from 'js/hooks/use-query-params';
import { Debug } from 'js/utils/debug';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import ContentDrawer, { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import { mapProps } from '../Map/Map';
import routes from '../Router/routes';
import { getGeocodeDataForCoordinates, getStateCode } from 'js/utils/map-data-utils';
import { easings } from 'js/utils/easings';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

function NationalGuide() {
	const [{ guideData }, { setState }] = useAppState(); // all guides data
	const [data, setData] = useState(null); // current guide data item
	const [curatedPois, setCuratedPois] = useState([]);
	const [poiIndex, setPoiIndex] = useState(0);
	const [poiLocation, setPoiLocation] = useState(null);
	const [curatedPoiViewedStates, setCuratedPoiViewedStates] = useState(null);
	const [canRenderOtherGuides, setCanRenderOtherGuides] = useState(false);
	const [goToOnClose, setGoToOnClose] = useState(null);
	const history = useHistory();
	const params = useParams();
	const query = useQueryParams();
	const contentRef = useRef(null);

	const { markerController } = mapProps;
	const { id: guideId } = params;
	const poiId = query.get('poiId');

	useEffect(() => {
		const guideDataItem = getGuideDataItem(guideData, guideId);

		// if bad guide id was passed in go back home
		if (guideId && guideData && !guideDataItem) {
			history.push(routes.home.path);
		}

		// setCurrentGuideId(guideId);
		const pois = getCuratedPoiIds(guideDataItem);
		setCuratedPois(pois);

		const poiViewedStates = {};
		pois.forEach((id) => {
			poiViewedStates[id] = false;
		});
		poiViewedStates[poiId] = true;
		setCuratedPoiViewedStates(poiViewedStates);

		setData(guideDataItem);
		setState({ activeGuideId: guideId });
	}, [guideId, guideData]);

	useEffect(() => {
		if (curatedPoiViewedStates && poiId) {
			curatedPoiViewedStates[poiId] = true;

			let result = true;
			Object.values(curatedPoiViewedStates).forEach((viewed) => {
				if (!viewed) result = false;
			});

			if (result) {
				setCanRenderOtherGuides(true);
			}
		}
	}, [poiId, curatedPoiViewedStates]);

	useEffect(() => {
		if (!curatedPois?.length) return;

		const poiData = getCuratedPoiData(data, poiId);
		setPoiIndex(curatedPois.indexOf(poiId));

		if (!poiData) {
			Debug.warn('incorrect poiId passed in');
			history.push(routes.notFound.path);
			return;
		}

		const {
			id,
			location: { lon, lat },
		} = poiData;

		// get/parse poi location
		getGeocodeDataForCoordinates(lon, lat)
			.then((response) => {
				// Debug.warn('response:', response);
				let poiCity, poiState;
				response?.features?.forEach((feature) => {
					if (feature?.place_type.includes('place')) {
						poiCity = feature?.text;
					} else if (feature?.place_type.includes('region')) {
						poiState = getStateCode(feature?.text);
					}
				});
				// used in ContentDrawer for POI location
				setPoiLocation({ city: poiCity, stateCode: poiState });
				// used in CurrentLocation as location override, rather than center of screen
				setState({ currentLocationOverride: { city: poiCity, state: poiState } });
				// Debug.log('POI Location:', poiCity, poiState);
			})
			.catch((e) => Debug.warn('Error getting geocode data for:', lon, lat));

		markerController?.setMarkerActive(id);

		// calculate map view offset
		const contentWidth = contentRef.current?.getBoundingClientRect()?.width;
		const windowWidth = window.innerWidth;
		const offsetX = windowWidth * 0.5 - (windowWidth - contentWidth) * 0.5;

		const options = {
			offset: IS_PHONE ? [0, 0] : [-offsetX, 0],
			zoom: ZOOM_LEVELS.STREET,
			easing: easings.easeInOutQuad,
		};

		CameraController.instance?.flyTo([lon, lat], options);
		if (!IS_PHONE) setGoToOnClose([lon, lat]);

		return () => {
			setState({ currentLocationOverride: null });
		};
	}, [data, poiId]);

	const handleBack = () => {
		if (!data) return;

		const currentIndex = curatedPois.indexOf(poiId);
		if (currentIndex > -1) {
			const newIndex = currentIndex > 0 ? currentIndex - 1 : curatedPois.length - 1;
			const newId = curatedPois[newIndex];
			setPoiIndex(newIndex);
			history.push({ pathname: generatePath(routes.nationalGuide.path, { id: guideId }), search: `?poiId=${newId}` });
		}
	};

	const handleNext = () => {
		if (!data) return;

		const currentIndex = curatedPois.indexOf(poiId);
		if (currentIndex > -1) {
			const newIndex = currentIndex < curatedPois.length - 1 ? currentIndex + 1 : 0;
			const newId = curatedPois[newIndex];
			setPoiIndex(newIndex);
			history.push({ pathname: generatePath(routes.nationalGuide.path, { id: guideId }), search: `?poiId=${newId}` });
		}
	};

	return (
		<>
			<HelmetWrapper>
				<title>{`Black Elevation Map | ${data?.title}`}</title>
				<meta name="description" content={data?.guideDescription} />
			</HelmetWrapper>
			<ContentDrawer
				contentType={CONTENT_TYPES.NATIONAL_GUIDE}
				content={data}
				poiIndex={poiIndex}
				poiId={poiId}
				poiLocation={poiLocation}
				onBack={handleBack}
				onNext={handleNext}
				reference={contentRef}
				canRenderOtherGuides={canRenderOtherGuides}
				goToOnClose={goToOnClose}
			/>
		</>
	);
}

function getGuideDataItem(guideData, guideId) {
	const guideDataArray = Object.values(guideData);
	const result = guideDataArray?.filter(({ id }) => id === guideId)[0];
	return result;
}

function getCuratedPoiData(guideDataItem, poiId) {
	const result = guideDataItem?.curatedPois?.filter(({ id }) => id === poiId)[0];
	return result;
}

function getCuratedPoiIds(guideDataItem) {
	const result = [];
	guideDataItem?.curatedPois?.forEach(({ id }) => result.push(id));
	return result;
}

export default NationalGuide;
