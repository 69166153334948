import { DEFAULT_DATASET_INDEX, POI_CATEGORIES } from 'js/config/constants';
import { getSavedLocations, setSavedLocations } from 'js/utils/map-data-utils';
import { getCookieConsented, setCookieConsented } from 'js/utils/cookie-consent-utils';
import { createStore, createHook } from 'react-sweet-state';
import { MathUtils } from 'three/build/three.module';
import { getParameterByName } from 'js/utils/utils';

const Store = createStore({
	initialState: {
		stateData: [],
		cityData: [],
		guideData: [],
		poiStateData: [],
		peopleData: [],
		curatedFeatures: [],
		savedLocations: getSavedLocations(),
		cookieConsented: getCookieConsented(),
		deepLinkData: null,
		locationDetailData: null,
		nationalGuidesViewedStatus: null,
		cityGuidesViewedStatus: null,
		assets: {},
		mapLoaded: false,
		mapReady: false,
		elevationLayerReady: false,
		routesEnabled: false,
		activeGuideId: -1,
		activeDatasetId: DEFAULT_DATASET_INDEX,
		introViewed: false,
		showIntro: true,
		showHomeCTA: true,
		currentLocationOverride: null,
		currentPoi: null,
		shareModal: false,
		audioOn: true,
		dataSelectorEnabled: true,
		dataSelectorVisible: true,
		mapLegendOpen: false,
		mapId: MathUtils.generateUUID(),
		caseStudyControls: getParameterByName('csctrls') ? true : false,
		skipOnBoardingAnimation: true,
		helmetTitle: 'Black Elevation Map',
		helmetChangeKey: null,
		filters: {
			// {true} === visible
			[POI_CATEGORIES.FAVORITES]: true,
			[POI_CATEGORIES.CURATED]: true,
			[POI_CATEGORIES.EAT]: true,
			[POI_CATEGORIES.STAY]: true,
			[POI_CATEGORIES.SEE]: true,
			[POI_CATEGORIES.LEARN]: true,
			[POI_CATEGORIES.ENTERTAINMENT]: true,
			[POI_CATEGORIES.BEAUTY]: true,
			[POI_CATEGORIES.SHOP]: true,
			[POI_CATEGORIES.FLEX]: true,
		},
	},
	actions: {
		setState:
			(value) =>
			({ setState }) => {
				setState(value);
			},
		setFilters:
			(value) =>
			({ setState, getState }) => {
				const { filters } = getState();
				setState({ filters: { ...filters, ...value } });
			},
		setSavedLocations:
			(value) =>
			({ setState }) => {
				const newLocations = [...new Set(value)];
				setState({ savedLocations: newLocations });
				setSavedLocations(newLocations);
			},
		setCookieConsented:
			(value) =>
			({ setState }) => {
				setState({ cookieConsented: value });
				setCookieConsented(value);
			},
	},
});

export const useAppState = createHook(Store);
