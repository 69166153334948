import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../Router/routes';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import CameraController from 'js/controllers/camera-controller';
import { easings } from 'js/utils/easings';
import { mapProps } from '../Map/Map';
import { wait } from 'js/utils/utils';

import './CloseButtonX.scss';

function CloseButtonX(props) {
	const history = useHistory();

	function close(e) {
		e.stopPropagation();

		if (props?.onClick) {
			props.onClick();
		} else {
			history.push(routes.home.path);
		}

		if (props?.goToOnClose) {
			const {
				reactProps: { getCanRenderCurrentLocation },
			} = mapProps;
			// only move the map if CurrentLocation is currently rendered
			if (getCanRenderCurrentLocation && getCanRenderCurrentLocation()) {
				wait(props?.goToOnCloseDelay || 0).then(() => {
					CameraController.instance?.easeTo({
						center: props.goToOnClose,
						duration: 600,
						ease: easings.easeInOutCubic,
					});
				});
			}
		}
	}

	const style = props?.style || null;

	const lineClass1 = classNames({
		'line-1': true,
		'theme-dark': props?.darkTheme,
		'theme-light': props?.lightTheme,
		small: props.small,
	});
	const lineClass2 = classNames({
		'line-2': true,
		'theme-dark': props?.darkTheme,
		'theme-light': props?.lightTheme,
		small: props.small,
	});

	return (
		<motion.div className="CloseButtonX" onClick={close} onMouseUp={(e) => e.stopPropagation()} style={style}>
			<div className={lineClass1} />
			<div className={lineClass2} />
		</motion.div>
	);
}

export default CloseButtonX;
