/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';
import { IS_PHONE, MARGIN_HORIZONTAL, MARGIN_VERTICAL, MOBILE_LOCATION_DETAIL_OFFSET, ZOOM_LEVELS } from 'js/config/constants';
import CameraController from 'js/controllers/camera-controller';
import { useAppState } from 'js/hooks/app-state';
import { Debug } from 'js/utils/debug';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { getStateCode } from 'js/utils/map-data-utils';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import React, { useEffect, useState } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';
import { mapProps } from '../Map/Map';
import routes from '../Router/routes';
import SearchBarMapbox from '../SearchBarMapbox/SearchBarMapbox';

import './SearchMapbox.scss';

const motionProps0 = MOTION_PRESETS.FADE;

function Search() {
	const history = useHistory();
	const [selection, setSelection] = useState(null);
	const [cityGuideData, setCityGuideData] = useState(null);
	const [backingSelectedTime, setBackingSelectedTime] = useState(Date.now());
	const [{ guideData }] = useAppState();
	const { reactProps } = mapProps;

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);
			const rootEl = document.querySelector(':root');
			rootEl?.style?.setProperty('--background-color', '#0e0c0f');

			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
				rootEl?.style?.setProperty('--background-color', '#AF997E');
			};
		}
	}, []);

	useEffect(() => {
		if (guideData) {
			const cityGuides = guideData.filter((dataItem) => dataItem?.city !== undefined);
			setCityGuideData(cityGuides);
		}
	}, [guideData]);

	useEffect(() => {
		if (selection) {
			Debug.log('search selection:', selection);

			let cityName, stateCode;

			const {
				place_type,
				geometry: { coordinates },
				bbox,
			} = selection;

			// https://docs.mapbox.com/api/search/geocoding/#data-types

			if (place_type === 'bem-curated') {
				// BEM Curated POI's
				const { id, guideId, guideType } = selection.properties;
				const route = guideType === CONTENT_TYPES.CITY_GUIDE ? routes.cityGuide.path : routes.nationalGuide.path;
				history.push({ pathname: generatePath(route, { id: guideId }), search: `?poiId=${id}` });
			} else if (place_type === 'bem') {
				// BEM POI's
				history.push(generatePath(routes.location.path, { id: selection.p.gid.toString() }));
			} else if (place_type?.includes('poi')) {
				const address = selection.place_name.split(`${selection.text}, `)[1];
				// MAPBOX POI's
				history.push(
					generatePath(routes.poi.path, {
						name: encodeURI(selection.text),
						lng: coordinates[0].toString(),
						lat: coordinates[1].toString(),
						address: encodeURI(address),
					})
				);
			} else if (place_type?.includes('place') || place_type?.includes('neighborhood') || place_type?.includes('locality')) {
				// cities, neighborhoods, etc
				cityName = selection.text;

				// special cases to trigger guides
				if (cityName === 'New York City') {
					cityName = 'New York';
				}

				selection.context.forEach(({ id, text }) => {
					if (id.includes('region')) {
						stateCode = getStateCode(text);
					}
				});

				if (!stateCode && selection.place_name?.includes('Puerto Rico')) {
					stateCode = 'PR';
				}

				const curatedGuideId = checkForCuratedCity(cityName, stateCode);

				if (curatedGuideId) {
					const options = {
						zoom: ZOOM_LEVELS.CITY + 1,
						offset: IS_PHONE ? MOBILE_LOCATION_DETAIL_OFFSET : [0, 0],
					};
					history.push(routes.home.path);
					CameraController.instance.flyTo(coordinates, options).then(() => {
						history.push(generatePath(routes.cityGuideLanding.path, { id: curatedGuideId }));
					});
				} else if (cityName && stateCode) {
					// standard city
					const finalZoom = ZOOM_LEVELS.CITY + (place_type?.includes('neighborhood') ? 2 : 1);
					history.push({
						pathname: generatePath(routes.standardCity.path, { city: encodeURI(cityName), state: stateCode }),
						search: `?lng=${coordinates[0]}&lat=${coordinates[1]}&zoom=${finalZoom}`,
					});
					// history.push(generatePath(routes.standardCity.path, { city: encodeURI(cityName), state: stateCode }));
				} else {
					CameraController.instance?.fitBounds(bbox, { duration: 2500 });
					history.push(routes.home.path);
				}
			} else if (place_type?.includes('country')) {
				if (selection.text === 'United States') {
					reactProps?.goToNationalView();
					history.push(routes.home.path);
				} else {
					CameraController.instance?.fitBounds(bbox, { duration: 2500 });
					history.push(routes.home.path);
				}
			} else if (place_type?.includes('address') || place_type?.includes('postcode')) {
				CameraController.instance?.flyTo(coordinates, { zoom: ZOOM_LEVELS.STREET + 4 });
				history.push(routes.home.path);
			} else {
				// place_type like region
				CameraController.instance?.flyTo(coordinates, { zoom: ZOOM_LEVELS.STATE });
				history.push(routes.home.path);
			}
		}
	}, [selection]);

	const checkForCuratedCity = (city, state) => {
		let result;

		cityGuideData.forEach(({ id, city: { cityName, cityState } }) => {
			if (city === cityName && cityState === state) {
				result = id;
			}
		});

		return result;
	};

	const handleBackingSelected = (e) => {
		e.stopPropagation();
		setBackingSelectedTime(Date.now());

		if (!IS_PHONE) {
			history.push(routes.home.path);
		}
	};

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Search</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className="Search"
				onAnimationStart={() => {
					if (IS_PHONE) {
						mapProps?.layers?.elevation?.resume();
					}
				}}
				onAnimationComplete={(definition) => {
					if (IS_PHONE && definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				{IS_PHONE && <div className="backing" onTouchStart={handleBackingSelected} />}
				{!IS_PHONE && <div className="backing" onClick={handleBackingSelected} />}
				<div className="content">
					<div className="header-container">
						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} className="searchbar">
							<SearchBarMapbox placeholder="Search" onChange={setSelection} backingSelectedTime={backingSelectedTime} />
						</motion.div>
					</div>
				</div>
				{IS_PHONE && (
					<CloseButtonX
						style={{ position: 'fixed', top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 20 }}
						lightTheme={true}
						onClick={() => {
							setBackingSelectedTime(Date.now());
							history.push(routes.home.path);
						}}
					/>
				)}
			</motion.div>
		</>
	);
}

export default Search;
