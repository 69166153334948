import classNames from 'classnames';
import React, { memo, useEffect, useRef, useState } from 'react';

import './SocialEmbed.scss';

function SocialEmbed(props) {
	// Generic Travel - 1e6daca6-85e0-40a4-84c1-ac8142d5b3d6
	// Generic BEM - 5039ad39-cbe2-4711-9e9c-6c10c7dee3a9
	// Generic Culture - 8f6ce0b5-d7bb-4915-a01a-794618381bea

	// curator-feed-ba-generictravel-layout
	// curator-feed-ba-genericbem-layout
	// curator-feed-ba-genericculture-layout

	const [loaded, setLoaded] = useState(false);
	const containerRef = useRef(null);

	const { feedId = '1e6daca6-85e0-40a4-84c1-ac8142d5b3d6', containerId = 'curator-feed-ba-generictravel-layout' } = props;

	useEffect(() => {
		if (feedId) {
			const config = { childList: true };
			const callback = (mutationList, observer) => {
				// Debug.log(mutationList, observer);
				observer?.disconnect();
				setLoaded(true);
			};

			const observer = new MutationObserver(callback);
			observer.observe(containerRef.current, config);

			(function () {
				var i,
					e,
					d = document,
					s = 'script';
				i = d.createElement('script');
				i.async = 1;
				i.charset = 'UTF-8';
				i.src = `https://cdn.curator.io/published/${feedId}.js`;
				e = d.getElementsByTagName(s)[0];
				e.parentNode.insertBefore(i, e);
			})();

			return () => {
				observer?.disconnect();
			};
		}
	}, [feedId]);

	const mainClass = classNames({
		SocialEmbed: true,
		visible: loaded,
	});

	return (
		<div className={mainClass}>
			<div id={containerId} ref={containerRef}>
				<a href="https://curator.io" target="_blank" rel="noreferrer" className="crt-logo crt-tag">
					Powered by Curator.io
				</a>
			</div>
		</div>
	);
}

export default memo(SocialEmbed);
