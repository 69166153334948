/* eslint-disable import/no-anonymous-default-export */
import { ABOUT } from '../config/content';

export default [
	{
		id: ABOUT.ID_WHAT,
		title: 'WHAT IS THE BLACK ELEVATION MAP?',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					'The Black Elevation Map is an immersive digital experience that reimagines the American landscape by visualizing the heights of The Culture as elevation. The platform takes cultural data, such as Black population data, historical markers, Black-owned businesses, and social media activity, and visualizes it as points of interest on a dynamic, searchable elevation map of the United States. The greater the density of data, the higher the elevation. ',
				],
			},
		],
	},

	{
		id: ABOUT.ID_WHY,
		title: 'WHY A MAP?',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					"From redlining to modern urban planning, you don't have to look far to see ways in which maps have been used to marginalize, divide and oppress communities around the world. We want this project to help Black travelers see the country in a way that prioritizes and celebrates the contributions of folks who look like us - and facilitates travel choices that deepen engagement within our community. Repurposing a traditional elevation map is a way for us to weave joy and depth into the story, the experience, and our interpretation of the data.",
				],
			},
		],
	},

	{
		id: ABOUT.ID_WHERE,
		title: "WHERE'S THE DATA COMING FROM?",
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					'At launch, the Black Elevation Map contains more than 30,000 unique points of interest, plus detailed population data, and Black-travel-related social media activity data. In choosing which data to include in this project, we wanted sources where the Black community had self-identified, versus data collection sources that try to identify without consent.',

					'Our sources:<br/>Yelp and Google, both of which have options for business owners to self-identify as Black-owned. The map also includes data classified as "African American" from The Historical Markers Database.',
				],
			},
		],
	},

	{
		id: ABOUT.ID_HOW,
		title: 'HOW DOES THE TRAVEL FEED WORK?',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					"The travel feed pulls in Black travel-related social media conversations from Twitter and Insta based on hashtag use. It's categorized across three main streams, for a total of 1,500 social sources across 53 unique embedded data feeds.",
				],
			},
		],
	},

	{
		id: ABOUT.ID_ICONS,
		title: 'ABOUT THE MAP ICONS',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					'Inspired by the rich diasporic cultures and languages of West Africa, the website uses unique iconography rooted in African symbology, designed by Washington, DC-based designer Tré Seals.',
					'<a href="https://www.vocaltype.co" target="_blank" rel="noopener noreferrer">https://www.vocaltype.co</a>',
				],
			},
			{
				type: 'AboutIcons',
				content: [
					{
						label: 'Curated',
						icon: 'Curated',
						description: `Ananse Ntontan literally means "spider's web". It's a symbol of wisdom, creativity and the complexities of life.`,
					},
					{
						label: 'Fitness',
						icon: 'Flex',
						description: `The "fitness" icon is inspired by the Nkyinkyim, an Adinkra symbol for initiative, dynamism and versatility.`,
					},
					{
						label: 'Vibe',
						icon: 'Entertainment',
						description: `This icon is inspired by the African bongo drum.`,
					},
					{
						label: 'Learn',
						icon: 'Learn',
						description: `This icon is inspired by the constant search to understand and find out more about our ancestry.`,
					},
					{
						label: 'See',
						icon: 'See',
						description: `Abode Santann means "all-seeing eye". It's based on the idea that God is omnipresent and he sees everyone at every time.`,
					},
					{
						label: 'Shop',
						icon: 'Shop',
						description: `The Hwehwemudua is the Adinkra symbol known as the measuring rod. It's a symbol of excellence, knowledge and quality.`,
					},
					{
						label: 'Self-care',
						icon: 'Self-care',
						description: `A "duafe" is a wooden comb and an Adinkra symbol of beauty and cleanliness.`,
					},
					{
						label: 'Stay',
						icon: 'Stay',
						description: `This icon is inspired by the symbol for home from the Bantu symbol systems.`,
					},
					{
						label: 'Eat',
						icon: 'Eat',
						description: `The "eat" icon is inspired by one of the many pictograms of the early Nsibidi language from Nigeria.`,
					},
					{
						label: 'Saved',
						icon: 'Favorites',
						description: `Sankofa is a word in the Akan Twi and Fante languages of Ghana translating "to go back and get it". It also refers to the Bono Adinkra symbol of a stylized heart.`,
					},
					{
						label: 'Searched',
						icon: 'General',
						description: `This icon represents searchable locations that do not contribute to the map's elevation.`,
					},
				],
			},
		],
	},

	{
		id: ABOUT.ID_WHO,
		title: 'WHO IS BLACK & ABROAD?',
		blocks: [
			{
				type: 'AboutImage',
				content: {
					src: '/assets/about/about-who.jpg',
					alt: 'Eric Martin and Kent Johnson, Founders of Black and Abroad',
				},
			},
			{
				type: 'AboutQuote',
				content: {
					text: `Our mission is to impact change within the travel industry about what it means to be a Black person exploring the world.`,
					attrib: 'Eric Martin & Kent Johnson, Founders of Black & Abroad',
				},
			},
			{
				type: 'AboutParagraphs',
				content: [
					'Black & Abroad is a multi-platform travel and lifestyle company. Since 2015, our digital campaigns, international events and travel experiences have redefined exploration for the modern Black traveler. We pride ourselves on working together to connect people all over the world through culturally conscious and thought-driven initiatives centered around exploration, while providing crowd-sourced information hubs for urban travelers. Seeing a need to elevate the community through world exploration, our company was created to expose the world to nuanced, diverse narratives within the travel community.',
					'<a href="https://www.blackandabroad.com/" target="_blank" rel="noopener noreferrer">Visit BlackAndAbroad.com</a>',
				],
			},
		],
	},

	{
		id: ABOUT.ID_BACK,
		title: 'THE "GO BACK TO AFRICA" Project',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					`In 2019, we created a pan-Africa tourism campaign that turned that racial slur "Go Back To Africa" into a relentlessly positive call to action for travel to the Continent. Since then, we've seen our community embrace #GoBackToAfrica and the spirit of the campaign to build new narratives around what it means to be Black and abroad. Let's keep it going!`,
					// '<a href="https://youtu.be/PoyutLAPUbQ" target="_blank" rel="noopener noreferrer">https://youtu.be/PoyutLAPUbQ</a>',
				],
			},
			{
				type: 'AboutVideo',
				content: {
					title: 'video-gbta',
					video: 'https://www.youtube.com/embed/PoyutLAPUbQ?modestbranding=1&playsinline=1',
				},
			},
		],
	},

	// [CODY!] -- uncomment this block to enable Contributors -- move up and down Array for positioning
	/*
	{
    id: ABOUT.ID_CONTRIBUTORS,
    title: 'CONTRIBUTORS',
    blocks: [
      {
        type: 'AboutContributors',
      },
    ]
  },
	*/

	{
		id: ABOUT.ID_PARTNERS,
		title: 'SHOUTOUTS',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					`It took a ton of collaborators and collaboration to bring this project to life. Big shoutout to everyone who worked hard to make it happen, including our friends at Alfredo Films, our director Kelly Fyffe-Marshall, the poet Jasmine Mans, Alter Ego Post, TA2 Sound + Music, our editor Michael 'Middy' Ofori-Attah, our friends at Performance Art and so many more.`,
				],
			},
			{
				type: 'AboutPartners',
				content: [
					{ link: 'https://www.alfredofilms.com/' },
					{ link: 'https://www.kellyfyffemarshall.com/' },
					{ link: 'https://www.jasminemans.com/' },
					{ link: 'https://www.alteregopost.com/' },
					{ link: 'https://www.ta2music.com/' },
					{ link: 'https://www.ohmiddy.com' },
					{ link: 'https://performanceart.com/' },
				],
			},
			{
				type: 'AboutParagraphs',
				content: [
					'We also had the support of some great media and technology partners, including Initiative Media, Reprise, Kinesso and Meta. Thanks for helping us get this map into the hands of the folks who might find it most interesting and useful.',
				],
			},
		],
	},

	{
		id: ABOUT.ID_LAST,
		title: 'ONE LAST THING',
		blocks: [
			{
				type: 'AboutParagraphs',
				content: [
					"We know there's no way one map could visualize the true heights of The Culture. For us, this map is a story. A way to help reframe the way the country thinks about itself. A part of the journey. If you're a business owner, think about adding yours to the map. And let us know what you think.",
					`<a href="https://business.google.com/create" target="_blank" rel="noreferrer noopener">Add to Google</a>`,
					`<a href="https://business.yelp.com/" target="_blank" rel="noreferrer noopener">Add to Yelp</a>`,
				],
			},
		],
	},

	{
		id: ABOUT.ID_SUBSCRIBE,
		blocks: [
			{
				type: 'AboutSubscribe',
			},
		],
	},
];
