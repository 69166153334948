import { DEBUG_USER_LOCATION, POI_RATING_FILTER } from 'js/config/constants';
import { useAppState } from 'js/hooks/app-state';
import { getFeaturesNearBy } from 'js/utils/map-data-utils';
import React, { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentDrawer, { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';
import { Debug } from 'js/utils/debug';

function UserLocation() {
	const params = useParams();
	const { lon, lat } = params;
	const [nearbyLocations, setNearbyLocations] = useState([]);
	const [{ poiStateData }] = useAppState();

	useEffect(() => {
		if (lon && lat && poiStateData) {
			// Debug.log('UserLocation:', lon, lat);
			const coords = DEBUG_USER_LOCATION ? [-74.18496608734131, 40.85121557428249] : [lon, lat];
			const nearbyFeatures = getFeaturesNearBy(poiStateData, null, null, coords, 200, 12, POI_RATING_FILTER);
			setNearbyLocations(nearbyFeatures);
			// Debug.log('FEATURES NEAR USER:', nearbyFeatures);
		}
	}, [lon, lat, poiStateData]);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Around Me</title>
				<meta
					name="description"
					content="See a piece of The Culture you'd like to visit? Save it and email it straight to your inbox to help plan your next trip."
				/>
			</HelmetWrapper>
			<ContentDrawer contentType={CONTENT_TYPES.USER_LOCATION} content={nearbyLocations} />
		</>
	);
}

export default memo(UserLocation);
