import { IS_PHONE } from 'js/config/constants';
import { mapProps } from '../Map/Map';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../Router/routes';
import { motion } from 'framer-motion';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import ContentFooter from '../ContentFooter/ContentFooter';
import { MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './HamburgerMenu.scss';

const containerMotion = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.3,
			staggerChildren: 0.05,
			delayChildren: 0,
			ease: EASE_OUT,
		},
	},
	exit: {
		opacity: 0,
	},
};

const MENU_LIST = [
	{ label: 'About', link: routes.about.path },
	{ label: 'Tour the Culture', link: routes.nationalGuides.path },
	{ label: 'City Guides', link: routes.cityGuides.path },
	{ label: 'Add To Map', link: routes.addToMap.path },
	{ label: 'My Saved Places', link: routes.savedLocations.path },
	{ label: 'Travel Feed', link: routes.socialNational.path },
];

function HamburgerMenu(props) {
	const containerRef = useRef(null);

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);
			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
			};
		}
	}, []);

	const history = useHistory();

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Menu</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={MOTION_PRESETS.FADE}
				className="HamburgerMenu"
				ref={containerRef}
				onAnimationStart={() => {
					if (IS_PHONE) {
						mapProps?.layers?.elevation?.resume();
					}
				}}
				onAnimationComplete={(definition) => {
					if (IS_PHONE && definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				<div className="HamburgerMenu-inner">
					<div className="pad-container">
						<motion.div className="scroll-container" variants={containerMotion}>
							{MENU_LIST.map((d, i) => {
								return (
									<motion.div
										key={i}
										className="menu-item"
										onClick={() => {
											history.push(d.link);
										}}
									>
										<motion.div variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}>
											{d.label}
										</motion.div>
									</motion.div>
								);
							})}
						</motion.div>
					</div>

					<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={MOTION_PRESETS.FADE} className="footer-container">
						<ContentFooter />
					</motion.div>
				</div>
				{IS_PHONE ? <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 22 }} lightTheme={true} /> : <CloseButtonX />}
			</motion.div>
		</>
	);
}

export default HamburgerMenu;
