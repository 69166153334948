import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import 'fonts/VTC_William_Desktop/VTCWilliam.css';
import 'fonts/martin-web/VTCMartin.css';

// FIXME: color values should always come from the palette

const darkTheme = createMuiTheme({
	typography: {
		fontFamily: 'VTCWilliam',
		fontSize: 12,
	},
	palette: {
		type: 'dark',
	},
	props: {
		MuiTypography: {
			color: 'textPrimary',
		},
		MuiLink: {
			color: 'textPrimary',
			underline: 'always',
		},
	},
	overrides: {
		MuiPaper: {
			root: {
				backgroundColor: 'rgba(0,0,0,0.3)', // expects dark theme
				borderRadius: '0 !important', // we use !important to be concise
				boxShadow: '0 0 100px 100px rgba(0,0,0,0.3) !important',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: '#FFF',
				height: '5px',
			},
		},
		MuiIconButton: {
			root: {
				color: '#000',
			},
		},
	},
});

const MuiThemeProvider = ({ children }) => (
	<ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
);

export default MuiThemeProvider;
