export const EASE_IN_OUT = [0.85, 0, 0.15, 1];
export const EASE_IN = [0.55, 0, 1, 0.45];
export const EASE_OUT = [0, 0.55, 0.45, 1];
export const EASE_OUT_BACK = [0.34, 1.56, 0.64, 1];

export const SLIDE_DURATION = 1;
export const BACK_DURATION = 0.6;
export const FADE_DURATION = 0.5;
export const SCALE_DURATION = 1;
export const COMMON_DURATION = 0.4;
export const STAGGER_DELAY = 0.075;

export const MOTION_VARIANTS = {
	initial: ({ initial }) => ({
		...initial,
	}),
	animate: ({ animate }) => ({
		...animate,
	}),
	exit: ({ exit }) => ({
		...exit,
	}),
};

export function overrideMotionVariants(props) {
	const { initial, animate, exit, enterTransProps = {}, exitTransProps = {} } = props;

	const variants = {
		initial: { ...initial },
		animate: { ...animate },
		exit: { ...exit },
	};

	variants.animate.transition = { ...variants.animate.transition, ...enterTransProps };
	variants.exit.transition = { ...variants.exit.transition, ...exitTransProps };

	return variants;
}

export const MOTION_PRESETS = {
	SLIDE_FROM_LEFT: {
		initial: {
			transform: 'translateX(-150%)',
		},
		animate: {
			transform: 'translateX(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			transform: 'translateX(-100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	SLIDE_FROM_RIGHT: {
		initial: {
			transform: 'translateX(100%)',
		},
		animate: {
			transform: 'translateX(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			transform: 'translateX(100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	SLIDE_FROM_BOTTOM: {
		initial: {
			transform: 'translateY(100%)',
		},
		animate: {
			transform: 'translateY(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			transform: 'translateY(100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	SLIDE_FROM_TOP: {
		initial: {
			transform: 'translateY(-100%)',
		},
		animate: {
			transform: 'translateY(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			transform: 'translateY(-100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	FADE_SLIDE_FROM_LEFT: {
		initial: {
			opacity: 0,
			transform: 'translateX(-100%)',
		},
		animate: {
			opacity: 1,
			transform: 'translateX(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			opacity: 0,
			transform: 'translateX(-100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	FADE_SLIDE_FROM_RIGHT: {
		initial: {
			opacity: 0,
			transform: 'translateX(100%)',
		},
		animate: {
			opacity: 1,
			transform: 'translateX(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			opacity: 0,
			transform: 'translateX(100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	FADE_SLIDE_FROM_BOTTOM: {
		initial: {
			opacity: 0,
			transform: 'translateY(100%)',
		},
		animate: {
			opacity: 1,
			transform: 'translateY(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			opacity: 0,
			transform: 'translateY(100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	FADE_SLIDE_FROM_TOP: {
		initial: {
			opacity: 0,
			transform: 'translateY(-100%)',
		},
		animate: {
			opacity: 1,
			transform: 'translateY(0%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			opacity: 0,
			transform: 'translateY(-100%)',
			transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
		},
	},
	FADE: {
		initial: {
			opacity: 0,
		},
		animate: {
			opacity: 1,
			transition: { duration: FADE_DURATION, ease: EASE_OUT },
		},
		exit: {
			opacity: 0,
			transition: { duration: FADE_DURATION, ease: EASE_OUT },
		},
	},
	SCALE_FROM_LEFT: {
		initial: {
			transformOrigin: 'left',
			transform: 'scaleX(0)',
		},
		animate: {
			transform: 'scaleX(1)',
			transition: { duration: SCALE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			transform: 'scaleX(0)',
			transition: { duration: SCALE_DURATION, ease: EASE_IN_OUT },
		},
	},
	SCALE_FROM_RIGHT: {
		initial: {
			transformOrigin: 'right',
			transform: 'scaleX(0)',
		},
		animate: {
			transform: 'scaleX(1)',
			transition: { duration: SCALE_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			transform: 'scaleX(0)',
			transition: { duration: SCALE_DURATION, ease: EASE_IN_OUT },
		},
	},
	WIDTH: {
		initial: {
			width: '0px',
		},
		animate: {
			width: '100px',
			transition: { duration: COMMON_DURATION, ease: EASE_IN_OUT },
		},
		exit: {
			width: '0px',
			transition: { duration: COMMON_DURATION, ease: EASE_IN_OUT },
		},
	},
};
