import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import routes from '../Router/routes';
import { motion } from 'framer-motion';
import { IS_PHONE } from 'js/config/constants';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';

import './NationalGuidesCTA.scss';

function NationalGuidesCTA(props) {
	const { isMainInterface = true, route = routes.nationalGuides.path } = props;

	const history = useHistory();

	const theme = isMainInterface ? 'dark' : 'light';

	const mainStyle =
		isMainInterface || IS_PHONE
			? null
			: {
					position: 'relative',
					left: 'unset',
					bottom: 'unset',
					transform: 'unset',
					alignSelf: 'center',
			  };

	const arrowStyle = isMainInterface
		? null
		: {
				transform: 'scaleY(-1)',
		  };

	const className = classNames({
		NationalGuidesCTA: true,
		dark: theme === 'dark',
		light: theme === 'light',
	});

	return (
		<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={MOTION_PRESETS.FADE} className={className} style={mainStyle} onClick={() => history.push(route)}>
			<div className="arrows" style={arrowStyle}>
				{renderArrows(props)}
			</div>
			<div className="label">Tour The Culture</div>
			<div className="arrows" style={arrowStyle}>
				{renderArrows(props)}
			</div>
		</motion.div>
	);
}

function renderArrows(props) {
	const { color = '#000000' } = props;

	return (
		<svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.846124 14.7221L1.14524 15.7754L10.2227 12.2243L19.3001 15.7754L19.5992 14.7221L10.2227 11.0532L0.846124 14.7221Z" fill={color} />
			<path d="M0.846124 11.1132L1.14524 12.1665L10.2227 8.61539L19.3001 12.1665L19.5992 11.1132L10.2227 7.44428L0.846124 11.1132Z" fill={color} />
			<path d="M0.846124 7.50233L1.14524 8.55566L10.2227 5.00455L19.3001 8.55566L19.5992 7.50233L10.2227 3.83344L0.846124 7.50233Z" fill={color} />
			<path d="M0.846124 3.89073L1.14524 4.94629L10.2227 1.39518L19.3001 4.94629L19.5992 3.89073L10.2227 0.224066L0.846124 3.89073Z" fill={color} />
		</svg>
	);
}

export default NationalGuidesCTA;
