/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import getLocation from 'js/api/getLocation';
import { useAppState } from 'js/hooks/app-state';
import { Debug } from 'js/utils/debug';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

const Location = ({ match }) => {
	const { id } = match.params;
	const [{ locationDetailData }, { setState }] = useAppState();

	useEffect(() => {
		getLocation(id).then((result) => {
			Debug.log('Location:', id, result);
			setState({ locationDetailData: result });
		});

		return () => {
			setState({ locationDetailData: null });
		};
	}, []);

	return (
		<HelmetWrapper>
			<title>{`Black Elevation Map | ${locationDetailData?.name}`}</title>
			<meta name="description" content="Explore a new map of America - with curated guides, 30,000 points of interest and the millions who make it rise." />
		</HelmetWrapper>
	);
};

export default Location;
