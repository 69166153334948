import { CanvasTexture, ClampToEdgeWrapping, LinearFilter, UVMapping, Vector3 } from 'three/build/three.module';
import { ELEVATION_GEOMETRY_CONFIG, LAYER_SCALE } from '../config/constants';
import { abs, map, randomInt } from './utils';
import CanvasFastBlur from './canvas-blur';
import { Debug } from './debug';
import { getTier } from './graphics';
// import SimplexNoise from 'simplex-noise';

// const simplex = new SimplexNoise();

export function getHeightmap(options) {
	Debug.time(`getHeightmap / ${options.key}`);

	const { data, key, maxValue, minRadius = 1.5, maxRadius = 3, blurAmount, topLeft, tb, maskImage, outputRender } = options;
	const { heightmapWidth, heightmapHeight, radiusScalar } = ELEVATION_GEOMETRY_CONFIG;

	const canvas = document.createElement('canvas');
	canvas.width = heightmapWidth;
	canvas.height = heightmapHeight;
	const ctx = canvas.getContext('2d');

	// if (SHOW_HEIGHT_MAP || outputRender) {
	if (outputRender) {
		canvas.className = 'heightmap';
		const container = document.querySelector('.heightmap-container');
		container?.appendChild(canvas);
	}

	ctx.fillStyle = 'black';
	ctx.fillRect(0, 0, canvas.width, canvas.height);

	ctx.globalCompositeOperation = 'lighten';

	const position = new Vector3();
	const meshWidth = ELEVATION_GEOMETRY_CONFIG.width * LAYER_SCALE;
	const meshHeight = ELEVATION_GEOMETRY_CONFIG.height * LAYER_SCALE;

	// data|mountains
	data.forEach((item) => {
		const {
			geometry: { coordinates },
			properties,
		} = item;

		// use specific property to generate heightmap
		const value = properties[key];

		if (value >= 1) {
			tb.projectToWorld(coordinates, position);
			const relativeX = abs(position.x - topLeft.x);
			const relativeY = abs(position.y - topLeft.y);
			const x = ((relativeX / meshWidth) * heightmapWidth) | 0;
			const y = ((relativeY / meshHeight) * heightmapHeight) | 0;
			const elevationColor = map(value, 0, maxValue, 50, 255) | 0;
			const radius = map(value, 0, maxValue, minRadius * radiusScalar, maxRadius * radiusScalar);
			const innerRadius = 0; // mountain tip - only 0 works in FF Windows
			const outerRadius = radius; // mountain base

			// data
			drawGradient(canvas, elevationColor, x, y, innerRadius, outerRadius, value > 2 ? undefined : 1);
		}
	});

	// smoothing
	if (blurAmount) {
		blurCanvas(canvas, blurAmount);
	}

	// draw alpha mask over top
	if (maskImage) {
		maskCanvas(canvas, maskImage);
	}

	// add data in top left corner so positions are correct in 3d / three-box
	drawGradient(canvas, 20, 0, 0, 1, 1);

	const tex = getCanvasTexture(canvas);

	Debug.timeEnd(`getHeightmap / ${options.key}`);

	return tex;
}

export function drawGradient(canvas, color, x, y, innerRadius, outerRadius, stepsOverride) {
	const ctx = canvas.getContext('2d');
	const tier = getTier();
	const steps = tier > 0 ? stepsOverride || randomInt(3, 6) : 1;

	const radScalar = 0.66;
	const maxOffset = 3;
	let iRad = innerRadius;
	let oRad = outerRadius;
	let c = color; // * (colorScalar * steps);

	for (let i = 0; i < steps; i++) {
		const offX = x + (i > 0 ? randomInt(-maxOffset, maxOffset) : 0);
		const offY = y + (i > 0 ? randomInt(-maxOffset, maxOffset) : 0);

		const gradient = ctx.createRadialGradient(offX, offY, iRad, offX, offY, oRad);
		gradient.addColorStop(0, `rgb(${c}, ${c}, ${c})`);
		gradient.addColorStop(1, 'black');

		ctx.fillStyle = gradient;
		ctx.beginPath();
		ctx.arc(offX, offY, oRad, 0, 2 * Math.PI);
		ctx.closePath();
		ctx.fill();

		iRad *= radScalar;
		oRad *= radScalar;
	}
}

export function getCanvasTexture(canvas) {
	return new CanvasTexture(canvas, UVMapping);
}

export function maskCanvas(canvas, alphaMap) {
	const ctx = canvas.getContext('2d');
	const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);

	const alphaCanvas = document.createElement('canvas');
	alphaCanvas.width = canvas.width;
	alphaCanvas.height = canvas.height;
	const alphaCtx = alphaCanvas.getContext('2d');
	alphaCtx.drawImage(alphaMap, 0, 0, alphaMap.width, alphaMap.height, 0, 0, alphaCanvas.width, alphaCanvas.height);
	const alphaImgData = alphaCtx.getImageData(0, 0, alphaCanvas.width, alphaCanvas.height);

	const len = imgData.data.length;
	for (let i = 0; i < len; i += 4) {
		const maskColor = map(alphaImgData.data[i + 0], 0, 255, 0, 1);
		imgData.data[i + 0] = imgData.data[i + 0] * maskColor;
		imgData.data[i + 1] = imgData.data[i + 1] * maskColor;
		imgData.data[i + 2] = imgData.data[i + 2] * maskColor;
	}

	ctx.putImageData(imgData, 0, 0);
}

export function blurCanvas(canvas, blur = 1) {
	const canvasBlur = new CanvasFastBlur({ blur });
	canvasBlur.initCanvas(canvas);
	canvasBlur.gBlur();
}

export function getTileFromSource(src, x, y, w, h) {
	const canvas = document.createElement('canvas');
	canvas.width = w;
	canvas.height = h;

	Debug.log('getTileFromSource:', x, y, w, h);

	const ctx = canvas.getContext('2d');
	ctx.drawImage(src, x, y, w, h, 0, 0, w, h);

	const tex = new CanvasTexture(canvas);
	tex.wrapS = tex.wrapT = ClampToEdgeWrapping;
	return tex;
}

export function height2normal(canvas, smooth, smoothAmount = 1) {
	const width = canvas.width;
	const height = canvas.height;

	const dstCanvas = document.createElement('canvas');
	dstCanvas.width = width;
	dstCanvas.height = height;

	const srcContext = canvas.getContext('2d');
	const dstContext = dstCanvas.getContext('2d');

	const src = srcContext.getImageData(0, 0, width, height);
	const dst = dstContext.createImageData(width, height);

	for (let i = 0, l = width * height * 4; i < l; i += 4) {
		let x1, x2, y1, y2;

		if (i % (width * 4) === 0) {
			// left edge

			x1 = src.data[i];
			x2 = src.data[i + 4];
		} else if (i % (width * 4) === (width - 1) * 4) {
			// right edge

			x1 = src.data[i - 4];
			x2 = src.data[i];
		} else {
			x1 = src.data[i - 4];
			x2 = src.data[i + 4];
		}

		if (i < width * 4) {
			// top edge

			y1 = src.data[i];
			y2 = src.data[i + width * 4];
		} else if (i > width * (height - 1) * 4) {
			// bottom edge

			y1 = src.data[i - width * 4];
			y2 = src.data[i];
		} else {
			y1 = src.data[i - width * 4];
			y2 = src.data[i + width * 4];
		}

		dst.data[i] = x1 - x2 + 127;
		dst.data[i + 1] = y1 - y2 + 127;
		dst.data[i + 2] = 255;
		dst.data[i + 3] = 255;
	}

	dstContext.putImageData(dst, 0, 0);

	// smoothing
	if (smooth) {
		blurCanvas(dstCanvas, smoothAmount);
	}

	const tex = getCanvasTexture(dstCanvas);

	return tex;
}

export function createColorSteps(canvas, numSteps) {
	const increment = 255 / numSteps;

	Debug.time('createColorSteps');

	const ctx = canvas.getContext('2d');
	const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height);

	const len = imgData.data.length;
	for (let i = 0; i < len; i += 4) {
		const g = imgData.data[i + 1];

		for (let c = 0; c < 255; c += increment) {
			if (Math.abs(c - g) < increment) {
				imgData.data[i + 0] = c;
				imgData.data[i + 1] = c;
				imgData.data[i + 2] = c;
			}
		}
	}

	ctx.putImageData(imgData, 0, 0);

	Debug.timeEnd('createColorSteps');
}

export function getPixels(canvas) {
	const ctx = canvas.getContext('2d');
	const pixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
	return pixels;
}
