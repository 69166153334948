import classNames from 'classnames';
import { motion } from 'framer-motion';
import getLocation from 'js/api/getLocation';
import { locationIcons } from 'js/config/asset-manifest';
import { POI_CATEGORIES, POI_LABELS, TOOL_TIPS } from 'js/config/constants';
import { useAppState } from 'js/hooks/app-state';
import { Debug } from 'js/utils/debug';
import { getGeocodeDataForCoordinates, getStateCode } from 'js/utils/map-data-utils';
import { EASE_IN_OUT, EASE_OUT, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION } from 'js/utils/motion';
import { formatPhoneNumber, replaceAll } from 'js/utils/utils';
import React, { memo, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import Image from '../Image/Image';
import OptionsFooter from '../OptionsFooter/OptionsFooter';
import routes from '../Router/routes';
import StarRating from '../StarRating/StarRating';
import SaveLocationButton from '../SaveLocationButton/SaveLocationButton';
import ToolTip from '../ToolTip/ToolTip';
import FlagModal from '../FlagModal/FlagModal';

import './PoiCard.scss';

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateX(100%)',
	},
	animate: {
		opacity: 1,
		transform: 'translateX(0%)',
		transition: { duration: SLIDE_DURATION / 2, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateX(-100%)',
		transition: { duration: SLIDE_DURATION / 2, ease: EASE_IN_OUT },
	},
};

function PoiCard(props) {
	const { feature, delay = 0 } = props;
	const [data, setData] = useState(null);
	const [isCurated, setIsCurated] = useState(false);
	const [cityDetails, setCityDetails] = useState(null);
	const [flagModalData, setFlagModalData] = useState(null);
	const history = useHistory();
	// const [{ savedLocations }, { setSavedLocations }] = useAppState();

	useEffect(() => {
		if (feature?.p?.gid) {
			getLocation(feature.p.gid).then((result) => {
				// Debug.warn('feature/result:', feature, result);
				setData(result);
			});
		} else if (feature?.properties?.type === POI_CATEGORIES.CURATED) {
			setIsCurated(true);
			setData(feature);

			// for national guide curated locations
			if (!feature?.properties?.address && !feature?.properties?.cityName) {
				const {
					location: { lon, lat },
				} = feature.properties;

				getGeocodeDataForCoordinates(lon, lat)
					.then((response) => {
						let cityName, cityState;
						response?.features?.forEach((feature) => {
							if (feature?.place_type.includes('place')) {
								cityName = feature?.text;
							} else if (feature?.place_type.includes('region')) {
								cityState = getStateCode(feature?.text);
							}
						});
						setCityDetails({ cityName, cityState });
					})
					.catch((e) => Debug.warn('Error getting geocode data for:', lon, lat));
			}
		}
	}, [feature]);

	if (!data || !feature) return null;

	const motionProps = overrideMotionVariants({
		...elementMotionProps,
		enterTransProps: { delay },
		exitTransProps: { delay },
	});

	const renderCommon = () => {
		const starStyle = {
			bg: { backgroundColor: '#ffffff' },
			fg: { backgroundColor: '#0E0C0F' },
		};

		// Debug.warn('PoiCard.renderCommon - data:', data);

		// const isSaved = savedLocations?.includes(feature?.p?.gid);
		const icon = locationIcons.white[data?.type];

		let directionsUrl;

		if (data?.geometry) {
			const {
				geometry: { coordinates },
			} = data;

			const locationEncoded = encodeURI(`${coordinates[1]},${coordinates[0]}`);
			directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${locationEncoded}`;
		}

		let reviewLinkLabel = ' ';
		let isGoogle = false;

		if (data?.source === 'yelp') {
			reviewLinkLabel = ' Yelp ';
		} else if (data?.source === 'google') {
			reviewLinkLabel = ' Google ';
			isGoogle = true;
		}

		let reviewsLabel = data?.reviewCount > 1 ? 'reviews' : 'review';

		const historicalMarker = data?.website?.includes('hmdb.org');
		const canRenderFlag = historicalMarker ? false : !isGoogle || (isGoogle && data?.website);

		const handleFlag = (e) => {
			e.stopPropagation();
			e.preventDefault();

			let flagUrl;

			if (data?.source === 'yelp' && data?.yelpId) {
				flagUrl = `https://www.yelp.com/biz_attribute?biz_id=${data?.yelpId}`;
			} else if (data?.source === 'google') {
				flagUrl = data?.website;
			}

			setFlagModalData({
				source: data?.source,
				url: flagUrl,
			});
		};

		const handleCloseFlagModal = () => {
			setFlagModalData(null);
		};

		const flagClass = classNames({
			'poi-flag': true,
			active: flagModalData !== null,
		});

		const iconBgClass = classNames({
			'poi-icon-bg': true,
		});

		const finalAddress = data?.address ? replaceAll(data.address, '"', '') : '';
		const finalName = data?.name ? replaceAll(data.name, '"', '') : '';
		const type = data?.type ? replaceAll(data.type, '"', '').toLowerCase() : '';

		// TODO : consolidate local stored images like this to static/global location?
		const imageSource = data?.images?.length ? data?.images[0] : historicalMarker ? '/assets/images/img-hmdb.jpg' : null;

		// if (historicalMarker) {
		// 	console.table(data);
		// }
		return (
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps}
				className={'PoiCard ' + type}
			>
				{imageSource && (
					<div className="poi-card-image">
						<Image src={imageSource} alt={finalName} />
					</div>
				)}

				<div className="poi-card-content">
					<div className="poi-card-content-location-header">
						<div className="poi-card-title">{finalName}</div>
						{icon && (
							<div className="poi-card-icon">
								<div className={iconBgClass} />
								<img src={icon} alt="icon" />
							</div>
						)}
					</div>

					<div className="poi-card-content-location-details">
						{data?.rating > 0 && (
							<>
								<div className="poi-rating">{data?.rating?.toFixed(1)} OUT OF 5</div>
								{false && <StarRating rating={data?.rating || 0} styles={starStyle} />}
								<div className="poi-review-count">
									{data?.reviewCount}
									{reviewLinkLabel}
									{reviewsLabel}
								</div>
							</>
						)}
						{!historicalMarker && (
							<>
								<div className="poi-review-price-and-type">
									{data?.price && <div className="poi-review-price">{data.price}&nbsp;-&nbsp;</div>}
									{data?.type && <div className="poi-review-type">{POI_LABELS[data.type]}</div>}
								</div>
								<div className="poi-address">
									{data?.address && data?.address !== ' ' && (
										<div className="address">
											{finalAddress}
											<br />
										</div>
									)}
									{data?.city && data?.state && data?.zip && (
										<div className="address">
											{data.city}, {getStateCode(data.state) || data.state}&nbsp;{data.zip}
										</div>
									)}
									{data?.phoneNumber && (
										<div className="phone">{formatPhoneNumber(data.phoneNumber) || data.phoneNumber}</div>
									)}
								</div>
							</>
						)}
						{historicalMarker && (
							<div className="poi-address poi-address--historical">
								<div className="address">{data?.description}</div>
							</div>
						)}
					</div>

					<div
						className="poi-hitarea"
						onClick={() => history.push(generatePath(routes.location.path, { id: feature?.p?.gid }))}
					/>
					<div className="poi-card-content-footer">
						{canRenderFlag && (
							<div
								className="poi-flag--wrap"
								onClick={handleFlag}
								onMouseUp={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
							>
								<div className={flagClass} />
								<ToolTip className="tooltip" label={TOOL_TIPS.FLAG} />
							</div>
						)}
						{!canRenderFlag && <div className="poi-no-flag" />}

						<div className="poi-divider" />
						<OptionsFooter
							poiId={feature?.p?.gid}
							website={data?.website}
							source={data?.source}
							directions={directionsUrl}
							historicalMarker={historicalMarker}
							curated={true}
							theme="dark"
						/>
						<SaveLocationButton id={feature?.p?.gid} theme="dark" type={data?.type} />
					</div>
				</div>
				{flagModalData && <FlagModal data={flagModalData} onClose={handleCloseFlagModal} />}
			</motion.div>
		);
	};

	const renderCurated = () => {
		// const isSaved = savedLocations?.includes(feature?.properties?.id);
		const icon = locationIcons.white[POI_CATEGORIES.CURATED];

		const handleClick = (e) => {
			e.stopPropagation();
			e.preventDefault();

			const { guideType, guideId, id } = feature?.properties;

			if (guideType === CONTENT_TYPES.CITY_GUIDE) {
				history.push({
					pathname: generatePath(routes.cityGuide.path, {
						id: guideId,
					}),
					search: `?poiId=${id}`,
				});
			} else if (guideType === CONTENT_TYPES.NATIONAL_GUIDE) {
				history.push({
					pathname: generatePath(routes.nationalGuide.path, {
						id: guideId,
					}),
					search: `?poiId=${id}`,
				});
			}
		};

		const formatCuratedAddress = (curatedAddress) => {
			const index = curatedAddress.indexOf(',');
			const line1 = curatedAddress.substr(0, index);
			const line2 = curatedAddress.substr(index + 1);
			return `${line1}\n${line2}`;
		};

		let directionsUrl;

		if (feature?.properties?.address) {
			const addressEncoded = encodeURI(feature?.properties?.address);
			directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${addressEncoded}`;
		}

		const iconBgClass = classNames({
			'poi-icon-bg': true,
		});

		return (
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps}
				className="PoiCard"
				onClick={handleClick}
			>
				{feature?.properties?.coverImage && (
					<div className="poi-card-image">
						<Image src={feature?.properties?.coverImage?.url} alt={feature?.properties?.title} maxHeight={450} />
					</div>
				)}
				<div className="poi-card-content">
					<div className="poi-card-content-location-header">
						<div className="poi-card-title">{feature?.properties?.locationName}</div>
						{icon && (
							<div className="poi-card-icon">
								<div className={iconBgClass} />
								<img src={icon} alt="icon" />
							</div>
						)}
					</div>
					<div className="poi-card-content-location-details">
						<div className="poi-review-price-and-type">
							<div className="poi-review-type">{POI_CATEGORIES.CURATED}</div>
						</div>
						<div className="poi-address">
							{feature?.properties?.address && (
								<div className="address">
									{formatCuratedAddress(feature.properties.address)}
									<br />
								</div>
							)}
							{!feature?.properties?.address && (feature?.properties?.cityName || cityDetails) && (
								<div className="address">
									{feature?.properties?.cityName || cityDetails.cityName},{' '}
									{feature?.properties?.cityState || cityDetails.cityState}
									<br />
								</div>
							)}
						</div>
					</div>
					<div className="poi-card-content-footer">
						<div className="poi-divider" />
						<OptionsFooter
							website={feature?.properties?.website}
							directions={directionsUrl}
							source={feature?.properties?.source}
							historicalMarker={feature?.properties?.website?.includes('hmdb.org')}
							curated={true}
							theme="dark"
						/>
						<SaveLocationButton id={feature?.properties?.id} theme="dark" type="Curated" />
					</div>
				</div>
			</motion.div>
		);
	};

	if (isCurated) {
		return renderCurated();
	} else {
		return renderCommon();
	}
}

export default memo(PoiCard);
