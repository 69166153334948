/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';
import { useAppState } from 'js/hooks/app-state';
import { COMMON_DURATION, EASE_IN_OUT, EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION, STAGGER_DELAY } from 'js/utils/motion';
import React, { useEffect, useCallback, memo } from 'react';
import { useHistory, generatePath } from 'react-router-dom';
import NationalGuidesCTA from '../NationalGuidesCTA/NationalGuidesCTA';
import routes from '../Router/routes';
import Image from '../Image/Image';
import { IS_PHONE, MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import { mapProps } from '../Map/Map';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './NationalGuides.scss';

const motionProps0 = IS_PHONE ? MOTION_PRESETS.FADE : MOTION_PRESETS.SLIDE_FROM_BOTTOM;

const delay = 0.6;

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateY(100px)',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
};

function NationalGuides() {
	const history = useHistory();
	const [{ guideData }] = useAppState();

	const renderGuides = useCallback(() => {
		const nationalGuideData = guideData.filter((dataItem) => dataItem?.city === undefined);

		return nationalGuideData.map((guide, index) => {
			const { id, title, guideDescription, coverImage, curatedPois } = guide;

			const guideId = id;
			const poiId = curatedPois.length ? curatedPois[0].id : null;

			return (
				<motion.div
					variants={MOTION_VARIANTS}
					initial="initial"
					animate="animate"
					exit="exit"
					custom={overrideMotionVariants({
						...elementMotionProps,
						enterTransProps: { delay: delay + STAGGER_DELAY * index },
					})}
					key={id}
					className="item"
					onClick={() =>
						history.push({
							pathname: generatePath(routes.nationalGuide.path, { id: guideId }),
							search: `?poiId=${poiId}`,
						})
					}
				>
					<div className="image">{coverImage?.url && <Image src={coverImage.url + '?w=308'} alt={title} />}</div>
					<div className="text">
						<div className="text-title-container">
							<div className="text-title">{title}</div>
						</div>
						<div className="text-body">{guideDescription}</div>
					</div>
				</motion.div>
			);
		});
	}, []);

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);

			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
			};
		}
	}, []);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Tour The Culture</title>
				<meta name="description" content="Take a tour across America with these culture-packed guides." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className="NationalGuides"
				onAnimationStart={() => {
					if (IS_PHONE) {
						mapProps?.layers?.elevation?.resume();
					}
				}}
				onAnimationComplete={(definition) => {
					if (IS_PHONE && definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				<div className="content">
					<NationalGuidesCTA isMainInterface={false} route={routes.home.path} />
					<div className="sub-header">Take a tour across America with these culture-packed guides.</div>

					<div className="content-container">
						<div className="content-sections">
							<div className="item-section">
								{renderGuides()}
								<div className="bottom-spacer" />
							</div>
						</div>
					</div>
				</div>
				{IS_PHONE ? <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 22 }} lightTheme={true} /> : <React.Fragment />}
			</motion.div>
		</>
	);
}

export default memo(NationalGuides);
