import { Vector2 } from 'three/build/three.module';

import vert from '../../glsl/post-shader.vert';
import frag from '../../glsl/post-shader.frag';

const PostShader = {
	uniforms: {
		tDiffuse: { value: null },

		// fxaa
		resolution: { value: new Vector2(window.innerWidth, window.innerHeight) },
		renderFXAA: { value: true },

		// color tint
		// focusScalar: { value: 0.0 },

		// depth
		tDepth: { value: null },

		// LUT
		tLookup: { value: null },

		// grain
		grainIntensity: { value: 0.025 },
		time: { value: 0.0 },
	},
	vertexShader: vert,
	fragmentShader: frag,
};

export default PostShader;
