/* eslint-disable react-hooks/exhaustive-deps */
import { useAppState } from 'js/hooks/app-state';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import { Debug } from 'js/utils/debug';
import React from 'react';
import { motion } from 'framer-motion';
import { COMMON_DURATION, EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants } from 'js/utils/motion';

import './ShareModal.scss';

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
};

const motionProps0 = MOTION_PRESETS.FADE;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: 0.5 },
});

function ShareModal() {
	const [{ currentPoi }, { setState }] = useAppState();

	const Copy = (url) => {
		Debug.log(url);
		var text = url;
		window.navigator.clipboard.writeText(text).then(
			function () {},
			function (err) {}
		);
	};
	let title = currentPoi?.locationName ? currentPoi?.locationName : currentPoi?.name;
	return (
		<div className="ShareModal">
			<motion.div className="ShareModal-overlay" variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} />
			<motion.div className="ShareModal-content" variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1}>
				<span className="uppercase">Share</span>
				<CloseButtonX onClick={() => setState({ shareModal: false })} style={{ position: 'absolute', top: 24, right: 24, marginLeft: '24px' }} />
				<div className="ShareModal-title">
					<img src="/assets/share.png" alt="" />
					<h1 className="title">
						See <span>{currentPoi.locationName ? currentPoi.locationName : currentPoi.name}</span> and over 30,000 points of interest on the Black Elevation Map.
					</h1>
				</div>
				<div className="ShareModal-flex">
					<div className="ShareModal-left">
						<a className="ShareModal-facebook" target="_blank" rel="noopener noreferrer" href={'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href}>
							Facebook
						</a>
						<a
							className="ShareModal-twitter"
							target="_blank"
							rel="noopener noreferrer"
							href={'https://twitter.com/intent/tweet?hashtags=blackelevationmap&text=' + title + '&url=' + window.location.href}
						>
							Twitter
						</a>
					</div>
					<div className="ShareModal-center">OR</div>
					<div className="ShareModal-right">
						<button className="btn btn-gold" onClick={() => Copy(window.location.href)}>
							Copy Link
						</button>
					</div>
				</div>
			</motion.div>
		</div>
	);
}

export default ShareModal;
