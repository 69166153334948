/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';
import { IS_PHONE } from 'js/config/constants';
import { easings } from 'js/utils/easings';
import { BACK_DURATION, EASE_OUT, FADE_DURATION, MOTION_VARIANTS, overrideMotionVariants } from 'js/utils/motion';
import { wait } from 'js/utils/utils';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';
import { mapProps } from '../Map/Map';
import routes from '../Router/routes';

import './NotFound.scss';

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(50px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: BACK_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transition: { duration: FADE_DURATION, ease: EASE_OUT },
	},
};

const delay = IS_PHONE ? 2 : 1.5;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay },
	exitTransProps: { delay: 0.3 },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + 0.1 },
	exitTransProps: { delay: 0.2 },
});

const motionProps3 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + 0.2 },
	exitTransProps: { delay: 0.1 },
});

const motionProps4 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + 0.3 },
	exitTransProps: { delay: 0 },
});

function NotFound() {
	const history = useHistory();
	const { reactProps } = mapProps;

	useEffect(() => {
		wait(500).then(() => {
			reactProps?.goTo404View(2000);
		});
	}, []);

	const goHome = () => {
		history.push(routes.home.path);
		reactProps?.goToNationalView(2500, easings.easeInOutCubic, false, true);
	};

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Page Not Found</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<div className="NotFound">
				<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="header">
					404
				</motion.div>
				<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="sub-header">
					Oops, my bad!
				</motion.div>
				<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps3} className="content">
					I can't find what you're looking for. Want to go back to the map?
				</motion.div>
				<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps4} className="cta" onClick={goHome}>
					<div className="icon" />
					<div className="label">Take me home</div>
				</motion.div>
			</div>
		</>
	);
}

export default NotFound;
