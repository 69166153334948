import { motion } from 'framer-motion';
import { IS_PHONE } from 'js/config/constants';
import { BACK_DURATION, EASE_IN, EASE_OUT, FADE_DURATION, MOTION_VARIANTS, overrideMotionVariants, STAGGER_DELAY } from 'js/utils/motion';
import React from 'react';
import { useHistory } from 'react-router-dom';
import NextArrows from '../NextArrows/NextArrows';
import routes from '../Router/routes';

import './HomeCTA.scss';

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: BACK_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateY(200px)',
		transition: { duration: FADE_DURATION, ease: EASE_IN },
	},
};

const delay = 0.5;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay },
	exitTransProps: { delay: 0.2 },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY },
	exitTransProps: { delay: 0.1 },
});

const motionProps3 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY * 2 },
	exitTransProps: { delay: 0 },
});

function HomeCTA() {
	const history = useHistory();

	const arrowProps = IS_PHONE
		? {
				width: 11,
				height: 18,
		  }
		: {
				width: 20,
				height: 32,
		  };

	return (
		<div className="Home">
			<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="title">
				See the heights
				<br />
				of the culture
			</motion.div>
			<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="description">
				Explore a new map of America - with curated guides, 30,000 points of interest and the millions who make it rise.
			</motion.div>
			<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps3} className="start-btn" onClick={() => history.push(routes.nationalGuides.path)}>
				<div className="label">Start</div>
				<NextArrows {...arrowProps} />
			</motion.div>
		</div>
	);
}

export default HomeCTA;
