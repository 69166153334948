import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
  EASE_OUT,
} from 'js/utils/motion';

import './AboutPartners.scss';

const containerMotion = {
  initial: { 
    opacity: 0 
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.1,
      delayChildren: 0.3,
      ease: EASE_OUT,
    }
  },
  exit: { 
    opacity: 0 
  }
};

function AboutPartners(props) {
  const { content, animOnce } = props;
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <motion.div 
      className="AboutPartners"
      variants={containerMotion}
      initial="initial"
      animate={anim}
      ref={ref}
    >
      { content.map((d,i) => {
        return (
          <div className="partner-item" key={i}>
            <motion.a
              variants={MOTION_VARIANTS}
              custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
              href={d.link} 
              rel="noopener noreferrer" 
              target="_blank"
            >
              <span>{d.link}</span>
            </motion.a>
          </div>
        )
      })}
    </motion.div>
  )
}

export default AboutPartners;