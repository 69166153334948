/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FADE_DURATION, MOTION_VARIANTS, overrideMotionVariants, MOTION_PRESETS, STAGGER_DELAY, COMMON_DURATION } from 'js/utils/motion';
import classNames from 'classnames';
import { EASE_OUT } from 'js/utils/motion';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import { DATASET_TYPES, IS_PHONE, ZOOM_LEVELS } from 'js/config/constants';
import { useAppState } from 'js/hooks/app-state';
import { getStateLabelCoordinates, getStateLabelId, getStateName } from 'js/utils/map-data-utils';
import { STATS } from 'js/config/content';
import { mapProps } from '../Map/Map';
import { Debug } from 'js/utils/debug';

import './DataSelector.scss';
import CameraController from 'js/controllers/camera-controller';

const options = [];

Object.values(DATASET_TYPES).forEach((type) => {
	options.push(type);
});

const mobileSelectorMotionPropsBase = {
	initial: {
		opacity: 0,
		transform: 'translateY(-30px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transition: { duration: FADE_DURATION * 0.6, ease: EASE_OUT },
	},
};

const preset = MOTION_PRESETS.FADE;
const selectorMotionProps = overrideMotionVariants({
	...preset,
	enterTransProps: { delay: 0.6 },
});

const infoPanelMotionProps = IS_PHONE ? mobileSelectorMotionPropsBase : MOTION_PRESETS.SLIDE_FROM_BOTTOM;

const numberFormatter = new Intl.NumberFormat('en-US');

const sharedProps = {};

const totals = {
	businesses: 0,
	population: 0,
	historical: 0,
	totalPopulation: 0,
};

function DataSelector(props) {
	const { enabled } = props;
	const [{ activeDatasetId, stateData, mapLegendOpen, showHomeCTA }, { setState }] = useAppState();
	const [activeElementIndex, setActiveElementIndex] = useState(-1);
	const [showInfoPanel, setShowInfoPanel] = useState(false);
	const [businessContent, setBusinessContent] = useState(null);
	const [populationContent, setPopulationContent] = useState(null);
	const [totalPopulationContent, setTotalPopulationContent] = useState(null);
	const [historicalContent, setHistoricalContent] = useState(null);
	const contentRef = useRef(null);
	const optionRef1 = useRef(null);
	const optionRef2 = useRef(null);
	const optionRef3 = useRef(null);
	const optionRef4 = useRef(null);
	const optionRef5 = useRef(null);
	const interval = useRef(null);

	useEffect(() => {
		sharedProps.setState = setState;
		sharedProps.setShowInfoPanel = setShowInfoPanel;
		sharedProps.contentRef = contentRef;
		sharedProps.stateData = stateData;

		if (stateData) {
			let b = 0,
				p = 0,
				h = 0,
				tp = 0;

			stateData.forEach(({ b_o_businesses, b_pop, historical_sites, population }) => {
				b += b_o_businesses;
				p += b_pop;
				h += historical_sites;
				tp += population;
			});

			totals.businesses = b;
			totals.population = p;
			totals.historical = h;
			totals.totalPopulation = tp;
		}

		setBusinessContent(getBusinessesContent(contentRef));
		setPopulationContent(getPopulationContent(contentRef));
		setTotalPopulationContent(getTotalPopulationContent(contentRef));
		setHistoricalContent(getHistoricalContent(contentRef));
	}, [contentRef, stateData]);

	useEffect(() => {
		if (contentRef.current) contentRef.current.scrollTop = 0;
	}, [activeDatasetId]);

	useEffect(() => {
		if (IS_PHONE && mapLegendOpen) {
			setShowInfoPanel(false);
		}
	}, [mapLegendOpen]);

	useEffect(() => {
		mapProps?.reactProps?.setStateLabelsVisible(activeDatasetId > 0 && showInfoPanel);
	}, [showInfoPanel, activeDatasetId]);

	// dataset cycle animation
	useEffect(() => {
		let index = 0;

		const stop = () => {
			clearInterval(interval.current);
		};

		const start = () => {
			stop();
			step();
			interval.current = setInterval(step, 2000);
		};

		const step = () => {
			if (index === options.length) {
				index = 0;
				stop();
			}
			setState({ activeDatasetId: options[index].datasetIndex });
			index++;
		};

		if (showHomeCTA) {
			start();
		} else {
			stop();
		}
	}, [showHomeCTA]);

	const renderDesktop = () => {
		const infoPanelVisible = activeDatasetId > 0 && showInfoPanel;
		const optionRefs = [optionRef1, optionRef2, optionRef3, optionRef4, optionRef5];

		const selectorClass = classNames({
			selector: true,
			'panel-visible': infoPanelVisible,
		});

		return (
			<div className="desktop">
				<AnimatePresence>
					{infoPanelVisible &&
						renderInfoPanel(activeDatasetId, businessContent, populationContent, historicalContent, totalPopulationContent)}
				</AnimatePresence>
				<div className={selectorClass}>
					<div className="options">
						{options.map(({ label, datasetIndex }, index) => {
							const className = classNames({
								option: true,
								active: datasetIndex === activeDatasetId,
								highlighted: datasetIndex === activeDatasetId,
								disabled: !enabled || datasetIndex === undefined,
							});

							return (
								<motion.div
									variants={MOTION_VARIANTS}
									initial="initial"
									animate="animate"
									exit="exit"
									custom={overrideMotionVariants({
										...MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM,
										enterTransProps: {
											delay: 0.5 + STAGGER_DELAY * index,
											ease: EASE_OUT,
											duration: COMMON_DURATION,
										},
									})}
									key={index}
									className={className}
									ref={optionRefs[index]}
									onClick={() => {
										setState({ activeDatasetId: datasetIndex });
										setActiveElementIndex(index);
										setShowInfoPanel(true);
									}}
								>
									<div className="option-inner">
										<div className="icon" />
										{label}
									</div>
								</motion.div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	const renderMobile = () => {
		const infoPanelVisible = activeDatasetId > 0 && showInfoPanel;
		const optionRefs = [optionRef1, optionRef2, optionRef3, optionRef4, optionRef5];

		return (
			<div className="mobile">
				<AnimatePresence>{infoPanelVisible && renderInfoPanelMobile(activeDatasetId, activeElementIndex, optionRefs)}</AnimatePresence>
				<div className="selector">
					<div className="options">
						{options.map(({ label, datasetIndex }, index) => {
							const className = classNames({
								option: true,
								active: datasetIndex === activeDatasetId,
								highlighted: datasetIndex === activeDatasetId,
								disabled: !enabled || datasetIndex === undefined,
							});

							return (
								<motion.div
									variants={MOTION_VARIANTS}
									initial="initial"
									animate="animate"
									exit="exit"
									custom={overrideMotionVariants({
										...MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM,
										enterTransProps: {
											delay: 0.5 + STAGGER_DELAY * index,
											ease: EASE_OUT,
											duration: COMMON_DURATION,
										},
									})}
									key={index}
									className={className}
									ref={optionRefs[index]}
									onClick={() => {
										setState({ activeDatasetId: datasetIndex });
										setActiveElementIndex(index);
										setShowInfoPanel(true);
									}}
								>
									<div className="icon" />
									{label}
								</motion.div>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	return (
		<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={selectorMotionProps} className="DataSelector">
			{!IS_PHONE && renderDesktop()}
			{IS_PHONE && renderMobile()}
		</motion.div>
	);
}

function renderInfoPanelMobile(activeDatasetId, activeElementIndex, optionRefs) {
	let title, bodyContent;

	switch (activeDatasetId) {
		case 1:
			title = DATASET_TYPES.BUSINESSES.label;
			bodyContent = STATS.BLACK_OWNED_BUSINESSES.description;
			break;

		case 2:
			title = DATASET_TYPES.POPULATION.label;
			bodyContent = STATS.BLACK_POPULATION.description;
			break;

		case 3:
			title = DATASET_TYPES.HISTORICAL.label;
			bodyContent = STATS.HISTORICAL_MARKERS.description;
			break;

		// TODO: finish this
		case 4:
			title = DATASET_TYPES.TOTAL_POPULATION.label;
			bodyContent = STATS.TOTAL_POPULATION.description;
			break;

		default:
			title = '';
			bodyContent = null;
			break;
	}

	const { setShowInfoPanel } = sharedProps;

	let bottomArrowStyle = {};
	const actualIndex = activeElementIndex;
	const optionWidth = optionRefs[actualIndex]?.current?.clientWidth;
	const optionOffsetX = optionRefs[actualIndex]?.current?.offsetLeft;
	const startX = -10;

	if (optionWidth !== undefined && optionOffsetX !== undefined) {
		bottomArrowStyle = {
			left: `${startX + optionOffsetX + optionWidth / 2}px`,
		};
	}

	return (
		<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={infoPanelMotionProps} className="info-panel">
			<div className="title">{title}</div>
			<div className="content">{bodyContent}</div>
			<div className="bottom-arrow" style={bottomArrowStyle} />
			<CloseButtonX style={{ top: 10, right: 10 }} small={true} darkTheme={true} onClick={() => setShowInfoPanel(false)} />
		</motion.div>
	);
}

function renderInfoPanel(activeDatasetId, businessContent, populationContent, historicalContent, totalPopulationContent) {
	return (
		<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={infoPanelMotionProps} className="info-panel">
			{renderInfoPanelContent(activeDatasetId, businessContent, populationContent, historicalContent, totalPopulationContent)}
		</motion.div>
	);
}

function renderInfoPanelContent(activeDatasetId, businessContent, populationContent, historicalContent, totalPopulationContent) {
	let bodyContent;

	const { setShowInfoPanel } = sharedProps;

	switch (activeDatasetId) {
		case 1:
			bodyContent = businessContent;
			break;

		case 2:
			bodyContent = populationContent;
			break;

		case 3:
			bodyContent = historicalContent;
			break;

		case 4:
			bodyContent = totalPopulationContent;
			break;

		default:
			bodyContent = null;
			break;
	}

	return (
		<div className="info-panel-content">
			<div className="content">{bodyContent}</div>
			<CloseButtonX
				darkTheme={true}
				onClick={() => {
					setShowInfoPanel(false);
				}}
				style={{ top: '20px', right: '20px' }}
				small={true}
			/>
		</div>
	);
}

function highlightState(stateCode) {
	const {
		reactProps: { setStateLabelHighlighted },
	} = mapProps;

	// Debug.log('highlightState:', stateCode, getStateLabelId(stateCode));

	if (setStateLabelHighlighted) {
		setStateLabelHighlighted(getStateLabelId(stateCode), stateCode !== null);
	}
}

function goToState(stateCode) {
	if (stateCode) {
		const coords = getStateLabelCoordinates(stateCode);
		CameraController.instance?.flyTo(coords, {
			zoom: ZOOM_LEVELS.STATE,
			duration: 2500,
		});
	}
}

function getBusinessesContent(contentRef) {
	const total = totals.businesses;
	const { stateData } = sharedProps;

	stateData.sort((a, b) => {
		if (a.b_o_businesses < b.b_o_businesses) return 1;
		else if (a.b_o_businesses > b.b_o_businesses) return -1;
		else return 0;
	});

	return (
		<>
			<div className="content-left">
				<div className="title">
					<b>Black-Owned Businesses</b>
				</div>
				<div className="description">
					Self-reported Black-owned business data from Google
					<br />& Yelp.
				</div>
			</div>
			<div className="content-right" ref={contentRef}>
				<div className="list">
					<div key={0} className="list-item disabled">
						<div className="key">
							<b>Black-Owned Businesses Total</b>
						</div>
						<div className="value">
							<b>{numberFormatter.format(total)}</b>
						</div>
					</div>
					{stateData.map(({ state_abbreviation, b_o_businesses }, index) => {
						return (
							<div
								key={index + 1}
								className="list-item"
								onMouseOver={() => highlightState(state_abbreviation)}
								onMouseOut={() => highlightState(null)}
								onClick={() => goToState(state_abbreviation)}
							>
								<div className="key">{getStateName(state_abbreviation)}</div>
								<div className="value">{numberFormatter.format(b_o_businesses | 0)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

function getPopulationContent(contentRef) {
	const total = totals.population;
	const { stateData } = sharedProps;

	stateData.sort((a, b) => {
		if (a.b_pop < b.b_pop) return 1;
		else if (a.b_pop > b.b_pop) return -1;
		else return 0;
	});

	return (
		<>
			<div className="content-left">
				<div className="title">
					<b>Black Population Density</b>
				</div>
				<div className="description">
					Americans who self-identify as "Black Only" or "Black and Other Race(s)" for up to five races from the Decennial 2020 U.S. Census.
				</div>
			</div>
			<div className="content-right" ref={contentRef}>
				<div className="list">
					<div key={0} className="list-item disabled">
						<div className="key">
							<b>Black Population Total</b>
						</div>
						<div className="value">
							<b>{numberFormatter.format(total)}</b>
						</div>
					</div>
					{stateData.map(({ state_abbreviation, b_pop }, index) => {
						return (
							<div
								key={index + 1}
								className="list-item"
								onMouseOver={() => highlightState(state_abbreviation)}
								onMouseOut={() => highlightState(null)}
								onClick={() => goToState(state_abbreviation)}
							>
								<div className="key">{getStateName(state_abbreviation)}</div>
								<div className="value">{numberFormatter.format(b_pop)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

function getTotalPopulationContent(contentRef) {
	const total = totals.totalPopulation;
	const { stateData } = sharedProps;

	stateData.sort((a, b) => {
		if (a.population < b.population) return 1;
		else if (a.population > b.population) return -1;
		else return 0;
	});

	return (
		<>
			<div className="content-left">
				<div className="title">
					<b>Total Population Density</b>
				</div>
				<div className="description">The total population density of the United States according to the Decennial 2020 U.S. Census.</div>
			</div>
			<div className="content-right" ref={contentRef}>
				<div className="list">
					<div key={0} className="list-item disabled">
						<div className="key">
							<b>Total Population</b>
						</div>
						<div className="value">
							<b>{numberFormatter.format(total)}</b>
						</div>
					</div>
					{stateData.map(({ state_abbreviation, population }, index) => {
						return (
							<div
								key={index + 1}
								className="list-item"
								onMouseOver={() => highlightState(state_abbreviation)}
								onMouseOut={() => highlightState(null)}
								onClick={() => goToState(state_abbreviation)}
							>
								<div className="key">{getStateName(state_abbreviation)}</div>
								<div className="value">{numberFormatter.format(population)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

function getHistoricalContent(contentRef) {
	const total = totals.historical;
	const { stateData } = sharedProps;

	stateData.sort((a, b) => {
		if (a.historical_sites < b.historical_sites) return 1;
		else if (a.historical_sites > b.historical_sites) return -1;
		else return 0;
	});

	return (
		<>
			<div className="content-left">
				<div className="title">
					<b>Historical Markers</b>
				</div>
				<div className="description">Historical sites and landmarks tagged "African American" from The Historical Marker Database.</div>
			</div>
			<div className="content-right" ref={contentRef}>
				<div className="list">
					<div key={0} className="list-item disabled">
						<div className="key">
							<b>Historical Markers Total</b>
						</div>
						<div className="value">
							<b>{numberFormatter.format(total)}</b>
						</div>
					</div>
					{stateData.map(({ state_abbreviation, historical_sites }, index) => {
						return (
							<div
								key={index + 1}
								className="list-item"
								onMouseOver={() => highlightState(state_abbreviation)}
								onMouseOut={() => highlightState(null)}
								onClick={() => goToState(state_abbreviation)}
							>
								<div className="key">{getStateName(state_abbreviation)}</div>
								<div className="value">{numberFormatter.format(historical_sites)}</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
}

export default DataSelector;
