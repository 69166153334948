/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { ADD_ICONS_TO_SEARCH_RESULTS, IS_PHONE, MAPBOX_API_KEY, POI_CATEGORIES } from 'js/config/constants';
import mapboxgl from 'mapbox-gl';
import { useAppState } from 'js/hooks/app-state';
import { useHistory } from 'react-router-dom';
import { locationIcons } from 'js/config/asset-manifest';
import { isArray, replaceAll, wait } from 'js/utils/utils';
import { Debug } from 'js/utils/debug';

import './SearchBarMapbox.scss';
// import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'; // styles overriden in SearchBarMapbox.scss

// on phone, use this value when done button is selected without tapping an option
// tried using as state var, but always null in handleBlur for some reason
let firstResult = null;
let onChangeTime = 0;
let usableBackingSelectedTime = 0;
let currentQuery = '';

function SearchBarMapbox(props) {
	const { placeholder = 'Search', onChange, backingSelectedTime = 0 } = props;
	const [{ poiStateData, curatedFeatures }] = useAppState();
	// const [query, setQuery] = useState('');
	const geocoderRef = useRef(null);
	const history = useHistory();

	usableBackingSelectedTime = backingSelectedTime;

	const handleResult = useCallback(
		({ result }) => {
			geocoderRef.current?.clear();
			onChangeTime = Date.now();

			Debug.log({
				event: 'search',
				user_input: currentQuery,
				selection: result.place_name,
			});

			window.dataLayer.push({
				event: 'search',
				user_input: currentQuery,
				selection: result.place_name,
			});

			if (onChange) {
				// Debug.warn('handleResult:', result);
				onChange(result);
			}
		},
		[geocoderRef.current]
	);

	const handleBlur = useCallback(() => {
		if (IS_PHONE && onChange) {
			wait(100).then(() => {
				const diff1 = Math.abs(Date.now() - usableBackingSelectedTime);
				const diff2 = Math.abs(Date.now() - onChangeTime);
				// Debug.warn('handleBlur:', diff1, diff2);
				if (diff1 > 500 && diff2 > 500) {
					if (firstResult) {
						// Debug.warn('handleBlur:', firstResult);
						onChange(firstResult);
						firstResult = null;
					}
					// history.push(routes.home.path);
				}
			});
		}
	}, [backingSelectedTime]);

	useEffect(() => {
		firstResult = null;
		onChangeTime = 0;
		usableBackingSelectedTime = 0;

		// Debug.log('curatedFeatures:', curatedFeatures);

		geocoderRef.current = new MapboxGeocoder({
			accessToken: MAPBOX_API_KEY,
			mapboxgl,
			countries: 'US,PR',
			//https://docs.mapbox.com/api/search/geocoding/#data-types
			// types: 'country,region,postcode,district,place,locality,neighborhood,address,poi',
			limit: 6,
			marker: true,
			autocomplete: true,
			placeholder,
			clearAndBlurOnEsc: true,
			// flyTo: true,
			// fuzzyMatch: false,
			localGeocoder: (query) => {
				// Debug.log(query.toLowerCase());
				const matchingFeatures = [];
				curatedFeatures?.forEach((feature) => {
					if (feature?.properties?.locationName?.toLowerCase()?.includes(query.toLowerCase())) {
						feature.place_name = feature.properties.locationName;
						feature.center = feature.geometry.coordinates;
						feature.place_type = 'bem-curated';
						matchingFeatures.push(feature);
					}
				});
				poiStateData?.forEach((feature) => {
					const valid = feature?.p?.yr === undefined; // filter out historical sites
					if (feature?.p?.n?.toLowerCase()?.includes(query.toLowerCase()) && valid) {
						feature.place_name = feature.p.n;
						feature.center = feature.geometry.coordinates;
						feature.place_type = 'bem';
						matchingFeatures.push(feature);
					}
				});
				if (matchingFeatures.length > 4) matchingFeatures.length = 4;
				return matchingFeatures;
			},
			render: function (item) {
				const placeName = replaceAll(item.place_name, '"', ''); // strip quotes
				let result = placeName;
				let nameEl, address, addressEl;

				if (ADD_ICONS_TO_SEARCH_RESULTS) {
					if (item.place_type === 'bem') {
						const category = item.p.pc[0];
						if (category) {
							const icon = locationIcons.white[category];
							nameEl = `<span class="poi-name">${placeName}</span>`;
							address = item.p.c && item.p.s ? `${item.p.c}, ${item.p.s}` : null;
							addressEl = address ? `<span class="poi-address">${address}</span>` : '';
							result = `<div class="result-icon"><img height="100%" src="${icon}" alt="${category}" /></div><div>${nameEl}${addressEl}</div>`;
						}
					} else if (item.place_type === 'bem-curated') {
						const category = POI_CATEGORIES.CURATED;
						const icon = locationIcons.white[category];
						nameEl = `<span class="poi-name">${placeName}</span>`;
						address = item.properties.address ? item.properties.address : null;
						addressEl = address ? `<span class="poi-address">${address}</span>` : '';
						result = `<div class="result-icon"><img height="100%" src="${icon}" alt="${category}" /></div><div>${nameEl}${addressEl}</div>`;
					}
				}

				if (isArray(item.place_type)) {
					if (!item.place_type.includes('postcode')) {
						nameEl = `<span class="poi-name">${item.text}</span>`;
						address = item.place_name.split(`${item.text}, `)[1];
						addressEl = address ? `<span class="poi-address">${address}</span>` : '';

						if (item.place_type.includes('poi')) {
							const icon = locationIcons.white[POI_CATEGORIES.GENERAL_POI];
							result = `<div class="result-icon"><img height="100%" src="${icon}" alt="${item.text}" /></div><div>${nameEl}${addressEl}</div>`;
						} else {
							result = `<div>${nameEl}${addressEl}</div>`;
						}
					} else {
						nameEl = `<span class="poi-name">${item.text}</span>`;
						address = item.place_name.replace(` ${item.text}`, '');
						addressEl = address ? `<span class="poi-address">${address}</span>` : '';
						result = `<div>${nameEl}${addressEl}</div>`;
					}
				}

				return `<div class='geocoder-dropdown-item'>
                    <span class='geocoder-dropdown-text'>
                    ${result}
                    </span>
                </div>`;
			},
		});

		geocoderRef.current.addTo('.SearchBarMapbox');
		geocoderRef.current.on('result', handleResult);

		geocoderRef.current.on('loading', ({ query }) => {
			// Debug.log('query:', query);
			currentQuery = query;
		});

		geocoderRef.current.on('results', ({ features }) => {
			// Debug.log('setFirstResult:', features[0]);
			firstResult = features[0];
		});

		geocoderRef.current._inputEl?.setAttribute('autofocus', true);
		geocoderRef.current._inputEl.onblur = handleBlur;

		geocoderRef.current.clear();

		// Debug.log('geocoderRef.current:', geocoderRef.current, geocoderRef.current._inputEl);

		return () => {
			geocoderRef.current.off('result', handleResult);
			geocoderRef.current._inputEl.onblur = null;
			const el = document?.querySelector('.mapboxgl-ctrl-geocoder');
			el?.parentNode?.removeChild(el);
		};
	}, [poiStateData, history]);

	return (
		<div className="SearchBarMapbox">
			<div className="search-icon" />
		</div>
	);
}

export default SearchBarMapbox;
