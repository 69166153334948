/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';

import { SLIDE_DURATION, MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import ContentFooter from '../ContentFooter/ContentFooter';
import AboutSection from '../AboutSection/AboutSection';
import AboutHero from '../AboutHero/AboutHero';
import AboutNav from '../AboutNav/AboutNav';
import routes from '../Router/routes';
import aboutData from '../../data/about-data';
import { ABOUT } from '../../config/content';
import { mapProps } from '../Map/Map';
import { useAppState } from 'js/hooks/app-state';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './About.scss';

const motionProps0 = MOTION_PRESETS.SLIDE_FROM_BOTTOM;

export const navData = [
	{
		id: ABOUT.ID_WHAT,
		lbl: 'What is the Black Elevation Map',
	},
	{
		id: ABOUT.ID_HOW,
		lbl: 'How is it made?',
	},
	{
		id: ABOUT.ID_WHO,
		lbl: 'Who is Black & Abroad',
	},
	{
		id: ABOUT.ID_INSPIRATION,
		lbl: 'What inspired the Design?',
	},
	{
		id: ABOUT.ID_CONTRIBUTORS,
		lbl: 'Contributors',
	},
	{
		id: ABOUT.ID_PARTNERS,
		lbl: 'Partners',
	},
];

const ABOUT_VIDEO = 'https://www.youtube.com/embed/hrCLlX-W6F8?modestbranding=1&playsinline=1';

// toggle this to make about page animations trigger over and over
const ANIM_ONCE = true;

function About(props) {
	const aboutRef = useRef();
	const [contributorsReady, setContributorsReady] = useState(false);
	const [{ mapLoaded }, { setState }] = useAppState();

	const history = useHistory();

	useEffect(() => {
		// [CODY!] uncomment out "&& contributorsReady" when you add contributors back in
		if (aboutRef?.current /*&& contributorsReady*/) {
			const checkHash = window.location.hash;
			if (checkHash) {
				const hashElem = aboutRef.current.querySelector(checkHash);
				if (hashElem) {
					setTimeout(() => hashElem.scrollIntoView(), SLIDE_DURATION * 1000);
				}
			}
		}
	}, [aboutRef, contributorsReady]);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | About</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className="About"
				onAnimationStart={() => {
					mapProps?.layers?.elevation?.resume();
				}}
				onAnimationComplete={(definition) => {
					if (definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				<div className="content" ref={aboutRef}>
					<div className="content-container">
						<div className="header-container">
							<AboutHero title="About" video={ABOUT_VIDEO} animOnce={ANIM_ONCE} />
							{false && <AboutNav navData={navData} />}
						</div>
						<div className="content-sections">
							{aboutData.map((d, i) => (
								<AboutSection key={i} {...d} animOnce={ANIM_ONCE} setContributorsReady={setContributorsReady} />
							))}
						</div>
						<div className="footer-container">
							<ContentFooter />
						</div>
					</div>

					<CloseButtonX
						onClick={() => {
							history.push(routes.home.path);
							if (!mapLoaded) {
								history.push(routes.landing.path);
							}
						}}
						style={{ top: '38px', right: '38px' }}
						lightTheme={true}
					/>
				</div>
			</motion.div>
		</>
	);
}

export default About;
