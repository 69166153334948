import React from 'react';
import classNames from 'classnames';

import './ToolTip.scss';

function ToolTip(props) {
	const { label, leftSide = false, className = 'tooltip' } = props;

	const cls = classNames('ToolTip', className, { 'arrow-left-side': leftSide });

	return (
		<div className={cls}>
			<span>{label}</span>
		</div>
	);
}

export default ToolTip;
