import { IS_PHONE } from 'js/config/constants';
import { Debug } from 'js/utils/debug';
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import ContentFooter from '../ContentFooter/ContentFooter';
import { MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { COMMON_DURATION, EASE_IN_OUT, EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION, STAGGER_DELAY } from 'js/utils/motion';
import classNames from 'classnames';
import { mapProps } from '../Map/Map';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './AddToMap.scss';

const delay = 0.6;

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateY(50px)',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
};

const motionProps0 = MOTION_PRESETS.FADE;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY },
});

const motionProps3 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY * 2 },
});

function AddToMap() {
	const [transitionComplete, setTransitionComplete] = useState(false);
	const containerRef = useRef(null);

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);

			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
			};
		}
	}, []);

	const copyClass = classNames({
		'AddToMap-copy': true,
		'no-scroll': !transitionComplete,
	});

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Add To Map</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className="AddToMap HamburgerMenu"
				ref={containerRef}
				onAnimationStart={() => {
					if (IS_PHONE) {
						mapProps?.layers?.elevation?.resume();
					}
				}}
				onAnimationComplete={(definition) => {
					if (IS_PHONE && definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				<div className="HamburgerMenu-inner">
					<div className="pad-container">
						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="title">
							Add to map
						</motion.div>
						<div className="scroll-container">
							<div className={copyClass}>
								<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="sub-header">
									Thank you for contributing to the Black Elevation Map.
								</motion.div>
								<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps3} onAnimationComplete={() => setTransitionComplete(true)}>
									<p>
										To keep our platform fresh and up to date, we encourage you to add your business or organization to the Black Elevation Map. With your help, we can continue to
										highlight the contributions and achievements of Black Culture across America.
									</p>

									<p>
										<strong>How do I add to the map?</strong>
										<br />
										To add your business or organization to the map, you must first self-identify it as "Black-owned" on Google or Yelp. Once it is approved on either platform, it
										will be added to the Black Elevation Map.
									</p>

									<p>
										<strong>Why self-identify on Google & Yelp?</strong>
										<br />
										Google & Yelp are two of the largest platforms to search for Black-owned businesses in America. When you self-identify as "Black-owned," you increase the
										visibility of your business or organization - and you help keep our data as accurate as possible. While Google & Yelp help power the Black Elevation Map, we
										recognize that our data will never be complete. Because no single source of data could possibly represent Black Culture in its entirety.
									</p>
									<p>&nbsp;</p>
									<p>
										<a href="https://business.google.com/create" target="_blank" rel="noreferrer noopener" className="external-link">
											Add to Google
										</a>
									</p>
									<p>
										<a href="https://business.yelp.com/" target="_blank" rel="noreferrer noopener" className="external-link">
											Add To YELP
										</a>
									</p>
								</motion.div>
							</div>
						</div>

						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} className="footer-container">
							<ContentFooter />
						</motion.div>
					</div>
				</div>
				{IS_PHONE ? <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 22 }} lightTheme={true} /> : <CloseButtonX />}
			</motion.div>
		</>
	);
}

export default AddToMap;
