import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import Blocks from './blocks';

import './AboutSection.scss';

function AboutSection(props) {
	const { id, title, blocks, setContributorsReady, animOnce = true } = props;
	const anim = useAnimation();
	const [ref, inView] = useInView({
		rootMargin: '-150px 0px',
		triggerOnce: animOnce,
	});

	useEffect(() => {
		if (inView) {
			anim.start('animate');
		} else {
			anim.start('exit');
		}
	}, [anim, inView]);

	return (
		<section id={id} className="AboutSection" ref={ref}>
			<motion.div className="about-top-sep" initial="initial" animate={anim} variants={MOTION_VARIANTS} custom={MOTION_PRESETS.SCALE_FROM_LEFT} />
			{title && (
				<motion.h1 initial="initial" animate={anim} variants={MOTION_VARIANTS} custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}>
					{title}
				</motion.h1>
			)}
			{blocks.map((d, i) => {
				const Component = Blocks[d.type];
				return <Component key={i} {...d} animOnce={animOnce} setContributorsReady={setContributorsReady} />;
			})}
		</section>
	);
}

export default AboutSection;
