import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import {
	MOTION_PRESETS,
	MOTION_VARIANTS,
} from 'js/utils/motion';

import './AboutHero.scss';

function AboutHero(props) {
  const { title, video, animOnce = true } = props;
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <div className="AboutHero" ref={ref}>
      <motion.h1
        initial="initial"
        animate={anim}
        variants={MOTION_VARIANTS}
        custom={MOTION_PRESETS.FADE_SLIDE_FROM_BOTTOM}
      >
        <span>{title}</span>
      </motion.h1> 
      <div className="hero-video">
        <motion.iframe 
          initial="initial"
          animate={anim}
          variants={MOTION_VARIANTS}
          custom={MOTION_PRESETS.SLIDE_FROM_BOTTOM}
          src={video}
          title="youtube-about-hero"
          frameBorder="0"
          allowFullScreen={true}
        />
      </div>
    </div>
  )
}

export default AboutHero;