import { mapProps } from 'js/components/Map/Map';
import { createElement, deleteAllProperties, replaceAll } from 'js/utils/utils';
import { Marker } from 'mapbox-gl';
import routes from 'js/components/Router/routes';
import { locationIcons } from '../config/asset-manifest';
import { POI_CATEGORIES } from 'js/config/constants';
import { Debug } from 'js/utils/debug';
import gsap from 'gsap/gsap-core';
import { CONTENT_TYPES } from 'js/components/ContentDrawer/ContentDrawer';

// marker for locations
export default class LocationMarker extends Marker {
	constructor(feature, options = {}) {
		super({ element: undefined, anchor: 'center', offset: [0, 0] });

		const properties = feature?.p || feature?.properties;

		// add custom data object
		// use {properties.id} first for curated poi's
		this.options = options;
		this.userData = { id: properties?.id?.toString() || properties?.gid?.toString(), feature };

		this._element = this.getHTMLElement(feature, properties, options);
		this.iconContainer = this._element.querySelector('.location-marker-icon-container');

		this.setLngLat(feature.geometry.coordinates);
	}

	getId() {
		return this.userData?.id;
	}

	getValidIcon(categories, colorway) {
		let icon;
		for (let i = 0; i < categories.length; i++) {
			icon = locationIcons[colorway][categories[i]];
			if (icon) {
				break;
			}
		}

		return icon;
	}

	getHTMLElement(feature, properties, options = {}) {
		const { callbacks, colorway = 'black', iconOverride } = options;
		const { type } = properties;
		const {
			reactProps: { getLocationIsSaved },
		} = mapProps;
		const isSaved = getLocationIsSaved(this.getId());

		const name = type === POI_CATEGORIES.CURATED ? properties.locationName : properties.n;
		const finalName = name ? replaceAll(name, '"', '') : '';
		const icon = iconOverride
			? locationIcons[colorway][iconOverride]
			: isSaved
			? locationIcons[colorway][POI_CATEGORIES.FAVORITES]
			: this.getValidIcon(properties.pc, colorway);
		const category = iconOverride || properties.pc[0];
		const bgColorClass = category?.toLowerCase();

		const locationMarkerClass = ['location-marker', bgColorClass];
		if (isSaved) {
			locationMarkerClass.push('saved');
		}

		const el = createElement(
			'div',
			locationMarkerClass,
			`<div class="location-marker-icon-container">
				<div class="location-marker-bg"></div>
				<div class="location-marker-icon">
					<img class="location-marker-icon-image" src="${icon}" alt="${finalName}" />
				</div>
			</div>
			<div class="location-marker-label">${finalName}</div>`
		);

		el.onmouseover = (e) => {
			this.handleOver(feature, callbacks?.over);
		};

		el.onmouseout = (e) => {
			this.handleOut(callbacks?.out);
		};

		el.onclick = (e) => {
			e.stopPropagation();
			e.preventDefault();
			// Debug.log('marker selected:', feature);
			this.handleSelected(feature, callbacks?.select);
		};

		return el;
	}

	handleOver(feature, callback) {
		if (this.isOver) return;
		this.isOver = true;
	}

	handleOut(callback) {
		if (!this.isOver) return;
		this.isOver = false;
	}

	handleSelected(feature, callback) {
		if (typeof callback === 'function') {
			callback(this);
		} else {
			const {
				reactProps: { history, generatePath },
			} = mapProps;

			const properties = feature.p || feature.properties;

			const { type, gid, id, guideId, guideType } = properties;

			Debug.log('marker selected:', gid, typeof gid, id, type, guideId, guideType);
			// Debug.log('marker coordinates:', feature.geometry.coordinates);

			if (type === POI_CATEGORIES.CURATED) {
				// determine if national or city guide
				const route = guideType === CONTENT_TYPES.CITY_GUIDE ? routes.cityGuide.path : routes.nationalGuide.path;
				history.push({ pathname: generatePath(route, { id: guideId }), search: `?poiId=${id}` });
			} else if (type === POI_CATEGORIES.GENERAL_POI && properties?.address) {
				const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${properties.address}`;
				window.open(directionsUrl);
			} else if (gid) {
				history.push(generatePath(routes.location.path, { id: gid.toString() }));
			}
		}
	}

	// if favorites are still required this method can be used to change the icon
	setIsSaved(isSaved = true) {
		const el = this.getElement();
		const { feature } = this.userData;
		const { colorway = 'black' } = this.options;
		const iconImageEl = el.querySelector('.location-marker-icon-image');
		const properties = feature.p || feature.properties;
		const icon = isSaved ? locationIcons[colorway][POI_CATEGORIES.FAVORITES] : locationIcons[colorway][properties.pc[0]];
		iconImageEl.src = icon;
	}

	showSelf(delay = 0) {
		return new Promise((resolve, reject) => {
			if (this.iconContainer) {
				gsap.to(this.iconContainer, {
					opacity: 1,
					scale: 1,
					ease: 'back.out',
					duration: 0.6,
					delay,
					overwrite: true,
					onComplete: resolve,
				});
			} else {
				resolve();
			}
		});
	}

	hideSelf(delay = 0) {
		return new Promise((resolve, reject) => {
			if (this.iconContainer) {
				gsap.to(this.iconContainer, {
					opacity: 0,
					scale: 0,
					ease: 'back.in',
					duration: 0.6,
					delay,
					overwrite: true,
					onComplete: resolve,
				});
			} else {
				resolve();
			}
		});
	}

	setHidden(hidden = true, delay = 0) {
		return new Promise((resolve) => {
			if (this.hidden === hidden) {
				resolve();
				return;
			}

			this.hidden = hidden;

			const el = this.getElement();

			gsap.to(el, {
				duration: 0.4,
				opacity: hidden ? 0 : 1,
				ease: 'power2.out',
				delay,
				onComplete: resolve,
			});
		});
	}

	dispose() {
		this.remove();
		deleteAllProperties(this);
	}
}
