import { ELEVATION_GEOMETRY_CONFIG } from 'js/config/constants';
import { deleteAllProperties } from 'js/utils/utils';
import { Mesh, MeshBasicMaterial, PlaneBufferGeometry } from 'three/build/three.module';

export default class MapShadow {
	constructor(props) {
		this.props = props;
		this.init();
	}

	init() {
		const { width, height } = ELEVATION_GEOMETRY_CONFIG;
		const { alphaMap, color = 0xffffff, opacity = 0.5 } = this.props;

		const geometry = new PlaneBufferGeometry(width, height);

		const material = new MeshBasicMaterial({
			color,
			alphaMap,
			transparent: true,
			depthWrite: false,
			opacity,
		});

		this.mesh = new Mesh(geometry, material);
		this.mesh.frustumCulled = false;
	}

	dispose() {
		deleteAllProperties(this);
	}
}
