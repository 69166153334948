/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../Router/routes';

import './ContentFooter.scss';

function ContentFooter() {
	const history = useHistory();

	return (
		<div className="footer">
			<div className="copyright">&copy;{new Date().getFullYear()} Black &amp; Abroad</div>
			<a className="link contact-link" href="https://www.blackandabroad.com/aboutus" target="_blank" rel="noopener noreferrer">
				Contact Us
			</a>
			<div className="link" onClick={() => history.push(routes.privacy.path)}>
				Privacy
			</div>
			<a className="twitter-link" href="https://twitter.com/blackandabroad" target="_blank" rel="noopener noreferrer" aria-label="Twitter Link" />
			<a className="instagram-link" href="https://www.instagram.com/blackandabroad/" target="_blank" rel="noopener noreferrer" aria-label="Instagram Link" />
		</div>
	);
}

export default ContentFooter;
