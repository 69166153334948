import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { IS_MOBILE, IS_PHONE } from 'js/config/constants';
import { STATS } from 'js/config/content';
import { Debug } from 'js/utils/debug';
import { BACK_DURATION, COMMON_DURATION, EASE_OUT, EASE_OUT_BACK, FADE_DURATION, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants } from 'js/utils/motion';
import React, { memo, useEffect, useRef, useState } from 'react';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import CityStatCounter from '../CityStatCounter/CityStatCounter';

import './CityStats.scss';

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transition: { duration: FADE_DURATION, ease: EASE_OUT },
	},
};

const numberFormatter = new Intl.NumberFormat('en-US');

const statDescriptions = [STATS.BLACK_POPULATION.description, STATS.BLACK_OWNED_BUSINESSES.description, STATS.HISTORICAL_MARKERS.description, STATS.TOTAL_POPULATION.description];

function CityStats(props) {
	const [activeStat, setActiveStat] = useState(-1);
	const { cityStats, delay = 0, descriptionLocation = 'top', defaultStat = -1 } = props;
	const stat1Ref = useRef(null);
	const stat2Ref = useRef(null);
	const stat3Ref = useRef(null);
	const stat4Ref = useRef(null);
	const inputHandlers = useRef(null);
	const cityStatDescRef = useRef(null);
	const cityStatsContainerRef = useRef(null);

	const usingHover = !IS_MOBILE && descriptionLocation === 'top';

	const mainMotionProps = overrideMotionVariants({
		...elementMotionProps,
		enterTransProps: { delay },
		exitTransProps: { delay: 0.3 },
	});

	const statDescMotionProps = overrideMotionVariants({
		...MOTION_PRESETS.FADE,
		enterTransProps: { duration: 0 },
		exitTransProps: { duration: 0 },
	});

	const statDescArrowMotionProps = overrideMotionVariants({
		...MOTION_PRESETS.FADE,
		enterTransProps: { duration: 0 },
		exitTransProps: { duration: 0 },
	});

	const mainClass = classNames({
		CityStats: true,
		bottom: descriptionLocation === 'bottom',
		hidden: activeStat === -1,
	});

	const statDescTextClass = classNames({
		'city-stat-desc-text': true,
		'using-hover': usingHover,
	});

	const statDescClass = classNames({
		'city-stat-desc': true,
		bottom: descriptionLocation === 'bottom',
	});

	const statDescClassHidden = classNames({
		'city-stat-desc': true,
		hidden: true,
		bottom: descriptionLocation === 'bottom',
	});

	const stat1Class = classNames({
		'city-stats-item': true,
		active: activeStat === 0 && !usingHover,
		hovered: activeStat === 0,
	});
	const stat2Class = classNames({
		'city-stats-item': true,
		active: activeStat === 1 && !usingHover,
		hovered: activeStat === 1,
	});
	const stat3Class = classNames({
		'city-stats-item': true,
		active: activeStat === 2 && !usingHover,
		hovered: activeStat === 2,
	});
	const stat4Class = classNames({
		'city-stats-item': true,
		active: activeStat === 3 && !usingHover,
		hovered: activeStat === 3,
	});

	useEffect(() => {
		setActiveStat(defaultStat);
	}, [defaultStat]);

	const renderDescription = () => {
		return (
			<AnimatePresence exitBeforeEnter>
				{activeStat !== -1 && (
					<motion.div
						variants={MOTION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={statDescMotionProps}
						className={statDescClass}
						key="stats-visible"
						ref={cityStatDescRef}
					>
						<div className={statDescTextClass}>{statDescriptions[activeStat]}</div>
						{descriptionLocation === 'top' && !usingHover && (
							<CloseButtonX
								onClick={() => setActiveStat(-1)}
								style={{
									position: 'relative',
									top: 0,
									right: 0,
									marginLeft: 'auto',
								}}
							/>
						)}
					</motion.div>
				)}
				{activeStat === -1 && (
					<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" className={statDescClassHidden} custom={statDescMotionProps} key="stats-hidden">
						<div className="city-stat-desc-text"></div>
					</motion.div>
				)}
			</AnimatePresence>
		);
	};

	if (!inputHandlers.current) {
		inputHandlers.current = usingHover
			? [
					{ onMouseOver: () => setActiveStat(0), onMouseOut: () => setActiveStat(-1) },
					{ onMouseOver: () => setActiveStat(1), onMouseOut: () => setActiveStat(-1) },
					{ onMouseOver: () => setActiveStat(2), onMouseOut: () => setActiveStat(-1) },
					{ onMouseOver: () => setActiveStat(3), onMouseOut: () => setActiveStat(-1) },
			  ]
			: [{ onClick: () => setActiveStat(0) }, { onClick: () => setActiveStat(1) }, { onClick: () => setActiveStat(2) }, { onClick: () => setActiveStat(3) }];
	}

	return (
		<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={mainMotionProps} className={mainClass}>
			{descriptionLocation === 'top' && renderDescription()}

			<div className="city-stats-container" ref={cityStatsContainerRef}>
				<div className={stat4Class} ref={stat4Ref} {...inputHandlers.current[3]}>
					<AnimatePresence>
						{descriptionLocation === 'top' && activeStat === 3 && (
							<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={statDescArrowMotionProps} className="bottom-arrow" />
						)}
					</AnimatePresence>
					<div className="stat-value">
						<CityStatCounter val={numberFormatter.format(cityStats.properties?.population || 0)} animDelay={1} />
					</div>
					<div className="stat-label">
						{STATS.TOTAL_POPULATION.title}
						<br />
						Found
					</div>
					<div className="stat-icon" />
				</div>
				<div className={stat1Class} ref={stat1Ref} {...inputHandlers.current[0]}>
					<AnimatePresence>
						{descriptionLocation === 'top' && activeStat === 0 && (
							<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={statDescArrowMotionProps} className="bottom-arrow" />
						)}
					</AnimatePresence>
					<div className="stat-value">
						<CityStatCounter val={numberFormatter.format(cityStats.properties?.b_pop || 0)} animDelay={1.1} />
					</div>
					<div className="stat-label">
						{STATS.BLACK_POPULATION.title}
						<br />
						Found
					</div>
					<div className="stat-icon" />
				</div>
				<div className={stat2Class} ref={stat2Ref} {...inputHandlers.current[1]}>
					<AnimatePresence>
						{descriptionLocation === 'top' && activeStat === 1 && (
							<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={statDescArrowMotionProps} className="bottom-arrow" />
						)}
					</AnimatePresence>
					<div className="stat-value">
						<CityStatCounter val={numberFormatter.format(cityStats.properties?.b_o_businesses || 0)} animDelay={1.2} />
					</div>
					<div className="stat-label">
						{STATS.BLACK_OWNED_BUSINESSES.title}
						<br />
						Found
					</div>
					<div className="stat-icon" />
				</div>
				<div className={stat3Class} ref={stat3Ref} {...inputHandlers.current[2]}>
					<AnimatePresence>
						{descriptionLocation === 'top' && activeStat === 2 && (
							<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={statDescArrowMotionProps} className="bottom-arrow" />
						)}
					</AnimatePresence>
					<div className="stat-value">
						<CityStatCounter val={numberFormatter.format(cityStats.properties?.historical_sites || 0)} animDelay={1.3} />
					</div>
					<div className="stat-label">
						{STATS.HISTORICAL_MARKERS.title}
						<br />
						Found
					</div>
					<div className="stat-icon" />
				</div>
			</div>
			{descriptionLocation === 'bottom' && renderDescription()}
		</motion.div>
	);
}

export default memo(CityStats);
