import { motion, useAnimation } from 'framer-motion';
import { EASE_IN_OUT, MOTION_VARIANTS } from 'js/utils/motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import detect from '@jam3/detect';
import { Debug } from 'js/utils/debug';

import './OnBoardingSection.scss';
import classNames from 'classnames';

const motionProps = {
	initial: {
		opacity: 0,
		width: '0.01%',
	},
	animate: {
		opacity: 1,
		width: '100%',
		transition: { duration: 1.6, ease: EASE_IN_OUT, delay: 0 },
	},
	exit: {
		opacity: 0,
		width: '0.01%',
		transition: { duration: 1.6, ease: EASE_IN_OUT },
	},
};

function OnBoardingSection(props) {
	const anim = useAnimation();
	const [ref, inView] = useInView({
		triggerOnce: true,
		fallbackInView: true,
	});

	useEffect(() => {
		if (inView) {
			anim.start('animate');
		} else {
			anim.start('exit');
		}
	}, [anim, inView]);

	const wrapperClass = classNames('content-wrapper', {
		android: detect.os.android,
	});

	return (
		<motion.div className="OnBoardingSection" initial="initial" animate={anim} variants={MOTION_VARIANTS} custom={motionProps} ref={ref}>
			<div className={wrapperClass}>{props.children}</div>
		</motion.div>
	);
}

export default OnBoardingSection;
