import { Debug } from 'js/utils/debug';
import React, { useState } from 'react';

import './Image.scss';

function Image(props) {
	const { src, alt, maxWidth, maxHeight, loading = 'eager' } = props;
	const [imageLoaded, setImageLoaded] = useState(false);

	const handleImageLoad = (e) => {
		// Debug.warn('handleImageLoad:', e);
		setImageLoaded(true);
	};

	const handleImageError = (e) => {
		Debug.warn('Image load error:', e);
	};

	const imageStyle = {
		opacity: imageLoaded ? 1 : 0,
	};

	let finalSrc = maxWidth ? `${src}?w=${maxWidth}` : src;
	finalSrc = maxHeight ? `${src}?h=${maxHeight}` : src;

	return <img className="Image" src={finalSrc} alt={alt} style={imageStyle} onLoad={handleImageLoad} onError={handleImageError} loading={loading} />;
}

export default Image;
