// import { Howl } from 'howler';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import {
	AudioLoader,
	AudioListener,
	Audio,
	CubeTextureLoader,
	JSONLoader,
	FontLoader,
	TextureLoader,
	UnsignedByteType,
	PMREMGenerator,
	MathUtils,
} from 'three/build/three.module';
import { Debug } from './debug';
import { deleteAllProperties } from './utils';

const LOG_LOADS = false;

export const ACTIVE_LOADERS = {};

export const JSON_TYPES = {
	DATA: 0,
	GEOMETRY: 1,
	FONT: 2,
};

export default class Loader {
	constructor() {
		this.update = this.update.bind(this);
		this.reset();
		this.id = MathUtils.generateUUID();
		ACTIVE_LOADERS[this.id] = this;
	}

	reset() {
		clearInterval(this.interval);
		this.loadInfo = {};
		this.assets = {};
	}

	load(manifest, progressCallback) {
		this.reset();
		this.progressCallback = progressCallback;

		return new Promise((resolve, reject) => {
			const promises = [];

			manifest.forEach((asset) => {
				if (!(Object.prototype.toString.call(asset.url) === '[object Array]')) {
					if (
						asset.url.indexOf('.png') > -1 ||
						asset.url.indexOf('.jpg') > -1 ||
						asset.url.indexOf('.jpeg') > -1 ||
						asset.url.indexOf('.gif') > -1
					) {
						this.assets.textures = this.assets.textures || {};
						promises.push(this.loadTexture(asset));
					} else if (asset.url.indexOf('.hdr') > -1) {
						this.assets.textures = this.assets.textures || {};
						promises.push(this.loadHDRTexture(asset));
					} else if (
						asset.url.indexOf('.gltf') > -1 ||
						asset.url.indexOf('.glb') > -1 ||
						asset.url.indexOf('.drc') > -1
					) {
						this.assets.geometry = this.assets.geometry || {};
						promises.push(this.loadGLTF(asset));
					} else if (asset.url.indexOf('.obj') > -1) {
						this.assets.geometry = this.assets.geometry || {};
						promises.push(this.loadOBJ(asset));
					} else if (asset.url.indexOf('.json') > -1) {
						if (asset.type === JSON_TYPES.DATA) {
							this.assets.data = this.assets.data || {};
							promises.push(this.loadData(asset));
						} else if (asset.type === JSON_TYPES.FONT) {
							this.assets.fonts = this.assets.fonts || {};
							promises.push(this.loadFontJSON(asset));
						} else {
							this.assets.geometry = this.assets.geometry || {};
							promises.push(this.loadGeometryJSON(asset));
						}
						// } else if (asset.url.indexOf('.dae') > -1) {
						// 	this.assets.geometry = this.assets.geometry || {};
						// 	promises.push(this.loadCollada(asset));
					} else if (asset.url.indexOf('.mp4') > -1 || asset.url.indexOf('.webm') > -1) {
						this.assets.video = this.assets.video || {};
						promises.push(this.loadVideo(asset));
					}
					// } else if (asset.url.indexOf('.mp3') > -1 || asset.url.indexOf('.ogg') > -1) {
					// 	this.assets.audio = this.assets.audio || {};
					// 	promises.push(this.loadHowlerAudio(asset));
					// }
				} else {
					this.assets.textures = this.assets.textures || {};

					if (asset.url[0].indexOf('.hdr') > -1) {
						promises.push(this.loadHDRCubemap(asset));
					} else {
						promises.push(this.loadCubemap(asset));
					}

					// promises.push(this.loadCubemap(asset));
				}
			});

			this.interval = setInterval(this.update, 1000 / 30);

			Promise.all(promises).then(() => {
				resolve({ assets: this.assets, loader: this });
			});
			// .catch((error) => reject({ error, assets: [] }));
		});
	}

	loadData(asset) {
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		const xhr = new XMLHttpRequest();

		let hasError = false;

		return new Promise((resolve, reject) => {
			const onError = () => {
				hasError = true;
				this.assets.data[asset.id] = null;
				this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total = 1;
				reject('loadData error');
			};

			xhr.addEventListener('progress', (e) => {
				this.loadInfo[asset.id].loaded = e.loaded;
				this.loadInfo[asset.id].total = e.total;
			});

			xhr.overrideMimeType('application/json');
			xhr.open('GET', asset.url, true);
			xhr.onreadystatechange = () => {
				if (this.loadInfo) {
					if (xhr.readyState === 4 && xhr.status === 200) {
						this.assets.data[asset.id] = JSON.parse(xhr.responseText);
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve(this.assets.data[asset.id]);
					} else if (xhr.status === 404 && !hasError) {
						onError();
					}
				}
			};
			xhr.onerror = onError;
			xhr.send();
		});
	}

	loadGLTF(asset) {
		let loader;
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		if (asset.dracoDecoderPath) {
			loader = new DRACOLoader();
			loader.setDecoderPath(asset.dracoDecoderPath);
			loader.preload();
			// loader.setDRACOLoader(new DRACOLoader());
			// DRACOLoader.getDecoderModule();
		} else {
			loader = new GLTFLoader();
		}

		if (LOG_LOADS) Debug.log('GLTF LOAD STARTED:', asset);

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(gltf) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total = 100; // fixes loading from cache if both are 0
						this.assets.geometry[asset.id] = gltf;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadOBJ(asset) {
		const loader = new OBJLoader();
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(obj) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total = 100; // fixes loading from cache if both are 0
						this.assets.geometry[asset.id] = obj;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadGeometryJSON(asset) {
		const loader = new JSONLoader();
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(geometry) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
						this.assets.geometry[asset.id] = geometry;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadFontJSON(asset) {
		const loader = new FontLoader();
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(font) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
						this.assets.fonts[asset.id] = font;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadTexture(asset) {
		// const loader = new AjaxTextureLoader();
		const loader = new TextureLoader();
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(texture) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
						this.assets.textures[asset.id] = texture;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadHDRTexture(asset) {
		const pmremGenerator = new PMREMGenerator(asset.renderer);
		const loader = new RGBELoader().setDataType(UnsignedByteType);
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		return new Promise((resolve, reject) => {
			loader.load(asset.url, (texture) => {
				if (this.loadInfo) {
					const hdrTexture = pmremGenerator.fromEquirectangular(texture).texture;
					texture.dispose();
					pmremGenerator.dispose();
					this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
					this.assets.textures[asset.id] = hdrTexture;
					if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
					resolve();
				}
			});
		});
	}

	loadCubemap(asset) {
		const loader = new CubeTextureLoader();
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(cubemapTexture) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
						this.assets.textures[asset.id] = cubemapTexture;
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadHDRCubemap(asset) {
		const loader = new HDRCubeTextureLoader().setDataType(UnsignedByteType);
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		const pmremGenerator = new PMREMGenerator(asset.renderer);
		pmremGenerator.compileCubemapShader();

		return new Promise((resolve, reject) => {
			if (this.loadInfo) {
				loader.load(asset.url, (texture) => {
					const hdrTexture = pmremGenerator.fromCubemap(texture).texture;
					this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
					this.assets.textures[asset.id] = hdrTexture;
					if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
					resolve();
				});
			}
		});
	}

	loadVideo(asset) {
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		const xhr = new XMLHttpRequest();
		const scope = this;

		xhr.addEventListener('progress', (e) => {
			this.loadInfo[asset.id].loaded = e.loaded;
			this.loadInfo[asset.id].total = e.total;
		});

		xhr.open('GET', asset.url, true);
		xhr.responseType = 'blob';

		return new Promise((resolve, reject) => {
			xhr.onload = function () {
				// Onload is triggered even on 404
				// so we need to check the status code
				if (this.status === 200) {
					const videoBlob = this.response;
					const vid = URL.createObjectURL(videoBlob); // IE10+
					// Video is now downloaded
					// and we can set it as source on the video element
					// video.src = vid;

					scope.files.video[asset.id] = vid;
					if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
					resolve();
				}
			};

			xhr.onerror = () => {
				reject('loadVideo error');
			};

			xhr.send();
		});
	}

	loadAudio(asset) {
		this.loadInfo[asset.id] = { loaded: 0, total: 0 };

		const loader = new AudioLoader();
		const listener = asset.listener || new AudioListener();
		const sound = asset.sound || new Audio(listener);

		return new Promise((resolve, reject) => {
			loader.load(
				asset.url,
				(buffer) => {
					if (this.loadInfo) {
						sound.setBuffer(buffer);
						this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
						this.assets.audio[asset.id] = { sound, listener, buffer };
						if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
						resolve();
					}
				},
				(xhr) => {
					if (this.loadInfo) {
						this.loadInfo[asset.id].loaded = xhr.loaded;
						this.loadInfo[asset.id].total = xhr.total;
					}
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	// loadHowlerAudio(asset) {
	// 	this.loadInfo[asset.id] = { loaded: 0, total: 1 };

	// 	const sound = new Howl({
	// 		src: asset.sources,
	// 		sprite: asset.spriteInfo,
	// 		loop: asset.loop,
	// 		preload: false,
	// 		// onloaderror: (id, error) => {
	// 		//     Debug.log('loadHowlerAudio error:', id, error);
	// 		// },
	// 		// onload: () => {
	// 		//     this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
	// 		//     this.assets.audio[asset.id] = { sound: sound };
	// 		//     if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
	// 		//     this.numLoaded++;
	// 		//     this.checkLoaded();
	// 		// }
	// 	});

	// 	const handleError = (id, error) => {
	// 		console.warn('loadHowlerAudio error:', id, error);
	// 	};

	// 	const handleLoad = () => {
	// 		sound.off('load', handleLoad);
	// 		sound.off('loaderror', handleError);
	// 		this.loadInfo[asset.id].loaded = this.loadInfo[asset.id].total;
	// 		this.assets.audio[asset.id] = { sound };
	// 		if (LOG_LOADS) Debug.log(`LOAD COMPLETE / ${asset.id}`);
	// 		this.numLoaded++;
	// 		this.checkLoaded();
	// 	};

	// 	sound.on('loaderror', handleError);
	// 	sound.on('load', handleLoad);

	// 	sound.load();
	// }

	update() {
		if (typeof this.progressCallback === 'function') {
			let loaded = 0;
			let total = 0;

			for (const info in this.loadInfo) {
				if (this.loadInfo[info].loaded) {
					loaded += this.loadInfo[info].loaded;
				}
				if (this.loadInfo[info].total) {
					total += this.loadInfo[info].total;
				}
			}

			this.progressCallback(loaded, total);
		}
	}

	dispose() {
		// Debug.log('dispose');
		clearInterval(this.interval);

		if (this.id && ACTIVE_LOADERS[this.id]) {
			delete ACTIVE_LOADERS[this.id];
		}

		deleteAllProperties(this);
	}
}

export async function getData(url = '') {
	// Default options are marked with *
	const response = await fetch(url, {
		method: 'GET', // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		// cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		headers: {
			'Content-Type': 'application/json',
		},
	});
	return response.json().catch((error) => Debug.log(error));
}
