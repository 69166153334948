import AboutParagraphs from './AboutParagraphs/AboutParagraphs';
import AboutImage from './AboutImage/AboutImage';
import AboutQuote from './AboutQuote/AboutQuote';
import AboutContributors from './AboutContributors/AboutContributors';
import AboutSubscribe from './AboutSubscribe/AboutSubscribe';
import AboutPartners from './AboutPartners/AboutPartners';
import AboutFonts from './AboutFonts/AboutFonts';
import AboutIcons from './AboutIcons/AboutIcons';
import AboutVideo from './AboutVideo/AboutVideo';

export default {
  AboutParagraphs,
  AboutImage,
  AboutQuote,
  AboutContributors,
  AboutSubscribe,
  AboutPartners,
  AboutFonts,
  AboutIcons,
  AboutVideo,
}