import { IS_PHONE, ZOOM_LEVELS } from 'js/config/constants';
import CameraController from 'js/controllers/camera-controller';
import { useAppState } from 'js/hooks/app-state';
import useQueryParams from 'js/hooks/use-query-params';
import { getStateName } from 'js/utils/map-data-utils';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ContentDrawer, { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import { mapProps } from '../Map/Map';
import { Debug } from 'js/utils/debug';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

function StandardCity() {
	const [data, setData] = useState(null);
	const [goToOnClose, setGoToOnClose] = useState(null);
	const [{ cityData }, { setState }] = useAppState();
	const params = useParams();
	const { city, state } = params;
	const query = useQueryParams();
	const lng = query.get('lng');
	const lat = query.get('lat');
	const forcedZoom = query.get('zoom');
	const contentRef = useRef(null);
	const decodedCity = decodeURI(city);

	useEffect(() => {
		if (cityData && decodedCity && contentRef.current) {
			const stateName = getStateName(state);
			let result = cityData.filter(({ properties: { city: cName, state: sName } }) => {
				return decodedCity === cName && stateName === sName;
			})[0];

			// add state code for display
			if (!result) {
				result = {
					properties: {
						city: decodedCity,
						state: stateName,
					},
				};
			}

			result.properties.stateCode = state;

			if (lng && lat) {
				// offset to be centered in available space
				const elWidth = contentRef.current?.getBoundingClientRect()?.width;
				const offsetX = window.innerWidth * 0.5 - (window.innerWidth - elWidth) * 0.5;
				const { map } = mapProps;
				const zoom = map.getZoom();
				const targetZoom = ZOOM_LEVELS.CITY + 1;
				const finalZoom = forcedZoom || (zoom > targetZoom ? zoom : targetZoom);

				const options = {
					zoom: finalZoom,
					offset: IS_PHONE ? [0, 0] : [-offsetX, 0],
				};

				const forceBasicMotion = map.getZoom() >= ZOOM_LEVELS.CITY;
				// Debug.warn('forceBasicMotion:', forceBasicMotion);

				CameraController.instance?.flyTo([Number(lng), Number(lat)], options, forceBasicMotion);
				if (!IS_PHONE) setGoToOnClose([lng, lat]);
			}

			setData(result);
			setState({ currentLocationOverride: { city: decodedCity, state } });
		}

		return () => {
			setState({ currentLocationOverride: null });
		};
	}, [cityData, decodedCity, state, lng, lat, contentRef, setState]);

	return (
		<>
			<HelmetWrapper>
				<title>{`Black Elevation Map | ${data?.properties?.city}`}</title>
				<meta name="description" content="Explore a new map of America – with curated guides, 30,000 points of interest and the millions who make it rise." />
			</HelmetWrapper>
			<ContentDrawer contentType={CONTENT_TYPES.STANDARD_CITY} content={data} reference={contentRef} goToOnClose={goToOnClose} />
		</>
	);
}

export default StandardCity;
