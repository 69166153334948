import React, { useEffect, useState, useRef } from 'react';
import { useAppState } from 'js/hooks/app-state';
import { wait } from 'js/utils/utils';
import gsap from 'gsap';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './Intro.scss';

const MIN_DURATION = 2;

function Intro() {
	const [{ mapReady }, { setState }] = useAppState();
	const [animationComplete, setAnimationComplete] = useState(false);
	const [minTimerComplete, setMinTimerComplete] = useState(false);
	const [backgroundImageIndex, setBackgroundImageIndex] = useState(false);
	const backgroundImages = ['barber', 'cowboy', 'fashion', 'grafitti', 'medium', 'restaurant']
	const introRef = useRef();

	useEffect(() => {
		if (mapReady && animationComplete) {
			setState({ introViewed: true });
			wait(500).then(() => setState({ showIntro: false }));
		}
	}, [mapReady, animationComplete, setState]);

	useEffect(() => {		
		setBackgroundImageIndex(Math.floor(Math.random() * 6))
		setTimeout(() => setMinTimerComplete(true), MIN_DURATION);
	}, [setMinTimerComplete]);

	useEffect(() => {
		if (introRef?.current && mapReady && minTimerComplete) {
			const lock1 = introRef.current.querySelector('.intro-bab-logo__lock');
			const lock2 = introRef.current.querySelector('.intro-bem-logo__lock');

			const tline = gsap.timeline({
				onComplete: () => {
					setAnimationComplete(true);
				},
			});

			tline.to(lock1, { opacity: 0, y: -30, ease: 'strong.out', duration: 0.6 }, 0);
			tline.to(lock2, { opacity: 0, y: -30, ease: 'strong.out', duration: 0.6 }, 0);
			tline.to(introRef.current, { y: '-100%', ease: 'strong.out', duration: 0.9 }, 0);
		}
	}, [introRef, mapReady, minTimerComplete, setAnimationComplete]);

	return (
		<>
			{/* <HelmetWrapper>
				<title>Black Elevation Map | Intro</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper> */}
			<div className="Intro" ref={introRef}>
				<div className={"intro-pre-load__bg "+backgroundImages[backgroundImageIndex]} />
				<div className="intro-pre-load">
					<div className="intro-bab-logo__lock">
						<div className="intro-bab-logo__wrap">
							<div className="intro-bab-logo" />
						</div>
						<div className="presents">Presents</div>
					</div>
					<div className="intro-bem-logo__lock">
						<div className="intro-bem-logo" />
					</div>
				</div>
				<div className="intro-load-spin" />
			</div>
		</>
	);
}

export default Intro;
