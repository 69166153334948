/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { manifests } from '../../config/asset-manifest';
import {
	SHOW_HEIGHT_MAP,
	SHOW_DEBUG_INFO,
	ENDPOINTS,
	IS_MOBILE,
	IS_DEVELOPMENT,
	FORCE_GLOBAL_REFS,
	SKIP_INTRO,
	IS_TABLET,
	IS_DESKTOP,
	IS_PHONE,
	API_BASE,
} from '../../config/constants';
import { useAppState } from '../../hooks/app-state';
import { Debug } from '../../utils/debug';
import graphics, { getGPU, getGraphicsMode, getTier, initGraphics } from '../../utils/graphics';
import Loader from '../../utils/loader';
import { getData } from '../../utils/utils';
import Map from '../Map/Map';
import { FEATURE_PROPS, getFeatureProperty, STATE_CODES } from 'js/utils/map-data-utils';
import routes from '../Router/routes';
import gsap from 'gsap/gsap-core';
import { CSSPlugin } from 'gsap/all';

import './MapWrapper.scss';

gsap.registerPlugin(CSSPlugin);

let abortController;

function MapWrapper() {
	const [{ mapId, mapLoaded }, { setState }] = useAppState();
	const [showLoadErrorMessage, setShowLoadErrorMessage] = useState(false);
	const locationRoute = useRouteMatch(routes.location);
	const homeRoute = useRouteMatch(routes.home);
	const landingRoute = useRouteMatch(routes.landing);
	const history = useHistory();

	useEffect(() => {
		// for aborting initGraphics async function if it hasn't returned yet
		abortController = new AbortController();

		if (SKIP_INTRO) {
			setState({ showIntro: false, introViewed: true });
		}

		// GPU check
		initGraphics(abortController.signal)
			.then(() => {
				const tier = getTier();
				const gm = getGraphicsMode();
				const gpu = getGPU();
				const graphicsStr = `Graphics: ${gm}\nGPU: ${gpu}\nTier: ${tier}\nPixel Ratio: ${graphics[gm].pixelRatio}\nAsset Quality: ${graphics[gm].assetQuality}\nDesktop: ${IS_DESKTOP}\nPhone: ${IS_PHONE}\nTablet: ${IS_TABLET}`;
				Debug.log('ENV:', process.env.NODE_ENV);
				Debug.log('API_BASE:', API_BASE);
				Debug.log(graphicsStr);

				if (IS_DEVELOPMENT) {
					const container = document.querySelector('.heightmap-container');
					if (container) container.innerHTML = '';
				}

				if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
					window._ = window._ || {};
					window._.graphicsStr = graphicsStr;
				}

				window.devicePixelRatio = graphics[gm].pixelRatio;

				abortController = null;

				let deepLinkId = null;

				if (locationRoute?.isExact && locationRoute?.params?.id) {
					deepLinkId = locationRoute?.params?.id;
				}

				const processGuideData = (guideData) => {
					const nationalGuidesViewedStatus = {};
					const cityGuidesViewedStatus = {};
					const peopleData = [];

					guideData.forEach((guideDataItem) => {
						const { id, city, curatedPeople } = guideDataItem;

						// set viewed states for all guides
						if (!city) {
							nationalGuidesViewedStatus[id] = { viewed: false };
						} else {
							cityGuidesViewedStatus[id] = { viewed: false };

							// find all curated people for use in city guides / nearby people
							curatedPeople?.forEach((curatedPerson) => peopleData.push(curatedPerson));
						}
					});

					setState({
						peopleData,
						nationalGuidesViewedStatus,
						cityGuidesViewedStatus,
					});
				};

				// load data
				if (!IS_PHONE || (!homeRoute && !landingRoute)) {
					loadAll(deepLinkId)
						.then(([dataResult, { assets, loader }]) => {
							const stateData = [...dataResult[0]]; // not formatted in features
							const cityData = [...dataResult[1].features];
							const poiStateData = dedupPoiData([
								...validateData(dataResult[2]?.features),
								...validateData(dataResult[3]?.features),
								...validateData(dataResult[4]?.features),
							]);
							const guideData = [...dataResult[5].features];

							dedupGuidePoiData(poiStateData, guideData);
							processGuideData(guideData);

							// convert deep link data to initial payload format
							const feature = dataResult[6]?.feature;
							let deepLinkData = null;

							if (feature) {
								const { properties, geometry } = feature;

								deepLinkData = {
									geometry,
									p: {
										c: properties.city,
										gid: properties.business_id.toString(),
										n: properties.name,
										pc: properties.parent_category,
										r: properties.rating,
										rc: properties.review_count,
										s: STATE_CODES[properties.state],
										yr: properties.yearerected,
									},
								};
							}

							Debug.log('deepLinkData:', deepLinkData);

							// save data & assets to global state
							setState({
								stateData,
								cityData,
								guideData,
								poiStateData,
								deepLinkData,
								assets: { ...assets },
								mapLoaded: true,
							});

							loader?.dispose();
						})
						.catch((error) => {
							Debug.warn('Data load error:', error);
							setShowLoadErrorMessage(true);
						});
				} else {
					let guideData;
					history.push(routes.landing.path);

					loadMobileStage1()
						.then((dataResult) => {
							guideData = [...dataResult[0].features];

							Debug.log('guideData:', guideData);

							processGuideData(guideData);

							setState({
								guideData,
							});

							loadMobileStage2()
								.then((dataResult) => {
									// Debug.log('load complete:', dataResult);
									const stateData = [...dataResult[0]]; // not formatted in features
									const cityData = [...dataResult[1].features];
									const poiStateData = dedupPoiData([
										...validateData(dataResult[2]?.features),
										...validateData(dataResult[3]?.features),
										...validateData(dataResult[4]?.features),
									]);
									const { assets, loader } = dataResult[5];

									dedupGuidePoiData(poiStateData, guideData);

									setState({
										stateData,
										cityData,
										poiStateData,
										assets: { ...assets },
										mapLoaded: true,
									});

									loader?.dispose();
								})
								.catch((error) => {
									Debug.warn('Data load error:', error);
									setShowLoadErrorMessage(true);
								});
						})
						.catch((error) => {
							Debug.warn('Data load error:', error);
							setShowLoadErrorMessage(true);
						});
				}
			})
			.catch((error) => Debug.log(error));

		return () => {
			abortController?.abort();
		};
	}, []);

	function renderDevContent() {
		return (
			<>
				{SHOW_HEIGHT_MAP && <div className="heightmap-container" />}
				{SHOW_DEBUG_INFO && <div className="debug-info" />}
				{showLoadErrorMessage && (
					<div className="load-error" onClick={() => window.location?.reload()}>
						<div className="message">Something went wrong, please refresh your browser to try again.</div>
					</div>
				)}
			</>
		);
	}

	return (
		<div className="MapWrapper">
			{mapLoaded && <Map key={mapId} />}
			<div className="vignette" />
			{renderDevContent()}
		</div>
	);
}

function loadAll(deepLinkId) {
	return Promise.all([loadData(deepLinkId), loadAssets()]);
}

function loadMobileStage1() {
	const promises = [loadGuides()];
	return Promise.all(promises);
}

function loadMobileStage2() {
	const promises = [loadStates(), loadCities(), loadGooglePois(), loadYelpPois(), loadHistoricalPois(), loadAssets()];
	return Promise.all(promises);
}

function loadData(deepLinkId) {
	const promises = [loadStates(), loadCities(), loadGooglePois(), loadYelpPois(), loadHistoricalPois(), loadGuides()];

	if (deepLinkId) {
		promises.push(loadDeeplink(deepLinkId));
	}

	return Promise.all(promises);
}

function loadStates() {
	return new Promise((resolve) => {
		Debug.time(`loadData / STATES`);
		getData(ENDPOINTS.STATES)
			.then((data) => {
				Debug.timeEnd(`loadData / STATES`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / STATES Error:', error));
	});
}

function loadCities() {
	return new Promise((resolve) => {
		Debug.time(`loadData / CITIES`);
		getData(ENDPOINTS.CITIES)
			.then((data) => {
				Debug.timeEnd(`loadData / CITIES`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / CITIES Error:', error));
	});
}

function loadGooglePois() {
	return new Promise((resolve) => {
		Debug.time(`loadData / POI_STATE_LEVEL_1_GOOGLE`);
		getData(ENDPOINTS.POI_STATE_LEVEL_1_GOOGLE)
			.then((data) => {
				Debug.timeEnd(`loadData / POI_STATE_LEVEL_1_GOOGLE`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / POI_STATE_LEVEL_1_GOOGLE Error:', error));
	});
}

function loadYelpPois() {
	return new Promise((resolve) => {
		Debug.time(`loadData / POI_STATE_LEVEL_1_YELP`);
		getData(ENDPOINTS.POI_STATE_LEVEL_1_YELP)
			.then((data) => {
				Debug.timeEnd(`loadData / POI_STATE_LEVEL_1_YELP`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / POI_STATE_LEVEL_1_YELP Error:', error));
	});
}

function loadHistoricalPois() {
	return new Promise((resolve) => {
		Debug.time(`loadData / POI_STATE_LEVEL_2`);
		getData(ENDPOINTS.POI_STATE_LEVEL_2)
			.then((data) => {
				Debug.timeEnd(`loadData / POI_STATE_LEVEL_2`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / POI_STATE_LEVEL_2 Error:', error));
	});
}

function loadGuides() {
	return new Promise((resolve) => {
		Debug.time(`loadData / GUIDES`);
		getData(ENDPOINTS.GUIDES)
			.then((data) => {
				Debug.timeEnd(`loadData / GUIDES`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / GUIDES Error:', error));
	});
}

function loadDeeplink(deepLinkId) {
	return new Promise((resolve) => {
		Debug.time(`loadData / DEEP LINK`);
		getData(`${ENDPOINTS.LOCATION}/${deepLinkId}`)
			.then((data) => {
				Debug.timeEnd(`loadData / DEEP LINK`);
				resolve(data);
			})
			.catch((error) => Debug.warn('loadData / DEEP LINK Error:', error));
	});
}

function loadAssets() {
	const loader = new Loader();
	return loader.load(IS_MOBILE ? manifests.mobile : manifests.desktop);
}

function validateData(data) {
	// make sure gid's are strings
	data.forEach((dataItem) => {
		if (dataItem?.p?.gid) {
			dataItem.p.gid = dataItem.p.gid.toString();
		}
	});
	return data?.filter((dataItem) => getFeatureProperty(dataItem, FEATURE_PROPS.ID) !== 'none' && dataItem.p?.pc !== '');
}

// remove any curated poi's with google id's from main poi dataset to avoid duplicate markers
function dedupGuidePoiData(poiStateData, guideData) {
	// find curated poi's with google id's
	const guidePoisWithLocationIds = [];
	guideData.forEach((guideDataItem) => {
		guideDataItem?.curatedPois?.forEach(({ googlePlacesId }) => {
			if (googlePlacesId) guidePoisWithLocationIds.push(googlePlacesId);
		});
	});

	// find google id's in main poi dataset and remove
	let index = poiStateData.length - 1;
	while (index--) {
		const {
			p: { gid },
		} = poiStateData[index];

		if (guidePoisWithLocationIds.includes(gid)) {
			poiStateData.splice(index, 1);
		}
	}
}

function dedupPoiData(data) {
	const results = {};

	// Debug.log('dedupPoiData:', data.length);

	data.forEach((dataItem) => {
		const {
			p: { gid },
		} = dataItem;
		if (!results[gid]) results[gid] = dataItem;
	});

	// Debug.log('dedupPoiData results:', Object.values(results).length);

	return Object.values(results);
}

export default MapWrapper;
