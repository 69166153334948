import React from 'react';

import './AboutNav.scss';

function AboutNav(props) {
  const { navData } = props;

  return (
    <div className="AboutNav">
			{ navData.map((d,i) => (
				<a href={`#${d.id}`} key={i}>{d.lbl}</a>
			))}
		</div>
  )
}

export default AboutNav;