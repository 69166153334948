import React from 'react';

import './NextArrows.scss';

function NextArrows(props) {
	const { color = '#ffffff', width = 21, height = 32, styles } = props;

	const style = { width: `${width}px`, height: `${height}px`, ...styles };

	return (
		<div className="NextArrows" style={style}>
			<svg width="21" height="32" viewBox="0 0 21 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M2.15464 30.682L0.835938 30.2163L5.28167 16.0813L0.835938 1.94623L2.15464 1.48047L6.74782 16.0813L2.15464 30.682Z"
					fill={color}
				/>
				<path d="M6.6727 30.682L5.354 30.2163L9.79973 16.0813L5.354 1.94623L6.6727 1.48047L11.2659 16.0813L6.6727 30.682Z" fill={color} />
				<path
					d="M11.1932 30.682L9.87451 30.2163L14.3202 16.0813L9.87451 1.94623L11.1932 1.48047L15.7864 16.0813L11.1932 30.682Z"
					fill={color}
				/>
				<path
					d="M15.7145 30.682L14.3931 30.2163L18.8388 16.0813L14.3931 1.94623L15.7145 1.48047L20.3049 16.0813L15.7145 30.682Z"
					fill={color}
				/>
			</svg>
		</div>
	);
}

export default NextArrows;
