import React from 'react';
import './AmbassadorLink.scss';
import { Link } from 'react-router-dom';
import { getAnchorFromName } from '../../utils/utils';
import routes from '../Router/routes';

function AmbassadorLink(props) {
	const { ambassadorName, ambassadorImage } = props;

	const contribLink = getAnchorFromName(ambassadorName);

	return (
		<div className="AmbassadorLink">
			{ambassadorImage && <img src={ambassadorImage.url} alt={ambassadorName} />}
			By&nbsp;
			<Link to={`${routes.about.path}#${contribLink}`}>{ambassadorName}</Link>
		</div>
	);
}

export default AmbassadorLink;
