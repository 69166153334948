// This module is responsible for fetching data from our API
// and parsing/transforming it into an object that can be consumed
// by our Location components.

import { ENDPOINTS } from 'js/config/constants';
// import { mapProps } from 'js/components/Map/Map';
// import { getCoordinatesForBusinessId } from 'js/utils/map-data-utils';

const URL = (id) => `${ENDPOINTS.LOCATION}/${id}`;

const getLocation = (id) => {
	// const {
	// 	reactProps: { poiStateData },
	// } = mapProps;

	return fetch(URL(id), {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
		},
	})
		.then((res) => res.json())
		.then((data) => data?.feature)
		.then((feature) => {
			// console.warn('getLocation raw:', id, feature);
			return {
				geometry: feature?.geometry,
				// geometry: getCoordinatesForBusinessId(poiStateData, id),
				...feature?.properties,
				// XXX: location for non-business is not an address, so I'm removing it.
				location: feature?.type === 'Business' ? feature?.properties?.location : '',
				description: feature?.type === 'HistoricalSite' ? feature?.properties?.location : '',
			};
		})
		.then((properties) => ({
			businessId: properties?.business_id,
			yelpId: properties?.yelp_id,
			geometry: properties?.geometry,
			images: [properties?.image_url].filter((x) => !!x),
			imageSource: 'Google Places',
			name: properties?.name,
			type: properties?.parent_category?.[0],
			location: `${properties?.city}, ${properties?.state}`,
			description: properties?.description,
			rating: properties?.rating,
			reviewCount: properties?.review_count,
			price: properties?.price,
			address: properties?.location,
			city: properties?.city,
			state: properties?.state,
			zip: properties?.zip,
			phoneNumber: properties?.phone,
			website: properties?.url,
			source: properties?.source,
		}))
		.catch((error) => console.warn(error));
};

export default getLocation;
