/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { MOTION_PRESETS, MOTION_VARIANTS, EASE_OUT, EASE_IN, COMMON_DURATION, EASE_IN_OUT } from 'js/utils/motion';
import { IS_DEVELOPMENT, POI_CATEGORIES, POI_LABELS } from 'js/config/constants';
import { locationIcons } from 'js/config/asset-manifest';
import { useAppState } from 'js/hooks/app-state';
import classNames from 'classnames';

import './MapLegend.scss';

const LEGEND_DATA = [
	{ id: POI_CATEGORIES.EAT, lbl: POI_LABELS[POI_CATEGORIES.EAT], cls: 'eat', filterKey: POI_CATEGORIES.EAT },
	{ id: POI_CATEGORIES.ENTERTAINMENT, lbl: POI_LABELS[POI_CATEGORIES.ENTERTAINMENT], cls: 'entertain', filterKey: POI_CATEGORIES.ENTERTAINMENT },
	{ id: POI_CATEGORIES.SHOP, lbl: POI_LABELS[POI_CATEGORIES.SHOP], cls: 'shop', filterKey: POI_CATEGORIES.SHOP },
	{ id: POI_CATEGORIES.SEE, lbl: POI_LABELS[POI_CATEGORIES.SEE], cls: 'see', filterKey: POI_CATEGORIES.SEE },
	{ id: POI_CATEGORIES.LEARN, lbl: POI_LABELS[POI_CATEGORIES.LEARN], cls: 'learn', filterKey: POI_CATEGORIES.LEARN },
	{ id: POI_CATEGORIES.STAY, lbl: POI_LABELS[POI_CATEGORIES.STAY], cls: 'stay', filterKey: POI_CATEGORIES.STAY },
	{ id: POI_CATEGORIES.BEAUTY, lbl: POI_LABELS[POI_CATEGORIES.BEAUTY], cls: 'beauty', filterKey: POI_CATEGORIES.BEAUTY },
	{ id: POI_CATEGORIES.FLEX, lbl: POI_LABELS[POI_CATEGORIES.FLEX], cls: 'flex', filterKey: POI_CATEGORIES.FLEX },
	{ id: POI_CATEGORIES.CURATED, lbl: POI_LABELS[POI_CATEGORIES.CURATED], cls: 'curated', filterKey: POI_CATEGORIES.CURATED },
	{ id: POI_CATEGORIES.FAVORITES, lbl: POI_LABELS[POI_CATEGORIES.FAVORITES], cls: 'favorites', filterKey: POI_CATEGORIES.FAVORITES },
	{ id: POI_CATEGORIES.GENERAL_POI, lbl: POI_LABELS[POI_CATEGORIES.GENERAL_POI], cls: 'general', filterKey: null },
];

const containerMotion = {
	initial: {
		opacity: 0,
	},
	animate: {
		opacity: 1,
		transition: {
			duration: 0.3,
			staggerChildren: 0.04,
			delayChildren: 0.1,
			ease: EASE_OUT,
		},
	},
	exit: {
		left: '-100%',
		transition: {
			duration: 0.1,
			ease: EASE_IN,
		},
	},
};

const labelMotionProps = {
	initial: {
		width: '0px',
	},
	animate: {
		width: '100px',
		transition: { duration: COMMON_DURATION, ease: EASE_IN_OUT, delay: 0.3 },
	},
	exit: {
		width: '0px',
		transition: { duration: COMMON_DURATION, ease: EASE_IN_OUT },
	},
};

const toggleMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateX(-30px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateX(0px)',
		transition: { duration: COMMON_DURATION * 1.5, ease: EASE_OUT, delay: 0.8 },
	},
	exit: {
		opacity: 0,
		transform: 'translateX(-30px)',
		transition: { duration: COMMON_DURATION, ease: EASE_IN_OUT },
	},
};

function MapLegend() {
	const [legendOpen, setLegendOpen] = useState(false);
	const [toggleAllOn, setToggleAllOn] = useState(true);
	const [{ filters }, { setState, setFilters }] = useAppState();
	const containerRef = useRef(null);

	useEffect(() => {
		containerRef.current = document.querySelector('.map-container');

		if (IS_DEVELOPMENT) {
			window._.setFilters = setFilters;
		}
	}, []);

	useEffect(() => {
		let numKeys = Object.keys(filters).length;
		let numOn = 0;
		for (let key in filters) {
			if (filters[key] === true) numOn++;
		}
		setToggleAllOn(numOn < numKeys);
	}, [filters, setToggleAllOn]);

	useEffect(() => {
		setState({ mapLegendOpen: legendOpen });
	}, [legendOpen, setState]);

	const handleToggle = (e, category) => {
		e.stopPropagation();
		e.preventDefault();

		switch (category) {
			case 'curated':
				setFilters({ [POI_CATEGORIES.CURATED]: !filters[POI_CATEGORIES.CURATED] });
				break;

			case 'favorites':
				setFilters({ [POI_CATEGORIES.FAVORITES]: !filters[POI_CATEGORIES.FAVORITES] });
				break;

			case 'entertain':
				setFilters({ [POI_CATEGORIES.ENTERTAINMENT]: !filters[POI_CATEGORIES.ENTERTAINMENT] });
				break;

			case 'see':
				setFilters({ [POI_CATEGORIES.SEE]: !filters[POI_CATEGORIES.SEE] });
				break;

			case 'beauty':
				setFilters({ [POI_CATEGORIES.BEAUTY]: !filters[POI_CATEGORIES.BEAUTY] });
				break;

			case 'eat':
				setFilters({ [POI_CATEGORIES.EAT]: !filters[POI_CATEGORIES.EAT] });
				break;

			case 'flex':
				setFilters({ [POI_CATEGORIES.FLEX]: !filters[POI_CATEGORIES.FLEX] });
				break;

			case 'learn':
				setFilters({ [POI_CATEGORIES.LEARN]: !filters[POI_CATEGORIES.LEARN], [POI_CATEGORIES.KNOW]: !filters[POI_CATEGORIES.KNOW] });
				break;

			case 'shop':
				setFilters({ [POI_CATEGORIES.SHOP]: !filters[POI_CATEGORIES.SHOP] });
				break;

			case 'stay':
				setFilters({ [POI_CATEGORIES.STAY]: !filters[POI_CATEGORIES.STAY] });
				break;

			default:
				break;
		}
	};

	const handleToggleAll = (on) => {
		for (let key in filters) {
			setFilters({ [key]: on ? true : false });
		}
	};

	const toggleClassName = classNames('toggle-all', {
		'toggle-all--off': !toggleAllOn,
	});

	return (
		<div className="MapLegend" open={legendOpen}>
			<button className="legend-button legend-open" onClick={() => setLegendOpen(true)}>
				<span className="label">Show legend</span>
				<span className="arrow" />
			</button>

			<AnimatePresence>
				{legendOpen && (
					<motion.div className="legend-tray" variants={containerMotion} initial="initial" animate="animate" exit="exit">
						<motion.div className="legend-mobile-bg" variants={MOTION_VARIANTS} custom={MOTION_PRESETS.SCALE_FROM_LEFT} />
						<div className="legend-head-wrap">
							<motion.button
								className="legend-button legend-close"
								variants={MOTION_VARIANTS}
								custom={MOTION_PRESETS.FADE_SLIDE_FROM_LEFT}
								onClick={() => setLegendOpen(false)}
							>
								<span className="arrow" />
								<span className="label">Hide legend</span>
							</motion.button>
							<motion.button
								className={toggleClassName}
								variants={MOTION_VARIANTS}
								custom={toggleMotionProps}
								onClick={() => handleToggleAll(toggleAllOn)}
							/>
						</div>

						<motion.div className="legend-list">
							{LEGEND_DATA.map((d, i) => {
								const filtered = !filters[d.filterKey] && d.filterKey !== null;
								const ico = locationIcons.black[d.id];
								const icoWhite = locationIcons.white[d.id];
								const className = classNames({
									'legend-item': true,
									[d.cls]: true,
									filtered,
								});
								return (
									<motion.div
										className={className}
										variants={MOTION_VARIANTS}
										custom={MOTION_PRESETS.FADE_SLIDE_FROM_LEFT}
										key={i}
										onClick={(e) => handleToggle(e, d.cls)}
									>
										<div className="icon">
											<div className="icon-bg" />
											<img className="icon-white" src={icoWhite} aria-hidden="true" alt={d.lbl} />
											<img className="icon-black" src={ico} aria-hidden="true" alt={d.lbl} />
										</div>
										<motion.div
											className="label"
											variants={MOTION_VARIANTS}
											custom={labelMotionProps}
											initial="initial"
											animate="animate"
											exit="exit"
										>
											{d.lbl}
										</motion.div>
									</motion.div>
								);
							})}
						</motion.div>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

export default MapLegend;
