import {
	BACKGROUND_COLOR,
	DEFAULT_DATASET_INDEX,
	ELEVATION_GEOMETRY_CONFIG,
	FORCE_GLOBAL_REFS,
	IS_DEVELOPMENT,
	MAP_SHADOW_DISTANCE,
} from 'js/config/constants';
import {
	Group,
	LinearFilter,
	Mesh,
	MeshStandardMaterial,
	PlaneBufferGeometry,
	TextureLoader,
	Vector2,
	MeshBasicMaterial,
	Box3,
	DoubleSide,
	Color,
} from 'three/build/three.module';
import { deleteAllProperties, wait } from 'js/utils/utils';
import { getHeightmap } from 'js/utils/image-utils';
import gsap from 'gsap/gsap-core';
import { mapProps } from 'js/components/Map/Map';
import { Debug } from 'js/utils/debug';
import { getGraphicsMode } from 'js/utils/graphics';
import MapFill from './map-fill';

// shaders
import terrainVert from '../../glsl/terrain.vert';
import terrainFrag from '../../glsl/terrain.frag';
// import terrainParticlesVert from '../../glsl/terrain-particles.vert';
// import terrainParticlesFrag from '../../glsl/terrain-particles.frag';

// textures
import gradientSrc from '../../images/map/terrain-gradient.png'; // texture that defines terrain color ramp (keep same dimensions)
import MapShadow from './map-shadow';
// import particleSrc from '../../images/map/particle.png'; // texture for each particle (keep same dimensions)

const textureLoader = new TextureLoader();

export default class Terrain {
	constructor(props) {
		this.props = props;
		this.init();
	}

	init() {
		const { meshElevationProps, width, height, tilesX, tilesY } = ELEVATION_GEOMETRY_CONFIG;
		const {
			usaHeightmap,
			genPopHeightmap,
			blackPopHeightmap,
			blackOwnedBusinessesHeightmap,
			historicalMarkersHeightmap,
			alphaMap,
			mapShadow,
		} = this.props;

		this.currentHeightmapIndex = DEFAULT_DATASET_INDEX;
		this.mesh = new Group();
		this.visible = true;

		// const b = [];
		// const p = [];
		// const h = [];

		// cityData?.forEach((d) => {
		// 	const { b_o_businesses, b_pop, historical_sites } = d.properties;
		// 	b.push(b_o_businesses);
		// 	p.push(b_pop);
		// 	h.push(historical_sites);
		// });

		const dataHeightmap0 = usaHeightmap;
		const dataHeightmap1 = blackOwnedBusinessesHeightmap;
		const dataHeightmap2 = blackPopHeightmap;
		const dataHeightmap3 = historicalMarkersHeightmap;
		const dataHeightmap4 = genPopHeightmap;

		// TODO: use these to output new static heightmaps to load, then disable again.
		// const dataHeightmap1 = this.getHeightmap(meshElevationProps.businesses, cityData, 'b_o_businesses', Math.max(...b));
		// const dataHeightmap3 = this.getHeightmap(meshElevationProps.historical, cityData, 'historical_sites', Math.max(...h));

		this.resolution = this.getTerrainResolution();

		const gradientTex = textureLoader.load(gradientSrc);
		gradientTex.minFilter = gradientTex.magFilter = LinearFilter;

		const tileW = width / tilesX;
		const tileH = height / tilesY;

		this.mapShadow = new MapShadow({
			alphaMap: mapShadow,
			color: 0x000000,
			opacity: 0.4,
		});
		// line up with terrain
		this.mapShadow.mesh.position.set(width / 2 - tileW / 2, height / 2 - tileH / 2, -0.01);

		this.mapFill = new MapFill({
			alphaMap,
			gradientTex,
		});

		// line up with terrain
		this.mapFill.mesh.position.set(width / 2 - tileW / 2, height / 2 - tileH / 2, -0.01);
		this.mesh.add(this.mapFill.mesh);

		this.terrainGroup = this.getTerrain(
			[dataHeightmap0, dataHeightmap1, dataHeightmap2, dataHeightmap3, dataHeightmap4],
			meshElevationProps.usa,
			this.resolution,
			gradientTex
		);
		this.mesh.add(this.terrainGroup);

		this.timeUpdatesEnabled = true;
		this.durationScalar = 1;

		// lods (level of detail) for various zoom levels. transitions between different heightmaps and animates the terrain.
		this.heightmaps = [
			{
				heightmap: dataHeightmap0,
				maxHeight: meshElevationProps.usa.maxHeight,
				dataHeightScalar: meshElevationProps.usa.dataHeightScalar,
				zPosPower: meshElevationProps.usa.zPosPower,
				noiseScalar: 1,
			},
			{
				heightmap: dataHeightmap1,
				maxHeight: meshElevationProps.businesses.maxHeight,
				dataHeightScalar: meshElevationProps.businesses.dataHeightScalar,
				zPosPower: meshElevationProps.businesses.zPosPower,
				noiseScalar: 1,
			},
			{
				heightmap: dataHeightmap2,
				maxHeight: meshElevationProps.population.maxHeight,
				dataHeightScalar: meshElevationProps.population.dataHeightScalar,
				zPosPower: meshElevationProps.population.zPosPower,
				noiseScalar: 1,
			},
			{
				heightmap: dataHeightmap3,
				maxHeight: meshElevationProps.historical.maxHeight,
				dataHeightScalar: meshElevationProps.historical.dataHeightScalar,
				zPosPower: meshElevationProps.historical.zPosPower,
				noiseScalar: 1,
			},
			{
				heightmap: dataHeightmap4,
				maxHeight: meshElevationProps.totalPopulation.maxHeight,
				dataHeightScalar: meshElevationProps.totalPopulation.dataHeightScalar,
				zPosPower: meshElevationProps.totalPopulation.zPosPower,
				noiseScalar: 1,
			},
		];

		this.uniformWeights = [
			'displacementWeight1',
			'displacementWeight2',
			'displacementWeight3',
			'displacementWeight4',
			'displacementWeight5',
		];

		if (IS_DEVELOPMENT || FORCE_GLOBAL_REFS) {
			window._.terrain = this;
		}
	}

	getTerrainResolution() {
		const { meshResolutions } = ELEVATION_GEOMETRY_CONFIG;

		const gm = getGraphicsMode();
		let res = 'low';

		if (gm === 'high') {
			res = 'medium';
		}

		Debug.log('terrain resolution:', res);

		return meshResolutions[res];
	}

	// creates heightmap to displace the terrain mesh
	getHeightmap(elevationProps, data, key, maxValue) {
		const { alphaMap, tb, topLeft } = this.props;
		const { minRadius, maxRadius, blurAmount, outputRender } = elevationProps;
		const { numElevationSteps } = ELEVATION_GEOMETRY_CONFIG;

		const heightmap = getHeightmap({
			data,
			key,
			maxValue,
			topLeft,
			tb,
			maskImage: alphaMap.image,
			elevationSteps: numElevationSteps,
			minRadius,
			maxRadius,
			blurAmount,
			outputRender,
		});

		return heightmap;
	}

	// creates terrain tiles (tiles offscreen won't be rendered therefore improving performance)
	getTerrain(heightmaps, elevationProps, meshResolution, gradientTex) {
		const { alphaMap, vertAlphaMap } = this.props;
		const { maxHeight, zPosPower } = elevationProps;
		const { width, height, tilesX, tilesY } = ELEVATION_GEOMETRY_CONFIG;
		const tileW = width / tilesX;
		const tileH = height / tilesY;
		const tileContainer = new Group();
		const baseGeometry = new PlaneBufferGeometry(
			tileW,
			tileH,
			(meshResolution[0] / tilesX) | 0,
			(meshResolution[1] / tilesY) | 0
		);

		this.terrainMaterials = [];
		// this.particleMaterials = [];
		this.terrainMeshes = [];

		const xInc = 1 / tilesX;
		const yInc = 1 / tilesY;
		// don't create these tiles since they don't cover the map
		const omit = [0, 1, 3, 7, 8, 10, 11, 15, 16, 20, 21, 22, 23, 28, 29, 30, 31];
		// for positioning only, need something in top-left anchor position
		const dummies = [24];
		let index = 0;
		for (let y = 0; y < 1; y += yInc) {
			for (let x = 0; x < 1; x += xInc) {
				if (!omit.includes(index)) {
					if (dummies.includes(index)) {
						const dummy = new Mesh(
							new PlaneBufferGeometry(tileW, tileH),
							new MeshBasicMaterial({
								color: 0xff0000,
								visible: false,
							})
						);
						dummy.position.set(width * x, height * y, 0);
						tileContainer.add(dummy);
					} else {
						const tileMesh = this.getTerrainTile({
							x,
							y,
							baseGeometry,
							alphaMap,
							vertAlphaMap,
							gradientTex,
							heightmaps,
							maxHeight,
							zPosPower,
							elevationProps,
						});
						tileContainer.add(tileMesh);
					}
				}
				index++;
			}
		}

		this.bounds = new Box3().setFromObject(tileContainer);

		return tileContainer;
	}

	// creates a single terrain tile
	getTerrainTile(props) {
		const { x, y, baseGeometry, alphaMap, vertAlphaMap, gradientTex, heightmaps, maxHeight, zPosPower, elevationProps } =
			props;
		const { width, height, tilesX, tilesY } = ELEVATION_GEOMETRY_CONFIG;

		const tile = new Group();

		const material = new MeshStandardMaterial({
			// color: new Color(Math.random(), Math.random(), Math.random()).getHex(),
			color: 0xffffff,
			metalness: 0,
			roughness: 0.8,
			flatShading: true,
			displacementMap: heightmaps[0],
			displacementScale: maxHeight,
			alphaMap,
			side: DoubleSide,
		});

		this.terrainMaterials.push(material);

		material.defines.DITHERING = '';

		material.extensions = {
			derivatives: true,
		};

		material.onBeforeCompile = (shader) => {
			const { uniforms } = shader;

			uniforms.tDisplacement1 = { value: heightmaps[0] };
			uniforms.tDisplacement2 = { value: heightmaps[1] };
			uniforms.tDisplacement3 = { value: heightmaps[2] };
			uniforms.tDisplacement4 = { value: heightmaps[3] };
			uniforms.tDisplacement5 = { value: heightmaps[4] };
			uniforms.tVertAlphaMap = { value: vertAlphaMap };
			uniforms.displacementWeight1 = { value: this.currentHeightmapIndex === 0 ? 1 : 0 };
			uniforms.displacementWeight2 = { value: this.currentHeightmapIndex === 1 ? 1 : 0 };
			uniforms.displacementWeight3 = { value: this.currentHeightmapIndex === 2 ? 1 : 0 };
			uniforms.displacementWeight4 = { value: this.currentHeightmapIndex === 3 ? 1 : 0 };
			uniforms.displacementWeight5 = { value: this.currentHeightmapIndex === 4 ? 1 : 0 };
			uniforms.tGradient = { value: gradientTex };
			uniforms.maxHeight = { value: maxHeight };
			uniforms.dataHeightScalar = { value: this.heightmaps[this.currentHeightmapIndex].dataHeightScalar };
			uniforms.zPosPower = { value: zPosPower };
			uniforms.noiseScalar = { value: 1 };
			uniforms.heightScalar = { value: 0 };
			uniforms.globalScalar = { value: 1 };
			uniforms.mask = { value: 1 };
			uniforms.uvOffset = { value: new Vector2(x, y) };
			uniforms.uvScale = { value: new Vector2(1.0 / tilesX, 1.0 / tilesY) };
			uniforms.opacityScalar = { value: 1 };
			uniforms.time = { value: 0 };
			uniforms.textureRepeat = { value: new Vector2(400, 200) };
			uniforms.backgroundColor = { value: new Color(BACKGROUND_COLOR) };

			Object.assign(uniforms, mapProps.depthUniforms);

			shader.vertexShader = terrainVert;
			shader.fragmentShader = terrainFrag;

			this.terrainUniforms = this.terrainUniforms || [];
			this.terrainUniforms.push(uniforms);
		};

		const terrainMesh = new Mesh(baseGeometry, material);
		terrainMesh.position.set(width * x, height * y, 0);
		terrainMesh.frustumCulled = false;
		tile.add(terrainMesh);
		this.terrainMeshes.push(terrainMesh);

		// // create particles for this tile
		// const particles = this.getParticles(
		// 	width * x,
		// 	height * y,
		// 	heightmaps,
		// 	elevationProps,
		// 	baseGeometry.attributes.position
		// );
		// particles.frustumCulled = true;
		// tile.add(particles);

		return tile;
	}

	// // creates particles
	// getParticles(x, y, heightmaps, elevationProps, terrainPositionAttr) {
	// 	// particles
	// 	const { alphaMap } = this.props;
	// 	const { maxHeight } = elevationProps;
	// 	const { width, height } = ELEVATION_GEOMETRY_CONFIG;

	// 	const particleTex = textureLoader.load(particleSrc);
	// 	particleTex.minFilter = particleTex.magFilter = LinearFilter;

	// 	const particleGeometry = new BufferGeometry();
	// 	const particleMaterial = new PointsMaterial({
	// 		color: 0xffffff,
	// 		map: particleTex,
	// 		transparent: true,
	// 		opacity: 0.3,
	// 		sizeAttenuation: true,
	// 		size: 12 / window.devicePixelRatio,
	// 	});

	// 	this.particleMaterials.push(particleMaterial);

	// 	// Debug.log(x, y);

	// 	particleMaterial.onBeforeCompile = (shader) => {
	// 		const { uniforms } = shader;
	// 		uniforms.tDisplacement1 = { value: heightmaps[0] };
	// 		uniforms.tDisplacement2 = { value: heightmaps[1] };
	// 		uniforms.tDisplacement3 = { value: heightmaps[2] };
	// 		uniforms.tDisplacement4 = { value: heightmaps[2] };
	// 		uniforms.displacementWeight1 = { value: 1 };
	// 		uniforms.displacementWeight2 = { value: 0 };
	// 		uniforms.displacementWeight3 = { value: 0 };
	// 		uniforms.displacementWeight4 = { value: 0 };
	// 		uniforms.tAlpha = { value: alphaMap };
	// 		uniforms.maxHeight = { value: maxHeight };
	// 		uniforms.heightScalar = { value: 1 };
	// 		uniforms.mask = { value: 1 };
	// 		uniforms.time = { value: 0 };
	// 		uniforms.uvProps = {
	// 			value: {
	// 				width,
	// 				height,
	// 				minX: this.bounds.min.x,
	// 				minY: this.bounds.min.y,
	// 				maxX: this.bounds.max.x,
	// 				maxY: this.bounds.max.y,
	// 			},
	// 		};

	// 		Object.assign(uniforms, mapProps.depthUniforms);

	// 		shader.vertexShader = terrainParticlesVert;
	// 		shader.fragmentShader = terrainParticlesFrag;

	// 		this.particleUniforms = this.particleUniforms || [];
	// 		this.particleUniforms.push(uniforms);
	// 	};

	// 	this.particleMaterials = this.particleMaterials || [];
	// 	this.particleMaterials.push(particleMaterial);

	// 	const len = terrainPositionAttr.array.length;
	// 	const timeOffsets = [];
	// 	const timeScalars = [];
	// 	const sizeScalars = [];
	// 	const positions = [];
	// 	const density = 2; // 1 === all vertices, higher integers === less particles
	// 	const inc = 3 * density;

	// 	for (let i = 0; i < len; i += inc) {
	// 		timeOffsets.push(randomRange(0, 100));
	// 		timeScalars.push(randomRange(0.25, 1.5));
	// 		sizeScalars.push(randomRange(0.5, 2));

	// 		positions.push(
	// 			x + terrainPositionAttr.array[i + 0],
	// 			y + terrainPositionAttr.array[i + 1],
	// 			0
	// 		);
	// 	}

	// 	// particleGeometry.setAttribute('position', terrainPositionAttr);
	// 	particleGeometry.setAttribute(
	// 		'position',
	// 		new BufferAttribute(new Float32Array(positions), 3)
	// 	);
	// 	particleGeometry.setAttribute(
	// 		'timeOffset',
	// 		new BufferAttribute(new Float32Array(timeOffsets), 1)
	// 	);
	// 	particleGeometry.setAttribute(
	// 		'timeScalar',
	// 		new BufferAttribute(new Float32Array(timeScalars), 1)
	// 	);
	// 	particleGeometry.setAttribute(
	// 		'sizeScalar',
	// 		new BufferAttribute(new Float32Array(sizeScalars), 1)
	// 	);
	// 	const particleMesh = new Points(particleGeometry, particleMaterial);

	// 	return particleMesh;
	// }

	getCurrentHeightmapIndex() {
		return this.currentHeightmapIndex;
	}

	setDurationScalar(scalar = 1) {
		this.durationScalar = scalar;
	}

	setCurrentHeightmapFlattened(heightmapIndex, duration = 1.2, delay = 0) {
		return new Promise((resolve) => {
			if (
				this.terrainUniforms &&
				// this.particleUniforms &&
				this.currentHeightmapIndex !== heightmapIndex
			) {
				this.flatten(true, duration * 0.75, delay, 'power2.inOut').then(() => {
					this.setCurrentHeightmap(heightmapIndex, 0);
					this.flatten(false, duration, 0, 'power2.out');
					resolve();
				});
			} else {
				resolve();
			}
		});
	}

	// sets & transitions between LOD's/heightmaps
	setCurrentHeightmap(heightmapIndex, duration = 1.2, ease = 'power2.inOut') {
		return new Promise((resolve, reject) => {
			if (
				this.terrainUniforms &&
				// this.particleUniforms &&
				this.currentHeightmapIndex !== heightmapIndex
			) {
				this.currentHeightmapIndex = heightmapIndex;

				const { maxHeight, dataHeightScalar, noiseScalar, zPosPower } = this.heightmaps[heightmapIndex];

				const dur = duration / this.durationScalar;

				this.uniformWeights?.forEach((key, index) => {
					const weight = index === heightmapIndex ? 1 : 0;
					const delay = 0;

					this.terrainUniforms?.forEach((tu) => {
						gsap.to(tu[key], {
							value: weight,
							duration: dur,
							ease,
							overwrite: true,
							delay,
						});
					});
					// this.particleUniforms.forEach((pu) => {
					// 	gsap.to(pu[key], {
					// 		value: weight,
					// 		duration: dur,
					// 		ease,
					// 		overwrite: true,
					// 	});
					// });
				});

				this.terrainUniforms.forEach((tu) => {
					// gsap.to(tu.mask, {
					// 	value: 0.5,
					// 	duration: dur,
					// 	ease,
					// 	overwrite: true,
					// 	yoyo: true,
					// 	repeat: 1,
					// });

					gsap.to(tu.maxHeight, {
						value: maxHeight,
						duration: dur,
						ease,
						overwrite: true,
					});

					gsap.to(tu.dataHeightScalar, {
						value: dataHeightScalar,
						duration: dur,
						ease,
						overwrite: true,
					});

					gsap.to(tu.zPosPower, {
						value: zPosPower,
						duration: dur,
						ease,
						overwrite: true,
					});

					gsap.to(tu.noiseScalar, {
						value: noiseScalar,
						duration: dur,
						ease,
						overwrite: true,
					});
				});

				wait(dur * 1000).then(resolve);

				// this.particleUniforms.forEach((pu) => {
				// 	gsap.to(pu.maxHeight, {
				// 		value: maxHeight,
				// 		duration,
				// 		ease,
				// 		overwrite: true,
				// 	});
				// });
			}
		});
	}

	handleZoom(zoom) {}

	show() {
		this.visible = true;
		this.terrainGroup.visible = true;
	}

	hide() {
		this.visible = false;
		this.terrainGroup.visible = false;
	}

	initShadow() {
		this.mapShadow.mesh.position.z -= MAP_SHADOW_DISTANCE;
		this.mesh.add(this.mapShadow.mesh);
	}

	transitionIn(duration = 3, delay = 0) {
		return new Promise((resolve) => {
			const setCulling = () => {
				this.terrainMeshes?.forEach((terrainMesh) => (terrainMesh.frustumCulled = true));
			};

			const len = this.terrainUniforms?.length;
			this.terrainUniforms?.forEach((tu, index) => {
				gsap.to(tu.heightScalar, {
					value: 1,
					duration,
					delay,
					ease: 'power2.inOut',
					onComplete: () => {
						if (index === len - 1) {
							setCulling();
							resolve();
						}
					},
				});
			});
		});

		// this.particleUniforms?.forEach((pu) => {
		// 	gsap.to(pu.heightScalar, {
		// 		value: 1,
		// 		duration,
		// 		delay,
		// 		ease: 'power2.inOut',
		// 	});
		// });
	}

	flatten(flat = true, duration = 1.2, delay = 0, ease = 'power2.inOut') {
		if (this.flattening) {
			clearTimeout(this.flattenTimeout);

			this.terrainUniforms?.forEach((tu) => gsap.killTweensOf(tu.heightScalar));
			// this.terrainUniforms?.forEach((tu) => gsap.killTweensOf(tu.mask));
			// this.particleUniforms?.forEach((pu) => gsap.killTweensOf(pu.mask));
		}

		this.isSemiFlat = flat;
		this.flattening = true;

		return new Promise((resolve) => {
			const value = flat ? 0 : 1;

			const dur = duration / this.durationScalar;

			this.terrainUniforms?.forEach((tu) => {
				// gsap.to(tu.mask, {
				gsap.to(tu.heightScalar, {
					value,
					duration: dur,
					delay,
					ease,
					overwrite: true,
				});
			});

			// this.particleUniforms?.forEach((pu) => {
			// 	gsap.to(pu.mask, {
			// 		value,
			// 		duration,
			// 		delay,
			// 		ease,
			// 		overwrite: true,
			// 	});
			// });

			this.flattenTimeout = setTimeout(() => {
				this.flattening = false;
				resolve();
			}, dur * 1000);
		});
	}

	flattenCompletely(flatten = true, duration = 1.2, delay = 0, ease = 'power2.inOut') {
		const value = flatten ? 0 : 1;
		this.isFlat = flatten;
		this.timeUpdatesEnabled = !this.isFlat;

		const dur = duration / this.durationScalar;

		this.terrainUniforms?.forEach((tu) => {
			gsap.to(tu.globalScalar, {
				value,
				duration: dur,
				delay,
				ease,
				overwrite: true,
			});
		});
	}

	update(delta = 0.01) {
		if (!this.visible) return;
		// update shader time for animations
		if (this.timeUpdatesEnabled) {
			const timeVal = this.terrainUniforms[0].time.value + delta * 0.75;
			this.terrainUniforms?.forEach((pu) => (pu.time.value = timeVal));
		}
		// this.particleUniforms?.forEach((pu) => (pu.time.value = timeVal));
	}

	dispose() {
		this.mapFill?.dispose();
		deleteAllProperties(this);
	}
}
