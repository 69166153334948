import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { EASE_OUT } from 'js/utils/motion';

const containerMotion = {
  initial: { 
    opacity: 0 
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      staggerChildren: 0.2,
      delayChildren: 0.3,
      ease: EASE_OUT,
    }
  },
  exit: { 
    opacity: 0 
  }
};

const childMotion = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 }
};

function AboutParagraphs(props) {
  const { content, animOnce }  = props;
  const anim = useAnimation();
  const [ref, inView] = useInView({
    rootMargin: '-150px 0px',
    triggerOnce: animOnce
  });

  useEffect(() => {
    if (inView) {
      anim.start('animate');
    } else {
      anim.start('exit');
    }
  }, [anim, inView]);

  return (
    <motion.div
      variants={containerMotion}
      initial="initial"
      animate={anim}
      ref={ref}
    >
      { content.map((d,i) => (
        <motion.p variants={childMotion} key={i} dangerouslySetInnerHTML={{__html:d}} />
      ))}
    </motion.div>
  )
}

export default AboutParagraphs;