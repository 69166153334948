/* eslint-disable import/no-anonymous-default-export */

// Use this exported data structure to build links.
export default {
	home: { path: '/', exact: true },
	intro: { path: '/intro' },
	location: { path: '/location/:id' },
	poi: { path: '/poi/:name/:lng/:lat/:address', exact: true },
	about: { path: '/about' },
	hamburgerMenu: { path: '/menu' },
	cityGuides: { path: '/city-guides' },
	addToMap: { path: '/add-to-map' },
	savedLocations: { path: '/saved-locations' },
	privacy: { path: '/privacy' },
	terms: { path: '/terms' },
	search: { path: '/search' },
	cityGuideLanding: { path: '/city-guide-landing/:id' },
	cityGuide: { path: '/city-guide/:id' },
	standardCity: { path: '/city/:city/:state' },
	standardCityNoState: { path: '/city/:city' },
	nationalGuide: { path: '/national-guide/:id' },
	nationalGuides: { path: '/national-guides' },
	socialNational: { path: '/travel-feed' },
	landing: { path: '/landing' },
	userLocation: { path: '/user-location/:lon/:lat' },
	// socialState: { path: '/social-state/:id' },
	notFound: { path: '/404' },
};
