import { POI_CATEGORIES } from './constants';

// map textures
import usaAlpha4k from '../../images/map/usa-alpha-4k.png';
import usaAlpha2k from '../../images/map/usa-alpha-2k.png';
import usaVertAlpha4k from '../../images/map/usa-vert-alpha-4k.png';
import usaVertAlpha2k from '../../images/map/usa-vert-alpha-2k.png';
import usaHeightmap2k from '../../images/map/usa-heightmap-2k.png';
import usaHeightmap1k from '../../images/map/usa-heightmap-1k.png';
import genPopulationHeightmap2k from '../../images/map/gen-pop-density-2k.png';
import genPopulationHeightmap1k from '../../images/map/gen-pop-density-1k.png';
import blackPopulationHeightmap2k from '../../images/map/black-pop-density-2k.png';
import blackPopulationHeightmap1k from '../../images/map/black-pop-density-1k.png';
import blackOwnedBusinessesHeightmap2k from '../../images/map/black-owned-businesses-2k.png';
import blackOwnedBusinessesHeightmap1k from '../../images/map/black-owned-businesses-1k.png';
import historicalMarkersHeightmap2k from '../../images/map/historical-markers-2k.png';
import historicalMarkersHeightmap1k from '../../images/map/historical-markers-1k.png';
import mapShadow4k from '../../images/map/map-shadow-4k.png';
import mapShadow2k from '../../images/map/map-shadow-2k.png';

// white icons
import beautyWhite from '../../images/icons/white/beauty.svg';
import curatedWhite from '../../images/icons/white/curated.svg';
import eatWhite from '../../images/icons/white/eat.svg';
import entertainmentWhite from '../../images/icons/white/entertainment.svg';
import favouriteWhite from '../../images/icons/white/favourite.svg';
import flexWhite from '../../images/icons/white/flex.svg';
import learnWhite from '../../images/icons/white/learn.svg';
import seeWhite from '../../images/icons/white/see.svg';
import shopWhite from '../../images/icons/white/shop.svg';
import stayWhite from '../../images/icons/white/stay.svg';
import generalPoiWhite from '../../images/icons/white/general-poi.svg';

// black icons
import beautyBlack from '../../images/icons/black/beauty.svg';
import curatedBlack from '../../images/icons/black/curated.svg';
import eatBlack from '../../images/icons/black/eat.svg';
import entertainmentBlack from '../../images/icons/black/entertainment.svg';
import favouriteBlack from '../../images/icons/black/favourite.svg';
import flexBlack from '../../images/icons/black/flex.svg';
import learnBlack from '../../images/icons/black/learn.svg';
import seeBlack from '../../images/icons/black/see.svg';
import shopBlack from '../../images/icons/black/shop.svg';
import stayBlack from '../../images/icons/black/stay.svg';
import generalPoiBlack from '../../images/icons/black/general-poi.svg';

export const manifests = {
	desktop: [
		{ url: usaAlpha4k, id: 'alphaMap' },
		{ url: usaVertAlpha4k, id: 'vertAlphaMap' },
		{ url: usaHeightmap2k, id: 'usaHeightmap' },
		{ url: genPopulationHeightmap2k, id: 'genPopHeightmap' },
		{ url: blackPopulationHeightmap2k, id: 'blackPopHeightmap' },
		{ url: blackOwnedBusinessesHeightmap2k, id: 'blackOwnedBusinessesHeightmap' },
		{ url: historicalMarkersHeightmap2k, id: 'historicalMarkersHeightmap' },
		{ url: mapShadow4k, id: 'mapShadow' },
	],
	mobile: [
		{ url: usaAlpha2k, id: 'alphaMap' },
		{ url: usaVertAlpha2k, id: 'vertAlphaMap' },
		{ url: usaHeightmap1k, id: 'usaHeightmap' },
		{ url: genPopulationHeightmap1k, id: 'genPopHeightmap' },
		{ url: blackPopulationHeightmap1k, id: 'blackPopHeightmap' },
		{ url: blackOwnedBusinessesHeightmap1k, id: 'blackOwnedBusinessesHeightmap' },
		{ url: historicalMarkersHeightmap1k, id: 'historicalMarkersHeightmap' },
		{ url: mapShadow2k, id: 'mapShadow' },
	],
};

export const locationIcons = {
	white: {
		[POI_CATEGORIES.CURATED]: curatedWhite,
		[POI_CATEGORIES.FAVORITES]: favouriteWhite,
		[POI_CATEGORIES.BEAUTY]: beautyWhite,
		[POI_CATEGORIES.EAT]: eatWhite,
		[POI_CATEGORIES.ENTERTAINMENT]: entertainmentWhite,
		[POI_CATEGORIES.FLEX]: flexWhite,
		[POI_CATEGORIES.LEARN]: learnWhite,
		[POI_CATEGORIES.KNOW]: learnWhite,
		[POI_CATEGORIES.SEE]: seeWhite,
		[POI_CATEGORIES.SHOP]: shopWhite,
		[POI_CATEGORIES.STAY]: stayWhite,
		[POI_CATEGORIES.GENERAL_POI]: generalPoiWhite,
	},
	black: {
		[POI_CATEGORIES.CURATED]: curatedBlack,
		[POI_CATEGORIES.FAVORITES]: favouriteBlack,
		[POI_CATEGORIES.BEAUTY]: beautyBlack,
		[POI_CATEGORIES.EAT]: eatBlack,
		[POI_CATEGORIES.ENTERTAINMENT]: entertainmentBlack,
		[POI_CATEGORIES.FLEX]: flexBlack,
		[POI_CATEGORIES.LEARN]: learnBlack,
		[POI_CATEGORIES.KNOW]: learnBlack,
		[POI_CATEGORIES.SEE]: seeBlack,
		[POI_CATEGORIES.SHOP]: shopBlack,
		[POI_CATEGORIES.STAY]: stayBlack,
		[POI_CATEGORIES.GENERAL_POI]: generalPoiBlack,
	},
};
