import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import MapWrapper from 'js/components/_core/MapWrapper';
import AppRoutes from 'js/components/Router';
import MuiThemeProvider from 'js/MuiThemeProvider';
import { useAppState } from 'js/hooks/app-state';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { AnimatePresence } from 'framer-motion';
import CookieConsent from './CookieConsent/CookieConsent';
import Intro from './Intro/Intro';
import { IS_PHONE } from 'js/config/constants';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 15 * 60 * 1000,
		},
	},
});

const App = () => {
	const [{ routesEnabled, cookieConsented, showIntro }, { setCookieConsented }] = useAppState();
	return (
		<QueryClientProvider client={queryClient}>
			<HelmetProvider>
				<MuiThemeProvider>
					<BrowserRouter>
						{showIntro && <Intro />}
						<MapWrapper />
						<AppRoutes />
						<AnimatePresence>{(IS_PHONE || (!IS_PHONE && routesEnabled)) && !cookieConsented && <CookieConsent setCookieConsented={setCookieConsented} />}</AnimatePresence>
					</BrowserRouter>
				</MuiThemeProvider>
				{/* <ReactQueryDevtools initialIsOpen={false} /> */}
			</HelmetProvider>
		</QueryClientProvider>
	);
};

export default App;
