/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from 'framer-motion';
import { useAppState } from 'js/hooks/app-state';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { getStateName } from 'js/utils/map-data-utils';
import { COMMON_DURATION, EASE_IN_OUT, EASE_OUT, FADE_DURATION, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION, STAGGER_DELAY } from 'js/utils/motion';
import React, { useEffect, useRef, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router';
import NextArrows from '../NextArrows/NextArrows';
import routes from '../Router/routes';
import Image from '../Image/Image';
import CityStats from '../CityStats/CityStats';
import { IS_PHONE, IS_TABLET } from 'js/config/constants';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import Video from '../Video/Video';
import { mapProps } from '../Map/Map';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './CityLanding.scss';

const delay = 0.9;

const maskMotionProps = {
	initial: {
		height: '0%',
	},
	animate: {
		height: '100%',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
	exit: {
		height: '0%',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
};

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transition: { duration: FADE_DURATION, ease: EASE_OUT },
	},
};

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY * 2 },
	exitTransProps: { delay: 0.2 },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY * 3 },
	exitTransProps: { delay: 0.1 },
});

const motionProps3 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY * 4 },
	exitTransProps: { delay: 0 },
});

function CityLanding() {
	const [cityGuideData, setCityGuideData] = useState(null);
	const [cityStats, setCityStats] = useState(null);
	const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
	const [{ guideData, cityData }] = useAppState();
	const params = useParams();
	const imageMaskContainerRef = useRef(null);
	const history = useHistory();

	const { id: guideId } = params;

	useEffect(() => {
		if (guideData && guideId) {
			const cityGuide = guideData.filter((dataItem) => dataItem?.city !== undefined && dataItem?.id === guideId)[0];

			if (cityGuide) {
				setCityGuideData(cityGuide);
			} else {
				history.push(routes.notFound.path);
			}
			// Debug.log('cityGuide:', cityGuide);
		}
	}, [guideData, guideId]);

	useEffect(() => {
		if (cityGuideData) {
			// TODO: grab stats for city

			const {
				city: { cityName, cityState },
			} = cityGuideData;

			const stateName = getStateName(cityState);
			const result = cityData.filter(({ properties: { city, state } }) => city === cityName && state === stateName)[0];
			setCityStats(result);
			// Debug.log('stats:', cityName, stateName, result);
		}
	}, [cityGuideData, cityData]);

	useEffect(() => {
		ElementColorSwapper.instance?.setForceDark(true);

		const handleResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		return () => {
			ElementColorSwapper.instance?.setForceDark(false);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	if (!cityGuideData) return null;

	const {
		title,
		coverImage,
		coverVideo,
		city: { cityName, cityState },
		curatedPois,
	} = cityGuideData;

	const poiId = curatedPois.length ? curatedPois[0].id : null;

	const nextArrowProps = IS_PHONE
		? {
				width: 10,
				height: 14,
		  }
		: null;

	const closeButtonProps = IS_PHONE
		? {
				right: -30,
				top: 30,
		  }
		: IS_TABLET
		? {
				right: -40,
				top: 35,
		  }
		: { right: -40, top: 52 };

	return (
		<>
			<HelmetWrapper>
				<title>{`Black Elevation Map | City Guides - ${cityName}`}</title>
				<meta name="description" content={`Explore The Culture in ${cityName}`} />
			</HelmetWrapper>
			<motion.div className="CityLanding">
				<motion.div
					variants={MOTION_VARIANTS}
					initial="initial"
					animate="animate"
					exit="exit"
					custom={maskMotionProps}
					className="image-mask-container"
					ref={imageMaskContainerRef}
					onAnimationStart={() => mapProps?.layers?.elevation?.resume()}
					onAnimationComplete={(definition) => {
						if (definition === 'animate') {
							mapProps?.layers?.elevation?.pause();
						}
					}}
				>
					<div className="image-container">
						{!coverVideo?.url && coverImage?.url && <Image src={coverImage.url} alt={cityName} />}
						{coverVideo?.url && <Video src={coverVideo.url} />}
					</div>
				</motion.div>
				<motion.div className="content-container">
					{cityStats && <CityStats delay={delay} cityStats={cityStats} />}

					<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="location-name">
						{cityName}, {cityState}
						<CloseButtonX lightTheme={true} style={closeButtonProps} small={IS_PHONE} />
					</motion.div>

					<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="title">
						{title}
					</motion.div>

					<motion.div
						variants={MOTION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={motionProps3}
						className="start-btn"
						onClick={() => {
							imageMaskContainerRef.current.style.height = 0;
							history.push({
								pathname: generatePath(routes.cityGuide.path, { id: guideId }),
								search: `?poiId=${poiId}`,
							});
						}}
					>
						<div className="label">GO</div>
						<NextArrows {...nextArrowProps} />
					</motion.div>
				</motion.div>
			</motion.div>
		</>
	);
}

export default CityLanding;
