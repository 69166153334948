import classNames from 'classnames';
import { TOOL_TIPS } from 'js/config/constants';
import { useAppState } from 'js/hooks/app-state';
import React, { useEffect, useState } from 'react';
import { mapProps } from '../Map/Map';
import ToolTip from '../ToolTip/ToolTip';

import './SaveLocationButton.scss';

function SaveLocationButton(props) {
	const { id, isRelative, theme, size, type = '' } = props;
	const [{ savedLocations }, { setSavedLocations }] = useAppState();
	const [isSaved, setIsSaved] = useState(savedLocations?.includes(id));

	useEffect(() => {
		if (savedLocations) {
			setIsSaved(savedLocations.includes(id));
		}
	}, [savedLocations, id, setIsSaved]);

	const className = classNames({
		SaveLocationButton: true,
		saved: isSaved,
		relative: isRelative,
		large: size === 'large',
		'dark-theme': theme === 'dark',
		[type]: type?.length > 0,
	});

	const toggleSaved = (e) => {
		e.stopPropagation();
		e.preventDefault();

		const { markerController } = mapProps;
		const isSaved = savedLocations.includes(id);

		if (isSaved) {
			// toggle off
			const savedSet = new Set(savedLocations);
			savedSet.delete(id);
			setSavedLocations([...savedSet]);
			markerController?.setMarkerAsSaved(id, false);
		} else {
			// toggle on
			const savedSet = new Set(savedLocations);
			savedSet.add(id.toString());
			setSavedLocations([...savedSet]);
			markerController?.setMarkerAsSaved(id, true);
		}
	};

	const iconClass = classNames({
		icon: true,
		[type]: type?.length > 0,
	});

	const iconSavedClass = classNames({
		'icon-saved': true,
		[type]: type?.length > 0,
	});

	return (
		<div className={className} onClick={toggleSaved}>
			<div className={iconClass} />
			<div className={iconSavedClass} />
			<ToolTip className="save-location-tooltip" label={isSaved ? TOOL_TIPS.REMOVE : TOOL_TIPS.SAVE} />
		</div>
	);
}

export default SaveLocationButton;
