import CameraController from 'js/controllers/camera-controller';
import { createElement, deleteAllProperties } from 'js/utils/utils';
import { Marker } from 'mapbox-gl';

import clusterIconSrc from '../../images/icons/cluster-icon.svg';
import clusterOuterIconSrc from '../../images/icons/cluster-outer-icon.svg';

// marker for clusters
export default class ClusterMarker extends Marker {
	constructor(feature, supercluster, dataLength) {
		super({ element: undefined, anchor: 'center', offset: [0, 0] });
		this._element = this.getHTMLElement(feature, supercluster);
		this.setLngLat(feature.geometry.coordinates);
	}

	getHTMLElement(feature, supercluster) {
		const el = createElement(
			'div',
			['cluster-marker'],
			`<div class="cluster-marker-bg"></div>
			<div class="cluster-marker-icon">
				<img src="${clusterIconSrc}" alt="${feature.properties.point_count}" />
			</div>
			<div class="cluster-marker-outer-icon">
				<img src="${clusterOuterIconSrc}" alt="${feature.properties.point_count}" />
			</div>
			`
		);

		el.onclick = (e) => {
			e.stopPropagation();

			const extraZoom = 1;

			// zoom in further to reduce number of clicks needed to drill down
			CameraController.instance.flyTo(feature.geometry.coordinates, {
				zoom: supercluster.getClusterExpansionZoom(feature.properties.cluster_id) + extraZoom,
			});
		};

		return el;
	}

	getId() {
		return null;
	}

	dispose() {
		this.remove();
		deleteAllProperties(this);
	}
}
