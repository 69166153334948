import { BACKGROUND_COLOR, ELEVATION_GEOMETRY_CONFIG } from 'js/config/constants';
import { deleteAllProperties } from 'js/utils/utils';
import { Color, Mesh, MeshStandardMaterial, PlaneBufferGeometry } from 'three/build/three.module';

import frag from '../../glsl/map-fill.frag';

export default class MapFill {
	constructor(props) {
		this.props = props;
		this.init();
	}

	init() {
		const { width, height } = ELEVATION_GEOMETRY_CONFIG;
		const { alphaMap, gradientTex, color = 0xffffff } = this.props;

		const geometry = new PlaneBufferGeometry(width, height);

		const material = new MeshStandardMaterial({
			color,
			metalness: 0,
			roughness: 0.8,
			flatShading: true,
		});

		material.onBeforeCompile = (shader) => {
			shader.uniforms.tGradient = { value: gradientTex };
			shader.uniforms.tMask = { value: alphaMap };
			shader.uniforms.backgroundColor = { value: new Color(BACKGROUND_COLOR) };

			shader.fragmentShader = frag;
		};

		material.defines = material.defines || {};
		material.defines.USE_UV = 1;

		this.mesh = new Mesh(geometry, material);
	}

	dispose() {
		deleteAllProperties(this);
	}
}
