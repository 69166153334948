/* eslint-disable react-hooks/exhaustive-deps */
import { IS_PHONE, ZOOM_LEVELS } from 'js/config/constants';
import CameraController from 'js/controllers/camera-controller';
import { useAppState } from 'js/hooks/app-state';
import useQueryParams from 'js/hooks/use-query-params';
import { Debug } from 'js/utils/debug';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams, generatePath } from 'react-router-dom';
import ContentDrawer, { CONTENT_TYPES } from '../ContentDrawer/ContentDrawer';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';
import { mapProps } from '../Map/Map';
import routes from '../Router/routes';

function CityGuide() {
	const [{ guideData }, { setState }] = useAppState(); // all guides data
	const [data, setData] = useState(null); // current guide data item
	const [curatedPois, setCuratedPois] = useState([]);
	const [curatedPeople, setCuratedPeople] = useState([]);
	const [curatedPoiViewedStates, setCuratedPoiViewedStates] = useState(null);
	const [canRenderOtherGuides, setCanRenderOtherGuides] = useState(false);
	const [poiIndex, setPoiIndex] = useState(0);
	const [personIndex, setPersonIndex] = useState(0);
	const [goToOnClose, setGoToOnClose] = useState(null);
	const history = useHistory();
	const params = useParams();
	const query = useQueryParams();
	const contentRef = useRef(null);

	const { markerController } = mapProps;
	const { id: guideId } = params;
	const poiId = query.get('poiId');
	const personId = query.get('personId');

	useEffect(() => {
		const guideDataItem = getGuideDataItem(guideData, guideId);

		// if bad guide id was passed in go back home
		if (guideId && guideData && !guideDataItem) {
			history.push(routes.notFound.path);
		}

		const pois = getCuratedPoiIds(guideDataItem);
		setCuratedPois(pois);

		const poiViewedStates = {};
		pois.forEach((id) => {
			poiViewedStates[id] = false;
		});
		poiViewedStates[poiId] = true;
		setCuratedPoiViewedStates(poiViewedStates);

		setCuratedPeople(getCuratedPeopleIds(guideDataItem));
		setData(guideDataItem);
		setState({ activeGuideId: guideId });
	}, [guideId, guideData]);

	useEffect(() => {
		if (curatedPoiViewedStates && poiId) {
			curatedPoiViewedStates[poiId] = true;

			let result = true;
			Object.values(curatedPoiViewedStates).forEach((viewed) => {
				if (!viewed) result = false;
			});

			if (result) {
				setCanRenderOtherGuides(true);
			}
		}
	}, [poiId, curatedPoiViewedStates]);

	// curated poi's
	useEffect(() => {
		if (!curatedPois?.length || !poiId) return;

		const poiData = getCuratedPoiData(data, poiId);
		setPoiIndex(curatedPois.indexOf(poiId));

		if (!poiData) {
			Debug.warn('incorrect poiId passed in:', poiId);
			history.push(routes.notFound.path);
			return;
		}

		const {
			id,
			location: { lon, lat },
		} = poiData;

		markerController?.setMarkerActive(id);

		// calculate map view offset
		const contentWidth = contentRef.current?.getBoundingClientRect()?.width;
		const windowWidth = window.innerWidth;
		const offsetX = windowWidth * 0.5 - (windowWidth - contentWidth) * 0.5;

		const options = {
			offset: IS_PHONE ? [0, 0] : [-offsetX, 0],
			zoom: ZOOM_LEVELS.STREET,
		};

		CameraController.instance?.flyTo([lon, lat], options);
		if (!IS_PHONE) setGoToOnClose([lon, lat]);

		// for use in the CurrentLocation component
		const {
			city: { cityName, cityState },
		} = data;
		setState({ currentLocationOverride: { city: cityName, state: cityState } });

		return () => {
			setState({ currentLocationOverride: null });
		};
	}, [data, poiId]);

	// curated people
	useEffect(() => {
		if (!curatedPeople?.length || !personId) return;

		const personData = getCuratedPersonData(data, personId);
		setPersonIndex(curatedPeople.indexOf(personId));

		if (!personData) {
			Debug.warn('incorrect personId passed in');
			history.push(routes.home.path);
			return;
		}

		const {
			location: { lon, lat },
		} = personData;

		// calculate map view offset
		const contentWidth = contentRef.current?.getBoundingClientRect()?.width;
		const windowWidth = window.innerWidth;
		const offsetX = windowWidth * 0.5 - (windowWidth - contentWidth) * 0.5;

		const options = {
			offset: IS_PHONE ? [0, 0] : [-offsetX, 0],
			zoom: ZOOM_LEVELS.STREET,
		};

		CameraController.instance?.flyTo([lon, lat], options);
		if (!IS_PHONE) setGoToOnClose([lon, lat]);
	}, [data, personId]);

	const handleBack = () => {
		if (!data) return;

		// determine what the user is currently looking at, poi or person.
		const dataset = poiId ? curatedPois : personId ? curatedPeople : null;
		const id = poiId ? poiId : personId ? personId : null;
		const currentIndex = dataset.indexOf(id);

		if (currentIndex > -1) {
			const newIndex = currentIndex > 0 ? currentIndex - 1 : dataset.length - 1;
			const newId = dataset[newIndex];

			if (poiId) {
				setPoiIndex(newIndex);
				history.push({ pathname: generatePath(routes.cityGuide.path, { id: guideId }), search: `?poiId=${newId}` });
			} else if (personId) {
				setPersonIndex(newIndex);
				history.push({ pathname: generatePath(routes.cityGuide.path, { id: guideId }), search: `?personId=${newId}` });
			}
		}
	};

	const handleNext = () => {
		if (!data) return;

		// determine what the user is currently looking at, poi or person.
		const dataset = poiId ? curatedPois : personId ? curatedPeople : null;
		const id = poiId ? poiId : personId ? personId : null;
		const currentIndex = dataset.indexOf(id);

		if (currentIndex > -1) {
			const newIndex = currentIndex < dataset.length - 1 ? currentIndex + 1 : 0;
			const newId = dataset[newIndex];

			if (poiId) {
				setPoiIndex(newIndex);
				history.push({ pathname: generatePath(routes.cityGuide.path, { id: guideId }), search: `?poiId=${newId}` });
			} else if (personId) {
				setPersonIndex(newIndex);
				history.push({ pathname: generatePath(routes.cityGuide.path, { id: guideId }), search: `?personId=${newId}` });
			}
		}
	};

	return (
		<>
			<HelmetWrapper>
				<title>{`Black Elevation Map | City Guides - ${data?.city?.cityName}`}</title>
				<meta
					name="description"
					content="Explore a new map of America - with curated guides, 30,000 points of interest and the millions who make it rise."
				/>
			</HelmetWrapper>
			<ContentDrawer
				contentType={CONTENT_TYPES.CITY_GUIDE}
				content={data}
				poiIndex={poiIndex}
				poiId={poiId}
				personIndex={personIndex}
				personId={personId}
				onBack={handleBack}
				onNext={handleNext}
				reference={contentRef}
				canRenderOtherGuides={canRenderOtherGuides}
				goToOnClose={goToOnClose}
			/>
		</>
	);
}

function getGuideDataItem(guideData, guideId) {
	const guideDataArray = Object.values(guideData);
	const result = guideDataArray?.filter(({ id }) => id === guideId)[0];
	return result;
}

function getCuratedPoiData(guideDataItem, poiId) {
	const result = guideDataItem?.curatedPois?.filter(({ id }) => id === poiId)[0];
	return result;
}

function getCuratedPersonData(guideDataItem, personId) {
	const result = guideDataItem?.curatedPeople?.filter(({ id }) => id === personId)[0];
	return result;
}

function getCuratedPoiIds(guideDataItem) {
	const result = [];
	guideDataItem?.curatedPois?.forEach(({ id }) => result.push(id));
	return result;
}

function getCuratedPeopleIds(guideDataItem) {
	const result = [];
	guideDataItem?.curatedPeople?.forEach(({ id }) => result.push(id));
	return result;
}

export default CityGuide;
