import React from 'react';
import { motion } from 'framer-motion';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import { Link } from 'react-router-dom';
import routes from '../Router/routes';

import './CookieConsent.scss';

function CookieConsent({ setCookieConsented }) {
	return (
		<motion.div className="CookieConsent" variants={MOTION_VARIANTS} initial="initial" animate="animate" custom={MOTION_PRESETS.SLIDE_FROM_BOTTOM} exit="exit">
			<div className="content-wrap">
				<div className="consent-info">
					<p>By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.</p>
					<p>
						For more information, see our <Link to={`${routes.privacy.path}#cookie-policy`}>Cookie Policy</Link>
					</p>
				</div>
				<div className="consent-cta">
					<button className="button-accept" onClick={() => setCookieConsented(true)}>
						Accept
					</button>
				</div>
			</div>
		</motion.div>
	);
}

export default CookieConsent;
