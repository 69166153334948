import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';
import classNames from 'classnames';

import './CityStatCounter.scss';

const valArray = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const ValueColumn = ({ ch }) => {
	const isStandard = valArray.indexOf(ch) >= 0;
	const arr = isStandard ? [...valArray] : ['', ch];
	const targetIndex = isStandard ? valArray.indexOf(ch) : 1;

	return (
		<div className="val-col" data-targ={targetIndex}>
			{arr.map((val, i) => (
				<div key={i}>{val}</div>
			))}
		</div>
	);
};

function CityStatCounter(props) {
	const [animComplete, setAnimComplete] = useState(false);
	const scRef = useRef();
	const { val = '0', animDelay = 0 } = props;

	useEffect(() => {
		if (scRef?.current) {
			const cols = scRef.current.getElementsByClassName('val-col');
			const tline = gsap.timeline({ delay: animDelay, onComplete: () => setAnimComplete(true) });

			for (let i = 0; i < cols.length; i++) {
				const scVal = cols[i].dataset.targ;
				const dur = 0.4 + scVal * 0.05;

				tline.to(cols[i], { y: `-${scVal * 100}%`, opacity: 1, display: 'block', duration: dur, ease: 'power2.out' }, i * 0.05);
			}

			return () => {
				tline?.kill();
			};
		}
	}, [scRef, animDelay, setAnimComplete]);

	return (
		<div className="CityStatCounter" ref={scRef}>
			{[...val].map((ch, i) => {
				const cls = classNames({
					'orig-val': true,
					'anim-complete': animComplete,
				});
				return (
					<span key={i}>
						<span className={cls}>{ch}</span>
						{!animComplete && <ValueColumn ch={ch} />}
					</span>
				);
			})}
		</div>
	);
}

export default CityStatCounter;
