import { IS_PHONE } from 'js/config/constants';
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import ContentFooter from '../ContentFooter/ContentFooter';
import { MARGIN_HORIZONTAL, MARGIN_VERTICAL } from 'js/config/constants';
import ElementColorSwapper from 'js/utils/element-color-swapper';
import { COMMON_DURATION, EASE_IN_OUT, EASE_OUT, MOTION_PRESETS, MOTION_VARIANTS, overrideMotionVariants, SLIDE_DURATION, STAGGER_DELAY } from 'js/utils/motion';
import { mapProps } from '../Map/Map';
import HelmetWrapper from '../HelmetWrapper/HelmetWrapper';

import './Privacy.scss';

const delay = 0.6;

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(100px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: COMMON_DURATION, ease: EASE_OUT },
	},
	exit: {
		opacity: 0,
		transform: 'translateY(50px)',
		transition: { duration: SLIDE_DURATION, ease: EASE_IN_OUT },
	},
};

const motionProps0 = MOTION_PRESETS.FADE;

const motionProps1 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay },
});

const motionProps2 = overrideMotionVariants({
	...elementMotionProps,
	enterTransProps: { delay: delay + STAGGER_DELAY },
});

function Privacy() {
	const containerRef = useRef(null);

	useEffect(() => {
		if (IS_PHONE) {
			ElementColorSwapper.instance?.setForceDark(true);

			return () => {
				ElementColorSwapper.instance?.setForceDark(false);
			};
		}
	}, []);

	useEffect(() => {
		if (containerRef?.current) {
			const checkHash = window.location.hash;
			if (checkHash) {
				const hashElem = containerRef.current.querySelector(checkHash);
				if (hashElem) {
					setTimeout(() => hashElem.scrollIntoView(), SLIDE_DURATION * 1000);
				}
			}
		}
	}, [containerRef]);

	return (
		<>
			<HelmetWrapper>
				<title>Black Elevation Map | Privacy</title>
				<meta name="description" content="Discover an immersive experience that reimagines the American landscape by visualizing the heights of Black Culture." />
			</HelmetWrapper>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className="Privacy HamburgerMenu"
				ref={containerRef}
				onAnimationStart={() => {
					if (IS_PHONE) {
						mapProps?.layers?.elevation?.resume();
					}
				}}
				onAnimationComplete={(definition) => {
					if (IS_PHONE && definition === 'animate') {
						mapProps?.layers?.elevation?.pause();
					}
				}}
			>
				<div className="HamburgerMenu-inner">
					<div className="pad-container">
						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps1} className="title">
							Black & Abroad Privacy Policy
						</motion.div>
						<div className="scroll-container">
							<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps2} className="Privacy-copy">
								<p id="">
									Black &amp; Abroad and its related parties are committed to protecting your Personal Information that you share with us. We want you to understand our Privacy
									Policy and what it means to you, our customer. Please take a moment to review this Privacy Policy. By using our website or the services offered by us, you are
									consenting to our use of such personal information as you provide to us as described herein. If you do not consent to our use of your information, please do not use
									our services. Any questions you have about our Privacy Policy, or the information contained herein, please contact us at:&nbsp;
									<a href="mailto:info@weareblackandabroad.com">info@weareblackandabroad.com</a>
								</p>
								<p>The Privacy Policy applies to Black &amp; Abroad and its related parties:</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Black &amp; Abroad's global group of companies</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Black &amp; Abroad Journeys</p>
								<p>Which are collectively referred to as Black &amp; Abroad throughout this document for ease of reading.</p>
								<p>
									We do our utmost to ensure your personal information is safeguarded and protected, by adhering and acting in accordance with all applicable state and federal law,
									as applicable in the countries and states in which Black &amp; Abroad operates.
								</p>
								<h3>
									<strong>What is considered personal information and why would it be collected?</strong>
								</h3>
								<p>
									Black &amp; Abroad collects personal information only to the extent necessary to deliver the services, products, or information you, or an agent or other passenger
									booking on your behalf, request.
								</p>
								<p>
									Black &amp; Abroad, either directly or through our agency and booking partners collects, holds, uses and discloses, only as necessary the following personal
									information from customers and others in the course of offering services or providing services to you: full name, age, home address, phone number, e-mail address,
									passport and travel medical information, where required, insurance details, where applicable, nationality, residency, nutrition preferences, trip evaluations,
									travel &amp; accommodation preferences, comments you provide us, trip destination information, credit card and/or other payment information, etc. The collection of
									this information is done to allow us to provide services to you as requested.
								</p>
								<p>
									We may also collect personal information, including contact information, if you elect to enter a contest or sweepstakes with us, to subscribe to our newsletter, or
									to request a brochure, which we will use only to provide you with the service you have requested (or other substantially similar services directly related to your
									request).
								</p>
								<p>
									Black &amp; Abroad collects personal information from those individuals applying for employment or similar positions at Black &amp; Abroad to the extent necessary
									to (i) identify the individual and (ii) evaluate their suitability for the position applied for. This information may include name, contact information, educational
									and employment background, criminal record information, residency and/or citizenship information, and other information necessary for us to screen potential
									employees and staff.
								</p>
								<p>
									Black &amp; Abroad collects personal information from agents and agencies to the extent required to allow agents to book Black &amp; Abroad’s travel experiences or
									events, and to be properly credited for such booking, as necessary and as dictated by the relationship between the agent and Black &amp; Abroad. Such information
									may include name, employment relationship or employer information, work or business address, etc. If you are an agent traveling with Black &amp; Abroad, Black &amp;
									Abroad may collect the personal information noted above for other traveling passengers, as well as other employment or eligibility information. This information may
									be collected directly from you, or from your employer (where authorized).
								</p>
								<p>
									Where the collection of certain details of your personal information is not required in order to deliver requested services or products, you may forgo providing
									such details.&nbsp;
								</p>
								<p>
									For example, you may sign up for our electronic marketing and newsletters with only an e-mail address. You may also be entitled, in certain circumstances where
									verification of your identity is not required, to deal with Black &amp; Abroad using a pseudonym. Please ask Black &amp; Abroad to advise if the personal
									information in question is required to complete your request.
								</p>
								<h3>
									<strong>How is my personal information used or disclosed?</strong>
								</h3>
								<p>
									Black &amp; Abroad uses this personal information to provide the services and/or products you have requested, or to provide you with information about those
									services or Black &amp; Abroad. This includes using personal information to: register you to receive newsletters, answer your request for further information about
									our travel experiences or events, make a booking or booking(s), provide you with information about the Company and/or our services, and to receive and implement
									feedback on those services. Furthermore, the personal information allows us to:
								</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Understand your needs as a customer and determine eligibility for our products and services.</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Inform you about our products and services, or those of our partners that we believe may be of interest to you.</p>
								<p>
									&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Provide ongoing service and support, including resolution of any issues with the services or products you request from Black
									&amp; Abroad.
								</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;To ensure the safety and security of our passengers and products.</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Carry out internal accounting and administration of our business.</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Comply with laws, regulations, immigrations requirements, and the like.</p>
								<p>
									Black &amp; Abroad may also use your personal information to book products and services for you or on your behalf from our third party suppliers and service
									providers, or other service providers or merchants for whom we act as agent from time to time. This collection of personal information can be both for our internal
									purposes, for example, to process your booking, and occasionally on behalf of the parties for whom we act as agent, for their internal purposes in providing the
									services or products you have requested. Please be aware that the consent you provide to the collection and use of personal information by Black &amp; Abroad,
									applies equally to the parties whose products and services we sell or book on your behalf. For example, if you purchase a flight from us, you will be deemed to have
									consented to your personal information being provided to the airline to enable your flight to be booked.
								</p>
								<h3>
									<strong>What are the consequences of failing to provide Personal Information?</strong>
								</h3>
								<p>
									If you do not provide the Personal Information that we request, we may not be able to deliver the services you have requested, which may mean you won't be able to
									participate in our travel experiences or events, purchase the items from our store, or subscribe to our newsletter, to name a few. If you have paid a deposit and
									later refuse to provide necessary Personal Information to Black &amp; Abroad which Black &amp; Abroad (or our partners and suppliers) require to deliver the service
									for which the deposit was paid (for example, for a flight which requires passport information), you may not be entitled to a refund for that service. Please contact
									us if you have any questions about providing or not providing Personal Information when booking or otherwise dealing with Black &amp; Abroad. Personal information
									is not collected unless provided to us. This means you can visit and browse our website without having to provide any personal information to Black &amp;
									Abroad.&nbsp;
								</p>
								<h3>
									<strong>How do we collect this information and obtain your consent?&nbsp;</strong>
								</h3>
								<p>
									We collect information from customers during the booking (or option) process, or during the inquiry process where required, via our sales channels, such as our
									website booking form, our Client Concierge (via e-mail or telephone), using medical forms filled out by you or your authorized representative, using your Client
									Concierge profile, through online evaluations submitted by you, via online or other entry ballot for any contests, competitions, or sweepstakes, using brochure or
									other similar request forms, and/or through our travel agency partners and other individuals who make or update bookings on your behalf, or otherwise interact with
									us on your behalf, including during the inquiry process (whether or not the inquiry proceeds to a booking).
								</p>
								<p>This information is provided by you or on your behalf by someone you have authorized to make or inquire about a booking. We obtain consent by:</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Written permission via mail, email or fax, or online booking form.</p>
								<p>&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Verbal permission</p>
								<p>
									&nbsp;&nbsp; &nbsp;•&nbsp;&nbsp; &nbsp;Third party consent (i.e. from your travel agent, or the passenger booking on your behalf to whom you have provided your
									personal information for such purpose).
								</p>
								<h3>
									<strong>How is your Personal Information Protected?</strong>
								</h3>
								<p>
									The security and integrity of your Personal Information is important to us. All personal information is stored in secure databases at secure addresses to prevent
									unauthorized access. Passwords, encryption devices and firewalls protect these databases. Please note however that no data transmission over the Internet can be
									guaranteed to be 100% secure. While we take all steps to ensure the safety of your data, we cannot ensure the security of information you transmit to us over the
									Internet.
								</p>
								<p>
									Access to your personal and private information is limited to: authorized employees, of Black &amp; Abroad, and any of the third-party suppliers who require your
									information to provide the travel or other services you have requested (such as the airlines, transportation services, hotels, activity providers, and tour guides
									who deliver the services you purchase through Black &amp; Abroad or that we arrange on your behalf) . We keep our employees and our third party suppliers and other
									partners informed about our policies and procedures and require strict compliance with this Policy in order to ensure your information remains protected.
									Unauthorized access and/or disclosure of client information are strictly prohibited.
								</p>
								<h3>
									<strong>With whom do we share your personal information?</strong>
								</h3>
								<p>
									<strong>Third Party Partners and Suppliers:</strong>
								</p>
								<p>
									Black &amp; Abroad will share your information with the outside suppliers (such as guides, travel companies, hotel companies, insurance companies, equipment
									suppliers, promotion or sweepstakes co-sponsors, and credit card processing entities etc., all of which may be located outside of your country of residence and
									governed by differing privacy legislation) who perform services on our behalf. As you would expect, certain information must be provided to these suppliers in order
									to facilitate your booking and in order to provide you with service(s) you have requested. These partners and suppliers are located all over the world - you can
									reasonably expect your personal information to be shared with organizations we work with in the countries you are traveling to, and in some cases, to the country of
									the head office of such third party partner supplier.
								</p>
								<p>
									<strong>Affiliated Companies within Black &amp; Abroad:</strong>
								</p>
								<p>
									In order to provide you with superior customer service, and to ensure you benefit from our full range of products and services and as permitted by law, we may share
									your personal information within the Black &amp; Abroad group of companies listed above. Black &amp; Abroad satellite offices are located all over the world - for
									example, and your information may be stored on servers accessible from these international offices. This Privacy Policy applies to our operations globally and each
									affiliated company abides by it in all dealings with your Personal Information.
								</p>
								<p>
									Please be assured that Black &amp; Abroad and its contracted suppliers never sell, lease or trade information about you or your travel information to other parties
									in the ordinary course of conducting its business. Of course, if all of the assets of Black &amp; Abroad or any of the affiliated entities identified in this policy
									were to be sold, your personal information would be among the assets sold - though we commit that any subsequent entity would be bound by this Privacy Policy, or
									one with equal or more protective terms.
								</p>
								<h3>
									<strong>Can I access my information and whom do I contact?</strong>
								</h3>
								<p>You may access, update, and/or correct much of your Personal Information through your account on the Black &amp; Abroad website.</p>
								<p>
									Additionally, upon request in writing to the details below, you may request access to, or the right to view or correct, your personal information, subject to
									certain limitations. If you wish to review or verify the personal information you have provided to us, determine to whom your personal information was disclosed,
									amend or to delete any information, please contact us in writing at the following address, specifying in detail your request (whether to access, amend or delete):
								</p>
								<p>Black &amp; Abroad</p>
								<p>Attn: Privacy</p>
								<p>P.O. Box 361729</p>
								<p>Decatur, GA 30036</p>
								<p>
									E-Mail: <a href="mailto:info@weareblackandabroad.com">info@weareblackandabroad.com</a>
								</p>
								<p>
									Subject to legal restrictions, you can withdraw your consent to our use of your information at any time and with reasonable notice. If you refuse or withdraw your
									consent for any purpose that is required by us to fulfill our product or service contract with you or the Company, we will not be able to provide you, or continue
									to provide you, with the product or service.
								</p>
								<p>
									Please note that if you have booked through one of our agency partners, you will need to contact that booking agent or agency directly regarding the access,
									correction, or disposal of any of your Personal Information which they may hold. You may also wish to review the Privacy Policy of such agency as it may differ from
									that of Black &amp; Abroad.
								</p>
								<p>
									Black &amp; Abroad will only send you brochures, newsletters or other promotional and/or marketing materials if you have opted in to receive them. You can subscribe
									to receive such materials on our website.
								</p>
								<p>
									If you are subscribed to our promotional emails, you can unsubscribe from these at any time using the Unsubscribe link in the bottom of each email, or through your
									Black &amp; Abroad Client Concierge account.
								</p>
								<p>
									Black &amp; Abroad retains passenger personal information for as long as is reasonably required, with consideration to the purpose for which it was collected or a
									related or ancillary purpose, and to our legal and regulatory obligations. Black &amp; Abroad deletes or otherwise destroys passenger personal information after a
									reasonable period of time. On occasion, you may have to provide your personal information to us again where it has been so deleted.
								</p>
								<h3>
									<strong>Complaints</strong>
								</h3>
								<p>
									If you have any complaints about Black &amp; Abroad' collection, use, disclosure, or dealing with your Personal Information, if you believe that Black &amp; Abroad'
									is in violation of or have any questions about Black &amp; Abroad compliance with applicable privacy legislation in relation to your Personal Information, please
									send a written letter or an e-mail to Black &amp; Abroad at info@weareblackandabroad.com or at the postal address in the section above. You may also contact Black
									&amp; Abroad's Client Concierge team at the information on the Black &amp; Abroad Journeys website with the subject "Privacy Complaint.”
								</p>
								<p>
									Black &amp; Abroad takes all complaints regarding your privacy and Personal Information very seriously. Upon receipt, we will review your complaint within twenty
									(20) business days and will provide a written response to the return address indicated in the complaint. If such response is not satisfactory to you, we invite you
									to reply, and we will make every endeavor to resolve your concerns.
								</p>
								<p>
									In the event you provide us with an Opt Out Notice you information will be segregated and not disclosed or used in any manner about which you have objected.
									However, the personal information we collected may be retained by us if necessary for us to comply with any law or legal directive.
								</p>
								<h3 id="cookie-policy">
									<strong>Website Policies and Cookies</strong>
								</h3>
								<p>
									<strong>What is a Cookie?</strong>
								</p>
								<p>
									A cookie is a small text file containing a unique identification number that a website sends to your computer's web browser. While you visit a particular website, a
									cookie may be used to track the activities of your browser as well as provide you with a consistent, more efficient experience.
								</p>
								<p>
									<strong>How do third-party vendors use Cookies?</strong>
								</p>
								<p>
									Third party vendors, including Google, show Black &amp; Abroad's ads across the internet. These third party vendors use cookies to serve ads based on a user's prior
									visits to the Black &amp; Abroad website. Neither Black &amp; Abroad, or any affiliated third party vendor, store any personal information in the cookie data. Users
									may opt out of third-party usage of cookies by visiting the Network Advertising Initiative opt-out page.
								</p>
								<p>
									<strong>How does Black &amp; Abroad use cookies?</strong>
								</p>
								<p>
									Cookies may be used to help speed up the booking process so you do not need to re-type the same information again and again. We may use cookies to measure website
									usage such as entry and exit points of visitors, how many people visit a certain section or page and details of searches performed and related information. If you
									are making a purchase we may also use cookies to keep track of the transaction from one page to the next. We do not store any personal information in the cookie, or
									use it for any other purpose.
								</p>
								<h3>
									<strong>Changes to this Privacy Policy</strong>
								</h3>
								<p>
									From time to time it may be necessary for Black &amp; Abroad to amend or make revisions to this Privacy Policy, which amendments may also apply to your personal
									information collected under a previous version of this Policy. Black &amp; Abroad reserves the right to change this Privacy Policy at any time and for any reason.
									If we make a change to the Policy, the revised version will be posted on our website.
								</p>
								<p className="text-align-right">
									<em>Last Updated: July 2017</em>
								</p>
							</motion.div>
						</div>

						<motion.div variants={MOTION_VARIANTS} initial="initial" animate="animate" exit="exit" custom={motionProps0} className="footer-container">
							<ContentFooter />
						</motion.div>
					</div>
				</div>
				{IS_PHONE ? <CloseButtonX style={{ top: MARGIN_VERTICAL, right: MARGIN_HORIZONTAL, marginTop: 22 }} lightTheme={true} /> : <CloseButtonX />}
			</motion.div>
		</>
	);
}

export default Privacy;
