import detect from '@jam3/detect';

export const IS_DEVELOPMENT = process.env.NODE_ENV !== 'production';
export const FORCE_LOGS = false; // FIXME: set false before going live (forces logs in production)
export const FORCE_GLOBAL_REFS = true; // FIXME: set false before going live (forces window.{ref} in production)
export const SKIP_INTRO = false; //IS_DEVELOPMENT; // FIXME: set false before going live
export const DEBUG_ROADS = false; //IS_DEVELOPMENT; // FIXME: set false before going live
export const DEBUG_COUNTRY_BOUNDARIES = false; //IS_DEVELOPMENT; // FIXME: set false before going live
export const DEBUG_USER_LOCATION = false; //IS_DEVELOPMENT; // FIXME: set false before going live
export const USE_TEST_USER_LOCATION = false; //IS_DEVELOPMENT;
export const TEST_USER_LOCATION = { lon: -74.0497197, lat: 40.6974669 };
export const MAPBOX_API_KEY = process.env.REACT_APP_MAPBOX_API_KEY;
export const GOOGLE_PLACES_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
export const PUBLIC_URL = process.env.PUBLIC_URL;
export const SHOW_HEIGHT_MAP = false; //IS_DEVELOPMENT;
export const SHOW_DEBUG_INFO = false; //IS_DEVELOPMENT;
export const IS_MOBILE = detect.device.mobile;
export const IS_TABLET = detect.device.tablet;
export const IS_PHONE = detect.device.phone;
export const IS_DESKTOP = detect.device.desktop;
export const LAYER_SCALE = 95.7;
export const MAX_ELEVATION_SCORE = 100;
export const CENTER_BOUNDS_SIZE = 600; // TODO: set this based on device?
export const CENTER_BOUNDS_STYLE = {
	width: `${CENTER_BOUNDS_SIZE}px`,
	height: `${CENTER_BOUNDS_SIZE}px`,
};
export const BACKGROUND_COLOR = 0xaf997e;
export const DEPTH_ENABLED = false;
export const MARGIN_VERTICAL = IS_PHONE ? 20 : 50;
export const MARGIN_HORIZONTAL = IS_PHONE ? 20 : 40;
export const MOBILE_LOCATION_DETAIL_OFFSET = [0, 200];
export const DESKTOP_LOCATION_DETAIL_OFFSET = [0, 180];
export const DEFAULT_DATASET_INDEX = 0;
export const USE_GEOCODING_FOR_CURRENT_LOCATION = true; // uses MapBox's Geocoding API to get current location
export const POI_RATING_FILTER = 4.5;
export const ADD_ICONS_TO_SEARCH_RESULTS = true;
export const MAP_SHADOW_DISTANCE = 100;

// export const API_BASE = 'https://ba-elevation-qa.fcbwork.com/api'; //staging
// export const API_BASE = 'https://www.blackelevationmap.com/api'; // prod
export const API_BASE = IS_DEVELOPMENT ? 'https://www.blackelevationmap.com/api' : '/api';

export const ENDPOINTS = {
	SCORES: `${API_BASE}/scores`,
	CITIES: `${API_BASE}/cities`,
	STATES: `${API_BASE}/states`,
	STATE_SCORE: `${API_BASE}/scores/{stateCode}`,
	POI_NATIONAL_LEVEL: `${API_BASE}/places-of-interest/national-level`,
	POI_STATE_LEVEL_1_GOOGLE: `${API_BASE}/places-of-interest/google`,
	POI_STATE_LEVEL_1_YELP: `${API_BASE}/places-of-interest/yelp`,
	POI_STATE_LEVEL_2: `${API_BASE}/places-of-interest/historical-museums`,
	POI_STATE: `${API_BASE}/places-of-interest/{stateCode}`,
	LOCATION: `${API_BASE}/place-of-interest`,
	GUIDES: `${API_BASE}/guides`,
	CONTRIBUTORS: `${API_BASE}/contributors`,
	SAVE_LOCATIONS: `${API_BASE}/save-locations`,
};

// mapbox zoom levels
export const ZOOM_LEVELS = {
	NATIONAL: 4,
	STATE: 7,
	CITY: 11,
	STREET: 13,
	MIN: 4,
	MAX: 16,
	MAP_MIN: 0,
	MAP_MAX: 19,
	LOCATIONS: 13,
	TERRAIN_CUTOFF: 9,
	LABELS_VISIBLE: 12,
	MARKERS_VISIBLE: 5.5,
	MOBILE_BUFFER: 2.5,
};

export const EVENTS = {
	MOVE_END: 'MOVE_END',
};

// national view bounds for desktop & mobile
export const desktopBounds = [
	[-131.56046280279105, 22.4302005950655], // [west, south]
	[-60.56864238492628, 52.396556405171395], // [east, north]
];

export const mobileBounds = [
	[-131.56046280279105, 22.4302005950655], // [west, south]
	[-60.56864238492628, 52.396556405171395], // [east, north]
];

export const notFoundBounds = [
	[-86.44040364484809, 13.560710399616411], // [west, south]
	[-45.61214051637154, 61.03701905546109], // [east, north]
];

// base mapbox config
export const MAP_CONFIG = {
	// style: 'mapbox://styles/fcbsixconsulting/ckngfk67l0ex917qrlrq482o4?optimize=true',
	style: 'mapbox://styles/fcbsixconsulting/ckzcwadi8000k15poxq78z8t7?optimize=true',
	antialias: false,
	center: [-96, 38],
	padding: -400,
	offset: [75, -35],
	pitch: 53,
	bearing: 21,
	minPitch: 4,
	maxPitch: 80,
	bearingSnap: 7,
	zoom: ZOOM_LEVELS.NATIONAL,
	minZoom: ZOOM_LEVELS.NATIONAL,
	maxZoom: ZOOM_LEVELS.MAP_MAX,
	bounds: IS_PHONE ? mobileBounds : desktopBounds,
	maxBounds: [
		[-251.67190497375142, -42.81297783577255], // [west, south]
		[51.502539124007455, 78.63692110862183], // [east, north]
	],
	renderWorldCopies: false,
	attributionControl: false,
	localFontFamily: 'VTCWilliam',
	logoPosition: 'bottom-right',
};

// mapbox config overrides for landing -> home transition
export const LANDING_MAP_CONFIG_OVERRIDES = {
	center: [-115.1028741753805, 41.2497727774109],
	pitch: 49.5,
	bearing: -45,
	zoom: 6.5,
	bounds: null,
	maxBounds: null,
};

// webgl terrain geometry config
export const ELEVATION_GEOMETRY_CONFIG = {
	coords: [-211.45651885585585, 71.34282557786639],
	width: 5000,
	height: 2500 * 1.025390625, // scalar to reajust for squished alphamap to include puerto rico
	tilesX: 8,
	tilesY: 4,
	meshResolutions: {
		high: [1500, 750],
		medium: [1500, 500],
		low: [500, 250],
	},
	meshElevationProps: {
		usa: { maxHeight: 100, dataHeightScalar: 1, minRadius: 25, maxRadius: 50, blurAmount: 6, zPosPower: 1.2 },
		businesses: {
			maxHeight: 150,
			dataHeightScalar: 1,
			minRadius: 15,
			maxRadius: 50,
			blurAmount: 4,
			zPosPower: 1.2,
			outputRender: false,
		},
		population: { maxHeight: 150, dataHeightScalar: 1, minRadius: 25, maxRadius: 50, blurAmount: 4, zPosPower: 1.2 },
		totalPopulation: { maxHeight: 150, dataHeightScalar: 1, minRadius: 25, maxRadius: 50, blurAmount: 4, zPosPower: 1.2 },
		historical: {
			maxHeight: 150,
			dataHeightScalar: 1,
			minRadius: 15,
			maxRadius: 50,
			blurAmount: 4,
			zPosPower: 1.2,
			outputRender: false,
		},
	},
	heightmapWidth: IS_MOBILE || IS_TABLET ? 2048 : 4096,
	heightmapHeight: IS_MOBILE || IS_TABLET ? 1024 : 2048,
	radiusScalar: IS_MOBILE || IS_TABLET ? 0.5 : 1,
	maxElevationHeight: 120,
	numElevationSteps: 21,
};

// location categories
export const POI_CATEGORIES = {
	CURATED: 'Curated',
	BEAUTY: 'Self-care',
	EAT: 'Eat',
	ENTERTAINMENT: 'Entertainment',
	FAVORITES: 'Favorites',
	FLEX: 'Flex',
	LEARN: 'Learn',
	KNOW: 'Know',
	SEE: 'See',
	SHOP: 'Shop',
	STAY: 'Stay',
	GENERAL_POI: 'General',
};

export const POI_LABELS = {
	[POI_CATEGORIES.CURATED]: POI_CATEGORIES.CURATED,
	[POI_CATEGORIES.BEAUTY]: POI_CATEGORIES.BEAUTY,
	[POI_CATEGORIES.EAT]: POI_CATEGORIES.EAT,
	[POI_CATEGORIES.ENTERTAINMENT]: 'Vibe',
	[POI_CATEGORIES.FAVORITES]: 'Saved',
	[POI_CATEGORIES.FLEX]: 'Fitness',
	[POI_CATEGORIES.KNOW]: POI_CATEGORIES.LEARN,
	[POI_CATEGORIES.LEARN]: POI_CATEGORIES.LEARN,
	[POI_CATEGORIES.SEE]: POI_CATEGORIES.SEE,
	[POI_CATEGORIES.SHOP]: POI_CATEGORIES.SHOP,
	[POI_CATEGORIES.STAY]: POI_CATEGORIES.STAY,
	[POI_CATEGORIES.GENERAL_POI]: 'Searched',
};

export const DATASET_TYPES = {
	NATURAL: { label: 'Natural\nTopography', datasetIndex: 0 },
	TOTAL_POPULATION: { label: 'Total Pop.\nDensity', datasetIndex: 4 },
	POPULATION: { label: 'Black Pop.\nDensity', datasetIndex: 2 },
	BUSINESSES: { label: 'Black-owned\nBusinesses', datasetIndex: 1 },
	HISTORICAL: { label: 'Historical\nMarkers', datasetIndex: 3 },
};

export const MAP_COLORS = {
	CITY_LABEL: '#aaaaaa',
	CITY_LABEL_HOVER: '#ffffff',
	OTHER_LABEL: '#7f8089',
	OTHER_LABEL_DIMMED: '#5e5f65',
	ROAD: '#1f1f22',
	TEST: '#ff0000',
};

export const TOOL_TIPS = {
	FLAG: 'Something off? Flag it.',
	SAVE: 'Save to your list.',
	REMOVE: 'Remove from your list.',
	RESET: 'Recenter map',
	ZOOM_IN: 'Zoom in',
	ZOOM_OUT: 'Zoom out',
};
