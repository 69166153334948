/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import { Debug } from 'js/utils/debug';
import { MOTION_PRESETS, MOTION_VARIANTS } from 'js/utils/motion';
import React, { memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import OptionsFooter from '../OptionsFooter/OptionsFooter';
import { useAppState } from 'js/hooks/app-state';
import { generatePath, Link, useHistory } from 'react-router-dom';
import routes from '../Router/routes';
import Image from '../Image/Image';
import { getFeaturesNearBy } from 'js/utils/map-data-utils';
import PoiCard from '../PoiCard/PoiCard';
import { sendData, validateEmail, wait } from 'js/utils/utils';
import CityStats from '../CityStats/CityStats';
import { ENDPOINTS, IS_PHONE, POI_RATING_FILTER } from 'js/config/constants';
import { STATS } from 'js/config/content';
import Video from '../Video/Video';
import { useFormFields } from 'js/hooks/use-form-fields';
import SaveLocationButton from '../SaveLocationButton/SaveLocationButton';
import SocialEmbed from '../SocialEmbed/SocialEmbed';
import AmbassadorLink from '../AmbassadorLink/AmbassadorLink';
import FormSubmitButton from '../FormSubmitButton/FormSubmitButton';

import './ContentDrawer.scss';

const motionProps0 = IS_PHONE ? MOTION_PRESETS.SLIDE_FROM_BOTTOM : MOTION_PRESETS.SLIDE_FROM_RIGHT;

export const CONTENT_TYPES = {
	NATIONAL_GUIDE: 0,
	CITY_GUIDE: 1,
	STANDARD_CITY: 2,
	SAVED_LOCATIONS: 3,
	SOCIAL_NATIONAL: 4,
	SOCIAL_STATE: 5,
	USER_LOCATION: 6,
};

function ContentDrawer(props) {
	const [minimized, setMinimized] = useState(false);
	const [showPeople, setShowPeople] = useState(false);
	const [notViewedGuides, setNotViewedGuides] = useState([]);
	// for adding padding to content when scrollbar is present, trigger re-render
	const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
	const [nearByFeatures, setNearByFeatures] = useState([]);
	const [nearByFeaturesIndex, setNearByFeaturesIndex] = useState(0);
	// const [nearByPeople, setNearByPeople] = useState([]);
	// const [nearByPeopleIndex, setNearByPeopleIndex] = useState(0);
	const [socialFeedIndex, setSocialFeedIndex] = useState(0);
	const [formMessage, setFormMessage] = useState(null);
	const [formSending, setFormSending] = useState(false);
	const [formSent, setFormSent] = useState(false);
	const [fields, handleFieldChange, clearFields] = useFormFields({
		optIn: false,
		email: '',
	});
	const [
		{
			nationalGuidesViewedStatus,
			cityGuidesViewedStatus,
			guideData,
			poiStateData,
			peopleData,
			currentPoi,
			savedLocations,
			mapLoaded,
		},
		{ setState },
	] = useAppState();
	const {
		contentType,
		content,
		poiIndex,
		personIndex,
		poiId,
		personId,
		poiLocation,
		onBack,
		onNext,
		reference,
		canRenderOtherGuides = true,
		goToOnClose,
	} = props;
	const history = useHistory();
	const contentContainerRef = useRef(null);
	const contentRef = useRef(null);
	const checkboxRef = useRef(null);
	const emailRef = useRef(null);

	useEffect(() => {
		const handleResize = () => {
			setDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);

		// handle on load
		wait(500).then(handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		// set this national guide to viewed
		if (content?.id && contentType === CONTENT_TYPES.NATIONAL_GUIDE) {
			const status = nationalGuidesViewedStatus;
			status[content.id].viewed = true;
			setState({ nationalGuidesViewedStatus: status });
			// find guides that haven't been viewed yet
			setNotViewedGuides(getNotViewedGuides(contentType, status));
		} else if (content?.id && contentType === CONTENT_TYPES.CITY_GUIDE) {
			const status = cityGuidesViewedStatus;
			status[content.id].viewed = true;
			setState({ cityGuidesViewedStatus: status });
			// find guides that haven't been viewed yet
			setNotViewedGuides(getNotViewedGuides(contentType, status));
		} else if (contentType === CONTENT_TYPES.STANDARD_CITY) {
			const notViewedNational = getNotViewedGuides(CONTENT_TYPES.NATIONAL_GUIDE, nationalGuidesViewedStatus);
			const notViewedCity = getNotViewedGuides(CONTENT_TYPES.CITY_GUIDE, cityGuidesViewedStatus);
			setNotViewedGuides([notViewedNational, notViewedCity]);
		}
	}, [content?.id]);

	// find nearby features to current POI
	useEffect(() => {
		if (!content || poiIndex === undefined) return;

		const { curatedPois } = content;
		const currentPOI = curatedPois[poiIndex];

		if (!currentPOI) return;

		setState({ currentPoi: currentPOI });
		setNearByFeaturesIndex(0);

		const {
			title,
			googlePlacesId,
			location: { lon, lat },
		} = currentPOI;

		let nearbyFeatures = getFeaturesNearBy(poiStateData, googlePlacesId, title, [lon, lat], 20, 9, POI_RATING_FILTER);
		// extend distance if no results
		if (nearByFeatures.length < 9) {
			nearbyFeatures = getFeaturesNearBy(poiStateData, googlePlacesId, title, [lon, lat], 40, 9, POI_RATING_FILTER - 0.5);
		}
		setNearByFeatures(nearbyFeatures);
	}, [poiIndex, content]);

	// // find nearby people to current person
	// useEffect(() => {
	// 	if (!content) return;

	// 	const { curatedPeople } = content;
	// 	const currentPerson = curatedPeople[personIndex];

	// 	if (!currentPerson) return;
	// setNearByPeopleIndex(0);

	// 	const {
	// 		id,
	// 		location: { lon, lat },
	// 	} = currentPerson;

	// 	const people = getPeopleNearBy(peopleData, id, [lon, lat], 250, 9);

	// 	Debug.log('people:', people, peopleData);
	// 	setNearByPeople(people);
	// }, [personIndex, content]);

	useEffect(() => {
		// Debug.log('poiId:', poiId);
		// Debug.log('personId:', personId);
		if (poiId) {
			setShowPeople(false);
		} else if (personId) {
			setShowPeople(true);
		}
	}, [poiId, personId]);

	const getNotViewedGuides = (contentType, guidesStatus) => {
		let count = 0;
		const guides = [];

		guideData.forEach((guideItemData) => {
			const { id, city } = guideItemData;

			if (!city && contentType === CONTENT_TYPES.NATIONAL_GUIDE) {
				if (id !== content?.id && !guidesStatus[id]?.viewed && count < 3) {
					guides.push(guideItemData);
					count++;
				}
			} else if (city && contentType === CONTENT_TYPES.CITY_GUIDE) {
				if (id !== content?.id && !guidesStatus[id]?.viewed && count < 3) {
					guides.push(guideItemData);
					count++;
				}
			}
		});
		return guides;
	};

	const renderHeaderNav = (showBackButton = true) => {
		let backFunc = () => history.goBack();

		// if (contentType === CONTENT_TYPES.STANDARD_CITY) {
		// 	backFunc = () => history.goBack();
		// } else {
		// 	const backRoute = contentType === CONTENT_TYPES.NATIONAL_GUIDE ? routes.nationalGuides.path : content?.id ? generatePath(routes.cityGuideLanding.path, { id: content?.id }) : null;
		// 	backFunc = () => history.push(backRoute);
		// }

		const closeBtnStyle = IS_PHONE
			? {
					position: 'relative',
					top: 0,
					right: 0,
					marginLeft: '30px',
			  }
			: { position: 'relative', top: 0, right: 0, marginLeft: '24px' };

		const closeBtnSmall = IS_PHONE;

		return (
			<div className="header-nav">
				<div className="item-container">
					<div className="option-container">
						{showBackButton && mapLoaded && (
							<div className="back-btn" onClick={backFunc}>
								<div className="back-btn-icon" />
								<div className="back-btn-label">Back</div>
							</div>
						)}
						{/* <div className="sound" /> */}
						{mapLoaded && <div className="minimize" onClick={() => setMinimized((prevValue) => !prevValue)} />}
						{mapLoaded && <CloseButtonX style={closeBtnStyle} small={closeBtnSmall} goToOnClose={goToOnClose} />}
						{!mapLoaded && (
							<CloseButtonX
								style={closeBtnStyle}
								small={closeBtnSmall}
								onClick={() => {
									history.push(routes.landing.path);
								}}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	const renderNationalGuideContent = () => {
		if (!content) return null;

		const { title, curatedPois } = content;
		const currentPOI = curatedPois[poiIndex];

		return (
			<div className="content-container" ref={contentContainerRef}>
				<AnimatePresence exitBeforeEnter>
					<motion.div
						variants={MOTION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={MOTION_PRESETS.FADE}
						className="content"
						ref={contentRef}
						key={currentPOI?.id}
						onAnimationComplete={() => {
							// scroll back to top when new POI is chosen
							if (contentContainerRef.current) contentContainerRef.current.scrollTop = 0;
						}}
					>
						<div className="image">
							{!currentPOI?.coverVideo && currentPOI?.coverImage && (
								<Image src={currentPOI?.coverImage?.url} alt={currentPOI?.title} maxWidth={550} />
							)}
							{currentPOI?.coverVideo && <Video src={currentPOI?.coverVideo?.url} />}
						</div>
						<div className="guide-title">{title}</div>
						<div className="poi-title">{currentPOI?.title}</div>
						<div className="poi-location">
							{currentPOI?.locationName}
							<SaveLocationButton id={currentPOI?.id?.toString()} isRelative={true} size="large" type="Curated" />
						</div>

						{poiLocation && (
							<div className="guide-location">
								{poiLocation?.city}, {poiLocation?.stateCode}
							</div>
						)}

						{currentPOI?.ambassadorName && (
							<AmbassadorLink
								ambassadorName={currentPOI.ambassadorName}
								ambassadorImage={currentPOI.ambassadorImage}
							/>
						)}
						<div className="poi-desc">
							<p>{currentPOI?.description}</p>
						</div>
						{currentPOI?.embeddedYouTubeIds?.length > 0 && renderYouTube(currentPOI.embeddedYouTubeIds)}
						{currentPOI?.embeddedSpotifyIds?.length > 0 && renderSpotify(currentPOI.embeddedSpotifyIds)}
						{notViewedGuides?.length > 0 && renderOtherGuides()}
						{false && renderContentFooter()}
					</motion.div>
				</AnimatePresence>
			</div>
		);
	};

	const renderCityGuideContent = () => {
		if (!content) return null;

		const {
			curatedPois,
			// curatedPeople,
			city: { cityName, cityState },
		} = content;
		const currentPOI = curatedPois[poiIndex];
		// const currentPerson = curatedPeople[personIndex];

		const mainContainerClass = classNames({
			'main-container': true,
			// 'show-people': showPeople,
		});

		// add padding when scrollbar is present
		const contentClass = classNames({
			content: true,
			scrolled: contentContainerRef.current?.scrollHeight > contentContainerRef.current?.clientHeight,
		});

		return (
			<div className={mainContainerClass}>
				<div className="content-container" ref={contentContainerRef}>
					<AnimatePresence exitBeforeEnter>
						<motion.div
							variants={MOTION_VARIANTS}
							initial="initial"
							animate="animate"
							exit="exit"
							custom={MOTION_PRESETS.FADE}
							className={contentClass}
							ref={contentRef}
							key={currentPOI?.id}
							onAnimationComplete={() => {
								// scroll back to top when new POI is chosen
								if (contentContainerRef.current) contentContainerRef.current.scrollTop = 0;
							}}
						>
							<div className="image">
								{!currentPOI?.coverVideo && currentPOI?.coverImage && (
									<Image src={currentPOI?.coverImage?.url} alt={currentPOI?.title} maxWidth={550} />
								)}
								{currentPOI?.coverVideo && <Video src={currentPOI?.coverVideo?.url} />}
							</div>
							<div className="poi-title">{currentPOI?.title}</div>
							<div className="poi-location">
								{currentPOI?.locationName}
								<SaveLocationButton
									id={currentPOI?.id?.toString()}
									isRelative={true}
									size="large"
									type="Curated"
								/>
							</div>
							<div className="guide-location">
								{cityName}, {cityState}
							</div>

							{currentPOI?.ambassadorName && (
								<AmbassadorLink
									ambassadorName={currentPOI.ambassadorName}
									ambassadorImage={currentPOI.ambassadorImage}
								/>
							)}
							<div className="poi-desc">
								<p>{currentPOI?.description}</p>
							</div>
							{currentPOI?.embeddedYouTubeIds?.length > 0 && renderYouTube(currentPOI.embeddedYouTubeIds)}
							{currentPOI?.embeddedSpotifyIds?.length > 0 && renderSpotify(currentPOI.embeddedSpotifyIds)}
							{nearByFeatures?.length >= 3 && renderMorePoisInThisArea()}
							{notViewedGuides?.length > 0 && renderOtherGuides(true)}
							{false && renderContentFooter()}
						</motion.div>
					</AnimatePresence>
				</div>
				{/* <div className="people-container">
					<AnimatePresence exitBeforeEnter>
						<motion.div
							variants={MOTION_VARIANTS}
							initial="initial"
							animate="animate"
							exit="exit"
							custom={MOTION_PRESETS.FADE}
							className={contentClass}
							ref={contentRef}
							key={currentPerson?.id}
							onAnimationComplete={() => {
								// scroll back to top when new POI is chosen
								if (contentContainerRef.current) contentContainerRef.current.scrollTop = 0;
							}}
						>
							<div className="image">
								<Image src={currentPerson?.coverImage?.url} alt={currentPerson?.title} maxWidth={550} />
							</div>
							<div className="header-container">
								<div className="guide-title">People</div>
								<div className="other-section-btn" onClick={() => setPoiParam()}>
									<div className="label">Stories</div>
									<div className="arrows" />
								</div>
							</div>
							<div className="poi-title">{currentPerson?.name}</div>
							<div className="guide-location">
								{cityName}, {cityState}
							</div>

							{currentPerson?.ambassadorName && (
								<div className="ambassador">
									{currentPerson?.ambassadorImage && (
										<img src={currentPerson.ambassadorImage.url} alt={currentPerson.ambassadorName} />
									)}
									By&nbsp;
									<a href="https://google.com" alt={currentPerson?.ambassadorName}>
										{currentPerson?.ambassadorName}
									</a>
								</div>
							)}
							<div className="poi-desc">{currentPerson?.bio}</div>
							{notViewedGuides?.length > 0 && renderOtherGuides(true)}
							{renderContentFooter()}
						</motion.div>
					</AnimatePresence>
				</div> */}
			</div>
		);
	};

	const renderStandardCityContent = () => {
		if (!content) return null;

		const {
			properties: { city, stateCode },
		} = content;

		return (
			<div className="content-container no-footer" ref={contentContainerRef}>
				<AnimatePresence exitBeforeEnter>
					<motion.div
						variants={MOTION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={MOTION_PRESETS.FADE}
						className="content"
						ref={contentRef}
						onAnimationComplete={() => {
							// scroll back to top when new POI is chosen
							if (contentContainerRef.current) contentContainerRef.current.scrollTop = 0;
						}}
					>
						{stateCode && (
							<div className="poi-title">
								{city}, {stateCode}
							</div>
						)}

						{!stateCode && <div className="poi-title">{city}</div>}

						{content?.properties?.b_o_businesses !== undefined && (
							<CityStats cityStats={content} descriptionLocation="bottom" defaultStat={3} />
						)}

						{content?.properties?.b_o_businesses === undefined && (
							<div className="standard-city-no-stats">
								<div className="body-content">{STATS.STANDARD_CITY_NO_STATS.body}</div>
								<div className="content-footer-option" onClick={() => history.push(routes.addToMap.path)}>
									<div className="content-footer-option-label">Add a point of interest</div>
									<div className="content-footer-option-arrows" />
								</div>
							</div>
						)}

						{notViewedGuides[0]?.length > 0 &&
							renderOtherGuides(false, 'TOUR THE CULTURE', notViewedGuides[0], CONTENT_TYPES.NATIONAL_GUIDE)}
						{notViewedGuides[1]?.length > 0 &&
							renderOtherGuides(true, 'Explore City Guides', notViewedGuides[1], CONTENT_TYPES.CITY_GUIDE)}
						{renderContentFooter()}
					</motion.div>
				</AnimatePresence>
			</div>
		);
	};

	const renderSavedLocations = () => {
		const validate = () => {
			// Debug.log('validate:', fields?.email, fields?.optIn);
			return validateEmail(fields?.email) && savedLocations.length;
		};

		const handleCheckboxChange = (e) => {
			e._valueKeyOverride = 'checked';
			handleFieldChange(e);
		};

		const handleSubmit = (e) => {
			Debug.log('handleSubmit:', fields?.email, fields?.optIn, savedLocations?.join(','));
			e.preventDefault();

			setFormSending(true);
			setFormMessage('Sending');

			sendData(ENDPOINTS.SAVE_LOCATIONS, {
				email: fields?.email,
				location_ids: savedLocations?.join(','),
				opt_in: fields?.optIn,
			})
				.then((response) => {
					Debug.log('Saved Locations Sent:', response);

					if (response?.feature[0]?.status === 'sent') {
						setFormMessage('Sent');
						setFormSent(true);

						wait(3000).then(() => {
							// setFormMessage(null);
							setFormSent(false);
							clearFields();
						});
					}

					setFormSending(false);
				})
				.catch((error) => {
					console.warn(error);

					setFormMessage('Something Went Wrong');
					setFormSent(true);

					wait(3000).then(() => {
						setFormSent(false);
					});

					wait(4000).then(() => {
						setFormMessage('');
					});

					setFormSending(false);
				});
		};

		const noSavedLocations = content.length === 0;

		const headerClass = classNames({
			'saved-locations-header': true,
			'no-saved': noSavedLocations,
		});

		return (
			<div className="content-container no-footer">
				<div className="content">
					<div className="saved-locations">
						<div className={headerClass}>
							<div className="saved-locations-title">My saved places</div>
							{!noSavedLocations && (
								<>
									<div className="saved-locations-message">
										<p>
											See a piece of The Culture you'd like to visit? Save it here and email it straight to
											your inbox to help plan your next trip. Just remember, this will only be saved to the
											device and browser you're currently using.
										</p>
									</div>
									<form onSubmit={handleSubmit}>
										<div className="opt-in-container">
											<input
												type="checkbox"
												id="optIn"
												checked={fields?.optIn}
												onChange={handleCheckboxChange}
												ref={checkboxRef}
											/>
											<div>
												<label htmlFor="optIn">
													Yes, I consent to receive communications from Black & Abroad&nbsp;&nbsp;
												</label>
												<Link to={routes.privacy.path}>Privacy Policy</Link>
											</div>
										</div>
										<br />
										<div className="email-container">
											<input
												type="email"
												id="email"
												value={fields?.email}
												placeholder="Your Email"
												onChange={handleFieldChange}
												required
												ref={emailRef}
											/>
											<FormSubmitButton
												handleSubmit={handleSubmit}
												formSending={formSending}
												formSent={formSent}
												validate={validate}
												formMessage={formMessage}
											/>
										</div>
									</form>
								</>
							)}
						</div>
						{!noSavedLocations && (
							<div className="location-list">
								{content.map((feature, index) => {
									return (
										<AnimatePresence exitBeforeEnter key={index}>
											<PoiCard key={feature?.p?.gid} feature={feature} delay={index * 0.1} />
										</AnimatePresence>
									);
								})}
							</div>
						)}
						{noSavedLocations && (
							<div className="no-saved-locations-message">
								<div className="no-saved--wrap">
									<div className="icon" />
									<div className="title">
										<p>Looks like there's nothing in your Saved Places!</p>
									</div>
									<div className="desc">
										<p>
											See a place on the map you'd like to visit?
											<br />
											Add it to your list by clicking the 'Saved' icon. When you're ready, you can send it
											to your email to help plan your next trip.
										</p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	const renderUserLocation = () => {
		const noLocations = content.length === 0;

		const headerClass = classNames({
			'saved-locations-header': true,
			'no-saved': noLocations,
		});

		return (
			<div className="content-container no-footer">
				<div className="content">
					<div className="saved-locations">
						<div className={headerClass}>
							<div className="saved-locations-title">Top Spots Near Me</div>
							{!noLocations && (
								<>
									<div className="saved-locations-message">
										<p>Discover the highest-rated Black-owned businesses, restaurants and more near you.</p>
									</div>
								</>
							)}
						</div>
						{!noLocations && (
							<div className="location-list">
								{content.map((feature, index) => {
									return (
										<AnimatePresence exitBeforeEnter key={index}>
											<PoiCard key={feature?.p?.gid} feature={feature} delay={index * 0.1} />
										</AnimatePresence>
									);
								})}
							</div>
						)}
						{noLocations && (
							<div className="no-saved-locations-message">
								<div className="no-saved--wrap">
									<div className="title">
										<p>
											Looks like there's nothing here.
											<br />
											{STATS.STANDARD_CITY_NO_STATS.body}
										</p>
									</div>
									<div className="content-footer-option" onClick={() => history.push(routes.addToMap.path)}>
										<div className="content-footer-option-label">Add a point of interest</div>
										<div className="content-footer-option-arrows" />
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	};

	const renderSocialNational = () => {
		// Generic Travel - 1e6daca6-85e0-40a4-84c1-ac8142d5b3d6
		// Generic BEM - 5039ad39-cbe2-4711-9e9c-6c10c7dee3a9
		// Generic Culture - 8f6ce0b5-d7bb-4915-a01a-794618381bea

		// curator-feed-ba-generictravel-layout
		// curator-feed-ba-genericbem-layout
		// curator-feed-ba-genericculture-layout

		const feeds = [
			{ feedId: '1e6daca6-85e0-40a4-84c1-ac8142d5b3d6', containerId: 'curator-feed-ba-generictravel-layout' },
			{ feedId: '5039ad39-cbe2-4711-9e9c-6c10c7dee3a9', containerId: 'curator-feed-ba-genericbem-layout' },
			{ feedId: '8f6ce0b5-d7bb-4915-a01a-794618381bea', containerId: 'curator-feed-ba-genericculture-layout' },
		];

		const optionClass1 = classNames({
			option: true,
			active: socialFeedIndex === 0,
		});

		const optionClass2 = classNames({
			option: true,
			active: socialFeedIndex === 1,
		});

		const optionClass3 = classNames({
			option: true,
			active: socialFeedIndex === 2,
		});

		return (
			<div className="content-container no-footer">
				<div className="content">
					<div className="social-header">
						<div className="title">Travel Feed</div>
						<div className="subtitle">
							What's the word
							<br />
							on social?
						</div>
						<div className="options">
							<div className={optionClass1} onClick={() => setSocialFeedIndex(0)}>
								Black Travel
							</div>
							<div className={optionClass2} onClick={() => setSocialFeedIndex(1)}>
								Black &amp; Abroad
							</div>
							<div className={optionClass3} onClick={() => setSocialFeedIndex(2)}>
								Black Culture &amp; Events
							</div>
						</div>
					</div>
					<div className="social-content">
						<SocialEmbed
							key={feeds[socialFeedIndex]?.feedId}
							feedId={feeds[socialFeedIndex]?.feedId}
							containerId={feeds[socialFeedIndex]?.containerId}
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderOtherGuides = (
		withBorder,
		mainTitle = 'Other Guides You Might Like',
		guideArrayOverride,
		contentTypeOverride
	) => {
		if (!canRenderOtherGuides) return null;

		const mainClass = classNames({
			'other-guides': true,
			'with-border': withBorder,
		});

		const itemsClass = classNames({
			'other-guides-items': true,
			full: notViewedGuides.length === 3,
		});

		const cType = contentTypeOverride !== undefined ? contentTypeOverride : contentType;

		return (
			<div className={mainClass}>
				<div className="other-guides-title">{mainTitle}</div>
				<div className={itemsClass}>
					{(guideArrayOverride || notViewedGuides).map((guideItemData, index) => {
						const { id, city, title, coverImage, coverVideo, guideDescription, curatedPois } = guideItemData;
						const guideId = id;
						const poiId = curatedPois?.length ? curatedPois[0].id : null;

						return (
							<div
								key={index}
								className="other-guide-item"
								onClick={() => {
									if (cType === CONTENT_TYPES.CITY_GUIDE) {
										history.push({
											pathname: generatePath(routes.cityGuideLanding.path, {
												id: guideId,
											}),
										});
									} else if (cType === CONTENT_TYPES.NATIONAL_GUIDE) {
										history.push({
											pathname: generatePath(routes.nationalGuide.path, {
												id: guideId,
											}),
											search: `?poiId=${poiId}`,
										});
									}
								}}
							>
								<div className="image">
									{coverImage && <Image src={coverImage?.url} alt={title} maxWidth={200} />}
									{!coverImage && coverVideo && <Video src={coverVideo?.url} />}
								</div>
								<div className="other-guide-item-content">
									{guideDescription && <div className="other-guide-item-desc">{guideDescription}</div>}
									{!city && <div className="other-guide-item-title">{title}</div>}
									{city?.cityName && city?.cityState && (
										<div className="other-guide-item-title">
											{city?.cityName}, {city?.cityState}
										</div>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	};

	const renderMorePoisInThisArea = () => {
		const features = [];
		for (let i = 0; i < nearByFeaturesIndex + 3; i++) {
			features.push(nearByFeatures[i]);
		}

		return (
			<div className="more-in-this-area">
				<div className="more-in-this-area-title">Explore More In This Area</div>
				<div className="more-in-this-area-items">
					{features.map((feature, index) => {
						return (
							<AnimatePresence exitBeforeEnter key={index}>
								<PoiCard key={feature?.p?.gid} feature={feature} delay={index * 0.1} />
							</AnimatePresence>
						);
					})}
				</div>
				{features.length < 9 && (
					<div
						className="more-in-this-area-load-more-btn"
						onClick={() =>
							setNearByFeaturesIndex((prevValue) => {
								const newValue = prevValue + 3 > 6 ? 0 : prevValue + 3;
								return newValue;
							})
						}
					>
						<div className="down-arrows" />
						<div className="label">Load More</div>
						<div className="down-arrows" />
					</div>
				)}
			</div>
		);
	};

	// const renderMorePeopleInThisArea = () => {
	// 	const people = [nearByPeople[nearByPeopleIndex + 0], nearByPeople[nearByPeopleIndex + 1], nearByPeople[nearByPeopleIndex + 2]];

	// 	return (
	// 		<div className="more-in-this-area">
	// 			<div className="more-in-this-area-title">Explore More People In This Area</div>
	// 			<div className="more-in-this-area-items">
	// 				<AnimatePresence exitBeforeEnter>
	// 					<PoiCard key={people[0]?.p?.gid} feature={people[0]} delay={0} />
	// 				</AnimatePresence>
	// 				<AnimatePresence exitBeforeEnter>
	// 					<PoiCard key={people[1]?.p?.gid} feature={people[1]} delay={0.1} />
	// 				</AnimatePresence>
	// 				<AnimatePresence exitBeforeEnter>
	// 					<PoiCard key={people[2]?.p?.gid} feature={people[2]} delay={0.2} />
	// 				</AnimatePresence>
	// 			</div>
	// 			<div
	// 				className="more-in-this-area-load-more-btn"
	// 				onClick={() =>
	// 					setNearByPeopleIndex((prevValue) => {
	// 						const newValue = prevValue + 3 > 6 ? 0 : prevValue + 3;
	// 						return newValue;
	// 					})
	// 				}
	// 			>
	// 				<div className="down-arrows" />
	// 				<div className="label">Load More</div>
	// 				<div className="down-arrows" />
	// 			</div>
	// 		</div>
	// 	);
	// };

	const renderYouTube = (mediaIds) => {
		return mediaIds.map((mediaId, index) => (
			<div className="media-embed" key={index}>
				<iframe
					src={`https://www.youtube.com/embed/${mediaId}?modestbranding=1&playsinline=1`}
					title={`youtube-${index}`}
					width="100%"
					height="300"
					frameBorder="0"
					allowFullScreen={true}
				></iframe>
			</div>
		));
	};

	const renderSpotify = (mediaIds) => {
		return mediaIds.map((mediaId, index) => (
			<div className="media-embed" key={index}>
				<iframe
					title={`spotify-${index}`}
					src={`https://open.spotify.com/embed/playlist/${mediaId}`}
					width="100%"
					height="280"
					frameBorder="0"
					allow="encrypted-media; fullscreen; picture-in-picture"
				></iframe>
			</div>
		));
	};

	const renderContentFooter = () => {
		return (
			<div className="content-footer">
				<div className="content-footer-option" onClick={() => history.push(routes.addToMap.path)}>
					<div className="content-footer-option-label">Add a point of interest</div>
					<div className="content-footer-option-arrows" />
				</div>
				{contentType !== CONTENT_TYPES.STANDARD_CITY && (
					<div className="content-footer-option" onClick={() => Debug.log('TODO: Report a problem link')}>
						<div className="content-footer-option-label">Report a problem</div>
						<div className="content-footer-option-arrows" />
					</div>
				)}
			</div>
		);
	};

	const renderFooterNav = () => {
		if (!content) return null;

		const { curatedPois, curatedPeople } = content;

		let currentEntity, directionsUrl, index, len;

		if (poiId) {
			// viewing poi
			index = curatedPois.length > 0 ? poiIndex + 1 : 0;
			len = curatedPois.length;
			currentEntity = curatedPois[poiIndex];

			if (currentEntity) {
				const {
					location: { lat, lon },
					googlePlacesId,
				} = currentEntity;

				const locationEncoded = encodeURI(`${lat},${lon}`);
				directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${locationEncoded}`;
				if (googlePlacesId) directionsUrl += `&destination_place_id=${googlePlacesId}`;
			}
		} else if (personId) {
			// viewing person
			index = curatedPeople.length > 0 ? personIndex + 1 : 0;
			len = curatedPeople.length;
			currentEntity = curatedPeople[personIndex];
		}

		const clsBack = classNames({
			'drawer-nav-btn': true,
			'drawer-nav-btn--back': true,
			'show-highlight': index === len,
		});
		const clsNext = classNames({
			'drawer-nav-btn': true,
			'drawer-nav-btn--next': true,
			'show-highlight': index !== len,
		});

		return (
			<AnimatePresence>
				{!minimized && (
					<motion.div
						variants={MOTION_VARIANTS}
						initial="initial"
						animate="animate"
						exit="exit"
						custom={MOTION_PRESETS.SLIDE_FROM_BOTTOM}
						className="footer-nav"
					>
						<OptionsFooter
							website={currentEntity?.website}
							source={currentEntity?.source}
							directions={directionsUrl}
							historicalMarker={currentEntity?.website?.includes('hmdb.org')}
							curated={true}
						/>

						<div className="poi-nav">
							<div className="index-indicator">
								{index}/{len}
							</div>

							<button
								className={clsBack}
								onClick={() => {
									onBack();
								}}
							>
								<div className="back-btn" />
								<span className="arrow-btn-label">BACK</span>
							</button>

							<button
								className={clsNext}
								onClick={() => {
									onNext();
								}}
							>
								<span className="arrow-btn-label">NEXT</span>
								<div className="next-btn" />
							</button>
						</div>
					</motion.div>
				)}
			</AnimatePresence>
		);
	};

	const className = classNames({
		ContentDrawer: true,
		minimized,
	});

	const renderBackButton =
		contentType !== CONTENT_TYPES.SAVED_LOCATIONS &&
		contentType !== CONTENT_TYPES.SOCIAL_NATIONAL &&
		contentType !== CONTENT_TYPES.SOCIAL_STATE;

	return (
		<>
			<motion.div
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={motionProps0}
				className={className}
				ref={reference}
			>
				<div className="content-drawer-container">
					{renderHeaderNav(renderBackButton)}
					{contentType === CONTENT_TYPES.NATIONAL_GUIDE && renderNationalGuideContent()}
					{contentType === CONTENT_TYPES.CITY_GUIDE && renderCityGuideContent()}
					{contentType === CONTENT_TYPES.STANDARD_CITY && renderStandardCityContent()}
					{contentType === CONTENT_TYPES.SAVED_LOCATIONS && renderSavedLocations()}
					{contentType === CONTENT_TYPES.SOCIAL_NATIONAL && renderSocialNational()}
					{contentType === CONTENT_TYPES.USER_LOCATION && renderUserLocation()}
					{contentType !== CONTENT_TYPES.STANDARD_CITY &&
						contentType !== CONTENT_TYPES.SAVED_LOCATIONS &&
						renderFooterNav()}
				</div>
			</motion.div>
		</>
	);
}

export default memo(ContentDrawer);
