/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { POI_CATEGORIES, TOOL_TIPS } from 'js/config/constants';
import { useAppState } from 'js/hooks/app-state';
import { getStateCode } from 'js/utils/map-data-utils';
import { BACK_DURATION, EASE_OUT, EASE_OUT_BACK, FADE_DURATION, MOTION_VARIANTS } from 'js/utils/motion';
import { formatPhoneNumber, replaceAll } from 'js/utils/utils';
import React, { memo, useEffect, useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import CloseButtonX from '../CloseButtonX/CloseButtonX';
import Image from '../Image/Image';
import OptionsFooter from '../OptionsFooter/OptionsFooter';
import routes from '../Router/routes';
import SaveLocationButton from '../SaveLocationButton/SaveLocationButton';
import StarRating from '../StarRating/StarRating';
import ToolTip from '../ToolTip/ToolTip';
import FlagModal from '../FlagModal/FlagModal';

import './LocationDetail.scss';

const elementMotionProps = {
	initial: {
		opacity: 0,
		transform: 'translateY(-50px)',
	},
	animate: {
		opacity: 1,
		transform: 'translateY(0px)',
		transition: { duration: BACK_DURATION, ease: EASE_OUT_BACK, delay: 0.5 },
	},
	exit: {
		opacity: 0,
		transition: { duration: FADE_DURATION, ease: EASE_OUT },
	},
};

function LocationDetail(props) {
	const { reference, marker, routesEnabled } = props;
	const [{ locationDetailData }, { setState }] = useAppState();
	const [placeId, setPlaceId] = useState(null);
	const [flagModalData, setFlagModalData] = useState(null);
	const [goToOnClose, setGoToOnClose] = useState(null);
	const locationRoute = useRouteMatch(routes.location);
	const history = useHistory();

	useEffect(() => {
		if (locationDetailData) {
			setFlagModalData(null);
			setState({ currentPoi: locationDetailData });
			// Debug.log('locationDetailData:', locationDetailData);
			if (locationDetailData.geometry) {
				const {
					geometry: { coordinates },
				} = locationDetailData;
				marker?.setLngLat(coordinates);

				setGoToOnClose(coordinates);
			} else {
				history.push(routes.notFound.path);
			}

			// use location names from feature rather than geocoding to make sure they match the current location
			if (locationDetailData?.city && locationDetailData?.state) {
				const { city, state } = locationDetailData;

				setState({ currentLocationOverride: { city, state } });

				return () => {
					setState({ currentLocationOverride: null });
				};
			}
		}
	}, [locationDetailData, marker]);

	useEffect(() => {
		if (locationRoute?.isExact && locationRoute?.params?.id) {
			setPlaceId(locationRoute.params.id);
		}
	}, [locationRoute?.params?.id]);

	const renderContent = () => {
		if (!locationDetailData) return null;

		const { type, name, address, city, state, zip, rating, reviewCount, website, phoneNumber, images, source, yelpId, businessId } =
			locationDetailData;

		const historicalMarker = website?.includes('hmdb.org');

		const isRestaurant = type === POI_CATEGORIES.EAT;

		const bgColorClass = type?.toLowerCase();

		const locationMarkerClass = [bgColorClass];

		const mainClass = classNames({
			content: true,
			restaurant: isRestaurant,
		},locationMarkerClass);

		const finalAddress = address ? replaceAll(address, '"', '') : '';
		const finalName = name ? replaceAll(name, '"', '') : '';

		const addressEncoded = encodeURI(`${finalAddress}, ${city}, ${state} ${zip}`);
		const directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${addressEncoded}&destination_place_id=${placeId}`;

		const reviewsClass = classNames({
			reviews: true,
			disabled: website === null,
		});

		let reviewLinkLabel = ' ';
		let isGoogle = false;

		if (source === 'yelp') {
			reviewLinkLabel = ' Yelp ';
		} else if (source === 'google') {
			reviewLinkLabel = ' Google ';
			isGoogle = true;
		}

		let reviewsLabel = reviewCount > 1 ? 'reviews' : 'review';
		const canRenderFlag = historicalMarker ? false : !isGoogle || (isGoogle && website);

		const handleFlag = (e) => {
			e.stopPropagation();
			e.preventDefault();

			let flagUrl;

			if (source === 'yelp' && yelpId) {
				flagUrl = `https://www.yelp.com/biz_attribute?biz_id=${yelpId}`;
			} else if (source === 'google') {
				flagUrl = website;
			}

			setFlagModalData({
				source,
				url: flagUrl,
			});
		};

		const handleCloseFlagModal = () => {
			setFlagModalData(null);
		};

		const flagClass = classNames({
			flag: true,
			active: flagModalData !== null,
		});

		const imageSource = images?.length ? images[0] : historicalMarker ? '/assets/images/img-hmdb.jpg' : null;
		const detailsClass = classNames({
			details: true,
			'hm-database': historicalMarker,
		});
		return (
			<motion.div
				key={businessId}
				variants={MOTION_VARIANTS}
				initial="initial"
				animate="animate"
				exit="exit"
				custom={elementMotionProps}
				className={mainClass}
			>
				{!historicalMarker && name && <div className="title">{finalName}</div>}

				{rating && (
					<div className="rating-reviews">
						<div className="poi-rating">{rating?.toFixed(1)} OUT OF 5</div>
						{false && <StarRating rating={rating || 0} />}
						{reviewCount && (
							<a className={reviewsClass} href={website} target="_blank" alt="reviews">
								{reviewCount}
								{reviewLinkLabel}
								{reviewsLabel}
							</a>
						)}
					</div>
				)}
				<div className={detailsClass}>
					<div className="text">
						{address && address !== ' ' && (
							<div className="address">
								{finalAddress}
								<br />
							</div>
						)}
						{historicalMarker && (
							<div className="address hm-database">
								<div className="title hm-database">
									{name ? (
										<>{finalName}</>
									) : (
										<>
											THE HISTORICAL
											<br />
											MARKER DATABASE
										</>
									)}
								</div>
								{website && (
									<>
										<a href={website} target="_blank" rel="noopener noreferrer">
											Learn more at HMdb.org
										</a>
									</>
								)}
							</div>
						)}
						{city && state && zip && (
							<div className="address">
								{city}, {getStateCode(state) || state}&nbsp;{zip}
							</div>
						)}
						{phoneNumber && <div className="phone">{formatPhoneNumber(phoneNumber) || phoneNumber}</div>}
					</div>
					{imageSource && (
						<div className="image">
							<Image key={imageSource} src={imageSource} alt={finalName} />
						</div>
					)}
				</div>
				{canRenderFlag && (
					<div className="flag--wrap" onClick={handleFlag}>
						<div className={flagClass} />
						<ToolTip className="tooltip" label={TOOL_TIPS.FLAG} />
					</div>
				)}
				{!canRenderFlag && <div className="no-flag" />}
				<OptionsFooter website={website} source={source} directions={directionsUrl} historicalMarker={historicalMarker} />
				<CloseButtonX style={{ top: 10, right: 20 }} small={true} goToOnClose={goToOnClose} goToOnCloseDelay={100} />
				<SaveLocationButton id={businessId?.toString()} type={type} />
				<div className="bottom-arrow" />
				{flagModalData && <FlagModal data={flagModalData} onClose={handleCloseFlagModal} />}
			</motion.div>
		);
	};

	return (
		<div className="LocationDetail" ref={reference}>
			<AnimatePresence>{routesEnabled && locationDetailData && locationRoute && renderContent()}</AnimatePresence>
		</div>
	);
}

export default memo(LocationDetail);
