import distance from '@turf/distance';
import { mapProps } from 'js/components/Map/Map';
import { DESKTOP_LOCATION_DETAIL_OFFSET, IS_MOBILE, IS_PHONE, MOBILE_LOCATION_DETAIL_OFFSET, POI_RATING_FILTER, ZOOM_LEVELS } from 'js/config/constants';
import CameraController from 'js/controllers/camera-controller';
import { Debug } from 'js/utils/debug';
import { FEATURE_PROPS, getFeatureProperty, getPoiData } from 'js/utils/map-data-utils';
import { clamp, deleteAllProperties, map as mapValue, randomRange, wait } from 'js/utils/utils';
import Supercluster from 'supercluster';
import ClusterMarker from './cluster-marker';
import LocationMarker from './location-marker';

export default class MarkerSet {
	constructor(props) {
		this.props = props;
		this.handleZoom = this.handleZoom.bind(this);
		this.update = this.update.bind(this);
		this.updateNonCluster = this.updateNonCluster.bind(this);
		this.handleMoveEnd = this.handleMoveEnd.bind(this);
		this.init();
	}

	createSuperCluster(minPointsOverride, radiusOverride) {
		const { data, clusterProps } = this.props;

		// Debug.log('createSuperCluster:', minPointsOverride, radiusOverride);

		const {
			minZoom = ZOOM_LEVELS.MAP_MIN,
			maxZoom = ZOOM_LEVELS.STREET,
			radius = 100,
			minPoints = 10,
			extent = 256,
			nodeSize = 64,
			// clusterThresholds = [],
		} = clusterProps;

		this.supercluster = new Supercluster({
			minZoom, // cluster levels are determined by min/max zoom levels
			maxZoom,
			minPoints: minPointsOverride || minPoints, // min points to include in a cluster before breaking them apart
			radius: radiusOverride || radius, // radius for adding markers to cluster
			extent, // this also has an affect on radius
			nodeSize,
			generateId: false,
			log: false,
		}).load(data);
	}

	init() {
		const { data, visibilityProps, visible, useCluster, clusterProps, deepLinkData, conditionValues } = this.props;
		const { map } = mapProps;

		this.enabled = false;
		this.visible = visible;
		this.conditionValues = conditionValues;
		this.frame = IS_MOBILE ? 30 : 15;
		this.frameThrottle = IS_MOBILE ? 30 : 15;

		// append deeplink data item if it doesn't exist in current dataset
		if (deepLinkData) {
			const deepLinkDataFound = data.find((item) => item?.p?.gid === deepLinkData.p.gid);
			if (deepLinkData && !deepLinkDataFound) {
				data.push(deepLinkData);
			}

			Debug.log('deepLinkDataFound:', this.getId(), deepLinkDataFound, deepLinkData);
		}

		this.markersOnScreen = {};

		if (useCluster) {
			this.createSuperCluster();

			const { clusterThresholds = [] } = clusterProps;
			this.currentClusterThreshold = clusterThresholds.length ? 0 : -1;

			// Debug.log('supercluster:', this.supercluster);

			map.on('move', this.update);
			map.on('moveend', this.handleMoveEnd);
			this.update(true);
		} else {
			this.allMarkers = [];
			const savedMarkers = [];

			const passed = this.testConditions();

			data.forEach((feature) => {
				// const { geometry } = feature;
				const properties = feature.p || feature.properties;
				const id = (properties.id || properties.gid).toString();
				const marker = this.getLocationMarker(feature);
				this.markersOnScreen[id] = marker;
				this.allMarkers.push(marker);

				const markerEl = marker.getElement();
				markerEl.style.opacity = 0;

				const isSaved = this.getMarkerIsSavedById(id);

				if (isSaved) {
					savedMarkers.push(marker);
				}

				if ((this.visible && passed) || isSaved) {
					wait(randomRange(100, 300)).then(() => {
						// Debug.log('init / adding curated marker:', isSaved);
						marker?.addTo(map);
						if (markerEl) markerEl.style.opacity = 1;
					});
				}
			});

			map.on('move', this.updateNonCluster);
			map.on('moveend', this.handleMoveEnd);
			this.updateNonCluster(true);
		}

		if (visibilityProps) {
			map.on('zoom', this.handleZoom);
		}
	}

	getMaxMarkerDistance() {
		// variable distance based on zoom level
		const { map } = mapProps;
		const zoom = map.getZoom();
		const min = IS_PHONE ? 7.5 : 15;
		const max = IS_PHONE ? 425 : 850;
		const maxDist = clamp(mapValue(zoom, ZOOM_LEVELS.STATE, ZOOM_LEVELS.STREET, max, min), min, max);

		return maxDist;
	}

	getSavedMarkers(activeMarkers) {
		const {
			map,
			reactProps: { savedLocations, poiStateData },
		} = mapProps;

		const savedLocationMarkers = {};
		savedLocations?.forEach((savedLocationId) => {
			const existsInDataset = this.getDataForBusinessId(savedLocationId) !== undefined;

			if (!this.markersOnScreen[savedLocationId] && !activeMarkers[savedLocationId] && existsInDataset) {
				const poiFeature = getPoiData(savedLocationId, poiStateData);
				// skip curated features
				if (poiFeature) {
					const marker = this.getLocationMarker(poiFeature);
					const markerEl = marker.getElement();
					markerEl.style.opacity = 1;
					marker.addTo(map);
					savedLocationMarkers[savedLocationId] = marker;
				}
			}
		});

		return savedLocationMarkers;
	}

	updateNonCluster(overrideThrottle) {
		if (this.supercluster) return;

		if (this.frame === this.frameThrottle || overrideThrottle === true) {
			this.frame = 0;

			const { map } = mapProps;
			const passed = this.testConditions();

			this.allMarkers?.forEach((marker) => {
				const isSaved = this.getMarkerIsSavedById(marker.getId());
				let valid = (this.visible && this.enabled) || isSaved;

				if (!isSaved) {
					const withinRange = this.checkIfMarkerIsWithinRange(marker.getLngLat().toArray());
					if (!withinRange || !passed) valid = false;
				}

				if (valid) {
					// Debug.log('updateNonCluster / adding curated marker:', isSaved);
					marker?.addTo(map);
					marker?.setHidden(false);
					marker?.showSelf();
				} else {
					if (this.getCanRemoveMarker(marker)) {
						marker?.setHidden(true).then(() => {
							marker?.remove();
						});
					}
				}
			});
		}

		this.frame++;
	}

	update(overrideThrottle) {
		if (!this.supercluster) return;

		if (!this.visible || !this.enabled) {
			const savedLocationMarkers = this.getSavedMarkers({});

			this.markersOnScreen = { ...this.markersOnScreen, ...savedLocationMarkers };
			return;
		}

		if (this.frame === this.frameThrottle || overrideThrottle === true) {
			this.frame = 0;

			const { map } = mapProps;

			const zoom = Math.round(map.getZoom());
			const bounds = map.getBounds();
			const clusters = this.supercluster.getClusters([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()], zoom);
			const newMarkers = {};

			clusters.forEach((feature) => {
				const { geometry } = feature;
				const properties = feature.p || feature.properties;
				let id,
					marker,
					valid = true,
					skipAdd = false;

				// don't show tons of markers off in the distance when zoomed in
				if (zoom >= ZOOM_LEVELS.STATE) {
					const withinRange = this.checkIfMarkerIsWithinRange(geometry.coordinates);
					if (!withinRange) valid = false;
				}

				if (valid) {
					if (properties.cluster) {
						id = properties.cluster_id.toString();
						marker = this.markersOnScreen[id];
						if (!marker) {
							marker = this.getClusterMarker(feature, this.supercluster);
						}
						newMarkers[id] = marker;
					} else {
						if (this.checkIfMarkerMeetsRatingForZoomRequirements(properties.r, ZOOM_LEVELS.STREET - 1, zoom)) {
							id = properties.gid.toString();
							// omit saved locations and append after so always visible
							if (!newMarkers[id] && !this.getMarkerIsSavedById(id)) {
								marker = this.markersOnScreen[id];
								if (!marker) {
									marker = this.getLocationMarker(feature);
								}
								newMarkers[id] = marker;
							} else {
								skipAdd = true;
							}
						} else {
							skipAdd = true;
						}
					}

					if (!this.markersOnScreen[id] && !skipAdd) {
						const markerEl = marker.getElement();
						markerEl.style.opacity = 0;
						marker.addTo(map);

						wait(randomRange(100, 300)).then(() => {
							if (markerEl) markerEl.style.opacity = 1;
						});
					}
				}
			});

			const activeMarkers = {};
			for (const id in this.markersOnScreen) {
				if (!newMarkers[id]) {
					const marker = this.markersOnScreen[id];
					if (this.getCanRemoveMarker(marker)) {
						this.fadeThenRemoveMarker(marker, 0, 250, 250);
					} else {
						activeMarkers[id] = marker;
					}
				}
			}

			// append all markers for saved locations so they're always visible
			const savedLocationMarkers = this.getSavedMarkers(activeMarkers);

			this.markersOnScreen = { ...newMarkers, ...activeMarkers, ...savedLocationMarkers };
		}

		this.frame++;
	}

	handleMoveEnd() {
		if (this.supercluster) {
			this.update(true);
		} else {
			this.updateNonCluster(true);
		}
	}

	handleZoom() {
		const { visibilityProps } = this.props;
		if (!visibilityProps) return;

		const passed = this.testConditions();

		if (passed) {
			if (!this.visible) this.setVisible(true);
		} else if (this.visible) {
			this.setVisible(false);
		}

		// update minPoints at different zooms to ensure markers aren't visible
		// at national view, but don't have too many clusters when zoomed in
		if (this.supercluster) {
			const {
				clusterProps: { clusterThresholds = [] },
			} = this.props;
			const { map } = mapProps;

			const zoom = map.getZoom();

			clusterThresholds.forEach(({ minPoints, radius, minZoom, maxZoom }, index) => {
				if (zoom >= minZoom && zoom <= maxZoom && this.currentClusterThreshold !== index) {
					this.currentClusterThreshold = index;
					this.createSuperCluster(minPoints, radius);
					this.update(true);
				}
			});
		} else {
			this.updateNonCluster(true);
		}
	}

	getId() {
		return this.props?.id;
	}

	getClusterMarker(feature, supercluster) {
		const { data } = this.props;
		const marker = new ClusterMarker(feature, supercluster, data.length);
		return marker;
	}

	getLocationMarker(feature) {
		const marker = new LocationMarker(feature);
		return marker;
	}

	getDataForBusinessId(businessId) {
		const { data } = this.props;
		const item = data.filter((dataItem) => getFeatureProperty(dataItem, FEATURE_PROPS.ID) === businessId.toString())[0];
		return item;
	}

	getMarkerForBusinessId(businessId) {
		for (const id in this.markersOnScreen) {
			const marker = this.markersOnScreen[id];
			if (marker?.userData?.id === businessId) {
				return marker;
			}
		}
	}

	setEnabled(enabled) {
		this.enabled = enabled;

		if (this.supercluster) {
			this.update(true);
		} else {
			if (this.enabled) {
				const passed = this.testConditions();

				if (passed) {
					if (!this.visible) this.setVisible(true);
				} else if (this.visible) {
					this.setVisible(false);
				}
			} else {
				this.setVisible(false);
			}
		}
	}

	setVisible(visible) {
		if (this.visible === visible) return;

		this.visible = visible;

		if (!this.supercluster) {
			if (this.visible) {
				this.show();
			} else {
				this.hide();
			}
		} else {
			if (this.visible) {
				this.update(true);
			} else {
				this.removeAll();
			}
		}
	}

	setConditionValues(conditionValues) {
		this.conditionValues = conditionValues;
		const passed = this.testConditions();

		if (passed) {
			if (!this.visible) this.setVisible(true);
		} else if (this.visible) {
			this.setVisible(false);
		}
	}

	// sets marker as selected
	setMarkerActive(businessId) {
		this.resetAllActiveStates();
		const marker = this.getMarkerForBusinessId(businessId);

		if (marker) {
			marker.getElement().classList.add('active');
			this.update(true);
			this.updateNonCluster(true);
			return true;
		}

		this.update(true);
		this.updateNonCluster(true);
		this.triggerMapZoom();
		return false;
	}

	setMarkerAsSaved(businessId, saved) {
		const marker = this.getMarkerForBusinessId(businessId);

		if (marker) {
			if (saved) {
				marker.getElement()?.classList.add('saved');
				marker.setIsSaved(true);
			} else {
				marker.getElement()?.classList.remove('saved');
				marker.setIsSaved(false);
			}
			this.update(true);
			this.updateNonCluster(true);
			return true;
		}

		return false;
	}

	testConditions() {
		let result = true;

		const { map } = mapProps;

		if (!map) return false;

		const zoom = map.getZoom();
		const {
			visibilityProps: { minZoom, maxZoom, conditions },
		} = this.props;

		// test zoom first
		result = zoom >= minZoom && zoom <= maxZoom;

		// test conditions
		if (result && conditions && this.conditionValues) {
			for (let i = 0; i < conditions.length; i++) {
				const c = conditions[i];
				const v = this.conditionValues[c.key];
				if (c.lessThan !== undefined) {
					result = v < c.lessThan;
				} else if (c.equals !== undefined) {
					result = v === c.equals;
				} else if (c.greaterThan !== undefined) {
					result = v > c.greaterThan;
				}

				if (!result) break; // return false if any fail
			}
		}

		return result;
	}

	checkIfMarkerIsWithinRange(coordinates) {
		const { map } = mapProps;
		const center = map.getCenter().toArray();
		const maxDist = this.getMaxMarkerDistance();
		const dist = distance(coordinates, center, {
			units: 'kilometers',
		});
		return dist <= maxDist;
	}

	checkIfMarkerMeetsRatingForZoomRequirements(markerRating = 0, maxZoom, currentZoom) {
		return currentZoom >= maxZoom || (currentZoom < maxZoom && markerRating >= POI_RATING_FILTER);
	}

	checkActiveAndSavedMarkers() {
		if (!this.supercluster) {
			const passed = this.testConditions();

			this.allMarkers.forEach((marker) => {
				if (!this.getCanRemoveMarker(marker)) {
					marker.setHidden(false);
					marker.showSelf();
					marker.addTo(mapProps?.map);
					this.setVisible(true);
				} else {
					let valid = true;
					const withinRange = this.checkIfMarkerIsWithinRange(marker.getLngLat().toArray());
					if (!withinRange || !passed) valid = false;

					if (!valid) {
						this.fadeThenRemoveMarker(marker, 0, 250, 250);
					}
				}
			});
		} else {
			this.update(true);
		}

		this.triggerMapZoom();
	}

	triggerMapZoom() {
		const { map } = mapProps;
		const trigger = () => {
			const currentZoom = map.getZoom();
			if (currentZoom < ZOOM_LEVELS.MARKERS_VISIBLE) {
				map?.setZoom(ZOOM_LEVELS.MAP_MAX);
				map?.setZoom(currentZoom);
			}
		};

		if (!map.isMoving()) {
			trigger();
		} else {
			map.once('moveend', trigger);
		}
	}

	// deselects all markers
	resetAllActiveStates() {
		for (const id in this.markersOnScreen) {
			const m = this.markersOnScreen[id];
			m.getElement().classList.remove('active');
		}
		this.update(true);
		this.updateNonCluster(true);
	}

	removeAll(instant) {
		const activeMarkers = {};

		if (instant) {
			for (const id in this.markersOnScreen) {
				const marker = this.markersOnScreen[id];
				if (this.getCanRemoveMarker(marker)) {
					marker.remove();
				} else {
					activeMarkers[id] = marker;
				}
			}
		} else {
			for (const id in this.markersOnScreen) {
				const marker = this.markersOnScreen[id];
				if (this.getCanRemoveMarker(marker)) {
					this.fadeThenRemoveMarker(marker, 0, 250, 250);
				} else {
					activeMarkers[id] = marker;
				}
			}
		}

		const savedLocationMarkers = this.getSavedMarkers(activeMarkers);
		this.markersOnScreen = { ...activeMarkers, ...savedLocationMarkers };
	}

	fadeThenRemoveMarker(marker, minDelay = 0, maxDelay = 500, fadeDuration = 300) {
		wait(randomRange(minDelay, maxDelay)).then(() => {
			marker.getElement().style.opacity = 0;
			wait(fadeDuration).then(() => marker.remove());
		});
	}

	// if a location is selected but the zoom level still has the marker
	// inside a cluster zoom down to the level needed to expand the cluster
	expandClusterIfActiveMarkerIsInside(businessId, zoomOverride = -1, setActiveAfter) {
		return new Promise((resolve) => {
			if (!this.supercluster) {
				resolve();
				return;
			}

			const { map } = mapProps;
			const currentZoom = map.getZoom();
			const zoom = Math.round(currentZoom);
			const bounds = map.getBounds();

			const clusters = this.supercluster.getClusters([bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()], zoom);

			let found = false;

			const traverse = (feature, parentClusterId, callback) => {
				const properties = feature.p || feature.properties;
				const { cluster_id } = properties;

				callback(properties, feature.geometry, parentClusterId);

				if (cluster_id) {
					const children = this.supercluster.getChildren(cluster_id);
					children.forEach((childFeature) => {
						traverse(childFeature, cluster_id, callback);
					});
				}
			};

			clusters.forEach((feature) => {
				traverse(feature, null, (properties, geometry, clusterId) => {
					if (properties.gid && properties.gid === businessId && clusterId) {
						const expansionZoom = this.supercluster.getClusterExpansionZoom(clusterId);
						found = true;

						CameraController.instance.setMapLayerVisibility('none');

						const zoom = Math.max(zoomOverride, expansionZoom);

						CameraController.instance
							.flyTo(geometry.coordinates, {
								zoom,
								offset: IS_MOBILE ? MOBILE_LOCATION_DETAIL_OFFSET : DESKTOP_LOCATION_DETAIL_OFFSET,
							})
							.then(() => {
								CameraController.instance.setMapLayerVisibility('visible');

								if (setActiveAfter) {
									this.setMarkerActive(businessId);
								}
								resolve();
							});
					}
				});
			});

			if (!found) {
				if (setActiveAfter) {
					this.setMarkerActive(businessId);
				}
				resolve();
			}
		});
	}

	getMarkerIsActive(marker) {
		return marker?.getElement()?.classList.contains('active');
	}

	getMarkerIsSaved(marker) {
		return this.getMarkerIsSavedById(marker?.getId());
	}

	getMarkerIsSavedById(id) {
		const {
			reactProps: { getLocationIsSaved },
		} = mapProps;

		return getLocationIsSaved(id);
	}

	getCanRemoveMarker(marker) {
		const result = !this.getMarkerIsActive(marker) && !this.getMarkerIsSaved(marker);
		return result;
	}

	show() {
		const { useStaggeredTransition, showDelay = 0 } = this.props;
		const { map } = mapProps;
		const passed = this.testConditions();

		Object.values(this.markersOnScreen).forEach((marker, index) => {
			let valid = true;
			const isSaved = this.getMarkerIsSavedById(marker.getId());

			if (!isSaved) {
				const withinRange = this.checkIfMarkerIsWithinRange(marker.getLngLat().toArray());
				if (!withinRange || !passed) valid = false;
			}

			if (valid) {
				const delay = showDelay + (useStaggeredTransition ? randomRange(0, 300) : 0);
				marker?.showSelf(delay / 1000);

				wait(delay).then(() => {
					marker?.addTo(map);
					const markerEl = marker?.getElement();
					if (markerEl) {
						markerEl.style.opacity = 1;
					}
				});
			}
		});
	}

	hide() {
		const { useStaggeredTransition, hideDelay = 0 } = this.props;

		Object.values(this.markersOnScreen).forEach((marker) => {
			const canRemove = this.getCanRemoveMarker(marker);

			if (canRemove) {
				const delay = hideDelay + (useStaggeredTransition ? randomRange(0, 300) : 0);
				marker?.hideSelf(delay / 1000).then(() => marker.remove());
			}
		});
	}

	dispose() {
		const { map } = mapProps;
		map?.off('move', this.update);
		map?.off('move', this.updateNonCluster);
		map?.off('moveend', this.handleMoveEnd);
		map?.off('moveend', this.handleMoveEnd);
		map?.off('zoom', this.handleZoom);

		for (const id in this.markersOnScreen) {
			this.markersOnScreen[id]?.dispose();
		}

		this.removeAll();

		deleteAllProperties(this);
	}
}
